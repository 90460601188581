import { useContext, useState } from 'react';
import { useEffect } from 'react';
import styles from './login.module.scss';
import globalStyles from '../../styles/global.module.scss';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { AuthService } from '../../service/AuthServiceV2';
import { Alert, Button, Col, Form, Input, Modal, Row, Upload } from 'antd';
import { ILoginInputs } from '../../types/login';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { accountSelector, forgotPassword, login, resetPassword } from '../../store/account';
import { notifyError, notifySuccess } from '../../helpers/notify';

import { Header } from 'antd/es/layout/layout';
import uniteamLogo from '../../resources/images/uniteamLogo.svg';
import { languageSelector } from '../../store/language';
import { getLocalString } from '../../helpers/language';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const [isForgotModalOpen, setIsForgotModalOpen] = useState<boolean>(false);
  const [isNewPasswordModalOpen, setIsNewPasswordModalOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [formForgot] = Form.useForm();
  const [formReset] = Form.useForm();
  const [isMailSent, setIsMailSent] = useState<boolean>(false);
  useEffect(() => {
    if (token) {
      setIsNewPasswordModalOpen(true);
    }
  }, [token]);

  const onFinish = (values: ILoginInputs) => {
    dispatch(
      login({
        email: values.email,
        password: values.password,
        onSuccess: (data: any) => {
          console.log('Login SuccessFull Data');
          navigate('/dashboard');
        },
        onError: (error: any) => notifyError(error),
      })
    );
  };

  const onFinishForgot = async (values: any) => {
    dispatch(
      forgotPassword({
        email: values.email,
        onSuccess: (data: any) => {
          setIsMailSent(true);
        },
      })
    );
  };

  const onFinishFailedForgot = (errorInfo: any) => {
    notifyError(getLocalString(translations, 'Apply.NotifyError.PleaseCheck'));
  };

  const onFinishReset = (values: any) => {
    token &&
      dispatch(
        resetPassword({
          token: token,
          password_confirmation: values.confirm,
          password: values.password,
          onSuccess: (data: any) => {
            setIsNewPasswordModalOpen(false);
            notifySuccess(getLocalString(translations, 'Login.ResetPasswordModal.Message.Changed'));
            navigate('/');
          },
          onError: (error: any) => notifyError(error),
        })
      );
  };

  const passwordRules = [
    {
      required: true,
      message: getLocalString(translations, 'Login.NewPasswordModal.Message.PleaseEnterPassword'),
    },
    {
      min: 8,
      message: getLocalString(translations, 'Login.NewPasswordModal.Message.PasswordLong'),
    },
    {
      pattern: new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/),
      message: getLocalString(translations, 'Login.NewPasswordModal.Message.RequireChar'),
    },
  ];

  return (
    <div
      style={{
        background: '#FAF5FF',
        minHeight: '100vh',
      }}
    >
      <Row>
        <img style={{ margin: '55px 78px' }} src={uniteamLogo} alt='' />
      </Row>
      <div
        style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', height: '80vh' }}
      >
        <Row>
          <Col xs={2} sm={4} md={6} lg={6} xl={6}></Col>
          <Col xs={20} sm={16} md={12} lg={12} xl={12}>
            <Row
              style={{
                borderRadius: '24px',
                background: '#FAF5FF',
                width: '100%',
                padding: '48px',
                boxShadow: '0px 16px 72px 0px rgba(6, 28, 61, 0.08)',
                transform: 'translate(0%, -5%)',
              }}
            >
              <Row style={{ width: '100%' }}>
                <Row
                  style={{ color: '#555', width: '100%', marginBottom: '16px' }}
                  justify={'center'}
                  className={`${globalStyles.headline_m_medium} `}
                >
                  {getLocalString(translations, 'Login.Uniteam.Login.Text')}
                </Row>
                <Row justify={'center'} style={{ color: '#555', width: '100%' }}>
                  {getLocalString(translations, 'Login.Text1')}
                </Row>
                <Row
                  justify={'center'}
                  style={{ color: '#555', width: '100%', marginBottom: '16px' }}
                  className={`${globalStyles.body_r_medium} `}
                >
                  {getLocalString(translations, 'Login.Text2')}
                </Row>
              </Row>
              <Row style={{ width: '100%' }}>
                <Form
                  name='basic'
                  style={{ width: '100%' }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  autoComplete='off'
                  layout='vertical'
                  requiredMark={false}
                >
                  <Form.Item<ILoginInputs>
                    style={{ width: '100%' }}
                    label={getLocalString(translations, 'Login.Email')}
                    name='email'
                    // initialValue={'admin@admin.com'}
                    rules={[
                      {
                        type: 'email',
                        message: getLocalString(translations, 'Login.EmailNotValid'),
                      },
                      {
                        required: true,
                        message: getLocalString(translations, 'Login.InputYourEMail'),
                      },
                    ]}
                  >
                    <Input maxLength={35} style={{ width: '100%' }} size='large' />
                  </Form.Item>
                  <Form.Item<ILoginInputs>
                    style={{ width: '100%', marginBottom: '24px' }}
                    label={getLocalString(translations, 'Login.Password')}
                    name='password'
                    // initialValue={'password'}
                    rules={[
                      {
                        required: true,
                        message: getLocalString(translations, 'Login.InputYourPassword'),
                      },
                      {
                        min: 8,
                        message: getLocalString(translations, 'Login.PasswordNotLong'),
                      },
                    ]}
                  >
                    <Input.Password maxLength={35} style={{ width: '100%' }} size='large' />
                  </Form.Item>
                  <Button
                    style={{ width: '100%', marginBottom: '16px', color: '#555' }}
                    type='link'
                    size='small'
                    onClick={() => setIsForgotModalOpen(true)}
                  >
                    {getLocalString(translations, 'Login.ForgotPassword')} &nbsp;?
                  </Button>

                  <Form.Item style={{ width: '100%', marginBottom: '0px' }}>
                    <Button
                      // style={{ width: '100%', borderRadius: '0px' }}
                      style={{ width: '100%' }}
                      type='primary'
                      size='large'
                      htmlType='submit'
                    >
                      {getLocalString(translations, 'Login.Submit')} <ArrowRightOutlined />
                    </Button>
                  </Form.Item>
                </Form>
              </Row>
            </Row>
          </Col>
          <Col xs={2} sm={4} md={6} lg={6} xl={6}></Col>

          <Modal
            open={isForgotModalOpen}
            footer={null}
            title={getLocalString(translations, 'Login.ForgotPasswordModal.Title')}
            onCancel={() => {
              setIsForgotModalOpen(false);
              setIsMailSent(false);
              formForgot.setFieldsValue({ email: '' }); //
            }}
          >
            {!isMailSent
              ? getLocalString(translations, 'Login.ForgotPasswordModal.PleaseEnterMail')
              : getLocalString(translations, 'Login.ForgotPasswordModal.CheckMail')}
            {!isMailSent && (
              <Form
                form={formForgot}
                onFinish={onFinishForgot}
                onFinishFailed={onFinishFailedForgot}
              >
                <Row style={{ width: '100%', marginTop: '16px' }}>
                  <Row style={{ width: '100%' }}>
                    <Form.Item
                      style={{ width: '100%' }}
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: getLocalString(translations, 'Apply.Message.Mail'),
                        },
                        {
                          type: 'email',
                          message: getLocalString(translations, 'Apply.Message.Mail2'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                              return Promise.reject(
                                new Error(getLocalString(translations, 'Apply.Error.Mail'))
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Row style={{ width: '100%', gap: '5px' }}>
                        <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                          {getLocalString(translations, 'Apply.EmailAddress')}
                        </Row>
                        <Input
                          maxLength={35}
                          onChange={(e) => {
                            formForgot.setFieldsValue({ email: e.currentTarget.value.trim() });
                          }}
                          placeholder={getLocalString(translations, 'Apply.Placeholder.Email')}
                          size='large'
                        />
                      </Row>
                    </Form.Item>
                  </Row>
                </Row>

                <Row style={{ width: '100%' }} justify={'center'}>
                  <Button
                    // loading={isOn}
                    size='large'
                    type='primary'
                    onClick={() => formForgot.submit()}
                  >
                    {getLocalString(
                      translations,
                      'Login.ForgotPasswordModal.SendResetPasswordMail'
                    )}
                  </Button>
                </Row>
              </Form>
            )}
          </Modal>

          <Modal
            open={isNewPasswordModalOpen}
            title={getLocalString(translations, 'Login.NewPasswordModal.Title')}
            closable={false}
            footer={null}
          >
            <Form form={formReset} onFinish={onFinishReset} layout='vertical'>
              <Form.Item
                name='password'
                label={getLocalString(translations, 'Login.NewPasswordModal.NewPassword')}
                rules={passwordRules}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name='confirm'
                label={getLocalString(translations, 'Login.NewPasswordModal.NewPasswordConfirm')}
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: getLocalString(translations, 'Login.NewPasswordModal.EnterPassword'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(getLocalString(translations, 'Login.NewPasswordModal.NotMatch'))
                      );
                    },
                  }),
                  ...passwordRules, // Yeni şifrenin tekrarı için aynı doğrulama kurallarını uygula
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit' block>
                  {getLocalString(translations, 'Login.NewPasswordModal.ResetPassword')}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </Row>
      </div>
    </div>
  );
};

export default Login;
