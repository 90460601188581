import React from 'react';
import { Route, Outlet } from 'react-router-dom';
// import Sidebar from '../../components/SideBarN';

import styles from './index.module.scss';
import ApplyLayout from '../../layouts/ApplyLayout';

const ApplyPages: React.FC = () => {
  return (
    <div className={styles.main_content_container}>
      <ApplyLayout>
        <Outlet />
      </ApplyLayout>
    </div>
  );
};

export default ApplyPages;
