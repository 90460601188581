import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Card, Input, DatePicker } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import globalStyles from '../../../styles/global.module.scss';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import { constantsSelector } from '../../../store/constants';
import { apiURL } from '../../../service';
import { createDepartment, getDepartmentDetail, updateDepartment } from '../../../store/university';
import { useNavigate, useParams } from 'react-router-dom';
import isNumeric from '../../../helpers/isNumeric';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { notifySuccess } from '../../../helpers/notify';
import { StyleInfo } from 'antd/es/theme/util/genComponentStyleHook';
import moment from 'moment';
import { createFaculty, getFacultyDetail, updateFaculty } from '../../../store/faculty';

const { Option } = Select;

const AddUpdateFaculty: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { universityList, departmentList, programList, languageList } =
    useAppSelector(constantsSelector);

  const [universityId, setUniversityId] = useState<string>('');
  const [facultyName, setFacultyName] = useState<string>('');

  const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(true);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true);
  const [facultyInfo, setFacultyInfo] = useState<any>();

  useEffect(() => {
    if (id) {
      dispatch(
        getFacultyDetail({
          id: id,
          onSuccess: (data: any) => {
            setFacultyName(data.data.name);
            setUniversityId(data.data.university.id);
            setFacultyInfo(data.data);
          },
        })
      );
    }
  }, [id]);

  useEffect(() => {
    // Tüm alanların dolu olup olmadığını kontrol edin
    const isCreateDisabled = !universityId || !facultyName;

    // Eğer departmentInfo varsa ve alanlar değiştiyse düzenleme için kontrol edin
    if (facultyInfo?.name) {
      const isEditDisabled = id ? facultyName !== facultyInfo.name && facultyName.length > 0 : true; // departmentInfo yoksa düzenleme yapılamaz

      setIsEditDisabled(!isEditDisabled);
    }
    // State'leri güncelleyin
    setIsCreateDisabled(isCreateDisabled);
  }, [universityId, facultyName]);

  const renderUniversityOptions = useCallback(
    () => universityList.map((el: any) => ({ value: el.id, label: el.name })),
    [universityList]
  );

  return (
    <PageContainer
      pageName={
        id
          ? getLocalString(translations, 'AddUpdateFaculty.EditFaculty')
          : getLocalString(translations, 'AddUpdateFaculty.CreateFaculty')
      }
      backUrl={'/faculty-list'}
    >
      <Row style={{ width: '100%' }}>
        <Col offset={6} span={12}>
          <Row gutter={[16, 16]}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateFaculty.University')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={universityId || undefined}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateFaculty.Placeholder.SelectUniversity'
                )}
                disabled={!!id}
                size='large'
                onChange={(value) => {
                  setUniversityId(value);
                }}
                showSearch
                filterOption={filterOption}
                options={renderUniversityOptions()}
              ></Select>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateFaculty.FacultyName')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateFaculty.Placeholder.EnterFacultyName'
                )}
                value={facultyName || undefined}
                onChange={(e) => {
                  setFacultyName(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>
          </Row>

          {id && (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                onClick={() => {
                  dispatch(
                    updateFaculty({
                      university_id: universityId,
                      id: id,
                      name: facultyName,

                      onSuccess: (data: any) => {
                        notifySuccess(
                          getLocalString(
                            translations,
                            'AddUpdateFaculty.NotifySuccess.FacultyUpdated'
                          )
                        );
                        navigate('/faculty-list');
                      },
                    })
                  );
                }}
                size='large'
                type='primary'
                disabled={isEditDisabled && !isUpdate}
              >
                {getLocalString(translations, 'AddUpdateFaculty.EditFaculty')}
              </Button>
            </Row>
          )}

          {!id && (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                onClick={() => {
                  dispatch(
                    createFaculty({
                      university_id: universityId,
                      name: facultyName,
                      onSuccess: (data: any) => {
                        notifySuccess(
                          getLocalString(
                            translations,
                            'AddUpdateFaculty.NotifySuccess.FacultyCreated'
                          )
                        );
                        navigate('/faculty-list');
                      },
                    })
                  );
                }}
                size='large'
                type='primary'
                disabled={isCreateDisabled}
                style={!isCreate ? { display: 'none' } : {}}
              >
                {getLocalString(translations, 'AddUpdateFaculty.CreateFaculty')}
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </PageContainer>
  );
};

export default AddUpdateFaculty;
