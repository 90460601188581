import { BellOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Table, Button, Space, Tag, Dropdown, Badge, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import styles from './uniteamHeaderNotificationDropdown.module.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { updateNotificationRead } from '../../store/notification';
import { notifyError } from '../../helpers/notify';
import { getLocalString } from '../../helpers/language';

import { languageSelector } from '../../store/language';
import { constantsSelector, getNotifications } from '../../store/constants';
import { INotificationResults } from '../../types/constants';

// const UniteamHeaderNotificationDropdown: React.FC<INotificationProps> = ({ data, pending }) => {
const UniteamHeaderNotificationDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { notifications } = useAppSelector(constantsSelector);
  const [data, setData] = useState<INotificationResults[]>([]);
  const [previewPdf, setPreviewPdf] = useState(undefined);
  const [previewOpen, setPreviewOpen] = useState(false);
  const types = [
    {
      value: 0,
      label: 'All Types',
      color: '',
    },
    {
      value: 1,
      label: getLocalString(translations, 'Notifications.YouTagged'),
      color: 'gold',
    },
    {
      value: 2,
      label: getLocalString(translations, 'Notifications.FileAdded'),
      color: 'green',
    },
    {
      value: 3,
      label: getLocalString(translations, 'Notifications.General'),
      color: 'blue',
    },
    // {
    //   value: 4,
    //   label: 'Inventory',
    //   color: 'purple',
    // },
    // {
    //   value: 5,
    //   label: 'Campaign',
    //   color: 'red',
    // },
    // {
    //   value: 6,
    //   label: 'Account',
    //   color: 'volcano',
    // },
    // {
    //   value: 99,
    //   label: 'Deleted',
    //   color: '#f50',
    // },
  ];
  useEffect(() => {
    if (notifications) {
      setData(notifications.data);
    }
  }, [notifications]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getNotifications());
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const onRowClick = (record: any) => {
    dispatch(
      updateNotificationRead({
        notifyIds: [record.id],
        onError: (err) => {
          notifyError(err);
        },
        onSuccess: (data: any) => {
          dispatch(getNotifications());
        },
      })
    );

    switch (record.extra.model) {
      case 1:
        navigate('/applies-detail/' + record.extra.model_id);
        break;
      case 2:
        navigate('/update-student/' + record.extra.model_id);
        break;
      case 3:
        navigate('/update-interview/' + record.extra.model_id);
        break;
      default:
        break;
    }
  };

  const columns: ColumnsType<INotificationResults> = [
    {
      dataIndex: '',
      render: (record: INotificationResults) => (
        <Space direction='vertical'>
          <Space direction='vertical'>
            <div style={{ fontSize: 12, fontWeight: record.is_read ? 400 : 600 }}>
              {record.title}
            </div>
            {record.message !== '' && (
              <div style={{ fontSize: 12 }}>
                {getLocalString(translations, 'Notifications.Message')}: {record.message}
              </div>
            )}
            {record.name !== '' && <div style={{ fontSize: 12 }}>{record.name}</div>}
          </Space>
          <Tag color={types[record.type].color}>{types[record.type].label}</Tag>
        </Space>
      ),
    },
    {
      dataIndex: '',
      align: 'right',
      width: 185,
      render: (record) => (
        <Space size={'middle'} style={{ marginRight: 10 }}>
          <div>{moment(record.date).format('YYYY-MM-DD HH:MM')}</div>
          <MailOutlined style={{ color: record.isRead ? '#B3B9C1' : '#ED722E' }} />
        </Space>
      ),
    },
  ];

  const expandedColumns: ColumnsType<any> = [
    {
      dataIndex: 'title',
      key: 'title',
      title: 'Title',
    },
    {
      dataIndex: 'message',
      key: 'message',
      title: 'Message',
      align: 'right',
    },
    {
      dataIndex: 'date',
      key: 'date',
      title: 'Date',
      align: 'right',
      width: 130,
    },
  ];

  return (
    <>
      <Tooltip
        title={getLocalString(translations, 'Notifications.Notifications')}
        showArrow={false}
        placement='bottom'
      >
        <Dropdown
          trigger={['click']}
          placement='bottomRight'
          arrow
          dropdownRender={() => (
            <div className={styles.account__notification__dropdown}>
              <div className={styles.account__notification__dropdown_head}>
                <div className={styles.account__notification__dropdown_head_title}>
                  {getLocalString(translations, 'Notifications.Notifications')} (
                  {data.filter((item) => !item.is_read).length})
                </div>
                {/* <Button icon={<SettingOutlined />} size='small' type='text' /> */}
              </div>
              <div className={styles.account__notification__dropdown_body}>
                <Table
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={data}
                  showHeader={false}
                  pagination={false}
                  rowClassName={(row) => (row.is_read ? styles.isRead : styles.isNotRead)}
                  className={'notificationTable'}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        onRowClick(record);
                      },
                    };
                  }}
                  // expandable={{
                  //   expandedRowRender: (record) => (
                  //     <Table
                  //       bordered={false}
                  //       rowKey={(data, i) => data['title']}
                  //       dataSource={record.expandableData?.products}
                  //       columns={expandedColumns}
                  //       pagination={false}
                  //       size={'small'}
                  //       className={'notificationTableExpanded'}
                  //       footer={() => (
                  //         <div
                  //           style={{
                  //             textAlign: 'right',
                  //             fontSize: 12,
                  //             fontWeight: 600,
                  //           }}
                  //         >
                  //           {record.expandableData?.totalCurrency}
                  //           {record.expandableData?.totalPrice}
                  //         </div>
                  //       )}
                  //     />
                  //   ),
                  //   rowExpandable: (data) => data.expandableData !== null,
                  //   onExpandedRowsChange: (expandedRows) => {
                  //     const allTr = window.document.querySelectorAll('tr');
                  //     allTr.forEach((tr) => tr.classList.remove('active'));
                  //     expandedRows.map((item, i) => {
                  //       const tr = window.document.querySelector(`tr[data-row-key="${item}"]`);
                  //       tr !== null && tr.classList.add('active');
                  //     });
                  //   },
                  // }}
                />
              </div>
              <div className={styles.account__notification__dropdown_foot}>
                <Button
                  block
                  type='text'
                  onClick={() => {
                    navigate('/notifications');
                  }}
                >
                  {getLocalString(translations, 'Notifications.ShowAll')}
                </Button>
              </div>
            </div>
          )}
        >
          <Button
            icon={
              <Badge count={data.filter((item) => !item.is_read).length} size='small'>
                <BellOutlined />
              </Badge>
            }
            ghost={false}
            danger={false}
            type='text'
            size='large'
          />
        </Dropdown>
      </Tooltip>
    </>
  );
};

export default UniteamHeaderNotificationDropdown;
