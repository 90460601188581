import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';
import CustomizedTable from '../../../components/CustomizedTable';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import {
  constantsSelector,
  getFaculties,
  getInterviewTypes,
  getUniversities,
} from '../../../store/constants';
import { apiURL } from '../../../service';
import { useNavigate } from 'react-router-dom';
import { getUniversitiesExportWithFilter } from '../../../store/university';

const { Option } = Select;

const UniversitiesAppList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { turkeyCities } = useAppSelector(constantsSelector);
  const [nodeId, setNodeId] = useState<string>('');
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [roleId, setRoleId] = useState<string>('');
  const [cityId, setCityId] = useState<string>('');

  const [status, setStatus] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);

  const [filters, setFilters] = useState<object>();

  useEffect(() => {
    dispatch(getFaculties());
    dispatch(getUniversities());
    dispatch(getInterviewTypes());
  }, []);

  useEffect(() => {
    let filter: any = {};
    let filterCount = 0;
    if (cityId) {
      filter.city_id = cityId;
      filterCount++;
    }
    if (searchText) {
      filter.search_text = searchText;
    }
    setFilterCount(filterCount);
    setFilters(filter);
  }, [searchText, cityId]);

  const renderTurkeyCitiesOptions = useCallback(
    () =>
      turkeyCities.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name}
        </Option>
      )),
    [turkeyCities]
  );

  const tagColors = [
    'geekblue',
    'magenta',
    'red',
    'blue',
    'volcano',
    'orange',
    'green',
    'lime',
    'cyan',
    'purple',
  ];

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: getLocalString(translations, 'UniversitiesList.Logo'),
      key: 'logo',
      dataIndex: 'logo',
      width: 200,
      render: (e: any, record: any) =>
        record.files.find((el: any) => el.key === 'logo')?.path && (
          <img
            src={record.files.find((el: any) => el.key === 'logo')?.path}
            style={{ maxWidth: '180px', maxHeight: '80px' }}
          ></img>
        ),
    },
    {
      title: getLocalString(translations, 'UniversitiesList.University'),
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: getLocalString(translations, 'UniversitiesList.City'),
      dataIndex: 'name',
      key: 'name',
      render: (e: any, record: any) => {
        return record?.city?.name;
      },
      width: 150,
    },
    {
      title: getLocalString(translations, 'UniversitiesList.DepartmentCount'),
      dataIndex: 'department_count',
      key: 'department_count',
      width: 100,
    },
    {
      title: getLocalString(translations, 'UniversitiesList.Catalog'),
      dataIndex: 'catalog',
      key: 'catalog',
      width: 100,
      render: (e: any, record: any) =>
        record.files.find((el: any) => el.key === 'catalog')?.path && (
          <a href={record.files.find((el: any) => el.key === 'catalog')?.path}>
            <Tag
              color={tagColors[Math.floor(Math.random() * tagColors.length)]}
              style={{
                display: 'block',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                maxWidth: '100px',
              }}
            >
              {getLocalString(translations, 'UniversitiesList.DigitalCatalog')}
            </Tag>
          </a>
        ),
    },
  ];

  return (
    <PageContainer
      pageName={getLocalString(translations, 'UniversitiesList.Title')}
      extra={
        <Row style={{ gap: '16px' }}>
          <Button
            type='primary'
            style={!isCreate ? { display: 'none' } : {}}
            size='large'
            onClick={() => navigate('/add-university')}
          >
            {getLocalString(translations, 'UniversitiesList.AddUniversity')}
          </Button>
          <Button
            onClick={() => {
              dispatch(getUniversitiesExportWithFilter(filters));
            }}
            type='primary'
            size='large'
          >
            {getLocalString(translations, 'UniversitiesList.ExportExcel')}
          </Button>
        </Row>
      }
    >
      <PageFilter
        count={filterCount}
        clear={() => {
          setCityId('');
          setSearchText('');
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Select
              style={{ width: '100%' }}
              value={cityId || undefined}
              placeholder={getLocalString(translations, 'UniversitiesList.SelectCity')}
              onChange={(value) => {
                setCityId(value);
              }}
              allowClear
              showSearch
              filterOption={filterOption}
            >
              {renderTurkeyCitiesOptions()}
            </Select>
          </Col>
        </Row>
      </PageFilter>

      <Row style={{ marginTop: '24px' }}>
        <Row style={{ marginBottom: '24px' }}>
          <Search value={searchText} onChange={(value) => setSearchText(value)} />
        </Row>

        <Row>
          <CustomizedTable
            columns={columns}
            data={[]}
            url={apiURL.getUniversityList}
            filter={filters}
            actions={[
              {
                action: (record) => {
                  navigate('/edit-university/' + record.id);
                },
                type: 'detail',
                // hidden: (record: any) => record.id === 1,
              },
            ]}
          />
        </Row>
      </Row>
    </PageContainer>
  );
};

export default UniversitiesAppList;
