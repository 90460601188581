import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from './actions-types';
import { IActionCallback } from '../types';
import { apiURL, axiosClient } from '../../service';
import { notifyError } from '../../helpers/notify';
import { setSpinner } from '../spinner';

interface IGetAdminInput extends IActionCallback {
  id: string;
}
export const getAdmin = createAsyncThunk(
  ActionTypes.GET_ADMIN,
  async (data: IGetAdminInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getAdmin.replace(':adminId', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IAdminCreateInputs extends IActionCallback {
  name: string;
  surname: string;
  phone: string;
  phone_code: string;
  email: string;
  created_bys: string[];
  password: string;
  password_confirmation: string;
}
export const adminCreate = createAsyncThunk(
  ActionTypes.CREATE_ADMIN,
  async (data: IAdminCreateInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      name,
      surname,
      phone,
      phone_code,
      email,
      created_bys,
      password,
      password_confirmation,
      onSuccess,
    } = data;

    try {
      const response = await axiosClient.post(apiURL.createAdmin, {
        name,
        surname,
        phone,
        phone_code,
        email,
        created_bys,
        password,
        password_confirmation,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IAgencyCreateInputs extends IActionCallback {
  agency_name: string;
  name: string;
  surname: string;
  phone: string;
  phone_code: string;
  country_id: string;
  agency_phone: string;
  agency_phone_code: string;
  agency_address: string;
  email: string;
  password: string;
  password_confirmation: string;
}
export const agencyCreate = createAsyncThunk(
  ActionTypes.CREATE_AGENCY,
  async (data: IAgencyCreateInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      agency_name,
      name,
      country_id,
      surname,
      agency_address,
      phone,
      phone_code,
      agency_phone,
      agency_phone_code,
      email,
      password,
      password_confirmation,
      onSuccess,
    } = data;

    try {
      const response = await axiosClient.post(apiURL.createAgency, {
        agency_name,
        name,
        surname,
        phone,
        phone_code,
        agency_address,
        country_id,
        agency_phone,
        agency_phone_code,
        email,
        password,
        password_confirmation,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IGetAgencyDetail extends IActionCallback {
  id: string;
}
export const getAgency = createAsyncThunk(
  ActionTypes.GET_ADMIN,
  async (data: IGetAgencyDetail, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getAgency.replace(':agencyId', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IWorkerCreateInputs extends IActionCallback {
  agencyId: string;
  name: string;
  surname: string;
  phone: string;
  email: string;
  password: string;
  password_confirmation: string;
}
export const workerCreateByAdmin = createAsyncThunk(
  ActionTypes.CREATE_WORKER_BY_ADMIN,
  async (data: IWorkerCreateInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const { agencyId, name, surname, phone, email, password, password_confirmation, onSuccess } =
      data;

    try {
      const response = await axiosClient.post(
        apiURL.workerCreateByAdmin.replace(':agencyId', agencyId),
        {
          name,
          surname,
          phone,
          email,
          password,
          password_confirmation,
        }
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IAgencyUpdateInputs extends IActionCallback {
  agency_id: string;
  agency_name: string;
  name: string;
  surname: string;
  phone: string;
  phone_code: string;
  agency_phone: string;
  agency_phone_code: string;
  agency_address: string;
  country_id: string;
  email: string;
  password?: string;
  password_confirmation?: string;
}
export const agencyUpdate = createAsyncThunk(
  ActionTypes.UPDATE_AGENCY,
  async (data: IAgencyUpdateInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      agency_id,
      agency_name,
      name,
      surname,
      agency_address,
      phone,
      phone_code,
      country_id,
      agency_phone,
      agency_phone_code,
      email,
      password,
      password_confirmation,
      onSuccess,
    } = data;
    const requestData = {
      agency_name,
      agency_address,
      country_id,
      agency_phone,
      agency_phone_code,
      name,
      surname,
      phone,
      phone_code,
      email,
    };

    if (password) Object.assign(requestData, { password });
    if (password_confirmation) Object.assign(requestData, { password_confirmation });
    try {
      const response = await axiosClient.put(
        apiURL.agencyUpdate.replace(':agencyId', agency_id),
        requestData
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IGetWorkerByAdmin extends IActionCallback {
  agencyId: string;
  workerId: string;
}
export const getWorkerByAdmin = createAsyncThunk(
  ActionTypes.GET_WORKER_BY_ADMIN,
  async (data: IGetWorkerByAdmin, { dispatch }) => {
    dispatch(setSpinner(true));
    const { workerId, agencyId, onSuccess } = data;
    try {
      const response = await axiosClient.get(
        apiURL.getWorkerByAdmin.replace(':agencyId', agencyId).replace(':workerId', workerId)
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IWorkerUpdateInputs extends IActionCallback {
  workerId: string;
  agencyId: string;
  name: string;
  surname: string;
  phone: string;
  email: string;
  password: string;
  password_confirmation: string;
}
export const workerUpdateByAdmin = createAsyncThunk(
  ActionTypes.UPDATE_WORKER_BY_ADMIN,
  async (data: IWorkerUpdateInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      agencyId,
      workerId,
      name,
      surname,
      phone,
      email,
      password,
      password_confirmation,
      onSuccess,
    } = data;

    const requestData = { name, surname, phone, email };

    if (password) Object.assign(requestData, { password });
    if (password_confirmation) Object.assign(requestData, { password_confirmation });

    try {
      const response = await axiosClient.put(
        apiURL.workerUpdateByAdmin.replace(':agencyId', agencyId).replace(':workerId', workerId),
        requestData
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IAdminUpdateInputs extends IActionCallback {
  adminId: string;
  name: string;
  surname: string;
  phone: string;
  phone_code: string;
  email: string;
  created_bys: string[];
  password: string;
  password_confirmation: string;
}
export const adminUpdate = createAsyncThunk(
  ActionTypes.UPDATE_ADMIN,
  async (data: IAdminUpdateInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      adminId,
      name,
      surname,
      phone,
      phone_code,
      email,
      created_bys,
      password,
      password_confirmation,
      onSuccess,
    } = data;

    const requestData = { name, surname, phone, phone_code, email, created_bys };

    if (password) Object.assign(requestData, { password });
    if (password_confirmation) Object.assign(requestData, { password_confirmation });

    try {
      const response = await axiosClient.put(
        apiURL.updateAdmin.replace(':adminId', adminId),
        requestData
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IGetWorkerByAgencyUser extends IActionCallback {
  id: string;
}
export const getWorkerByAgencyUser = createAsyncThunk(
  ActionTypes.GET_WORKER_BY_AGENCY_USER,
  async (data: IGetWorkerByAgencyUser, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getWorkerByAgencyUser.replace(':workerId', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IWorkerCreateByAgencyInputs extends IActionCallback {
  name: string;
  surname: string;
  phone: string;
  email: string;
  password: string;
  password_confirmation: string;
}
export const workerCreateByAgency = createAsyncThunk(
  ActionTypes.CREATE_WORKER_BY_AGENCY,
  async (data: IWorkerCreateByAgencyInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const { name, surname, phone, email, password, password_confirmation, onSuccess } = data;

    try {
      const response = await axiosClient.post(apiURL.workerCreateByAgency, {
        name,
        surname,
        phone,
        email,
        password,
        password_confirmation,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IWorkerUpdateByAgencyInputs extends IActionCallback {
  workerId: string;

  name: string;
  surname: string;
  phone: string;
  email: string;
  password?: string;
  password_confirmation?: string;
}
export const workerUpdateByAgency = createAsyncThunk(
  ActionTypes.UPDATE_WORKER_BY_AGENCY,
  async (data: IWorkerUpdateByAgencyInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const { workerId, name, surname, phone, email, password, password_confirmation, onSuccess } =
      data;

    const requestData = { name, surname, phone, email };

    if (password) Object.assign(requestData, { password });
    if (password_confirmation) Object.assign(requestData, { password_confirmation });

    try {
      const response = await axiosClient.put(
        apiURL.workerUpdateByAgency.replace(':workerId', workerId),
        requestData
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IDeleteAdmin extends IActionCallback {
  id: string;
}
export const deleteAdmin = createAsyncThunk(
  ActionTypes.DELETE_ADMIN,
  async (data: IDeleteAdmin, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess, onError } = data;
    try {
      const response = await axiosClient.delete(apiURL.deleteAdmin.replace(':adminId', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IDeleteAgency extends IActionCallback {
  agencyId: string;
  workerId: string;
}
export const deleteAgency = createAsyncThunk(
  ActionTypes.DELETE_AGENCY,
  async (data: IDeleteAgency, { dispatch }) => {
    dispatch(setSpinner(true));
    const { workerId, agencyId, onSuccess, onError } = data;
    try {
      const response = await axiosClient.delete(
        apiURL.deleteAgency.replace(':workerId', workerId).replace(':agencyId', agencyId)
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IDeleteWorkerByAgency extends IActionCallback {
  workerId: string;
}
export const deleteWorkerByAgency = createAsyncThunk(
  ActionTypes.DELETE_AGENCY,
  async (data: IDeleteWorkerByAgency, { dispatch }) => {
    dispatch(setSpinner(true));
    const { workerId, onSuccess, onError } = data;
    try {
      const response = await axiosClient.delete(
        apiURL.deleteWorkerByAgency.replace(':workerId', workerId)
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

export const getAdminWorkerList = createAsyncThunk(ActionTypes.GET_ADMIN_WORKER_LIST, async () => {
  const response = await axiosClient.get(apiURL.getAdminWorkerList);
  return response.data;
});
interface IAgencyListInput extends IActionCallback {}
export const getAgencyList = createAsyncThunk(
  ActionTypes.AGENCY_LIST,
  async (data: IAgencyListInput) => {
    const { onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.agencyList);
      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      notifyError(error);
    }
  }
);
