import { createReducer } from '@reduxjs/toolkit';

interface IDashboardState {
  pending: boolean;
  error: boolean;
}

const initialState: IDashboardState = {
  pending: false,
  error: false,
};

export const dashboardReducer = createReducer(initialState, (builder) => {});
