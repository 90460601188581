import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Input, DatePicker } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import styles from './index.module.scss';
import globalStyles from '../../../styles/global.module.scss';

import PageContainer from '../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../components/Search';
import { menuSelector } from '../../store/menu';
import { getLocalString } from '../../helpers/language';
import { languageSelector } from '../../store/language';
import PageFilter from '../../components/pageFilter';
import {
  constantsSelector,
  getFaculties,
  getFacultiesByUniversity,
  getInterviewTypes,
  getUniversities,
} from '../../store/constants';
import { apiURL } from '../../service';
import { getDepartmentsExportWithFilter, universitySelector } from '../../store/university';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CustomizedTable from '../../components/CustomizedTable';
import { IConstantTypes, IDepartmentTypes } from '../../types/constants';
import { notifyError } from '../../helpers/notify';

const { Option } = Select;
const { RangePicker } = DatePicker;

const SearchDepartmentPublic: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);

  // const {
  //   is_view: isView,
  //   is_create: isCreate,
  //   is_update: isUpdate,
  //   is_delete: isDelete,
  // } = useAppSelector(menuSelector);

  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { universityList, facultyList, languageList, turkeyCities, programList } =
    useAppSelector(constantsSelector);

  const [universityId, setUniversityId] = useState<string>('');
  const [facultyId, setFacultyId] = useState<string>('');
  const [programId, setProgramId] = useState<string>('');

  const [language_id, setLanguage] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const [priceYearStart, setPriceYearStart] = useState<number>();
  const [priceYearEnd, setPriceYearEnd] = useState<number>();
  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);

  const [filters, setFilters] = useState<object>();

  const [facultyByUniversity, setFacultyByUniversity] = useState<IConstantTypes[]>([]);
  const [departmentByFaculty, setDepartmentByFaculty] = useState<IDepartmentTypes[]>([]);
  useEffect(() => {
    dispatch(getFaculties());
    dispatch(getUniversities());
  }, []);
  const renderUniversityOptions = useCallback(
    () => universityList.map((el: any) => ({ value: el.id, label: el.name })),
    [universityList]
  );

  useEffect(() => {
    if (universityId)
      dispatch(
        getFacultiesByUniversity({
          university_id: universityId,
          onSuccess: (data: any) => {
            setFacultyByUniversity(data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  }, [universityId]);

  const renderTurkeyCitiesOptions = useCallback(
    () =>
      turkeyCities.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name}
        </Option>
      )),
    [turkeyCities]
  );

  const renderProgramOptions = useCallback(
    () =>
      programList.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name}
        </Option>
      )),
    [programList]
  );

  const renderFacultiesOptions = useCallback(
    () =>
      universityId
        ? facultyByUniversity.map((el, i) => (
            <Option key={i} value={el.id}>
              {el.name}
            </Option>
          ))
        : facultyList.map((el, i) => (
            <Option key={i} value={el.id}>
              {el.name}
            </Option>
          )),
    [facultyList, universityId, facultyByUniversity]
  );

  const renderLanguageOptions = useCallback(
    () => languageList.map((el: any) => ({ value: el.id, label: el.name })),
    [languageList]
  );

  useEffect(() => {
    let filter: any = {};
    let filterCount = 0;
    if (universityId) {
      filter.university_id = universityId;
      filterCount++;
    }

    if (programId) {
      filter.program_id = programId;
      filterCount++;
    }
    if (facultyId) {
      filter.faculty_id = facultyId;
      filterCount++;
    }

    if (language_id) {
      filter.language_id = language_id;
      filterCount++;
    }
    if (status) {
      filter.is_status = status;
      filterCount++;
    }
    if (priceYearStart && priceYearEnd) {
      filter.price_year_start = priceYearStart;
      filter.price_year_end = priceYearEnd;
      filterCount++;
    }
    if (searchText) {
      filter.search_text = searchText;
    }
    setFilterCount(filterCount);
    setFilters(filter);
  }, [
    searchText,
    programId,
    status,
    facultyId,
    universityId,
    priceYearEnd,
    priceYearStart,
    language_id,
  ]);

  const columns = [
    // {
    //   title: 'ID',
    //   key: 'id',
    //   dataIndex: 'id',
    //   width: 50,
    // },
    {
      title: getLocalString(translations, 'SearchDepartment.Column.University'),
      dataIndex: 'university',
      key: 'university',
      render: (e: any, record: any) => record?.university?.name,
      width: 100,
    },

    {
      title: getLocalString(translations, 'SearchDepartment.Column.Location'),
      dataIndex: 'location',
      key: 'location',
      width: 100,
      render: (e: any, record: any) =>
        record?.university?.city?.name ? record?.university?.city?.name : '-',
    },
    {
      title: getLocalString(translations, 'SearchDepartment.Column.Faculty'),
      dataIndex: 'faculty',
      key: 'faculty',
      render: (e: any, record: any) => record?.faculty?.name,
      width: 150,
    },
    {
      title: getLocalString(translations, 'SearchDepartment.Column.DepartmentName'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    // {
    //   title: 'Translation',
    //   dataIndex: 'translation',
    //   key: 'translation',
    // },

    {
      title: getLocalString(translations, 'SearchDepartment.Column.Program'),
      dataIndex: 'program',
      key: 'program',
      render: (e: any, record: any) => record?.program?.name,
      width: 100,
    },
    {
      title: getLocalString(translations, 'SearchDepartment.Column.Language'),
      dataIndex: 'language_id',
      key: 'language_id',
      render: (e: any, record: any) => record?.language?.name,
      width: 100,
    },
    // {
    //   title: getLocalString(translations, 'SearchDepartment.Column.Fee'),
    //   dataIndex: 'fee',
    //   key: 'fee',
    //   width: 100,
    // },
    // {
    //   title: getLocalString(translations, 'SearchDepartment.Column.Discount'),
    //   dataIndex: 'discount',
    //   key: 'discount',
    //   width: 100,
    // },
    // {
    //   title: getLocalString(translations, 'SearchDepartment.Column.CashDiscount'),
    //   dataIndex: 'cash_discount',
    //   key: 'cash_discount',
    //   width: 100,
    // },
    // {
    //   title: getLocalString(translations, 'SearchDepartment.Column.Currency'),
    //   dataIndex: 'currency',
    //   key: 'currency',
    //   render: (e: any, record: any) => record.currency.name,
    //   width: 100,
    // },
    // {
    //   title: getLocalString(translations, 'SearchDepartment.Column.Notes'),
    //   dataIndex: 'note',
    //   key: 'note',
    //   width: 100,
    // },
    // {
    //   title: getLocalString(translations, 'SearchDepartment.Column.Status'),
    //   dataIndex: 'is_status',
    //   key: 'is_status',
    //   render: (e: any, record: any) => {
    //     if (record.is_status > 0) {
    //       return (
    //         <Tag color={'success'}> {getLocalString(translations, 'SearchDepartment.Active')}</Tag>
    //       );
    //     } else return <Tag> {getLocalString(translations, 'SearchDepartment.Passive')}</Tag>;
    //   },
    //   width: 100,
    // },
    {
      title: getLocalString(translations, 'SearchDepartment.Column.ApplicationYear'),
      dataIndex: 'price_year',
      key: 'price_year',
      render: (e: any, record: any) => record?.price_year_start + '-' + record?.price_year_end,
      width: 100,
    },
  ];

  return (
    <PageContainer pageName={getLocalString(translations, 'SearchDepartment.Title')}>
      <PageFilter
        count={filterCount}
        clear={() => {
          setUniversityId('');
          setProgramId('');
          setLanguage('');
          setFacultyId('');
          setPriceYearStart(undefined);
          setPriceYearEnd(undefined);
          setStatus('');
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={6}>
            <Select
              style={{ width: '100%' }}
              value={universityId || undefined}
              placeholder={getLocalString(
                translations,
                'SearchDepartment.Placeholder.SelectUniversity'
              )}
              onChange={(value) => {
                setUniversityId(value);
                setFacultyId('');
              }}
              allowClear
              showSearch
              filterOption={filterOption}
              options={renderUniversityOptions()}
            ></Select>
          </Col>
          {/* <Col span={8}>
            <Select
              style={{ width: '100%' }}
              value={facultyId || undefined}
              disabled={!universityId}
              placeholder={getLocalString(
                translations,
                'SearchDepartment.Placeholder.SelectFaculty'
              )}
              onChange={setFacultyId}
              allowClear
              showSearch
              filterOption={filterOption}
            >
              {renderFacultiesOptions()}
            </Select>
          </Col> */}
          {/* <Col span={8}>
            <Select
              showSearch
              filterOption={filterOption}
              style={{ width: '100%' }}
              value={status !== undefined ? status.toString() || undefined : undefined}
              placeholder={getLocalString(
                translations,
                'SearchDepartment.Placeholder.DepartmentStatus'
              )}
              onChange={(value) => {
                setStatus(value);
              }}
              allowClear
            >
              {
                <>
                  <Option value={'1'}>
                    {getLocalString(translations, 'SearchDepartment.Active')}
                  </Option>
                  <Option value={'0'}>
                    {getLocalString(translations, 'SearchDepartment.Passive')}
                  </Option>
                </>
              }
            </Select>
          </Col> */}
          <Col span={6}>
            <Select
              style={{ width: '100%' }}
              value={programId || undefined}
              placeholder={getLocalString(
                translations,
                'SearchDepartment.Placeholder.SelectProgram'
              )}
              onChange={setProgramId}
              allowClear
              showSearch
              filterOption={filterOption}
            >
              {renderProgramOptions()}
            </Select>
          </Col>
          <Col span={6}>
            <Select
              style={{ width: '100%' }}
              placeholder={getLocalString(
                translations,
                'SearchDepartment.Placeholder.SelectLanguage'
              )}
              value={language_id || undefined}
              onChange={setLanguage}
              allowClear
              showSearch
              filterOption={filterOption}
              options={renderLanguageOptions()}
            ></Select>
          </Col>
          <Col span={6}>
            <RangePicker
              style={{ width: '100%' }}
              picker='year'
              // disabledDate={(current: any) => {
              //   return current && current < dayjs().startOf('year');
              // }}
              value={
                priceYearEnd && priceYearStart
                  ? [dayjs().year(priceYearStart), dayjs().year(priceYearEnd)]
                  : undefined
              }
              onChange={(dates: any) => {
                setPriceYearStart(dates[0].year());
                setPriceYearEnd(dates[1].year());
              }}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]} style={{ marginTop: '8px' }}></Row>
      </PageFilter>

      <Row style={{ marginTop: '24px' }}>
        <Row style={{ marginBottom: '24px' }}>
          <Search value={searchText} onChange={(value) => setSearchText(value)} />
        </Row>

        <div style={{ width: '100%' }}>
          <CustomizedTable
            columns={columns}
            data={[]}
            url={apiURL.getDepartments}
            filter={filters}
            scrollX={1000}
            // scrollY={500}
          />
        </div>
      </Row>
    </PageContainer>
  );
};

export default SearchDepartmentPublic;
