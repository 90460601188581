import * as React from 'react';
import classnames from 'classnames';
import styles from './HeadLine.module.scss';

interface Props {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  level: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  strong?: boolean;
  children?: React.ReactNode;
  mb?: string | number;
}

export const HeadLine: React.FC<Props> = (props) => {
  const { level, strong, className, children, style, id, mb } = props;

  const elementClasses = classnames({
    [styles.headLine1]: level === 1,
    [styles.headLine2]: level === 2,
    [styles.headLine3]: level === 3,
    [styles.headLine4]: level === 4,
    [styles.headLine5]: level === 5,
    [styles.headLine6]: level === 6,
    [styles.headLine7]: level === 7,
    [styles.headLineStrong]: strong,
    className,
  });

  const Element: React.ReactElement<any> = React.createElement(
    `h${level}`,
    {
      className: `${styles.headLine} ${elementClasses}`,
    },
    children
  );

  return (
    <Element.type
      className={styles.headLine}
      style={{ marginBottom: mb, ...style }}
      {...Element.props}
    >
      {children}
    </Element.type>
  );
};
