import React from 'react';
import { Button } from 'antd';

import styles from './uniteamLinkButton.module.scss';
import { ILinkButtonProps } from './uniteamLinkButton-type';
import { Link } from 'react-router-dom';

const UniteamLinkButton: React.FC<ILinkButtonProps> = ({
  children,
  disabled = false,
  size = 'default',
  href = null,
  icon = null,
  endIcon = null,
  onClick,
}) => {
  const classNameProps = `${styles.btn} ${
    size === 'large' ? styles.sizeLarge : size === 'small' ? styles.sizeSmall : ''
  } ${disabled ? styles.disabled : ''}`;
  return href !== null ? (
    disabled ? (
      <a className={classNameProps}>
        {icon !== null && icon}
        <span>{children}</span>
        {endIcon !== null && endIcon}
      </a>
    ) : (
      <Link to={href} className={classNameProps}>
        {icon !== null && icon}
        <span>{children}</span>
        {endIcon !== null && endIcon}
      </Link>
    )
  ) : (
    <button onClick={onClick} disabled={disabled} className={classNameProps}>
      {icon !== null && icon}
      <span>{children}</span>
      {endIcon !== null && endIcon}
    </button>
  );
};

export default UniteamLinkButton;
