import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';
import CustomizedTable from '../../../components/CustomizedTable';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import { constantsSelector } from '../../../store/constants';
import { apiURL } from '../../../service';
import { universitySelector } from '../../../store/university';
import { useNavigate } from 'react-router-dom';
import { userSelector } from '../../../store/user';
import { accountReducer, accountSelector } from '../../../store/account';
import { deleteAgency, deleteWorkerByAgency } from '../../../store/admin';
import { notifySuccess } from '../../../helpers/notify';

const { Option } = Select;

const AdminList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { account } = useAppSelector(accountSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { universityList, departmentList, programList } = useAppSelector(constantsSelector);

  const [universityId, setUniversityId] = useState<string>('');
  const [DepartmentNameId, setWarehouseId] = useState<string>('');
  const [programId, setProgramId] = useState<string>('');

  const [languageId, setLanguageId] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);

  const [filters, setFilters] = useState<object>({});
  const [reload, setReload] = useState<boolean>(false);

  const columns = [
    {
      title: getLocalString(translations, 'UserList.AgencyName'),
      dataIndex: 'agency_name',
      key: 'agency_name',
    },
    {
      title: getLocalString(translations, 'UserList.Username'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: getLocalString(translations, 'UserList.Name'),
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: getLocalString(translations, 'UserList.Surname'),
      dataIndex: 'surname',
      key: 'surname',
    },

    {
      title: getLocalString(translations, 'UserList.Phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: getLocalString(translations, 'UserList.Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: getLocalString(translations, 'UserList.Role'),
      dataIndex: 'role',
      key: 'role',
      render: (record: any) => record?.name,
    },
  ];

  return (
    <PageContainer
      pageName={getLocalString(translations, 'UserList.WorkerList')}
      extra={
        <Button
          style={!isCreate ? { display: 'none' } : {}}
          size='large'
          type='primary'
          onClick={() => navigate('/user-add-update-worker')}
        >
          {getLocalString(translations, 'UserList.CreateWorker')}
          
        </Button>
      }
    >
      <Row style={{ marginTop: '24px' }}>
        <Row>
          <CustomizedTable
            columns={columns}
            data={[]}
            url={apiURL.getWorkersByAgency}
            filter={filters}
            reload={reload}
            setReload={setReload}
            actions={[
              {
                action: (record) => {
                  navigate('/user-add-update-worker/' + record.user_id);
                },
                type: 'edit',
                hidden: (record: any) => {
                  return !isCreate;
                },
              },
              {
                action: (record: any) => {
                  dispatch(
                    deleteWorkerByAgency({
                      workerId: record.user_id,
                      onSuccess: (data: any) => {
                        notifySuccess( getLocalString(translations, 'UserList.AgencyWorkerDeleted'));
                        setReload(true);
                      },
                    })
                  );
                },
                type: 'delete',
                hidden: (record: any) => {
                  return !isCreate;
                },
              },
            ]}
          />
        </Row>
      </Row>
    </PageContainer>
  );
};

export default AdminList;
