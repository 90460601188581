export enum ActionTypes {
  GET_ADMIN = 'GET_ADMIN',
  CREATE_ADMIN = 'CREATE_ADMIN',
  UPDATE_ADMIN = 'UPDATE_ADMIN',
  GET_ADMIN_WORKER_LIST = 'GET_ADMIN_WORKER_LIST',
  DELETE_ADMIN = 'DELETE_ADMIN',
  CREATE_AGENCY = 'CREATE_AGENCY',
  UPDATE_AGENCY = 'UPDATE_AGENCY',
  DELETE_AGENCY = 'DELETE_AGENCY',
  GET_AGENCY = 'GET_AGENCY',
  CREATE_WORKER_BY_ADMIN = 'CREATE_WORKER_BY_ADMIN',
  CREATE_WORKER_BY_AGENCY = 'CREATE_WORKER_BY_AGENCY',
  UPDATE_WORKER_BY_ADMIN = 'UPDATE_WORKER_BY_ADMIN',
  UPDATE_WORKER_BY_AGENCY = 'UPDATE_WORKER_BY_AGENCY',
  GET_WORKER_BY_AGENCY_USER = 'GET_WORKER_BY_AGENCY_USER',
  GET_WORKER_BY_ADMIN = 'GET_WORKER_BY_ADMIN',
  AGENCY_LIST = 'AGENCY_LIST',
}
