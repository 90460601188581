export enum ActionTypes {
  CREATE_UNIVERSITY = 'CREATE_UNIVERSITY',
  UPDATE_UNIVERSITY = 'UPDATE_UNIVERSITY',
  DELETE_UNIVERSITY = 'DELETE_UNIVERSITY',
  GET_UNIVERSITY_DETAIL = 'GET_UNIVERSITY_DETAIL',
  UNIVERSITY_EXPORT_EXCEL_WITH_FILTER = 'UNIVERSITY_EXPORT_EXCEL_WITH_FILTER',
  GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS',
  CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT',
  CREATE_DEPARTMENT = 'CREATE_DEPARTMENT',
  UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT',
  GET_DEPARTMENT_DETAIL = 'GET_DEPARTMENT_DETAIL',
  DEPARTMENT_EXPORT_EXCEL_WITH_FILTER = 'DEPARTMENT_EXPORT_EXCEL_WITH_FILTER',

}
