import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Card, Input, DatePicker } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import globalStyles from '../../../styles/global.module.scss';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import {
  constantsSelector,
  getCurrency,
  getFaculties,
  getUniversities,
  getUniversityFaculties,
} from '../../../store/constants';
import { apiURL } from '../../../service';
import { createDepartment, getDepartmentDetail, updateDepartment } from '../../../store/university';
import { useNavigate, useParams } from 'react-router-dom';
import isNumeric from '../../../helpers/isNumeric';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { notifyError, notifySuccess } from '../../../helpers/notify';
import { StyleInfo } from 'antd/es/theme/util/genComponentStyleHook';
import moment from 'moment';
import { IDepartmentTypes } from '../../../types/constants';

const { Option } = Select;
const { RangePicker } = DatePicker;

const SearchDepartment: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { universityList, departmentList, programList, languageList, currency } =
    useAppSelector(constantsSelector);

  const [universityId, setUniversityId] = useState<string>('');
  const [departmentName, setDepartmentName] = useState<string>('');
  const [programId, setProgramId] = useState<string>('');

  const [languageId, setLanguageId] = useState<string>('');
  const [fee, setFee] = useState<string>('');
  const [currencyId, setCurrencyId] = useState<number>();
  const [discount, setDiscount] = useState<string>('');
  const [cashDiscount, setCashDiscount] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [priceYearStart, setPriceYearStart] = useState<number>();
  const [priceYearEnd, setPriceYearEnd] = useState<number>();
  const [facultyId, setFacultyId] = useState<string>('');
  const [departmentInfo, setDepartmentInfo] = useState<any>({});
  const [status, setStatus] = useState<number>();
  const [facultyList, setFacultyList] = useState<IDepartmentTypes[]>([]);
  const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(true);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true);

  useEffect(() => {
    dispatch(getFaculties());
    dispatch(getUniversities());
    dispatch(getCurrency());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(
        getDepartmentDetail({
          id: id,
          onSuccess: (data: any) => {
            setProgramId(data.data.program.id);
            setCashDiscount(data.data.cash_discount || '0');
            setPriceYearEnd(data.data.price_year_end);
            setPriceYearStart(data.data.price_year_start);
            setDiscount(data.data.discount || '0');
            setFee(data.data.fee || '0');
            setStatus(data.data.is_status);
            setLanguageId(data.data.language.id);
            setDepartmentName(data.data.name);
            setNote(data.data.note);
            setUniversityId(data.data.university.id);
            setFacultyId(data.data.faculty.id);
            setDepartmentInfo(data.data);
            setCurrencyId(data.data.currency.id);
            // console.log(data.data);
          },
        })
      );
    }
  }, [id]);
  useEffect(() => {
    if (universityId)
      dispatch(
        getUniversityFaculties({
          universityID: universityId,
          onSuccess: (data: any) => {
            setFacultyList(data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  }, [universityId]);
  useEffect(() => {
    // Tüm alanların dolu olup olmadığını kontrol edin
    const isCreateDisabled =
      !universityId ||
      !facultyId ||
      !departmentName ||
      !programId ||
      !languageId ||
      !priceYearEnd ||
      !priceYearStart ||
      !currencyId ||
      !fee ||
      !discount ||
      !facultyId ||
      !cashDiscount;

    // Eğer departmentInfo varsa ve alanlar değiştiyse düzenleme için kontrol edin
    if (departmentInfo?.name) {
      const isChanged = id
        ? departmentName !== departmentInfo.name ||
          programId != departmentInfo.program.id ||
          facultyId != departmentInfo.faculty.id ||
          priceYearEnd != departmentInfo.price_year_end ||
          priceYearStart != departmentInfo.price_year_start ||
          fee != departmentInfo.fee ||
          currencyId != departmentInfo.currency ||
          cashDiscount != departmentInfo.cash_discount ||
          discount != departmentInfo.discount ||
          languageId != departmentInfo.language.id ||
          note != departmentInfo.note ||
          status != departmentInfo.is_status
        : true; // departmentInfo yoksa düzenleme yapılamaz
      setIsEditDisabled(!isChanged);
    } else setIsEditDisabled(true);
    // State'leri güncelleyin
    setIsCreateDisabled(isCreateDisabled);
  }, [
    universityId,
    departmentName,
    programId,
    priceYearEnd,
    priceYearStart,
    languageId,
    facultyId,
    fee,
    discount,
    cashDiscount,
    currencyId,
    note,
    status,
    departmentInfo, // departmentInfo değişiklikleri izlemek için bağımlılıklara eklendi
  ]);

  const renderUniversityOptions = useCallback(
    () => universityList.map((el: any) => ({ value: el.id, label: el.name })),
    [universityList]
  );
  const renderLanguageOptions = useCallback(
    () => languageList.map((el: any) => ({ value: el.id, label: el.name })),
    [languageList]
  );
  const renderProgramOptions = useCallback(
    () => programList.map((el: any) => ({ value: el.id, label: el.name })),
    [programList]
  );

  const renderFacultiesOptions = useCallback(
    () => facultyList.map((el: any) => ({ value: el.id, label: el.name })),
    [facultyList]
  );

  return (
    <PageContainer
      pageName={
        id
          ? getLocalString(translations, 'AddUpdateDepartment.EditDepartment')
          : getLocalString(translations, 'AddUpdateDepartment.CreateDepartment')
      }
      backUrl={'/search-department'}
    >
      <Row style={{ width: '100%' }}>
        <Col offset={6} span={12}>
          <Row gutter={[16, 16]}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateDepartment.University')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={universityId || undefined}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateDepartment.Placeholder.SelectUniversity'
                )}
                disabled={!!id}
                size='large'
                onChange={(value) => {
                  setUniversityId(value);
                  setFacultyId('');
                }}
                showSearch
                filterOption={filterOption}
                options={renderUniversityOptions()}
              ></Select>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateDepartment.Faculty')}
              </Row>
              <Select
                disabled={!universityId}
                style={{ width: '100%' }}
                value={facultyId || undefined}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateDepartment.Placeholder.SelectFaculty'
                )}
                size='large'
                onChange={(value) => {
                  setFacultyId(value);
                }}
                showSearch
                filterOption={filterOption}
                options={renderFacultiesOptions()}
              ></Select>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateDepartment.DepartmentName')}
              </Row>
              <Input
                maxLength={35}
                disabled={!facultyId}
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateDepartment.Placeholder.EnterDepartmentName'
                )}
                value={departmentName || undefined}
                onChange={(e) => {
                  setDepartmentName(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateDepartment.DepartmentProgram')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={programId || undefined}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateDepartment.Placeholder.SelectDepartmentProgram'
                )}
                onChange={(value) => {
                  setProgramId(value);
                }}
                size='large'
                showSearch
                filterOption={filterOption}
                options={renderProgramOptions()}
              ></Select>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateDepartment.DepartmentLanguage')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={languageId || undefined}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateDepartment.Placeholder.SelectDepartmentLanguage'
                )}
                onChange={(value) => {
                  setLanguageId(value);
                }}
                size='large'
                showSearch
                filterOption={filterOption}
                options={renderLanguageOptions()}
              ></Select>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateDepartment.PriceYear')}
              </Row>
              <RangePicker
                style={{ width: '100%' }}
                picker='year'
                disabledDate={(current: any) => {
                  return current && current < dayjs().startOf('year');
                }}
                value={
                  priceYearEnd && priceYearStart
                    ? [dayjs().year(priceYearStart), dayjs().year(priceYearEnd)]
                    : undefined
                }
                size='large'
                onChange={(dates: any) => {
                  setPriceYearStart(dates[0].year());
                  setPriceYearEnd(dates[1].year());
                }}
              />
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateDepartment.Label.Fee')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateDepartment.Placeholder.EnterFee'
                )}
                value={fee || undefined}
                onChange={(e) => {
                  setFee(e.currentTarget.value);
                }}
                size='large'
                onKeyDown={(e) => {
                  if (!isNumeric(e.key) && e.key !== 'Backspace') e.preventDefault();
                }}
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateDepartment.Label.Currency')}
              </Row>
              <Select
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateDepartment.Placeholder.Currency'
                )}
                value={currencyId || undefined}
                onChange={(e) => {
                  setCurrencyId(e);
                }}
                size='large'
                options={currency.map((el) => ({ value: el.id, label: el.name }))}
              ></Select>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateDepartment.Discount')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateDepartment.Placeholder.EnterDiscount'
                )}
                value={discount || undefined}
                onChange={(e) => {
                  setDiscount(e.currentTarget.value);
                }}
                size='large'
                onKeyDown={(e) => {
                  if (!isNumeric(e.key) && e.key !== 'Backspace') e.preventDefault();
                }}
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateDepartment.CashDiscount')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateDepartment.Placeholder.CashDiscount'
                )}
                value={cashDiscount || undefined}
                onChange={(e) => {
                  setCashDiscount(e.currentTarget.value);
                }}
                size='large'
                onKeyDown={(e) => {
                  if (!isNumeric(e.key) && e.key !== 'Backspace') e.preventDefault();
                }}
              ></Input>
            </Row>
            {id && (
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'AddUpdateDepartment.DepartmentStatus')}
                </Row>
                <Select
                  showSearch
                  filterOption={filterOption}
                  style={{ width: '100%' }}
                  value={status !== undefined ? status : undefined}
                  placeholder={getLocalString(
                    translations,
                    'AddUpdateDepartment.Placeholder.DepartmentStatus'
                  )}
                  onChange={(value) => {
                    setStatus(value);
                  }}
                  size='large'
                >
                  {
                    <>
                      <Option value={'1'}>
                        {getLocalString(translations, 'AddUpdateDepartment.Active')}
                      </Option>
                      <Option value={'0'}>
                        {getLocalString(translations, 'AddUpdateDepartment.Passive')}
                      </Option>
                    </>
                  }
                </Select>
              </Row>
            )}
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateDepartment.Note')}
              </Row>
              <TextArea
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateDepartment.Placeholder.EnterNoteHere'
                )}
                value={note || undefined}
                rows={3}
                onChange={(e) => {
                  setNote(e.currentTarget.value);
                }}
                size='large'
              ></TextArea>
            </Row>
          </Row>

          {id && (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                onClick={() => {
                  status &&
                    priceYearEnd &&
                    priceYearStart &&
                    dispatch(
                      updateDepartment({
                        university_id: universityId,
                        faculty_id: facultyId,
                        department_id: id,
                        name: departmentName,
                        language_id: languageId,
                        fee: fee,
                        discount: discount,
                        cash_discount: cashDiscount,
                        program_id: programId,
                        note: note,
                        currency: currencyId || 0,
                        price_year_start: priceYearStart,
                        price_year_end: priceYearEnd,
                        is_status: status,
                        onSuccess: (data: any) => {
                          notifySuccess(
                            getLocalString(
                              translations,
                              'AddUpdateDepartment.NotifySuccess.DepartmentUpdated'
                            )
                          );
                          navigate('/search-department');
                        },
                      })
                    );
                }}
                size='large'
                type='primary'
                disabled={isEditDisabled && !isUpdate}
              >
                {getLocalString(translations, 'AddUpdateDepartment.EditDepartment')}
              </Button>
            </Row>
          )}

          {!id && (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                onClick={() => {
                  dispatch(
                    createDepartment({
                      university_id: universityId,
                      faculty_id: facultyId,
                      name: departmentName,
                      program_id: programId,
                      language_id: languageId,
                      fee: fee,
                      discount: discount,
                      cash_discount: cashDiscount,
                      note: note,
                      currency: currencyId || 0,
                      price_year_start: priceYearStart,
                      price_year_end: priceYearEnd,
                      onSuccess: (data: any) => {
                        notifySuccess(
                          getLocalString(
                            translations,
                            'AddUpdateDepartment.NotifySuccess.DepartmentCreated'
                          )
                        );
                        navigate('/search-department');
                      },
                    })
                  );
                }}
                size='large'
                type='primary'
                disabled={isCreateDisabled}
                style={!isCreate ? { display: 'none' } : {}}
              >
                {getLocalString(translations, 'AddUpdateDepartment.CreateDepartment')}
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </PageContainer>
  );
};

export default SearchDepartment;
