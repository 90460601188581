import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from './actions-types';
import { IActionCallback } from '../types';
import { apiURL, axiosClient } from '../../service';
import { notifyError } from '../../helpers/notify';
import { setSpinner } from '../spinner';

export const getDashboardStatics = createAsyncThunk(
  ActionTypes.GET_DASHBOARD_STATICS,
  async (data: any, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getDashboardStatics);
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
