import React, { useEffect, useRef, useState } from 'react';
import { LayoutProps } from './publicLayout-types';
import styles from './publicLayout.module.scss';

import Icon from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../store/hooks';

import UniteamHeader from '../../components/uniteamHeader';

import UniteamFooter from '../../components/uniteamFooter';
import UniteamSideBar from '../../components/uniteamSideBar';
import { publicMenuListesi } from '../../helpers/menuList';
import { getMenuIcon } from '../../helpers/menu';
import { useNavigate } from 'react-router-dom';
import { setActiveMenu } from '../../store/menu';
import UniteamTopBar from '../../components/uniteamTopBar';
import { Layout, Row } from 'antd';
import { Header } from 'antd/es/layout/layout';

const PublicLayout: React.FC<LayoutProps> = ({
  children,
  style,
  title = '',
  actions = null,
  backBtn = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const menuListWithIcon = publicMenuListesi.map((el) => ({
    ...el,
    iconObject: getMenuIcon(el.icon),
  }));

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const contentRef = useRef<HTMLDivElement>(null);
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  useEffect(() => {
    const handleResize = () => {
      // User agent ile mobil cihaz kontrolü yapılıyor.
      const isMobileView = isMobileDevice() || window.innerWidth < 768;
      setIsMobile(isMobileView);

      // console.log('window.isMobileView', isMobileView);
    };

    // console.log('window.innerWidth', window.innerWidth);
    // İlk render'da ve her resize olayında çalışacak
    handleResize();
    window.addEventListener('resize', handleResize);

    // Komponent unmount olduğunda temizlik işlemi yapılıyor.
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const prepareMenulist = (menus: any) => {
    const preparedMenu = menus.map((item: any, i: number) => ({
      key: item.id,
      label: item.name,
      children: item.dropdown
        ? item.menuItems.map((subItem: any, y: number) => ({
            key: subItem.id,
            label: subItem.name,

            onClick: () => {
              dispatch(
                setActiveMenu({
                  id: item.id,
                  mainMenu: item.name,
                  subMenu: subItem.name,
                  is_create: subItem.isCreate,
                  is_delete: subItem.isDelete,
                  is_update: subItem.isUpdate,
                  is_view: subItem.isView,
                })
              );
              navigate(subItem.url);
            },
          }))
        : undefined,
      icon: <Icon component={item.iconObject as any} className={styles.icon} />,
      onClick: () => {
        if (!item.dropdown) {
          console.log('ana menü', item.url);
          navigate(item.url);
          dispatch(
            setActiveMenu({
              id: item.id,
              mainMenu: item.name,
              subMenu: item.name,
              is_create: item.isCreate,
              is_delete: item.isDelete,
              is_update: item.isUpdate,
              is_view: item.isView,
            })
          );
        }
      },
    }));

    return preparedMenu;
  };
  return (
    <>
      {!isMobile ? (
        <div className={`${styles.layout} ${styles.layout__has_sidebar}`}>
          <UniteamHeader
            app='business'
            // activeCompany={}
            projectSelector
            rightPanelRender={<></>}
          />
          <div className={`${styles.layout__body}`}>
            <UniteamSideBar menuItems={prepareMenulist(menuListWithIcon)} />
            <main className={styles.layout__container} style={style}>
              {children}
            </main>
          </div>
          <UniteamFooter
            menuItems={
              [
                // {
                //   label: 'Privacy',
                //   href: '/privacy',
                // },
                // {
                //   label: 'FAQ',
                //   href: '/faq',
                // },
                // {
                //   label: 'Help',
                //   href: '/help',
                // },
              ]
            }
            copyright
          />
        </div>
      ) : (
        <div style={{ height: '100%' }}>
          <Row style={{ padding: '25px' }} justify={'center'}>
            <svg
              width='108'
              height='20'
              viewBox='0 0 108 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clip-path='url(#clip0_8_77390)'>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M20.75 19.3335C20.1057 19.3335 19.5834 18.8112 19.5834 18.1668V8.00016C19.5834 7.17174 18.9118 6.50016 18.0834 6.50016H10.3334C9.50493 6.50016 8.83335 7.17173 8.83335 8.00016V13.0835C8.83335 13.7278 8.31102 14.2502 7.66669 14.2502C7.02236 14.2502 6.50002 13.7278 6.50002 13.0835V8.00016C6.50002 5.88307 8.21626 4.16683 10.3334 4.16683H18.0834C20.2004 4.16683 21.9167 5.88307 21.9167 8.00016V18.1668C21.9167 18.8112 21.3944 19.3335 20.75 19.3335Z'
                  fill='#840DDF'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M27.1667 5.09966e-08C27.811 7.91613e-08 28.3333 0.522335 28.3333 1.16667L28.3333 2.33333C28.3333 2.97767 27.811 3.5 27.1667 3.5C26.5223 3.5 26 2.97767 26 2.33333L26 1.16667C26 0.522334 26.5223 2.2832e-08 27.1667 5.09966e-08Z'
                  fill='#840DDF'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M105.833 15.8335C106.478 15.8335 107 16.3558 107 17.0002L107 18.8335C107 19.4778 106.478 20.0002 105.833 20.0002C105.189 20.0002 104.667 19.4778 104.667 18.8335L104.667 17.0002C104.667 16.3558 105.189 15.8335 105.833 15.8335Z'
                  fill='#840DDF'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M1.16667 4.16667C1.811 4.16667 2.33333 4.689 2.33333 5.33333V15.5C2.33333 16.3284 3.00491 17 3.83333 17H11.5833C12.4118 17 13.0833 16.3284 13.0833 15.5V10.4167C13.0833 9.77234 13.6057 9.25 14.25 9.25C14.8943 9.25 15.4167 9.77234 15.4167 10.4167V15.5C15.4167 17.6171 13.7004 19.3333 11.5833 19.3333H3.83333C1.71624 19.3333 0 17.6171 0 15.5V5.33333C0 4.689 0.522334 4.16667 1.16667 4.16667Z'
                  fill='#340860'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M27.1667 4.41667C27.811 4.41667 28.3333 4.939 28.3333 5.58333V18.3333C28.3333 18.9777 27.811 19.5 27.1667 19.5C26.5223 19.5 26 18.9777 26 18.3333V5.58333C26 4.939 26.5223 4.41667 27.1667 4.41667Z'
                  fill='#340860'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M33.6667 0C34.311 0 34.8333 0.522334 34.8333 1.16667V4.75H38.9167C39.561 4.75 40.0833 5.27233 40.0833 5.91667C40.0833 6.561 39.561 7.08333 38.9167 7.08333H34.8333V18.3333C34.8333 18.9777 34.311 19.5 33.6667 19.5C33.0223 19.5 32.5 18.9777 32.5 18.3333V1.16667C32.5 0.522335 33.0223 0 33.6667 0Z'
                  fill='#340860'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M41.5 8.5C41.5 6.38291 43.2162 4.66667 45.3333 4.66667H53.0833C55.2004 4.66667 56.9167 6.38291 56.9167 8.5V11.8333C56.9167 12.6618 56.2451 13.3333 55.4167 13.3333H46.25C45.6057 13.3333 45.0833 12.811 45.0833 12.1667C45.0833 11.5223 45.6057 11 46.25 11H54.5833V8.5C54.5833 7.67157 53.9118 7 53.0833 7H45.3333C44.5049 7 43.8333 7.67157 43.8333 8.5V15.5833C43.8333 16.4118 44.5049 17.0833 45.3333 17.0833H49.25C49.8943 17.0833 50.4167 17.6057 50.4167 18.25C50.4167 18.8943 49.8943 19.4167 49.25 19.4167H45.3333C43.2162 19.4167 41.5 17.7004 41.5 15.5833V8.5Z'
                  fill='#340860'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M59.8333 8.5C59.8333 6.38291 61.5496 4.66667 63.6667 4.66667H71.4167C73.5338 4.66667 75.25 6.38291 75.25 8.5V18.0833C75.25 18.7277 74.7277 19.25 74.0833 19.25C73.439 19.25 72.9167 18.7277 72.9167 18.0833V8.5C72.9167 7.67157 72.2451 7 71.4167 7H63.6667C62.8382 7 62.1667 7.67157 62.1667 8.5V15.5833C62.1667 16.4118 62.8382 17.0833 63.6667 17.0833H70.3333C70.9777 17.0833 71.5 17.6057 71.5 18.25C71.5 18.8943 70.9777 19.4167 70.3333 19.4167H63.6667C61.5496 19.4167 59.8333 17.7004 59.8333 15.5833V8.5Z'
                  fill='#340860'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M78.5 8.5C78.5 6.38291 80.2162 4.66667 82.3333 4.66667H90.0833C92.2004 4.66667 93.9167 6.38291 93.9167 8.5V18.75C93.9167 19.3943 93.3943 19.9167 92.75 19.9167C92.1057 19.9167 91.5833 19.3943 91.5833 18.75V8.5C91.5833 7.67157 90.9118 7 90.0833 7H82.3333C81.5049 7 80.8333 7.67157 80.8333 8.5V18.75C80.8333 19.3943 80.311 19.9167 79.6667 19.9167C79.0223 19.9167 78.5 19.3943 78.5 18.75V8.5Z'
                  fill='#340860'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M91.6667 8.5C91.6667 6.38291 93.3829 4.66667 95.5 4.66667H103.25C105.367 4.66667 107.083 6.38291 107.083 8.5V13.9167C107.083 14.561 106.561 15.0833 105.917 15.0833C105.272 15.0833 104.75 14.561 104.75 13.9167V8.5C104.75 7.67157 104.078 7 103.25 7H95.5C94.6716 7 94 7.67157 94 8.5V18.75C94 19.3943 93.4777 19.9167 92.8333 19.9167C92.189 19.9167 91.6667 19.3943 91.6667 18.75V8.5Z'
                  fill='#340860'
                />
              </g>
              <defs>
                <clipPath id='clip0_8_77390'>
                  <rect width='107.333' height='20' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </Row>
          <Row justify={'center'}>
            <UniteamTopBar menuItems={prepareMenulist(menuListWithIcon)} />
          </Row>
          <Row style={{ width: '100%', padding: '0 24px' }}>
            <main>{children}</main>
          </Row>
        </div>
      )}
    </>
  );
};

export default PublicLayout;
