import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import {
  constantsSelector,
  getFaculties,
  getInterviewTypes,
  getUniversities,
} from '../../../store/constants';
import { apiURL } from '../../../service';
import { universitySelector } from '../../../store/university';
import { useNavigate } from 'react-router-dom';
import { deleteFaculty } from '../../../store/faculty';
import { notifySuccess } from '../../../helpers/notify';
import CustomizedTable from '../../../components/CustomizedTable';

const { Option } = Select;

const FacultyList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { universityList, departmentList, programList } = useAppSelector(constantsSelector);

  const [universityId, setUniversityId] = useState<string>('');

  const [programId, setProgramId] = useState<string>('');

  const [languageId, setLanguageId] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);

  const [filters, setFilters] = useState<object>();
  const [reload, setReload] = useState<boolean>(false);

  const renderUniversityOptions = useCallback(
    () => universityList.map((el: any) => ({ value: el.id, label: el.name })),
    [universityList]
  );
  useEffect(() => {
    dispatch(getFaculties());
    dispatch(getUniversities());
    dispatch(getInterviewTypes());
  }, []);

  useEffect(() => {
    let filter: any = {};
    let filterCount = 0;
    if (universityId) {
      filter.university_id = universityId;
      filterCount++;
    }

    if (searchText) {
      filter.search_text = searchText;
    }
    setFilterCount(filterCount);
    setFilters(filter);
  }, [searchText, programId, universityId, languageId]);

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: getLocalString(translations, 'FacultyList.Columns.University'),
      dataIndex: 'university',
      key: 'university',
      render: (e: any, record: any) => {
        return record.university?.name;
      },
    },
    {
      title: getLocalString(translations, 'FacultyList.Columns.FacultyName'),

      dataIndex: 'name',
      key: 'name',
    },
    {
      title: getLocalString(translations, 'FacultyList.Columns.City'),

      dataIndex: 'city',
      key: 'city',
      render: (e: any, record: any) => {
        return record.location?.city;
      },
    },
  ];

  return (
    <PageContainer
      pageName={getLocalString(translations, 'FacultyList.Title')}
      extra={
        // isCreate && (
        <Button
          // disabled={!isCreate}
          size='large'
          type='primary'
          onClick={() => navigate('/create-faculty')}
        >
          {getLocalString(translations, 'FacultyList.CreateFaculty')}
        </Button>
        // )
      }
    >
      <PageFilter
        count={filterCount}
        clear={() => {
          setUniversityId('');
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Select
              style={{ width: '100%' }}
              value={universityId || undefined}
              placeholder={getLocalString(translations, 'FacultyList.Placeholder.SelectUniversity')}
              onChange={(value) => {
                setUniversityId(value);
              }}
              allowClear
              showSearch
              filterOption={filterOption}
              options={renderUniversityOptions()}
            ></Select>
          </Col>
        </Row>
      </PageFilter>

      <Row style={{ marginTop: '24px' }}>
        <Row style={{ marginBottom: '24px' }}>
          <Search value={searchText} onChange={(value) => setSearchText(value)} />
        </Row>

        <Row>
          <CustomizedTable
            columns={columns}
            data={[]}
            url={apiURL.getFacultyList}
            filter={filters}
            reload={reload}
            setReload={setReload}
            actions={[
              {
                action: (record) => {
                  navigate('/edit-faculty/' + record.id);
                },
                type: 'edit',

                hidden: (record: any) => !isUpdate ?? true,
              },
              {
                action: (record) => {
                  dispatch(
                    deleteFaculty({
                      id: record.id,
                      onSuccess: (data: any) => {
                        notifySuccess(
                          getLocalString(
                            translations,
                            'FacultyList.NotifySuccess.FacultyDeletedSuccessfully'
                          )
                        );
                        setReload(true);
                      },
                    })
                  );
                },
                type: 'delete',

                hidden: (record: any) => !isUpdate ?? true,
              },
            ]}
          />
        </Row>
      </Row>
    </PageContainer>
  );
};

export default FacultyList;
