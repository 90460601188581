export enum ActionTypes {
  GET_STUDENT_LIST = 'GET_STUDENT_LIST',
  GET_STUDENT = 'GET_STUDENT',
  CREATE_STUDENT = 'CREATE_STUDENT',
  UPDATE_STUDENT = 'UPDATE_STUDENT',
  UPLOAD_STUDENT_FIlE = 'UPLOAD_STUDENT_FIlE',
  DELETE_FILE = 'DELETE_FILE',
  DELETE_STUDENT = 'DELETE_STUDENT',
  APPLY_FOR_THIS_STUDENT = 'APPLY_FOR_THIS_STUDENT',
  GET_STUDENT_LIST_EXCEL_EXPORT = 'GET_STUDENT_LIST_EXCEL_EXPORT',
}
