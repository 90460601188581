import { IMenu } from '../types/menu';

export const adminMenuList: IMenu[] = [
  {
    id: '0',
    name: 'Dashboard',
    dropdown: false,
    url: '/dashboard',
    menuItems: [],
    icon: 'DashboardOutlined',
    menuGroups: [],
  },
  {
    id: '341',
    name: 'Students',
    url: '/student-list',
    icon: 'UserAddOutlined',
    is_view: true,
    is_create: true,
    is_update: true,
    is_delete: true,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 344,
        name: 'Student Edit',
        url: '/update-student',
      },
      {
        id: 343,
        name: 'Student Create',
        url: '/create-student',
      },
    ],
  },
  {
    id: '338',
    name: 'Applications',
    url: '/applications-list',
    icon: 'FolderAddOutlined',
    is_view: true,
    is_create: false,
    is_update: true,
    is_delete: false,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 340,
        name: 'Department Edit',
        url: '/applies-detail',
      },
    ],
  },

  {
    id: '329',
    name: 'Universities',
    url: '/universities-list',
    icon: 'HomeOutlined',
    is_view: true,
    is_create: true,
    is_update: true,
    is_delete: false,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 332,
        name: 'University Edit',
        url: '/edit-university',
      },
      {
        id: 331,
        name: 'University Create',
        url: '/add-university',
      },
    ],
  },
  {
    id: '334',
    name: 'Search Departments',
    url: '/search-department',
    icon: 'BankOutlined',
    is_view: true,
    is_create: true,
    is_update: true,
    is_delete: false,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 337,
        name: 'Department Edit',
        url: '/edit-department',
      },
      {
        id: 336,
        name: 'Department Create',
        url: '/create-department',
      },
    ],
  },

  {
    id: '346',
    name: 'Upcoming Deadlines',
    url: '/upcoming-deadlines-list',
    icon: 'ExclamationCircleOutlined',
    is_view: false,
    is_create: false,
    is_update: false,
    is_delete: false,
    dropdown: false,
    menuItems: [],
    menuGroups: [],
  },
  {
    id: '347',
    name: 'Exceeded Deadlines',
    url: '/exceed-deadlines-list',
    icon: 'StopOutlined',
    is_view: false,
    is_create: false,
    is_update: false,
    is_delete: false,
    dropdown: false,
    menuItems: [],
    menuGroups: [],
  },

  {
    id: '354',
    name: 'Admin List',
    url: '/admin-list',
    icon: 'UserOutlined',
    is_view: true,
    is_create: true,
    is_update: false,
    is_delete: false,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 355,
        name: 'Admin Update',
        url: '/update-admin',
      },
      {
        id: 356,
        name: 'Admin Create',
        url: '/create-admin',
      },
    ],
  },
  {
    id: '348',
    name: 'Agency',
    url: '/agency-list',
    icon: 'UserOutlined',
    is_view: true,
    is_create: true,
    is_update: false,
    is_delete: false,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 350,
        name: 'Agency Create',
        url: '/create-agency',
      },
      {
        id: 349,
        name: 'Agency View',
        url: '/agency-detail',
      },
      {
        id: 0,
        name: 'Create Worker',
        url: '/create-worker',
      },
    ],
  },
];

export const agencyMenuList: IMenu[] = [
  {
    id: '0',
    name: 'Dashboard',
    dropdown: false,
    url: '/dashboard',
    menuItems: [],
    icon: 'DashboardOutlined',
    menuGroups: [],
  },
  {
    id: '341',
    name: 'Students',
    url: '/student-list',
    icon: 'UserAddOutlined',
    is_view: true,
    is_create: true,
    is_update: true,
    is_delete: true,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 344,
        name: 'Student Edit',
        url: '/update-student',
      },
      {
        id: 343,
        name: 'Student Create',
        url: '/create-student',
      },
    ],
  },
  {
    id: '338',
    name: 'Applications',
    url: '/applications-list',
    icon: 'FolderAddOutlined',
    is_view: true,
    is_create: false,
    is_update: true,
    is_delete: false,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 340,
        name: 'Department Edit',
        url: '/applies-detail',
      },
    ],
  },
  {
    id: '329',
    name: 'Universities',
    url: '/universities-list',
    icon: 'HomeOutlined',
    is_view: true,
    is_create: true,
    is_update: true,
    is_delete: false,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 332,
        name: 'University Edit',
        url: '/edit-university',
      },
      {
        id: 331,
        name: 'University Create',
        url: '/add-university',
      },
    ],
  },
  {
    id: '334',
    name: 'Search Departments',
    url: '/search-department',
    icon: 'BankOutlined',
    is_view: true,
    is_create: true,
    is_update: true,
    is_delete: false,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 337,
        name: 'Department Edit',
        url: '/edit-department',
      },
      {
        id: 336,
        name: 'Department Create',
        url: '/create-department',
      },
    ],
  },

  {
    id: '346',
    name: 'Upcoming Deadlines',
    url: '/upcoming-deadlines-list',
    icon: 'ExclamationCircleOutlined',
    is_view: false,
    is_create: false,
    is_update: false,
    is_delete: false,
    dropdown: false,
    menuItems: [],
    menuGroups: [],
  },
  {
    id: '347',
    name: 'Exceeded Deadlines',
    url: '/exceed-deadlines-list',
    icon: 'StopOutlined',
    is_view: false,
    is_create: false,
    is_update: false,
    is_delete: false,
    dropdown: false,
    menuItems: [],
    menuGroups: [],
  },

  {
    id: '354',
    name: 'Admin List',
    url: '/admin-list',
    icon: 'UserOutlined',
    is_view: true,
    is_create: true,
    is_update: false,
    is_delete: false,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 355,
        name: 'Admin Update',
        url: '/update-admin',
      },
      {
        id: 356,
        name: 'Admin Create',
        url: '/create-admin',
      },
    ],
  },
  {
    id: '348',
    name: 'Agency',
    url: '/agency-list',
    icon: 'UserOutlined',
    is_view: true,
    is_create: true,
    is_update: false,
    is_delete: false,
    dropdown: true,
    menuItems: [],
    menuGroups: [
      {
        id: 350,
        name: 'Agency Create',
        url: '/create-agency',
      },
      {
        id: 349,
        name: 'Agency View',
        url: '/agency-detail',
      },
      {
        id: 0,
        name: 'Create Worker',
        url: '/create-worker',
      },
    ],
  },
];

export const a: IMenu[] = [
  {
    id: '0',
    name: 'Dashboard',
    dropdown: false,
    url: '/dashboard',
    menuItems: [],
    icon: 'DashboardOutlined',
  },
  {
    id: '1',
    name: 'Students',
    dropdown: false,
    url: '/student-list',
    menuItems: [],
    icon: 'UserAddOutlined',
  },
  {
    id: '2',
    name: 'Applications',
    dropdown: false,
    url: '/applications-list',
    menuItems: [],
    icon: 'FolderAddOutlined',
  },
  {
    id: '3',
    name: 'Universities',
    dropdown: false,
    url: '/universities-list',
    menuItems: [],
    icon: 'HomeOutlined',
  },
  {
    id: '4',
    name: 'Search Departments',
    dropdown: false,
    url: '/search-department',
    menuItems: [],
    icon: 'BankOutlined',
  },
  // {
  //   id: '5',
  //   name: 'My Contracts',
  //   dropdown: false,
  //   url: '/application',
  //   menuItems: [],
  //   icon: 'QuestionCircleOutlined',
  // },
  {
    id: '6',
    name: 'Upcoming Deadlines',
    dropdown: false,
    url: '/upcoming-deadlines-list',
    menuItems: [],
    icon: 'ExclamationCircleOutlined',
  },
  {
    id: '7',
    name: 'Exceeded Deadlines',
    dropdown: false,
    url: '/exceed-deadlines-list',
    menuItems: [],
    icon: 'StopOutlined',
  },
  // {
  //   id: '8',
  //   name: 'User Settings',
  //   dropdown: false,
  //   url: '/user-settings',
  //   menuItems: [],
  //   icon: 'UserOutlined', // sadece admin de çıkacak
  // },
  {
    id: '9',
    name: 'Admin',
    dropdown: false,
    url: '/admin-list',
    menuItems: [],
    icon: 'UserOutlined', // sadece admin de çıkacak
  },
  {
    id: '10',
    name: 'Agency',
    dropdown: false,
    url: '/agency-list',
    menuItems: [],
    icon: 'UserOutlined', // sadece admin de çıkacak
  },
];

export const publicMenuListesi: IMenu[] = [
  {
    id: '0',
    name: 'Apply',
    dropdown: false,
    url: '/apply',
    menuItems: [],
    icon: 'UserAddOutlined',
  },
  {
    id: '1',
    name: 'Tracking',
    dropdown: false,
    url: '/tracking-code',
    menuItems: [],
    icon: 'DashboardOutlined',
  },
  {
    id: '2',
    name: 'Programs',
    dropdown: false,
    url: '/study-search  ',
    menuItems: [],
    icon: 'BankOutlined',
  },
];

// menu = [
//   {
//     name: 'Accounting',
//     icon: 'CalculatorOutlined',
//     dropdown: true,
//     url: '',
//     menuItems: [
//       {
//         url: '/vendorList',
//         name: 'Vendor List',
//         isView: true,
//         isCreate: true,
//         isUpdate: true,
//         isDelete: true,
//         menuGroups: [
//           {
//             name: 'Create Vendor',
//             url: '/addVendor',
//           },
//           {
//             name: 'Vendor Details',
//             url: '/editVendor',
//           },
//         ],
//       },
//       {
//         url: '/billingList',
//         name: 'Billing List',
//         isView: true,
//         isCreate: true,
//         isUpdate: true,
//         isDelete: true,
//         menuGroups: [
//           {
//             name: 'Billing International Detail',
//             url: '/billingInternationalDetail',
//           },
//           {
//             name: 'Billing Domestic Detail',
//             url: '/billingDomesticDetail',
//           },
//         ],
//       },
//       {
//         url: '/accountingDashboard',
//         name: 'Accounting Details',
//         isView: true,
//         isCreate: true,
//         isUpdate: true,
//         isDelete: true,
//         menuGroups: [],
//       },
//     ],
//   },
// ];
