import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiURL, axiosClient } from '../../service';
import { IActionCallback } from '../types';
import { ActionTypes } from './action-types';
import { notifyError } from '../../helpers/notify';
import qs from 'qs';

export const getUniversities = createAsyncThunk(ActionTypes.GET_UNIVERSITIES, async () => {
  try {
    const response = await axiosClient.get(apiURL.getUniversities);
    return response.data;
  } catch (error: any) {
    notifyError(error.message);
  }
});
interface IGetUniversityDepartmentInput extends IActionCallback {
  universityID: string;
}

export const getUniversityDepartments = createAsyncThunk(
  ActionTypes.GET_UNIVERSITY_DEPARTMENTS,
  async (data: IGetUniversityDepartmentInput) => {
    const { universityID, onSuccess } = data;
    try {
      const response = await axiosClient.get(
        apiURL.getUniversityDepartments.replace(':id', universityID)
      );
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      notifyError(error.message);
    }
  }
);
export const getCountryList = createAsyncThunk(ActionTypes.GET_COUNTRIES, async () => {
  const response = await axiosClient.get(apiURL.getCountries);
  return response.data;
});

export const getGenders = createAsyncThunk(ActionTypes.GET_GENDERS, async () => {
  try {
    const response = await axiosClient.get(apiURL.getGenders);
    return response.data;
  } catch (error: any) {
    notifyError(error.message);
  }
});
export const getLanguages = createAsyncThunk(ActionTypes.GET_LANGUAGES, async () => {
  try {
    const response = await axiosClient.get(apiURL.getLanguages);
    return response.data;
  } catch (error: any) {
    notifyError(error.message);
  }
});
export const getApplicationTypes = createAsyncThunk(ActionTypes.GET_APPLICATION_TYPES, async () => {
  try {
    const response = await axiosClient.get(apiURL.getApplicationTypes);
    return response.data;
  } catch (error: any) {
    notifyError(error.message);
  }
});
export const getPrograms = createAsyncThunk(ActionTypes.GET_PROGRAMS, async () => {
  try {
    const response = await axiosClient.get(apiURL.getPrograms);
    return response.data;
  } catch (error: any) {
    notifyError(error.message);
  }
});
export const getSemesterList = createAsyncThunk(ActionTypes.GET_SEMESTERS, async () => {
  const response = await axiosClient.get(apiURL.getSemesters);
  return response.data;
});
export const getUsers = createAsyncThunk(ActionTypes.GET_USERS, async () => {
  try {
    const response = await axiosClient.get(apiURL.getUsers);
    return response.data;
  } catch (error: any) {
    notifyError(error.message);
  }
});

export const getAdminAndAdminWorkerUsers = createAsyncThunk(
  ActionTypes.GET_ADMIN_USERS,
  async () => {
    try {
      const response = await axiosClient.get(apiURL.getAdminUsers);
      return response.data;
    } catch (error: any) {
      notifyError(error.message);
    }
  }
);

export const getCurrency = createAsyncThunk(ActionTypes.GET_CURRENCY, async () => {
  try {
    const response = await axiosClient.get(apiURL.getCurrency);
    return response.data;
  } catch (error: any) {
    notifyError(error.message);
  }
});

export const getNotifications = createAsyncThunk(ActionTypes.GET_NOTIFICATIONS, async () => {
  try {
    const response = await axiosClient.get(apiURL.getNotifications);
    return response.data;
  } catch (error: any) {
    notifyError(error.message);
  }
});
export const getTurkeyCities = createAsyncThunk(ActionTypes.GET_TURKEY_CITIES, async () => {
  const response = await axiosClient.get(apiURL.getTurkeyCities);
  return response.data;
});
export const getFaculties = createAsyncThunk(ActionTypes.GET_FACULTIES, async () => {
  const response = await axiosClient.get(apiURL.getFaculties);
  return response.data;
});
interface IGetFacultiesByUniversityInput extends IActionCallback {
  university_id: string;
}

export const getFacultiesByUniversity = createAsyncThunk(
  ActionTypes.GET_UNIVERSITY_DEPARTMENTS,
  async (data: IGetFacultiesByUniversityInput) => {
    const { university_id, onSuccess } = data;
    try {
      const response = await axiosClient.get(
        apiURL.getFacultiesByUniversity.replace(':university_id', university_id)
      );
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      notifyError(error.message);
    }
  }
);
export const getInterviewTypes = createAsyncThunk(ActionTypes.GET_INTERVIEW_TYPES, async () => {
  const response = await axiosClient.get(apiURL.getInterviewTypes);
  return response.data;
});
export const getInterviewStatusList = createAsyncThunk(
  ActionTypes.GET_INTERVIEW_STATUS_LIST,
  async () => {
    const response = await axiosClient.get(apiURL.getInterviewStatusList);
    return response.data;
  }
);

interface IGetUniversityFacultiesInput extends IActionCallback {
  universityID: string;
}

export const getUniversityFaculties = createAsyncThunk(
  ActionTypes.GET_UNIVERSITY_FACULTIES,
  async (data: IGetUniversityFacultiesInput) => {
    const { universityID, onSuccess } = data;
    try {
      const response = await axiosClient.get(
        apiURL.getUniversityFaculties.replace(':id', universityID)
      );
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      notifyError(error.message);
    }
  }
);
interface IGetFacultyDepartmentInput extends IActionCallback {
  faculty_id: string;
  program_id?: string;
  language_id?: string;
}

export const getFacultyDepartments = createAsyncThunk(
  ActionTypes.GET_FACULTY_DEPARTMENTS,
  async (data: IGetFacultyDepartmentInput) => {
    const { faculty_id, program_id, language_id, onSuccess } = data;
    try {
      const response = await axiosClient.get(
        apiURL.getFacultyDepartments + qs.stringify({ faculty_id, program_id, language_id })
      );
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      notifyError(error.message);
    }
  }
);
