import { useContext, useState } from 'react';
import { useEffect } from 'react';
import styles from './login.module.scss';
import globalStyles from '../../styles/global.module.scss';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { AuthService } from '../../service/AuthServiceV2';
import { Alert, Button, Col, Form, Input, Modal, Row, Upload } from 'antd';
import { ILoginInputs } from '../../types/login';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { accountSelector, forgotPassword, login, resetPassword } from '../../store/account';
import { notifyError, notifySuccess } from '../../helpers/notify';

import { Header } from 'antd/es/layout/layout';
import uniteamLogo from '../../resources/images/uniteamLogo.svg';
import { languageSelector } from '../../store/language';
import { getLocalString } from '../../helpers/language';

const NewPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { token } = useParams();
  const { appLanguage, translations } = useAppSelector(languageSelector);

  const [formReset] = Form.useForm();

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token]);

  const onFinishReset = (values: any) => {
    token &&
      dispatch(
        resetPassword({
          token: token,
          password_confirmation: values.confirm,
          password: values.password,
          onSuccess: (data: any) => {
            notifySuccess(getLocalString(translations, 'Login.ResetPasswordModal.Message.Changed'));
            navigate('/');
          },
          onError: (error: any) => notifyError(error),
        })
      );
  };

  const passwordRules = [
    {
      required: true,
      message: getLocalString(translations, 'Login.NewPasswordModal.Message.PleaseEnterPassword'),
    },
    {
      min: 8,
      message: getLocalString(translations, 'Login.NewPasswordModal.Message.PasswordLong'),
    },
    {
      pattern: new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/),
      message: getLocalString(translations, 'Login.NewPasswordModal.Message.RequireChar'),
    },
  ];

  return (
    <div
      style={{
        background: '#FAF5FF',
        minHeight: '100vh',
      }}
    >
      <Row>
        <img style={{ margin: '55px 78px' }} src={uniteamLogo} alt='' />
      </Row>
      <div
        style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', height: '80vh' }}
      >
        <Row>
          <Col xs={2} sm={4} md={6} lg={6} xl={6}></Col>
          <Col xs={20} sm={16} md={12} lg={12} xl={12}>
            <Row
              style={{
                borderRadius: '24px',
                background: '#FAF5FF',
                width: '100%',
                padding: '48px',
                boxShadow: '0px 16px 72px 0px rgba(6, 28, 61, 0.08)',
                transform: 'translate(0%, -5%)',
              }}
            >
              <Row style={{ width: '100%' }}>
                <Row
                  style={{ color: '#555', width: '100%', marginBottom: '16px' }}
                  justify={'center'}
                  className={`${globalStyles.headline_m_medium} `}
                >
                  {getLocalString(translations, 'Login.NewPasswordModal.Title')}
                </Row>
              </Row>
              <Row style={{ width: '100%' }}>
                <Form
                  style={{ width: '100%' }}
                  form={formReset}
                  onFinish={onFinishReset}
                  layout='vertical'
                >
                  {' '}
                  <Row>
                    <Form.Item
                      style={{ width: '100%' }}
                      name='password'
                      label={getLocalString(translations, 'Login.NewPasswordModal.NewPassword')}
                      rules={passwordRules}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%' }}
                      name='confirm'
                      label={getLocalString(
                        translations,
                        'Login.NewPasswordModal.NewPasswordConfirm'
                      )}
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: getLocalString(
                            translations,
                            'Login.NewPasswordModal.EnterPassword'
                          ),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                getLocalString(translations, 'Login.NewPasswordModal.NotMatch')
                              )
                            );
                          },
                        }),
                        ...passwordRules, // Yeni şifrenin tekrarı için aynı doğrulama kurallarını uygula
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item style={{ width: '100%' }}>
                      <Button style={{ width: '100%' }} type='primary' htmlType='submit' block>
                        {getLocalString(translations, 'Login.NewPasswordModal.ResetPassword')}
                      </Button>
                    </Form.Item>{' '}
                  </Row>
                </Form>
              </Row>
            </Row>
          </Col>
          <Col xs={2} sm={4} md={6} lg={6} xl={6}></Col>
        </Row>
      </div>
    </div>
  );
};

export default NewPassword;
