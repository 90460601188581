import React, { useCallback, useEffect, useState } from 'react';
import { Table, Button, Modal, Tooltip, Popover, Menu } from 'antd';
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FileSearchOutlined,
  ExceptionOutlined,
  SendOutlined,
  MoreOutlined,
  CreditCardOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  LinkOutlined,
  PrinterOutlined,
  PauseCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import { CargoPickOutlined } from '../customIcons/CargoPickOutlined';

import qs from 'qs';

import styles from './index.module.scss';
import { useAppSelector } from '../../store/hooks';
import { getLocalString } from '../../helpers/language';
import { languageSelector } from '../../store/language';
import { axiosClient } from '../../service';
import _ from 'lodash';
import { PackageOutlined } from '../customIcons/packageOutlined';

const { confirm } = Modal;
interface actionButtons {
  action: (record: any) => void;
  disabled?: ((record: any) => boolean) | boolean;
  hidden?: ((record: any) => boolean) | boolean;
  url?: ((record: any) => void) | string;
  type:
    | 'accept'
    | 'active'
    | 'passive'
    | 'delete'
    | 'deleteCustom'
    | 'edit'
    | 'detail'
    | 'rejected'
    | 'label'
    | 'ready'
    | 'pay'
    | 'approve'
    | 'print'
    | 'reject'
    | 'read';
}

interface onRowSelection {
  selectedRowKeys: any[];
  onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => void;
  onSelect?: (record: any, selected: boolean, selectedRows: any[], nativeEvent: any) => void;
}

interface ITableProps {
  onDoubleClick?: (record: any) => void;
  onClick?: (record: any) => void;
  data: any[];
  columns: any[];
  defaultPageSize?: number;
  disablePagination?: boolean;
  showCounter?: boolean;
  loading?: boolean;
  totals?: any[];
  rowClass?: (record: any) => string;
  url?: string;
  filter?: {};
  actions?: actionButtons[];
  reload?: boolean;
  rowSelection?: onRowSelection;
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
  scrollX?: number;
  scrollY?: number;
  useFilter?: boolean;
  bordered?: boolean;
}

const CustomizedTable: React.FC<ITableProps> = ({
  onDoubleClick,
  onClick,
  data,
  columns,
  defaultPageSize,
  disablePagination,
  showCounter,
  loading,
  totals,
  rowClass,
  url,
  filter,
  actions,
  reload = false,
  rowSelection,
  setReload,
  scrollX,
  scrollY,
  bordered = true,
  useFilter = true,
}) => {
  const { appLanguage, translations } = useAppSelector(languageSelector);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [load, setLoad] = useState<boolean>(false);
  const [existingFilter, setExistingFilter] = useState<object | undefined>(undefined);

  const buttons = {
    delete: {
      icon: <DeleteOutlined className={styles.table_icon} />,
      tooltip: getLocalString(translations, 'CustomizedTable.Delete'),
    },
    deleteCustom: {
      icon: <DeleteOutlined className={styles.table_icon} />,
      tooltip: 'Delete',
    },
    edit: {
      icon: <EditOutlined className={styles.table_icon} />,
      tooltip: getLocalString(translations, 'CustomizedTable.Edit'),
    },
    label: {
      icon: <LinkOutlined className={styles.table_icon} />,
      tooltip: 'Link',
    },
    rejected: {
      icon: <ExceptionOutlined className={styles.table_icon} />,
      tooltip: 'Reddetme Sebebi',
    },
    detail: {
      icon: <FileSearchOutlined className={styles.table_icon} />,
      tooltip: getLocalString(translations, 'CustomizedTable.Detail'),
    },
    ready: {
      icon: <SendOutlined className={styles.table_icon} />,
      tooltip: 'Hazır',
    },
    pay: {
      icon: <CreditCardOutlined className={styles.table_icon} />,
      tooltip: 'Ödeme Yap',
    },
    approve: {
      icon: <CheckCircleOutlined className={styles.table_icon} />,
      tooltip: 'Onayla',
    },
    reject: {
      icon: <MinusCircleOutlined className={styles.table_icon} />,
      tooltip: 'Reddet',
    },
    print: {
      icon: <PrinterOutlined className={styles.table_icon} />,
      tooltip: 'Yazdır',
    },
    accept: {
      icon: <CheckCircleOutlined className={styles.table_icon} />,
      tooltip: 'Kabul Et',
    },

    active: {
      icon: <CheckCircleOutlined className={styles.table_icon} />,
      tooltip: 'Aktif',
    },
    passive: {
      icon: <PauseCircleOutlined className={styles.table_icon} />,
      tooltip: 'Pasif',
    },
    read: {
      icon: <EyeOutlined className={styles.table_icon} />,
      tooltip: 'Read',
    },
  };

  function showModal(record: any, action: actionButtons) {
    switch (action.type) {
      case 'delete':
        confirm({
          title:
            // getLocalString(
            //   translations[appLanguage],
            //   'Components.CustomizedTable.DeleteModal.Title'
            // ),

            'Do you want to delete this record?',
          // content: 'heyy',
          icon: <ExclamationCircleOutlined />,
          async onOk() {
            action.action(record);
          },
          // okText: 'Delete',
          okType: 'primary',
          onCancel() {},
        });
        break;
      case 'print':
        break;
      default:
        action.action(record);
        break;
    }
  }

  const getDataFromServer = async (filter: any, pageChanged?: boolean | undefined) => {
    if (url) {
      //if (load) return;
      // console.log('pageChanged', pageChanged);
      // console.log('currentPage', currentPage);

      // sayfa ilk açıldığında filtre undefined geliyorsa sorgu atma
      if (useFilter) if (!filter) return;

      if (pageChanged) {
        if (!filter) filter = { page: currentPage, per_page: perPage };
        if (filter) filter = { ...filter, page: currentPage, per_page: perPage };
      } else {
        setCurrentPage(1);
        if (!filter) filter = { page: 1, per_page: perPage };
        if (filter) filter = { ...filter, page: 1, per_page: perPage };
      }

      try {
        setLoad(true);

        const stringFilter = qs.stringify(filter);

        const result = await axiosClient.get(url + '?' + stringFilter);

        const newData = result.data.data.data.map((el: any, i: number) => ({
          key: el?.id || '' + i,
          ind: i,
          ...el,
        }));
        setParsedData(newData);

        setTotalCount(result.data.data.meta.total);
        setLoad(false);
      } catch (error) {
        setParsedData([]);
        setTotalCount(0);
        setLoad(false);
      }
    } else {
      // if (!pageChanged) {
      //   setCurrentPage(1);
      // }
      const newData = data.map((el, i) => ({ key: el?.id || '' + i, ind: i, ...el }));
      setParsedData(newData);
      setTotalCount(newData.length);
    }
  };

  useEffect(() => {
    if (url) {
      if (reload) {
        getDataFromServer(filter);
        if (setReload) setReload(false);
        // console.log(reload, 'jkhdfbksajhbfdlashjclasjs');
        return;
      }
      // console.log(reload, 2, 'jkhdfbksajhbfdlashjclasjs');
      // parent component renders 2 times and table component fecth data 2 times baceuse of the async which data come second
      // table filling this data. We put this kodu there for avoid 2 times fetch data to table
      // console.log('log', filter, existingFilter);
      if (_.isEqual(filter, existingFilter)) return;
      setExistingFilter(filter);
      getDataFromServer(filter);
    } else {
      getDataFromServer(filter);
    }
  }, [filter, data, reload]);

  useEffect(() => {
    getDataFromServer(filter, true);
  }, [currentPage]);

  useEffect(() => {
    getDataFromServer(filter);
  }, [perPage]);

  const drawActions = useCallback(
    (record: any) => {
      let actionArray: JSX.Element[] = [];
      if (actions) {
        let drawableActions = actions.filter((el) =>
          typeof el.hidden === 'function' ? !el.hidden?.(record) : !el.hidden
        );

        if (drawableActions.length > 2) {
          if (drawableActions[0].type === 'label') {
            actionArray.push(
              <Tooltip color='#4D5D6D' placement='bottom' title='Label'>
                {/* <a
                  target='_blank'
                  href={
                    typeof drowableActions[0].url === 'function'
                      ? drowableActions[0].url(record)
                      : drowableActions[0].url
                  }
                >
                  <Icon className={styles.table_icon} component={LabelIcon} />
                </a> */}

                <Button
                  size='middle'
                  className={styles.button}
                  icon={buttons[drawableActions[0].type].icon}
                  disabled={
                    typeof drawableActions[0].disabled === 'function'
                      ? drawableActions[0].disabled(record)
                      : drawableActions[0].disabled
                  }
                  hidden={
                    typeof drawableActions[0].hidden === 'function'
                      ? drawableActions[0].hidden?.(record)
                      : drawableActions[0].hidden
                  }
                  onClick={() =>
                    typeof drawableActions[0].url === 'function'
                      ? drawableActions[0].url(record)
                      : window.open(drawableActions[0].url, '_blank')
                  }
                ></Button>
              </Tooltip>
            );
          } else {
            actionArray.push(
              <Tooltip
                color='#4D5D6D'
                placement='bottom'
                title={buttons[drawableActions[0].type].tooltip}
              >
                <Button
                  size='middle'
                  className={styles.button}
                  icon={buttons[drawableActions[0].type].icon}
                  disabled={
                    typeof drawableActions[0].disabled === 'function'
                      ? drawableActions[0].disabled(record)
                      : drawableActions[0].disabled
                  }
                  hidden={
                    typeof drawableActions[0].hidden === 'function'
                      ? drawableActions[0].hidden?.(record)
                      : drawableActions[0].hidden
                  }
                  onClick={() => showModal(record, drawableActions[0])}
                ></Button>
              </Tooltip>
            );
          }

          actionArray.push(
            <Popover
              placement='bottom'
              overlayInnerStyle={{ padding: 0 }}
              content={
                <Menu
                  items={drawableActions.slice(1).map((el) => ({
                    label: buttons[el.type].tooltip,
                    icon: buttons[el.type].icon,
                    onClick: () => {
                      showModal(record, el);
                    },
                    key: el.type,
                    disabled: typeof el.disabled === 'function' ? el.disabled(record) : el.disabled,
                  }))}
                >
                  {/* {drowableActions.slice(1).map((el) => (
                    <Menu.Item
                      key={el.type}
                      onClick={() => {
                        showModal(record, el);
                      }}
                      disabled={
                        typeof el.disabled === 'function' ? el.disabled(record) : el.disabled
                      }
                      hidden={typeof el.hidden === 'function' ? el.hidden?.(record) : el.hidden}
                      icon={buttons[el.type].icon}
                    >
                      {buttons[el.type].tooltip}
                    </Menu.Item>
                  ))} */}
                </Menu>
              }
              trigger='hover'
            >
              <Button
                //type='button'
                size='middle'
                className={styles.button}
                icon={<MoreOutlined className={styles.table_icon} />}
                //disabled={editDisabled?.(record)}
                //onClick={() => }
              ></Button>
            </Popover>
          );
          return actionArray;
        } else {
          return drawableActions.map((el) =>
            el.type !== 'label' ? (
              <Tooltip color='#4D5D6D' placement='bottom' title={buttons[el.type].tooltip}>
                <Button
                  size='middle'
                  className={styles.button}
                  icon={buttons[el.type].icon}
                  disabled={typeof el.disabled === 'function' ? el.disabled(record) : el.disabled}
                  hidden={typeof el.hidden === 'function' ? el.hidden?.(record) : el.hidden}
                  onClick={() => showModal(record, el)}
                ></Button>
              </Tooltip>
            ) : (
              <Tooltip color='#4D5D6D' placement='bottom' title='Label'>
                <Button
                  size='middle'
                  className={styles.button}
                  icon={buttons[el.type].icon}
                  disabled={typeof el.disabled === 'function' ? el.disabled(record) : el.disabled}
                  hidden={typeof el.hidden === 'function' ? el.hidden?.(record) : el.hidden}
                  onClick={() =>
                    typeof el.url === 'function' ? el.url(record) : window.open(el.url)
                  }
                ></Button>
                {/* <a target='_blank' href={typeof el.url === 'function' ? el.url(record) : el.url}>
                  <Icon className={styles.table_icon} component={LabelIcon} />
                </a> */}
              </Tooltip>
            )
          );
        }
      } else {
        return <></>;
      }
    },
    [actions]
  );

  const fixedColumns: any = [];
  if (showCounter) {
    fixedColumns.push({
      title: '#',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      render: (e: any, record: any) => <div>{record.ind + ((currentPage - 1) * perPage + 1)}</div>,
      fixed: 'left',
    });
  }

  fixedColumns.push(...columns);

  if (actions) {
    fixedColumns.push({
      title: getLocalString(translations, 'CustomizedTable.Actions'),
      width: '140px',
      key: 'Action',
      fixed: 'right',
      render: (e: any, record: any) =>
        record.id !== 'new' && <div className={styles.action_colum}>{drawActions(record)}</div>,
    });
  }

  let checkBoxField = undefined;
  if (rowSelection) {
    checkBoxField = {
      // type: selectionType,
      preserveSelectedRowKeys: true, // data içinden satırlar çıkarılsa bile checklenmiş satır bilgisini dönmeye devm ediyor
      selectedRowKeys: rowSelection.selectedRowKeys,
      onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        rowSelection.onChange(selectedRowKeys, selectedRows);
      },
      onSelect: (record: any, selected: boolean, selectedRows: any[], nativeEvent: any) => {
        console.log(record, selected, selectedRows, nativeEvent);
      },
    };
  }

  return (
    <div style={{ width: '100%' }}>
      <Table
        className={styles.table}
        rowSelection={checkBoxField}
        onRow={(record) => {
          return {
            onDoubleClick: () => onDoubleClick?.(record),
            onClick: () => onClick?.(record),
          };
        }}
        rowClassName={(record) => rowClass?.(record) || ''}
        size={'middle'}
        bordered={bordered}
        columns={fixedColumns}
        dataSource={parsedData}
        scroll={{
          y: scrollY ? scrollY : 500,
          x: scrollX ? scrollX : undefined, // 'undefined' will not apply a horizontal scroll
        }}
        pagination={
          disablePagination || totalCount < 10
            ? false
            : {
                // position: ['bottomCenter'],
                current: currentPage,
                defaultCurrent: 1,
                defaultPageSize: defaultPageSize || 10,
                showSizeChanger: true,
                total: totalCount,
                showTotal: (total, range) => ` ${range[0]}-${range[1]} of ${total} items`,
              }
        }
        loading={loading || load}
        summary={(pageData) => {
          return (
            totals && (
              <Table.Summary.Row>
                {/* {countSummery()} */}
                {fixedColumns?.map((el: any, index: any) =>
                  totals?.find((el2) => el2.key === el.key) ? (
                    <Table.Summary.Cell index={index + 1}>
                      {
                        <div>
                          <p className={styles.summery_title}>
                            {totals?.find((el2) => el2.key === el.key).title}
                          </p>
                          <p className={styles.summery_value}>
                            {totals?.find((el2) => el2.key === el.key).value}
                          </p>
                        </div>
                      }
                    </Table.Summary.Cell>
                  ) : (
                    <Table.Summary.Cell index={index + 1}></Table.Summary.Cell>
                  )
                )}
              </Table.Summary.Row>
            )
          );
        }}
        onChange={(pagination, filters, sorter) => {
          setCurrentPage(pagination.current || 1);
          setPerPage(pagination.pageSize || 10);
        }}
      />
    </div>
  );
};

export default CustomizedTable;
