import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DEFAULT_LANGUAGE, languages } from '../../resources/constants/language';
import { apiURL, axiosClient } from '../../service';
import { ActionTypes } from './action-types';
import EN from './../../resources/language/en.json';
import TR from './../../resources/language/tr.json';

export const changeAppLanguage = createAsyncThunk(
  ActionTypes.CHANGE_APP_LANGUAGE,
  async (language: string) => {
    const selectedLanguage = languages.find((el) => el.name === language) || languages[0];

    localStorage.setItem('appLanguage', JSON.stringify(selectedLanguage));
    let languageData = EN;
    try {
      languageData = selectedLanguage?.name === 'tr' ? TR : EN;
    } catch (error) {
      console.log('localization error');
    }

    return {
      language: selectedLanguage.code,
      langugeData: languageData,
    };
  }
);

export const getAppLanguage = createAsyncThunk(ActionTypes.GET_APP_LANGUAGE, async () => {
  let language = localStorage.getItem('appLanguage') || '';
  let languageData = {};
  let currentLanguage;
  if (!language) {
    currentLanguage = DEFAULT_LANGUAGE;
    localStorage.setItem('appLanguage', JSON.stringify(DEFAULT_LANGUAGE));
  } else {
    currentLanguage = JSON.parse(language);
  }

  try {
    languageData = currentLanguage.name === 'tr' ? TR : EN;
  } catch (error) {
    languageData = {};
  }

  return {
    language: currentLanguage.code,
    langugeData: languageData,
  };
});
