import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import { constantsSelector } from '../../../store/constants';
import { apiURL } from '../../../service';
import { universitySelector } from '../../../store/university';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CustomizedTable from '../../../components/CustomizedTable';
import { deleteAdmin } from '../../../store/admin';
import { notifySuccess } from '../../../helpers/notify';

const { Option } = Select;

const AgencyList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { universityList, departmentList, programList } = useAppSelector(constantsSelector);

  const [universityId, setUniversityId] = useState<string>('');
  const [DepartmentNameId, setWarehouseId] = useState<string>('');
  const [programId, setProgramId] = useState<string>('');

  const [languageId, setLanguageId] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);

  const [filters, setFilters] = useState<object>({});
  const [reload, setReload] = useState<boolean>(true);

  const columns = [
    {
      title: getLocalString(translations, 'AgencyList.AgencyId'),
      dataIndex: 'agency_id',
      key: 'agency_id',
      width: 100,
    },
    {
      title: getLocalString(translations, 'AgencyList.AgencyName'),
      dataIndex: 'agency_name',
      key: 'agency_name',
      width: 120,
    },
    {
      title: getLocalString(translations, 'AgencyList.UserName'),
      dataIndex: 'username',
      key: 'username',
      width: 120,
    },
    {
      title: getLocalString(translations, 'AgencyList.Name'),
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },

    {
      title: getLocalString(translations, 'AgencyList.Surname'),
      dataIndex: 'surname',
      key: 'surname',
      width: 120,
    },
    {
      title: getLocalString(translations, 'AgencyList.Country'),
      dataIndex: 'agency_country',
      key: 'agency_country',
      width: 120,
    },

    {
      title: getLocalString(translations, 'AgencyList.Phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: 120,
    },
    {
      title: getLocalString(translations, 'AgencyList.AgencyPhone'),
      dataIndex: 'agency_phone',
      key: 'agency_phone',
      width: 120,
    },
    {
      title: getLocalString(translations, 'AgencyList.Email'),
      dataIndex: 'email',
      key: 'email',
      width: 200,
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    // },
    {
      title: getLocalString(translations, 'AgencyList.CreatedBy'),
      dataIndex: 'created_by',
      key: 'created_by',
      render: (record: any) => record?.username,
      width: 100,
    },
    {
      title: getLocalString(translations, 'AgencyList.CreatedDate'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (e: any, record: any) => dayjs(record.created_at).format('YYYY-MM-DD'),
      width: 100,
    },
    {
      title: getLocalString(translations, 'AgencyList.Role'),
      dataIndex: 'role',
      key: 'role',
      render: (record: any) => record?.name,
      width: 100,
    },
  ];

  return (
    <PageContainer
      pageName={getLocalString(translations, 'AgencyList.Title')}
      extra={
        <Button
          size='large'
          type='primary'
          disabled={!isCreate}
          //isCreate False olursa disable olur
          onClick={() => navigate('/create-agency')}
        >
          {getLocalString(translations, 'AgencyList.CreateAgency')}
        </Button>
      }
    >
      <Row style={{ marginTop: '24px' }}>
        <div style={{ width: '100%' }}>
          <CustomizedTable
            columns={columns}
            data={[]}
            url={apiURL.getAgencyList}
            filter={filters}
            reload={reload}
            setReload={setReload}
            actions={[
              {
                action: (record) => {
                  navigate('/agency-detail/' + record.agency_id);
                },
                type: 'detail',
              },
              {
                action: (record) => {
                  navigate('/update-agency/' + record.agency_id);
                },
                type: 'edit',
                // hidden: (record: any) => !isUpdate ?? true,
                //isUpdate False olursa Saklanır
              },
            ]}
          />
        </div>
      </Row>
    </PageContainer>
  );
};

export default AgencyList;
