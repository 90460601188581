import { createReducer } from '@reduxjs/toolkit';

interface StudentState {
  pending: boolean;
  error: boolean;
}

const initialState: StudentState = {
  pending: false,
  error: false,
};

export const studentReducer = createReducer(initialState, (builder) => {});
