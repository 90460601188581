import React from 'react';

import styles from './index.module.scss';
import { Spin } from 'antd';

import { useNavigate } from 'react-router-dom';
import PageHeader from '../pageHeader';
import { useAppSelector } from '../../store/hooks';
import { spinnerSelector } from '../../store/spinner';

const PageContainer: React.FC<{
  pageName: string;
  children: React.ReactNode;
  extra?: React.ReactNode;
  pageSubName?: string;
  backUrl?: (() => void) | string;
}> = ({ pageName, children, extra, pageSubName, backUrl }) => {
  const navigate = useNavigate();

  const { isOn } = useAppSelector(spinnerSelector);
  return (
    <div className={styles.page_container}>
      <Spin spinning={isOn} size='large' tip={'Loading'}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <PageHeader
            title={pageName}
            onBack={
              typeof backUrl === 'function'
                ? backUrl
                : typeof backUrl === 'string'
                ? () => navigate(backUrl)
                : undefined
            }
          />

          {extra}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {pageSubName && <PageHeader title={pageSubName} />}
        </div>
        {children}
        {/* </Content>
        <Footer className={styles.footer}>
          <div></div>
        </Footer>
      </Layout> */}
      </Spin>
    </div>
  );
};

export default PageContainer;
