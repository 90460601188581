import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Input, Upload, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';
import globalStyles from '../../../styles/global.module.scss';

import PageContainer from '../../../components/pageContainer';
import CustomizedTable from '../../../components/CustomizedTable';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import { constantsSelector } from '../../../store/constants';
import { apiURL } from '../../../service';

import { Modal } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useNavigate, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../helpers/notify';
import { uploadFile } from '../../../store/student';
import { createUniversity } from '../../../store/university';
import { unset } from 'lodash';

const { Option } = Select;

const AddUniversity: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { turkeyCities } = useAppSelector(constantsSelector);
  const [universityName, setUniversityName] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [cityId, setCityId] = useState<string>('');
  const [isAddButtonDisabled, setAddButtonDisabled] = useState<boolean>(true);
  useEffect(() => {
    let unSpacedName = universityName.replace(/\s/g, '').length;
    if (unSpacedName && !!cityId) setAddButtonDisabled(false);
    else setAddButtonDisabled(true);
  }, [universityName, cityId]);

  const renderTurkeyCitiesOptions = useCallback(
    () =>
      turkeyCities.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name}
        </Option>
      )),
    [turkeyCities]
  );

  return (
    <PageContainer
      pageName={getLocalString(translations, 'AddUniversity.AddUniversity')}
      backUrl={'/universities-list'}
    >
      <Form>
        <Row style={{ width: '100%' }}>
          <Col offset={6} span={12}>
            <Row style={{ width: '100%', marginTop: '24px' }}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Form.Item
                  style={{ width: '100%' }}
                  name={getLocalString(translations, 'AddUniversity.UniversityName')}
                  validateTrigger='onBlur'
                  rules={[
                    {
                      required: true,
                      message: getLocalString(translations, 'AddUniversity.Validation.Name'),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                          return Promise.reject(
                            new Error(
                              getLocalString(translations, 'AddUniversity.Validation.NameSpace')
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'AddUniversity.UniversityName')}
                    </Row>
                    <Input
                      maxLength={35}
                      value={universityName || undefined}
                      onChange={(e) => {
                        setUniversityName(e.currentTarget.value);
                      }}
                      onBlur={(e) => {
                        setUniversityName(e.currentTarget.value.trim());
                      }}
                      placeholder={getLocalString(
                        translations,
                        'AddUniversity.Placeholder.UniversityName'
                      )}
                      size='large'
                      style={{ width: '100%' }}
                    />
                  </Row>
                </Form.Item>
              </Row>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'AddUniversity.Content')}
                </Row>
                <Input
                  maxLength={400}
                  style={{ width: '100%' }}
                  value={content || undefined}
                  placeholder={getLocalString(translations, 'AddUniversity.Placeholder.Content')}
                  onChange={(e) => {
                    setContent(e.currentTarget.value);
                  }}
                  allowClear
                  size='large'
                ></Input>
              </Row>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Form.Item
                  style={{ width: '100%' }}
                  name={getLocalString(translations, 'AddUniversity.City')}
                  validateTrigger='onBlur'
                  rules={[
                    // { required: true, message: 'Please Select City Name!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value) {
                          getLocalString(translations, 'AddUniversity.Validator.City');
                          return Promise.reject(new Error(''));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Row style={{ width: '100%', marginTop: '24px', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'AddUniversity.City')}
                    </Row>
                    <Select
                      style={{ width: '100%' }}
                      value={cityId || undefined}
                      placeholder={getLocalString(
                        translations,
                        'AddUniversity.Placeholder.SelectCity'
                      )}
                      onChange={(value) => {
                        setCityId(value);
                      }}
                      allowClear
                      showSearch
                      size='large'
                      filterOption={filterOption}
                    >
                      {renderTurkeyCitiesOptions()}
                    </Select>
                  </Row>
                </Form.Item>
              </Row>
            </Row>

            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                disabled={isAddButtonDisabled}
                style={!isCreate ? { display: 'none' } : {}}
                onClick={() => {
                  dispatch(
                    createUniversity({
                      name: universityName,
                      content: content,
                      city_id: cityId,
                      onSuccess: (data: any) => {
                        notifySuccess('University added Successfully');
                        navigate('/edit-university/' + data.data.id);
                      },
                    })
                    //data.id
                  );
                }}
                type='primary'
                size='large'
              >
                {getLocalString(translations, 'AddUniversity.Save')}
              </Button>
            </Row>
          </Col>
        </Row>

        <Row></Row>
      </Form>
    </PageContainer>
  );
};

export default AddUniversity;
