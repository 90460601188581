import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Select,
  Collapse,
  Row,
  Col,
  Tag,
  Input,
  Upload,
  Divider,
  Card,
  List,
  DatePicker,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';
import globalStyles from '../../../styles/global.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import {
  constantsSelector,
  getFacultyDepartments,
  getUniversityFaculties,
} from '../../../store/constants';
import { apiURL } from '../../../service';

import { Modal } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import TextArea from 'antd/es/input/TextArea';
import { useParams } from 'react-router-dom';
import {
  addNote,
  applicationSelector,
  editApplication,
  editDeadlineAndPin,
  getApplication,
  getApplicationStatus,
  getApplicationUsers,
  getApplicationZip,
  updateApplicationStatus,
} from '../../../store/application';
import dayjs from 'dayjs';
import { notifyError, notifySuccess, notifyWarning } from '../../../helpers/notify';
import { deleteFile, uploadFile } from '../../../store/student';
import { IDepartmentTypes } from '../../../types/constants';
import { accountSelector } from '../../../store/account';

const { Option } = Select;

const StudentDetail: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const {
    countryList,
    programList,
    applicationTypeList,
    genderList,

    universityList,
    semesterList,
  } = useAppSelector(constantsSelector);
  const { applicationStatus } = useAppSelector(applicationSelector);
  const { account } = useAppSelector(accountSelector);

  const [studentPicture, setStudentPicture] = useState<UploadFile[]>([]);
  const [passportPicture, setPassportPicture] = useState<UploadFile[]>([]);
  const [transcriptPicture, setTranscriptPicture] = useState<UploadFile[]>([]);
  const [certificatePicture, setCertificatePicture] = useState<UploadFile[]>([]);
  const [secondPassportPicture, setSecondPassportPicture] = useState<any[]>([]);
  const [extraPicture, setExtraPicture] = useState<any[]>([]);
  const [acceptanceLetterPicture, setAcceptanceLetterPicture] = useState<any[]>([]);
  const [finalAcceptanceLetterPicture, setFinalAcceptanceLetterPicture] = useState<any[]>([]);
  const [identityCardPicture, setIdentityCardPicture] = useState<any[]>([]);
  const [receiptPicture, setReceiptPicture] = useState<any[]>([]);

  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState('');

  const [application, setApplication] = useState<any>();

  const [isEditApplicationModalOpen, setIsEditApplicationModalOpen] = useState<boolean>(false);

  const [universityId, setUniversityId] = useState<string>('');
  const [applicationType, setApplicationType] = useState<string>('');
  const [semester, setSemester] = useState<string>('');
  const [facultyId, setFacultyId] = useState<string>('');
  const [facultyList, setFacultyList] = useState<IDepartmentTypes[]>([]);
  const [departmentId, setDepartmentId] = useState<string>('');
  const [departmentList, setDepartmentList] = useState<IDepartmentTypes[]>([]);

  const [userTag, setUserTag] = useState<string[]>([]);
  const [note, setNote] = useState<string>('');
  const [notes, setNotes] = useState<any[]>();
  const [applicationUsers, setApplicationUsers] = useState<any[]>();

  const [applicationStatusId, setApplicationStatusId] = useState<number>();

  const [date, setDate] = useState<string>();
  const [pin, setPin] = useState<string>('');
  const tagColors = [
    'geekblue',
    'magenta',
    'red',
    'blue',
    'volcano',
    'orange',
    'green',
    'lime',
    'cyan',
    'purple',
  ];

  useEffect(() => {
    dispatch(getApplicationStatus());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(
        getApplication({
          id,
          onSuccess: (data: any) => {
            setApplication(data.data);
          },
        })
      );
      dispatch(
        getApplicationUsers({
          id,
          onSuccess: (data: any) => {
            setApplicationUsers(data.data);
          },
        })
      );
    }
  }, [id]);

  useEffect(() => {
    if (application && application?.application_files.length)
      application?.application_files.forEach((document: any) => {
        let picture: UploadFile;
        switch (document.key) {
          case 'picture':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setStudentPicture([picture]);
            break;
          case 'passport':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setPassportPicture([picture]);
            break;
          case 'transcript':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setTranscriptPicture([picture]);
            break;
          case 'certificate':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setCertificatePicture([picture]);
            break;
          case 'second-passport':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setSecondPassportPicture([picture]);
            break;
          case 'other':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setExtraPicture([picture]);
            break;
          case 'acceptance_letter':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setAcceptanceLetterPicture([picture]);
            break;
          case 'identity_card':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setIdentityCardPicture([picture]);
            break;
          case 'acceptance_letter':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setAcceptanceLetterPicture([picture]);
            break;
          case 'final_acceptance_letter':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setFinalAcceptanceLetterPicture([picture]);
            break;
          case 'receipt':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setReceiptPicture([picture]);
            break;
          default:
            break;
        }
      });
    if (application && application?.student_files.length)
      application?.student_files.forEach((document: any) => {
        let picture: UploadFile;
        switch (document.key) {
          case 'picture':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setStudentPicture([picture]);
            break;
          case 'passport':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setPassportPicture([picture]);
            break;
          case 'transcript':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setTranscriptPicture([picture]);
            break;
          case 'certificate':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setCertificatePicture([picture]);
            break;
          case 'second-passport':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setSecondPassportPicture([picture]);
            break;
          case 'other':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setExtraPicture([picture]);
            break;
          case 'acceptance_letter':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setAcceptanceLetterPicture([picture]);
            break;
          case 'final_acceptance_letter':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setFinalAcceptanceLetterPicture([picture]);
            break;
          case 'receipt':
            picture = {
              uid: document.id.toString(),
              name: document.name,
              status: 'done',
              url: document.path,
              size: document.size,
            };
            setReceiptPicture([picture]);
            break;
          default:
            break;
        }
      });

    if (application && application?.semester) setSemester(application?.semester.id);
    if (application && application?.application_type)
      setApplicationType(application?.application_type.id);
    if (application && application?.department) setDepartmentId(application?.department.id);
    if (application && application?.faculty) setFacultyId(application?.faculty.id);
    if (application && application?.university) setUniversityId(application?.university.id);
    setNotes(application?.notes);
    setDate(application?.deadline_date);
    setPin(application?.pin);
    setApplicationStatusId(application?.application_status.id);
  }, [application]);

  const handlePreview = (file: any) => {
    //Modal içi gösterim için silmeyin
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64(file.originFileObj);
    // }
    // setPreviewImage(file.url || file.preview);
    // setPreviewVisible(true);
    //Modal içi gösterim için silmeyin
    window.open(file.url, '_blank');
  };

  // // Dosyayı Base64 formatına çevirmek için yardımcı fonksiyon
  // const getBase64 = (file: any) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };
  const fileSave = (
    file: any,
    fileName: string,
    modelName: 'application' | 'student',
    studentId: string
  ) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

    if (!allowedTypes.includes(file.type)) {
      notifyError(getLocalString(translations, 'ApplicationsDetail.NotifyError.FileUploaded'));
      return false;
    }
    if (file.size > 4194304) {
      notifyError(getLocalString(translations, 'ApplicationsDetail.NotifyError.FileSize'));
      return false;
    }
    studentId &&
      dispatch(
        uploadFile({
          model_id: studentId,
          model: modelName,
          file_key: fileName,
          file: file,
          onSuccess: (data: any) => {
            notifySuccess(
              getLocalString(translations, 'ApplicationsDetail.NotifySuccess.FileUploaded')
            );
            dispatch(
              getApplication({
                id: id || '',
                onSuccess: (data: any) => {
                  setApplication(data.data);
                },
              })
            );
            // let document: any = {
            //   uid: data.data.id.toString(),
            //   name: data.data.name,
            //   status: 'done',
            //   url: data.data.path,
            //   size: data.data.size,
            // };

            // switch (data.data.key) {
            //   case 'picture':
            //     setStudentPicture([document]);
            //     break;
            //   case 'passport':
            //     setPassportPicture([document]);
            //     break;
            //   case 'transcript':
            //     setTranscriptPicture([document]);
            //     break;
            //   case 'certificate':
            //     setCertificatePicture([document]);
            //     break;
            //   case 'second-passport':
            //     setSecondPassportPicture([document]);
            //     break;
            //   case 'other':
            //     setExtraPicture([document]);
            //     break;
            //   case 'acceptance_letter':
            //     setAcceptanceLetterPicture([document]);
            //     break;
            //   case 'final_acceptance_letter':
            //     setFinalAcceptanceLetterPicture([document]);
            //     break;
            //   case 'identity_card':
            //     setIdentityCardPicture([document]);
            //     break;
            //   case 'receipt':
            //     setReceiptPicture([document]);
            //     break;

            //   default:
            //     break;
            // }
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  };
  useEffect(() => {
    if (universityId)
      dispatch(
        getUniversityFaculties({
          universityID: universityId,
          onSuccess: (data: any) => {
            setFacultyList(data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  }, [universityId, departmentId, facultyId]);
  useEffect(() => {
    if (universityId)
      dispatch(
        getFacultyDepartments({
          faculty_id: facultyId,
          onSuccess: (data: any) => {
            setDepartmentList(data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  }, [universityId, departmentId, facultyId]);

  const renderSemesterOptions = useCallback(
    () =>
      semesterList.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name}
        </Option>
      )),
    [semesterList]
  );
  const renderUniversityOptions = useCallback(
    () => universityList.map((el: any) => ({ value: el.id, label: el.name })),
    [universityList]
  );
  const renderDepartmentOptions = useCallback(
    () => departmentList.map((el: any) => ({ value: el.id, label: el.name })),
    [departmentList]
  );
  const renderFacultyOptions = useCallback(
    () => facultyList.map((el: any) => ({ value: el.id, label: el.name })),
    [facultyList]
  );
  const renderApplicationTypeOptions = useCallback(
    () =>
      applicationTypeList.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name}
        </Option>
      )),
    [applicationTypeList]
  );

  const renderUserOptions = useCallback(
    () =>
      applicationUsers?.length &&
      applicationUsers.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name}
        </Option>
      )),
    [applicationUsers]
  );

  return (
    <PageContainer
      pageName={getLocalString(translations, 'ApplicationsDetail.Title')}
      backUrl={'/applications-list'}
      extra={
        <Row style={{ gap: '16px' }}>
          <Button
            onClick={() => {
              id && dispatch(getApplicationZip({ applicationId: id }));
            }}
            type='primary'
            size='large'
          >
            {getLocalString(translations, 'ApplicationsDetail.ExportFileZip')}
          </Button>
          <Button type='primary' size='large' onClick={() => setIsEditApplicationModalOpen(true)}>
            {getLocalString(translations, 'ApplicationsDetail.EditThisApplication')}
          </Button>
        </Row>
      }
    >
      <Row className={`${globalStyles.title_r_medium} `} style={{ marginBottom: '8px' }}>
        {getLocalString(translations, 'ApplicationsDetail.StudentInformation')}
      </Row>

      <Row style={{ width: '100%' }} gutter={[24, 0]}>
        <Col span={12}>
          <Card style={{ height: '100%' }}>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Name')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{application?.name}</Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Surname')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{application?.surname}</Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.FatherName')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{application?.father_name}</Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.MotherName')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{application?.mother_name}</Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Gender')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {application?.gender && application.gender?.name}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Nation')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {application?.nation && application.nation?.name}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Country')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {application?.country && application.country?.name}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.SecondPassportCountry')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {(application?.second_passport_country &&
                  application?.second_passport_country.name) ||
                  '-'}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Phone')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{application?.phone}</Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Mail')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{application?.email}</Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.PassportNo')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}> {application?.passport_no}</Row>
            </Row>
            {isCreate && (
              <Row style={{ width: '100%' }}>
                <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'ApplicationsDetail.IdentityNo')}
                </Row>
                <Row className={`${globalStyles.body_r_large} `}> {application?.identity_no}</Row>
              </Row>
            )}
          </Card>
        </Col>

        {/* ---------------------------------------------------- Sağ Taraf------------------------------ */}
        <Col span={12}>
          <Card style={{ height: '100%' }}>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.SecondPassportNo')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {(application?.second_passport_country &&
                  application?.second_passport_country.name) ||
                  '-'}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.CreatedAt')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {dayjs(application?.created_at).format('YYYY-MM-DD')}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.DeadlineDate')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {application?.deadline_date
                  ? dayjs(application?.deadline_date).format('YYYY-MM-DD')
                  : '-'}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Pin')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {application?.pin ? application?.pin : '-'}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.School')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{application?.school}</Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.TrackingCode')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {application?.tracking_code ? application?.tracking_code : '-'}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.ApplicationType')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {application?.application_type && application.application_type?.name}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.University')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {application?.university && application.university?.name}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Faculty')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {application?.university && application.faculty?.name}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Department')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {application?.department && application.department?.name}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Semester')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {application?.semester && application.semester?.name}
              </Row>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Status')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>
                {' '}
                {application?.application_status.name}
              </Row>
            </Row>
          </Card>
        </Col>
      </Row>

      {account.role.id === 1 && (
        <Row
          className={`${globalStyles.title_r_medium} `}
          style={{ marginBottom: '8px', marginTop: '24px' }}
        >
          <Select
            style={{ width: '25%' }}
            value={applicationStatusId || undefined}
            onChange={(e) => {
              setApplicationStatusId(e);
              dispatch(
                updateApplicationStatus({
                  id: id || '',
                  statusId: e,
                  onSuccess: (data: any) => {
                    console.log(data.data);
                    setApplication(data.data);
                  },
                })
              );
            }}
            options={applicationStatus.map((el) => ({ value: el.id, label: el.name }))}
          ></Select>
        </Row>
      )}

      <Row
        className={`${globalStyles.title_r_medium} `}
        style={{ marginBottom: '8px', marginTop: '24px' }}
      >
        {getLocalString(translations, 'ApplicationsDetail.StudentDocuments')}
      </Row>

      <Card>
        {id && (
          <>
            {/*                     Files                  */}
            <Row style={{ width: '100%' }} gutter={[24, 24]}>
              <Col span={4}>
                <Row style={{ width: '100%', gap: '5px' }}>
                  <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                    {getLocalString(translations, 'ApplicationsDetail.Upload.StudentPicture')}
                  </Row>
                  <Upload
                    accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                    listType='picture-card'
                    fileList={studentPicture}
                    onPreview={(e) => handlePreview(e)}
                    beforeUpload={(file: any) => {
                      fileSave(file, 'picture', 'student', application?.student_id);

                      return false;
                    }}
                    onRemove={() => {
                      Modal.confirm({
                        title: getLocalString(
                          translations,
                          'ApplicationsDetail.Delete.StudentPicture'
                        ),
                        onOk: () => {
                          dispatch(
                            deleteFile({
                              id: studentPicture[0].uid,
                              onSuccess: (data: any) => {
                                setStudentPicture([]);
                                notifySuccess(
                                  getLocalString(
                                    translations,
                                    'ApplicationsDetail.NotifySuccess.FileDeletedSuccessfully'
                                  )
                                );
                              },
                              onError: (err) => notifyError(err),
                            })
                          );
                        },
                      });
                    }}
                  >
                    {studentPicture.length >= 1 ? null : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          {getLocalString(translations, 'ApplicationsDetail.Upload')}
                        </div>
                      </div>
                    )}
                  </Upload>
                </Row>
              </Col>
              <Col span={4}>
                <Row style={{ width: '100%', gap: '5px' }}>
                  <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                    {getLocalString(translations, 'ApplicationsDetail.Upload.Passport')}
                  </Row>
                  <Upload
                    accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                    listType='picture-card'
                    fileList={passportPicture}
                    onPreview={(e) => handlePreview(e)}
                    beforeUpload={(file: any) => {
                      fileSave(file, 'passport', 'student', application?.student_id);
                      return false;
                    }}
                    onRemove={() => {
                      Modal.confirm({
                        title: getLocalString(
                          translations,
                          'ApplicationsDetail.Delete.StudentPassport'
                        ),
                        onOk: () => {
                          dispatch(
                            deleteFile({
                              id: passportPicture[0].uid,
                              onSuccess: (data: any) => {
                                setPassportPicture([]);
                                notifySuccess(
                                  getLocalString(
                                    translations,
                                    'ApplicationsDetail.NotifySuccess.FileDeletedSuccessfully'
                                  )
                                );
                              },
                              onError: (err) => notifyError(err),
                            })
                          );
                        },
                      });
                    }}
                  >
                    {passportPicture.length >= 1 ? null : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          {getLocalString(translations, 'ApplicationsDetail.Upload')}
                        </div>
                      </div>
                    )}
                  </Upload>
                </Row>
              </Col>
              <Col span={4}>
                <Row style={{ width: '100%', gap: '5px' }}>
                  <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                    {getLocalString(translations, 'ApplicationsDetail.Upload.Transcript')}
                  </Row>
                  <Upload
                    accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                    listType='picture-card'
                    fileList={transcriptPicture}
                    onPreview={(e) => handlePreview(e)}
                    beforeUpload={(file: any) => {
                      fileSave(file, 'transcript', 'student', application?.student_id);
                      return false;
                    }}
                    onRemove={() => {
                      Modal.confirm({
                        title: getLocalString(
                          translations,
                          'ApplicationsDetail.Delete.StudentTranscript'
                        ),
                        onOk: () => {
                          dispatch(
                            deleteFile({
                              id: transcriptPicture[0].uid,
                              onSuccess: (data: any) => {
                                setTranscriptPicture([]);
                                notifySuccess(
                                  getLocalString(
                                    translations,
                                    'ApplicationsDetail.NotifySuccess.FileDeletedSuccessfully'
                                  )
                                );
                              },
                              onError: (err) => notifyError(err),
                            })
                          );
                        },
                      });
                    }}
                  >
                    {transcriptPicture.length >= 1 ? null : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          {getLocalString(translations, 'ApplicationsDetail.Upload')}
                        </div>
                      </div>
                    )}
                  </Upload>
                </Row>
              </Col>
              <Col span={4}>
                <Row style={{ width: '100%', gap: '5px' }}>
                  <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                    {getLocalString(translations, 'ApplicationsDetail.Upload.Certificate')}
                  </Row>
                  <Upload
                    accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                    listType='picture-card'
                    fileList={certificatePicture}
                    onPreview={(e) => handlePreview(e)}
                    beforeUpload={(file: any) => {
                      fileSave(file, 'certificate', 'student', application?.student_id);
                      return false;
                    }}
                    onRemove={() => {
                      Modal.confirm({
                        title: getLocalString(
                          translations,
                          'ApplicationsDetail.Delete.StudentCertificate'
                        ),
                        onOk: () => {
                          dispatch(
                            deleteFile({
                              id: certificatePicture[0].uid,
                              onSuccess: (data: any) => {
                                setCertificatePicture([]);
                                notifySuccess(
                                  getLocalString(
                                    translations,
                                    'ApplicationsDetail.NotifySuccess.FileDeletedSuccessfully'
                                  )
                                );
                              },
                              onError: (err) => notifyError(err),
                            })
                          );
                        },
                      });
                    }}
                  >
                    {certificatePicture.length >= 1 ? null : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          {getLocalString(translations, 'ApplicationsDetail.Upload')}
                        </div>
                      </div>
                    )}
                  </Upload>
                </Row>
              </Col>
              <Col span={4}>
                <Row style={{ width: '100%', gap: '5px' }}>
                  <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                    {getLocalString(translations, 'ApplicationsDetail.Upload.SecondPassport')}
                  </Row>
                  <Upload
                    accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                    listType='picture-card'
                    fileList={secondPassportPicture}
                    onPreview={(e) => handlePreview(e)}
                    beforeUpload={(file: any) => {
                      fileSave(file, 'second-passport', 'student', application?.student_id);
                      return false;
                    }}
                    onRemove={() => {
                      Modal.confirm({
                        title: getLocalString(
                          translations,
                          'ApplicationsDetail.Delete.StudentSecondPassport'
                        ),
                        onOk: () => {
                          dispatch(
                            deleteFile({
                              id: secondPassportPicture[0].uid,
                              onSuccess: (data: any) => {
                                setSecondPassportPicture([]);
                                notifySuccess(
                                  getLocalString(
                                    translations,
                                    'ApplicationsDetail.NotifySuccess.FileDeletedSuccessfully'
                                  )
                                );
                              },
                              onError: (err) => notifyError(err),
                            })
                          );
                        },
                      });
                    }}
                  >
                    {secondPassportPicture.length >= 1 ? null : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          {getLocalString(translations, 'ApplicationsDetail.Upload')}
                        </div>
                      </div>
                    )}
                  </Upload>
                </Row>
              </Col>
              <Col span={4}>
                <Row style={{ width: '100%', gap: '5px' }}>
                  <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                    {getLocalString(translations, 'ApplicationsDetail.Upload.Other')}
                  </Row>
                  <Upload
                    accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                    listType='picture-card'
                    fileList={extraPicture}
                    onPreview={(e) => handlePreview(e)}
                    beforeUpload={(file) => {
                      fileSave(file, 'other', 'student', application?.student_id);
                      return false;
                    }}
                    onRemove={() => {
                      Modal.confirm({
                        title: getLocalString(
                          translations,
                          'ApplicationsDetail.Delete.StudentOtherFile'
                        ),
                        onOk: () => {
                          dispatch(
                            deleteFile({
                              id: extraPicture[0].uid,
                              onSuccess: (data: any) => {
                                setExtraPicture([]);
                                notifySuccess(
                                  getLocalString(
                                    translations,
                                    'ApplicationsDetail.NotifySuccess.FileDeletedSuccessfully'
                                  )
                                );
                              },
                              onError: (err) => notifyError(err),
                            })
                          );
                        },
                      });
                    }}
                  >
                    {extraPicture?.length >= 1 ? null : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>
                          {getLocalString(translations, 'ApplicationsDetail.Upload')}
                        </div>
                      </div>
                    )}
                  </Upload>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {/* Agenta sadece preview görecek admin yükeleme ekranı görecek */}
      </Card>

      <Row
        className={`${globalStyles.title_r_medium} `}
        style={{ marginBottom: '8px', marginTop: '24px' }}
      >
        Application Process Document
      </Row>
      <Card>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Upload.AcceptanceLetter')}
              </Row>
              <Upload
                accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                listType='picture-card'
                fileList={acceptanceLetterPicture}
                onPreview={(e) => handlePreview(e)}
                beforeUpload={(file) => {
                  id && fileSave(file, 'acceptance_letter', 'application', id);
                  return false;
                }}
                onRemove={() => {
                  Modal.confirm({
                    title: getLocalString(
                      translations,
                      'ApplicationsDetail.Delete.StudentAcceptanceLatter'
                    ),
                    onOk: () => {
                      dispatch(
                        deleteFile({
                          id: acceptanceLetterPicture[0].uid,
                          onSuccess: (data: any) => {
                            setAcceptanceLetterPicture([]);
                            notifySuccess(
                              getLocalString(
                                translations,
                                'ApplicationsDetail.NotifySuccess.FileDeletedSuccessfully'
                              )
                            );
                          },
                          onError: (err) => notifyError(err),
                        })
                      );
                    },
                  });
                }}
              >
                {acceptanceLetterPicture?.length >= 1 ? null : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>
                      {getLocalString(translations, 'ApplicationsDetail.Upload')}
                    </div>
                  </div>
                )}
              </Upload>
            </Row>
          </Col>
          <Col span={12}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Upload.FinalAcceptanceLetter')}
              </Row>
              <Upload
                accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                listType='picture-card'
                fileList={finalAcceptanceLetterPicture}
                onPreview={(e) => handlePreview(e)}
                beforeUpload={(file) => {
                  id && fileSave(file, 'final_acceptance_letter', 'application', id);

                  return false;
                }}
                onRemove={() => {
                  Modal.confirm({
                    title: getLocalString(
                      translations,
                      'ApplicationsDetail.Delete.StudentFinalAcceptanceLatter'
                    ),
                    onOk: () => {
                      dispatch(
                        deleteFile({
                          id: finalAcceptanceLetterPicture[0].uid,
                          onSuccess: (data: any) => {
                            setFinalAcceptanceLetterPicture([]);
                            notifySuccess(
                              getLocalString(
                                translations,
                                'ApplicationsDetail.NotifySuccess.FileDeletedSuccessfully'
                              )
                            );
                          },
                          onError: (err) => notifyError(err),
                        })
                      );
                    },
                  });
                }}
              >
                {finalAcceptanceLetterPicture?.length >= 1 ? null : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>
                      {getLocalString(translations, 'ApplicationsDetail.Upload')}
                    </div>
                  </div>
                )}
              </Upload>
            </Row>
          </Col>
        </Row>
      </Card>

      <Row
        className={`${globalStyles.title_r_medium} `}
        style={{ marginBottom: '8px', marginTop: '24px' }}
      >
        Applies Options
      </Row>
      <Card>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Upload.StudentID')}
              </Row>
              <Upload
                accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                listType='picture-card'
                fileList={identityCardPicture}
                onPreview={(e) => handlePreview(e)}
                beforeUpload={(file) => {
                  id && fileSave(file, 'identity_card', 'application', id);
                  return false;
                }}
                onRemove={() => {
                  Modal.confirm({
                    title: getLocalString(
                      translations,
                      'ApplicationsDetail.Delete.StudentIdentityCard'
                    ),
                    onOk: () => {
                      dispatch(
                        deleteFile({
                          id: identityCardPicture[0].uid,
                          onSuccess: (data: any) => {
                            setIdentityCardPicture([]);
                            notifySuccess(
                              getLocalString(
                                translations,
                                'ApplicationsDetail.NotifySuccess.FileDeletedSuccessfully'
                              )
                            );
                          },
                          onError: (err) => notifyError(err),
                        })
                      );
                    },
                  });
                }}
              >
                {identityCardPicture?.length >= 1 ? null : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>
                      {getLocalString(translations, 'ApplicationsDetail.Upload')}
                    </div>
                  </div>
                )}
              </Upload>
            </Row>
          </Col>
          <Col span={12}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Upload.UploadReceipt')}
              </Row>
              <Upload
                accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                listType='picture-card'
                fileList={receiptPicture}
                onPreview={(e) => handlePreview(e)}
                beforeUpload={(file) => {
                  id && fileSave(file, 'receipt', 'application', id);

                  return false;
                }}
                onRemove={() => {
                  Modal.confirm({
                    title: getLocalString(translations, 'ApplicationsDetail.Delete.Receipt'),
                    onOk: () => {
                      dispatch(
                        deleteFile({
                          id: receiptPicture[0].uid,
                          onSuccess: (data: any) => {
                            setReceiptPicture([]);
                            notifySuccess(
                              getLocalString(
                                translations,
                                'ApplicationsDetail.NotifySuccess.FileDeletedSuccessfully'
                              )
                            );
                          },
                          onError: (err) => notifyError(err),
                        })
                      );
                    },
                  });
                }}
              >
                {receiptPicture?.length >= 1 ? null : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>
                      {getLocalString(translations, 'ApplicationsDetail.Upload')}
                    </div>
                  </div>
                )}
              </Upload>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row
        className={`${globalStyles.title_r_medium} `}
        style={isCreate ? { marginBottom: '8px', marginTop: '24px' } : { display: 'none' }}
      >
        {getLocalString(translations, 'ApplicationsDetail.DeadLinePin')}
      </Row>
      <Card style={isCreate ? {} : { display: 'none' }}>
        <Row gutter={[24, 24]}>
          <Col span={24} style={{ width: '100%' }}>
            <Row justify='space-between' align='middle' gutter={[16, 16]} style={{ width: '100%' }}>
              <Col flex={1}>
                <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'ApplicationsDetail.DeadLineDate')} :
                </Row>
              </Col>
              <Col flex={20}>
                <DatePicker
                  style={{ width: '100%' }}
                  value={date ? dayjs(date) : undefined}
                  format={'YYYY-MM-DD'}
                  onChange={(e) => {
                    if (e) {
                      const selectedDate = e.format('YYYY-MM-DD');
                      setDate(selectedDate);

                      // Bugünün tarihini kontrol et, eski ise uyarı ver
                      if (dayjs(selectedDate).isBefore(dayjs().startOf('day'))) {
                        notifyError(
                          getLocalString(translations, 'ApplicationsDetail.NotifyWarning.Date')
                        );
                      }
                    } else {
                      setDate(undefined);
                    }
                  }}
                  size='large'
                />
              </Col>
              <Col flex={1}>
                <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'ApplicationsDetail.Pin')} :
                </Row>
              </Col>
              <Col flex={20}>
                <Input
                  value={pin || undefined}
                  onChange={(e) => setPin(e.currentTarget.value.trim())}
                  style={{ width: '100%' }}
                  size='large'
                ></Input>
              </Col>
              <Col flex={3}>
                <Button
                  disabled={!date && !pin}
                  onClick={() =>
                    id &&
                    dispatch(
                      editDeadlineAndPin({
                        applicationId: id,
                        deadline_date: date,
                        pin: pin == '' ? undefined : pin,
                        onSuccess: (data: any) => {
                          notifySuccess(
                            getLocalString(
                              translations,
                              'ApplicationsDetail.NotifySuccess.PinDeadlineDateAdded'
                            )
                          );

                          if (id) {
                            dispatch(
                              getApplication({
                                id,
                                onSuccess: (data: any) => {
                                  setApplication(data.data);
                                },
                              })
                            );
                          }
                        },
                        onError: (error: any) => {
                          notifyError(error);
                        },
                      })
                    )
                  }
                  style={{ width: '100%' }}
                  size='large'
                >
                  {getLocalString(translations, 'ApplicationsDetail.Save')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Row style={{ width: '100%', marginTop: '24px' }} gutter={[24, 0]}>
        <Col span={12}>
          <Card style={{ height: '460px' }}>
            <Row className={`${globalStyles.title_r_medium} `}>
              {getLocalString(translations, 'ApplicationsDetail.Notes')}
            </Row>
            <Divider></Divider>
            <div style={{ maxHeight: 'calc(460px - 120px)', overflowY: 'auto' }}>
              <List
                itemLayout='horizontal'
                dataSource={notes}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <>
                          <Row>
                            {item.users.map((el: any) => (
                              <Tag color={tagColors[Math.floor(Math.random() * tagColors.length)]}>
                                {el.username}&nbsp;&nbsp;
                              </Tag>
                            ))}
                          </Row>
                          <Row> {item.note} &nbsp;</Row>
                        </>
                      }
                      description={dayjs(item.created_at).format('YYYY-MM-DD HH:MM')}
                    />
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card style={{ height: '460px' }}>
            <Row className={`${globalStyles.title_r_medium} `}>
              {getLocalString(translations, 'ApplicationsDetail.AddNotes')}
            </Row>
            <Row style={{ width: '100%', marginTop: '24px' }}>
              <Row
                className={`${globalStyles.label_r_large} ${styles.label_color}`}
                style={{ marginBottom: '8px' }}
              >
                {getLocalString(translations, 'ApplicationsDetail.AddTag')}
              </Row>
              <Select
                showSearch
                filterOption={filterOption}
                style={{ width: '100%' }}
                value={userTag || undefined}
                onChange={(e) => setUserTag(e)}
                size='large'
                mode='multiple'
              >
                {renderUserOptions()}
              </Select>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row
                className={`${globalStyles.label_r_large} ${styles.label_color}`}
                style={{ marginBottom: '8px', marginTop: '24px' }}
              >
                {getLocalString(translations, 'ApplicationsDetail.YourNote')}
              </Row>
              <TextArea
                value={note || undefined}
                onChange={(e) => setNote(e.currentTarget.value)}
                rows={6}
                size='large'
              ></TextArea>
            </Row>
            <Row style={{ width: '100%', marginTop: '24px' }}>
              <Button
                type='primary'
                size='large'
                disabled={!note || userTag.length === 0}
                onClick={() => {
                  id &&
                    userTag.length > 0 &&
                    dispatch(
                      addNote({
                        id: id,
                        note: note,
                        user_ids: userTag,
                        onSuccess: (data: any) => {
                          setUserTag([]);
                          setNote('');
                          dispatch(
                            getApplication({
                              id,
                              onSuccess: (data: any) => {
                                notifySuccess(
                                  getLocalString(
                                    translations,
                                    'ApplicationsDetail.NotifySuccess.TagCreatedSuccessfully'
                                  )
                                );
                                setApplication(data.data);
                              },
                            })
                          );
                        },
                      })
                    );
                }}
              >
                {getLocalString(translations, 'ApplicationsDetail.Add')}
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row style={{ width: '100%', marginTop: '24px' }} align={'middle'} justify={'end'}></Row>

      <Modal open={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img alt='pic' style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <Modal
        width={750}
        open={isEditApplicationModalOpen}
        title='Edit This Application'
        footer={null}
        onCancel={() => {
          setIsEditApplicationModalOpen(false);
        }}
      >
        <Row style={{ width: '100%', marginTop: '24px' }} gutter={[24, 24]}>
          <Col span={12}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.ApplicationType')}
              </Row>
              <Select
                style={{ width: '100%' }}
                showSearch
                filterOption={filterOption}
                value={applicationType || undefined}
                placeholder='Select Application Type'
                onChange={(e) => {
                  setApplicationType(e);
                }}
                allowClear
              >
                {renderApplicationTypeOptions()}
              </Select>
            </Row>
          </Col>
          <Col span={12}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Semester')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={semester || undefined}
                showSearch
                filterOption={filterOption}
                placeholder='Select Semester'
                onChange={(e) => {
                  setSemester(e);
                }}
                allowClear
              >
                {renderSemesterOptions()}
              </Select>
            </Row>
          </Col>
        </Row>
        <Row style={{ width: '100%', marginTop: '24px' }} gutter={[24, 24]}>
          <Col span={8}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.University')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={universityId || undefined}
                showSearch
                filterOption={filterOption}
                placeholder='Select University'
                onChange={(value) => {
                  setUniversityId(value);
                  setFacultyId('');
                  setDepartmentId('');
                  setDepartmentList([]);
                  setFacultyList([]);
                }}
                onClear={() => {
                  setDepartmentId('');
                  setFacultyId('');
                  setFacultyList([]);
                  setDepartmentList([]);
                }}
                allowClear
                options={renderUniversityOptions()}
              ></Select>
            </Row>
          </Col>
          <Col span={8}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Faculty')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={facultyId || undefined}
                showSearch
                disabled={!universityId}
                filterOption={filterOption}
                placeholder='Select faculty'
                onChange={(value) => {
                  setFacultyId(value);
                  setDepartmentId('');
                  setDepartmentList([]);
                }}
                onClear={() => {
                  setFacultyId('');
                  setDepartmentId('');
                  setDepartmentList([]);
                }}
                allowClear
                options={renderFacultyOptions()}
              ></Select>
            </Row>
          </Col>
          <Col span={8}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'ApplicationsDetail.Department')}
              </Row>
              <Select
                disabled={!facultyId}
                style={{ width: '100%' }}
                value={departmentId || undefined}
                placeholder='Select Department'
                onChange={(e) => {
                  setDepartmentId(e);
                }}
                showSearch
                filterOption={filterOption}
                allowClear
                options={renderDepartmentOptions()}
              ></Select>
            </Row>
          </Col>
        </Row>
        <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
          <Button
            disabled={!universityId || !semester || !departmentId || !applicationType}
            type='primary'
            onClick={() => {
              id &&
                dispatch(
                  editApplication({
                    id: id,
                    application_type_id: applicationType,
                    university_id: universityId,
                    faculty_id: facultyId,
                    semester_id: semester,
                    department_id: departmentId,
                    onSuccess: (data: any) => {
                      notifySuccess(
                        getLocalString(
                          translations,
                          'ApplicationsDetail.NotifySuccess.ApplicationEditedSuccessfully'
                        )
                      );
                      setIsEditApplicationModalOpen(false);
                      if (id) {
                        dispatch(
                          getApplication({
                            id,
                            onSuccess: (data: any) => {
                              setApplication(data.data);
                            },
                          })
                        );
                      }
                    },
                  })
                );
            }}
          >
            {getLocalString(translations, 'ApplicationsDetail.Save')}
          </Button>
        </Row>
      </Modal>
    </PageContainer>
  );
};

export default StudentDetail;
