import { useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import styles from './index.module.scss';
import globalStyles from './../../styles/global.module.scss';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { AuthService } from '../../service/AuthServiceV2';
import {
  Button,
  Card,
  Cascader,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Result,
  Row,
  Select,
  Space,
  Steps,
  Upload,
} from 'antd';

import type { UploadFile } from 'antd/es/upload/interface';

import { useAppDispatch, useAppSelector } from '../../store/hooks';

import { notifyError, notifySuccess } from '../../helpers/notify';

import PageContainer from '../../components/pageContainer';
import {
  constantsSelector,
  getFaculties,
  getFacultyDepartments,
  getInterviewTypes,
  getUniversities,
  getUniversityFaculties,
} from '../../store/constants';
import { languageSelector } from '../../store/language';
import { IDepartmentTypes } from '../../types/constants';

import { apiURL, axiosClient } from '../../service';
import filterOption from '../../helpers/filterOption';
import { menuSelector } from '../../store/menu';
import { setSpinner, spinnerSelector } from '../../store/spinner';
import { getLocalString } from '../../helpers/language';
const { Option } = Select;

const ApplyNow: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  localStorage.removeItem('access_token');
  const [form] = Form.useForm();
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const {
    countryList,

    applicationTypeList,
    genderList,
    universityList,
    semesterList,
  } = useAppSelector(constantsSelector);
  const { isOn } = useAppSelector(spinnerSelector);
  const [universityId, setUniversityId] = useState<string>();

  const [isDualCitizen, setIsDualCitizen] = useState<boolean>(false);
  const [facultyId, setFacultyId] = useState<string>('');
  const [departmentId, setDepartmentId] = useState<string>('');
  const [facultyList, setFacultyList] = useState<IDepartmentTypes[]>([]);
  const [departmentList, setDepartmentList] = useState<IDepartmentTypes[]>([]);
  const [identity_no, setIdentity_no] = useState<string>('');

  const [current, setCurrent] = useState<number>(0);

  const [previewImage, setPreviewImage] = useState(undefined);
  const [previewPdf, setPreviewPdf] = useState(undefined);
  const [previewOpen, setPreviewOpen] = useState(false);

  const [studentPicture, setStudentPicture] = useState<any[]>([]);
  const [passportPicture, setPassportPicture] = useState<any[]>([]);
  const [transcriptPicture, setTranscriptPicture] = useState<any[]>([]);
  const [certificatePicture, setCertificatePicture] = useState<any[]>([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [phonePrefix, setPhonePrefix] = useState<string>('+90');

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  useEffect(() => {
    const handleResize = () => {
      // User agent ile mobil cihaz kontrolü yapılıyor.
      const isMobileView = isMobileDevice() || window.innerWidth < 768;
      setIsMobile(isMobileView);
    };

    // İlk render'da ve her resize olayında çalışacak
    handleResize();
    window.addEventListener('resize', handleResize);

    // Komponent unmount olduğunda temizlik işlemi yapılıyor.
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Dosyanın MIME tipini kontrol et
      if (file[0].type === 'application/pdf') {
        // PDF dosyaları için özel işlem
        // Örnek olarak, PDF dosyalarını doğrudan base64 olarak oku
        reader.readAsDataURL(file[0]);
      } else {
        // Diğer dosya türleri için
        let blob = new Blob(file);
        reader.readAsDataURL(blob);
      }
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (file.type === 'application/pdf') {
      setPreviewPdf(file.url || file.preview);
      setPreviewImage(undefined);
    } else {
      setPreviewImage(file.url || file.preview);
      setPreviewPdf(undefined);
    }
    setPreviewOpen(true);
  };

  const beforeUpload = async (
    file: any,
    fieldName: string,
    updatePicture: (fileMeta: any) => void
  ) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

    if (!allowedTypes.includes(file[0].type)) {
      notifyError(getLocalString(translations, 'Apply.NotifyError.FileType'));

      return false;
    }
    if (file[0].size > 4194304) {
      notifyError(getLocalString(translations, 'Apply.NotifyError.FileSize'));
      return false;
    }
    const uid = Math.random().toString(36).substring(2, 15);
    const preview = await getBase64(file);
    const fileWithMeta = {
      uid: uid.toString(),
      name: file[0].name,
      status: 'done',
      originFileObj: file[0],
      url: preview,
      size: file[0].size,
      type: file[0].type,
    };

    updatePicture([fileWithMeta]);
    form.setFieldsValue({ [fieldName]: [fileWithMeta] });

    return false;
  };

  const onFinish = async (values: any) => {
    const formData = new FormData();
    //Bool yerine 1-0 kabul ediyor
    values.is_dual_citizenship = values.is_dual_citizenship === 'true' ? 1 : 0;
    values.is_contract = values.is_contract === 'true' ? 1 : 0;
    if (!isDualCitizen) {
      values.second_passport_no = '';
      values.second_nation_id = '';
    }

    if (
      studentPicture.length === 0 ||
      passportPicture.length === 0 ||
      transcriptPicture.length === 0 ||
      certificatePicture.length === 0
    ) {
      notifyError(getLocalString(translations, 'Apply.NotifyError.Address'));
      return;
    }
    // Form alanlarını FormData'ya ekle
    Object.keys(values).forEach((key) => {
      if (
        key !== 'studentPicture' &&
        key !== 'passport' &&
        key !== 'transcript' &&
        key !== 'certificate'
      ) {
        formData.append(key, values[key]);
      }
    });

    if (phonePrefix) formData.append('phone_code', phonePrefix);

    // Dosyaları FormData'ya ekle
    if (values.studentPicture) {
      values.studentPicture.forEach((file: any) => {
        // file.originFileObj dosya nesnesine doğrudan erişim sağlar. Orjinal dosyamız orada
        formData.append('files[picture]', file.originFileObj);
      });
    }

    if (values.passport) {
      values.passport.forEach((file: any) => {
        formData.append('files[passport]', file.originFileObj);
      });
    }

    if (values.transcript) {
      values.transcript.forEach((file: any) => {
        formData.append('files[transcript]', file.originFileObj);
      });
    }

    if (values.certificate) {
      values.certificate.forEach((file: any) => {
        formData.append('files[certificate]', file.originFileObj);
      });
    }

    try {
      dispatch(setSpinner(true));
      const response = await axiosClient.post(apiURL.applyWithoutSession, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSpinner(false));
      notifySuccess(getLocalString(translations, 'Apply.NotifySuccess.Created'));

      setIdentity_no(response.data.data.identity_no);
    } catch (error: any) {
      dispatch(setSpinner(false));
      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError(getLocalString(translations, 'GeneralErrorMessage.Action'));
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    notifyError(getLocalString(translations, 'Apply.NotifyError.PleaseCheck'));
  };

  useEffect(() => {
    if (universityId) {
      form.setFieldsValue({ faculty_id: undefined, department_id: undefined });
      setFacultyId('');
      setDepartmentId('');
      setDepartmentList([]);

      dispatch(
        getUniversityFaculties({
          universityID: universityId,
          onSuccess: (data: any) => {
            setFacultyList(data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
    } else {
      // Üniversite seçimi kaldırıldığında, fakülte ve bölüm seçimlerini sıfırla
      setFacultyList([]);
      setDepartmentList([]);
      setFacultyId('');
      setDepartmentId('');
      form.setFieldsValue({ faculty_id: undefined, department_id: undefined });
    }
  }, [universityId, dispatch, form]);

  useEffect(() => {
    if (facultyId) {
      // Fakülte seçildiğinde, departman listesini getir ve departman seçimini sıfırla
      dispatch(
        getFacultyDepartments({
          faculty_id: facultyId,
          onSuccess: (data: any) => {
            setDepartmentList(data.data);
            setDepartmentId('');
            form.setFieldsValue({ department_id: undefined });
          },
        })
      );
    } else {
      // Fakülte seçimi sıfırlandığında, departman listesini ve seçimini sıfırla
      setDepartmentList([]);
      setDepartmentId('');
      form.setFieldsValue({ department_id: undefined });
    }
  }, [facultyId, dispatch, form]);

  const renderCountryOptions = useCallback(
    () => countryList.map((el: any) => ({ value: el.id, label: el.name })),
    [countryList]
  );
  const renderApplicationTypeOptions = useCallback(
    () => applicationTypeList.map((el: any) => ({ value: el.id, label: el.name })),
    [applicationTypeList]
  );
  const renderSemesterOptions = useCallback(
    () => semesterList.map((el: any) => ({ value: el.id, label: el.name })),
    [semesterList]
  );

  const renderUniversityOptions = useCallback(
    () => universityList.map((el: any) => ({ value: el.id, label: el.name })),
    [universityList]
  );

  const renderDepartmentOptions = useCallback(
    () => departmentList.map((el: any) => ({ value: el.id, label: el.name })),
    [departmentList]
  );
  const renderFacultyOptions = useCallback(
    () => facultyList.map((el: any) => ({ value: el.id, label: el.name })),
    [facultyList]
  );
  const renderGenderOptions = useCallback(
    () => genderList.map((el: any) => ({ value: el.id, label: el.name })),
    [genderList]
  );

  const steps = [
    {
      title: getLocalString(translations, 'Apply.PersonalDetails'),
    },
    {
      title: getLocalString(translations, 'Apply.YourEducation'),
    },
    {
      title: getLocalString(translations, 'Apply.UploadingDocuments'),
    },
  ];

  return (
    <PageContainer pageName={getLocalString(translations, 'Apply.ApplyForUniversity')}>
      {identity_no.length > 0 ? (
        <Result
          style={{ marginTop: '68px' }}
          status='success'
          title={getLocalString(translations, 'Apply.ApplicationCreatedSuccessfully')}
          subTitle={
            <Row style={{ width: '100%' }} justify={'center'} align={'middle'}>
              <Row style={{ width: '100%' }} justify={'center'} align={'middle'}>
                {getLocalString(translations, 'Apply.ApplicationCreatedSuccessfullyNot1')}{' '}
                &nbsp;&nbsp;
                <span style={{ fontSize: '24px' }}> {identity_no}</span>
              </Row>
              <Row style={{ width: '100%' }} justify={'center'} align={'middle'}>
                {getLocalString(translations, 'Apply.ApplicationCreatedSuccessfullyNot2')}

                <span style={{ color: '#000' }}>
                  {getLocalString(translations, 'Apply.ApplicationCreatedSuccessfullyNot3')}
                </span>
              </Row>
            </Row>
          }
        />
      ) : (
        <>
          <Row style={{ width: '100%', height: '500px' }} justify={'center'} align={'middle'}>
            {!isMobile && (
              <Col span={4} style={{ width: '100%', height: '100%' }}>
                <div
                  style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center' }}
                >
                  <Steps
                    responsive
                    style={{ height: '60%' }}
                    direction='vertical'
                    current={current}
                    onChange={(e) => {
                      setCurrent(e);
                    }}
                    status='process'
                    className='site-navigation-steps'
                    items={steps}
                  />
                </div>
              </Col>
            )}
            {!isMobile && (
              <Col span={1} style={{ height: '70%' }}>
                <Divider type='vertical' style={{ height: '100%' }}></Divider>
              </Col>
            )}

            <Col span={isMobile ? 24 : 19} style={{ width: '100%' }}>
              <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <>
                  {/*                ----------------------         STEP 1  -------------------                                 */}

                  <Row className={isMobile ? '' : current === 0 ? '' : styles.hide}>
                    <Row style={{ width: '100%' }} gutter={isMobile ? 0 : [24, 24]}>
                      <Col span={isMobile ? 24 : 12}>
                        <Form.Item
                          name='name'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.Name'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                  return Promise.reject(
                                    new Error(getLocalString(translations, 'Apply.Error.Name'))
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.Name')}
                            </Row>
                            <Input
                              maxLength={35}
                              onChange={(e) => {
                                form.setFieldsValue({ name: e.currentTarget.value.trim() });
                              }}
                              placeholder={getLocalString(translations, 'Apply.Placeholder.Name')}
                              size='large'
                            />
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 12}>
                        <Form.Item
                          name='surname'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.Surname'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                  return Promise.reject(
                                    new Error(getLocalString(translations, 'Apply.Error.Surname'))
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.Surname')}
                            </Row>

                            <Input
                              maxLength={35}
                              onChange={(e) => {
                                form.setFieldsValue({ surname: e.currentTarget.value.trim() });
                              }}
                              placeholder={getLocalString(
                                translations,
                                'Apply.Placeholder.Surname'
                              )}
                              size='large'
                            />
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      style={{ width: '100%', marginTop: '24px' }}
                      gutter={isMobile ? 0 : [24, 24]}
                    >
                      <Col span={isMobile ? 24 : 8}>
                        <Form.Item
                          name='father_name'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.FatherName'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                  return Promise.reject(
                                    new Error(
                                      getLocalString(translations, 'Apply.Error.FatherName')
                                    )
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.FatherName')}
                            </Row>

                            <Input
                              maxLength={35}
                              // value={form.getFieldValue('father_name')}
                              // onChange={(e) => {
                              //   form.setFieldsValue({ father_name: e.currentTarget.value.trim() });
                              // }}
                              placeholder={getLocalString(
                                translations,
                                'Apply.Placeholder.FatherName'
                              )}
                              size='large'
                            />
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 8}>
                        <Form.Item
                          name='mother_name'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.MotherName'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                  return Promise.reject(
                                    new Error(
                                      getLocalString(translations, 'Apply.Error.MotherName')
                                    )
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.MotherName')}
                            </Row>
                            <Input
                              maxLength={35}
                              onChange={(e) => {
                                form.setFieldsValue({ mother_name: e.currentTarget.value.trim() });
                              }}
                              placeholder={getLocalString(
                                translations,
                                'Apply.Placeholder.MotherName'
                              )}
                              size='large'
                            />
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 8}>
                        <Form.Item
                          name='passport_no'
                          rules={[
                            { required: true, message: 'Please input your name!' },
                            {
                              message: getLocalString(translations, 'Apply.Message.Passport'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                  return Promise.reject(
                                    new Error(getLocalString(translations, 'Apply.Error.Passport'))
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.PassportNo')}
                            </Row>
                            <Input
                              maxLength={35}
                              onChange={(e) => {
                                form.setFieldsValue({ passport_no: e.currentTarget.value.trim() });
                              }}
                              placeholder={getLocalString(
                                translations,
                                'Apply.Placeholder.Passport'
                              )}
                              size='large'
                            />
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      style={{ width: '100%', marginTop: '24px' }}
                      gutter={isMobile ? 0 : [24, 24]}
                    >
                      <Col span={isMobile ? 24 : 12}>
                        <Form.Item
                          name='phone'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.Phone'),
                            },
                            {
                              pattern: new RegExp(/^[0-9]{10,15}$/),
                              message: getLocalString(translations, 'Apply.Message.Phone2'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                  return Promise.reject(
                                    new Error(getLocalString(translations, 'Apply.Error.Phone'))
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row
                              className={`${globalStyles.label_r_large} ${styles.label_color}`}
                              style={{ width: '100%' }}
                            >
                              {getLocalString(translations, 'Apply.PhoneNumber')}
                            </Row>
                            <Space.Compact size='large' style={{ width: '100%' }}>
                              <Select
                                style={{ width: '25%' }}
                                defaultValue={'+90'}
                                value={phonePrefix || '+90'}
                                options={countryList.map((el) => ({
                                  value: el.phone_code,
                                  label: `${el.phone_code} ${el.name}`,
                                }))}
                                onChange={(e) => {
                                  setPhonePrefix(e);
                                }}
                                showSearch
                                filterOption={filterOption}
                              />
                              <Input
                                maxLength={35}
                                onChange={(e) => {
                                  form.setFieldsValue({
                                    phone: e.currentTarget.value.trim(),
                                  });
                                }}
                              />
                            </Space.Compact>
                            {/* <Input
                              maxLength={35}
                              onChange={(e) => {
                                form.setFieldsValue({ phone: e.currentTarget.value.trim() });
                              }}
                              placeholder={getLocalString(translations, 'Apply.Placeholder.Phone')}
                              size='large'
                              addonBefore={
                                <Select defaultValue={218}>
                                  {countryList.map((el) => (
                                    <Option value={el.id}>{el.phone_code}</Option>
                                  ))}
                                </Select>
                              }
                            /> */}
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 12}>
                        <Form.Item
                          name='email'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.Mail'),
                            },
                            {
                              type: 'email',
                              message: getLocalString(translations, 'Apply.Message.Mail2'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                  return Promise.reject(
                                    new Error(getLocalString(translations, 'Apply.Error.Mail'))
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.EmailAddress')}
                            </Row>
                            <Input
                              maxLength={35}
                              onChange={(e) => {
                                console.log(form.getFieldsValue());
                                form.setFieldsValue({ email: e.currentTarget.value.trim() });
                              }}
                              placeholder={getLocalString(translations, 'Apply.Placeholder.Email')}
                              size='large'
                            />
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Row>

                  {/*                ----------------------         STEP 2  -------------------                                 */}

                  <Row className={isMobile ? '' : current === 1 ? '' : styles.hide}>
                    <Row
                      style={{
                        width: '100%',
                        marginTop: '24px',
                      }}
                      gutter={isMobile ? 0 : [24, 24]}
                    >
                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          name='nation_id'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.Nation'),
                            },
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.Nation')}
                            </Row>
                            <Select
                              showSearch
                              filterOption={filterOption}
                              style={{ width: '100%' }}
                              placeholder={getLocalString(translations, 'Apply.Placeholder.Nation')}
                              size='large'
                              onChange={(e) => {
                                form.setFieldsValue({ nation_id: e });
                              }}
                              options={renderCountryOptions()}
                            ></Select>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          name='country_id'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.Country'),
                            },
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.Country')}
                            </Row>
                            <Select
                              showSearch
                              filterOption={filterOption}
                              style={{ width: '100%' }}
                              placeholder={getLocalString(
                                translations,
                                'Apply.Placeholder.Country'
                              )}
                              size='large'
                              onChange={(e) => form.setFieldsValue({ country_id: e })}
                              options={renderCountryOptions()}
                            ></Select>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          name='gender_id'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.Gender'),
                            },
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.Gender')}
                            </Row>
                            <Select
                              showSearch
                              filterOption={filterOption}
                              onChange={(e) => form.setFieldsValue({ gender_id: e })}
                              style={{ width: '100%' }}
                              placeholder={getLocalString(translations, 'Apply.Placeholder.Gender')}
                              size='large'
                              options={renderGenderOptions()}
                            ></Select>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          name='application_type_id'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(
                                translations,
                                'Apply.Message.ApplicationType'
                              ),
                            },
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.SelectApplication')}
                            </Row>
                            <Select
                              showSearch
                              filterOption={filterOption}
                              style={{ width: '100%' }}
                              onChange={(e) => form.setFieldsValue({ application_type_id: e })}
                              placeholder={getLocalString(
                                translations,
                                'Apply.Placeholder.Application'
                              )}
                              size='large'
                              options={renderApplicationTypeOptions()}
                            ></Select>
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      style={{ width: '100%', marginTop: '24px' }}
                      gutter={isMobile ? 0 : [24, 24]}
                    >
                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          name='university_id'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.University'),
                            },
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.University')}
                            </Row>
                            <Select
                              style={{ width: '100%' }}
                              placeholder={getLocalString(
                                translations,
                                'Apply.Placeholder.University'
                              )}
                              size='large'
                              onChange={(e) => {
                                form.setFieldsValue({ university_id: e });
                                setUniversityId(e);
                                setFacultyId('');
                                setDepartmentId('');
                                form.setFieldsValue({ department_id: '' });
                                form.setFieldsValue({ faculty_id: '' });
                              }}
                              showSearch
                              filterOption={filterOption}
                              options={renderUniversityOptions()}
                            ></Select>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          name='faculty_id'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.Faculty'),
                            },
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.Faculty')}
                            </Row>
                            <Select
                              style={{ width: '100%' }}
                              placeholder={getLocalString(
                                translations,
                                'Apply.Placeholder.Faculty'
                              )}
                              size='large'
                              value={form.getFieldValue('faculty_id') || undefined}
                              disabled={!form.getFieldValue('university_id')}
                              onChange={(e) => {
                                form.setFieldsValue({ faculty_id: e });
                                setFacultyId(e);
                                setDepartmentId('');
                                form.setFieldsValue({ department_id: undefined });
                              }}
                              showSearch
                              filterOption={filterOption}
                              options={renderFacultyOptions()}
                            ></Select>
                          </Row>
                        </Form.Item>
                      </Col>

                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          name='department_id'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.Department'),
                            },
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.Department')}
                            </Row>
                            <Select
                              key={`department-select-${facultyId}`}
                              showSearch
                              filterOption={filterOption}
                              value={form.getFieldValue('department_id') || undefined}
                              disabled={!form.getFieldValue('faculty_id')}
                              onChange={(e) => {
                                setDepartmentId(e);
                                form.setFieldsValue({ department_id: e });
                              }}
                              style={{ width: '100%' }}
                              placeholder={getLocalString(
                                translations,
                                'Apply.Placeholder.Department'
                              )}
                              size='large'
                              options={renderDepartmentOptions()}
                            ></Select>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          name='semester_id'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.Semester'),
                            },
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.Semester')}
                            </Row>
                            <Select
                              showSearch
                              filterOption={filterOption}
                              onChange={(e) => form.setFieldsValue({ semester_id: e })}
                              style={{ width: '100%' }}
                              placeholder={getLocalString(
                                translations,
                                'Apply.Placeholder.Semester'
                              )}
                              size='large'
                              options={renderSemesterOptions()}
                            ></Select>
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      style={{ width: '100%', marginTop: '24px' }}
                      gutter={isMobile ? 0 : [24, 24]}
                    >
                      <Col span={24}>
                        <Form.Item
                          name='school'
                          rules={[
                            {
                              required: true,
                              message: getLocalString(translations, 'Apply.Message.School'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                  return Promise.reject(
                                    new Error(getLocalString(translations, 'Apply.Error.School'))
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.School')}
                            </Row>
                            <Input
                              maxLength={70}
                              onChange={(e) => {
                                form.setFieldsValue({ school: e.currentTarget.value.trim() });
                              }}
                              style={{ width: '100%' }}
                              placeholder={getLocalString(translations, 'Apply.Placeholder.School')}
                              size='large'
                            ></Input>
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ width: '100%' }} gutter={isMobile ? 0 : [24, 24]}>
                      <Col span={24}>
                        <Form.Item
                          name='is_dual_citizenship'
                          valuePropName='checked'
                          initialValue={false}
                        >
                          <Checkbox
                            onChange={(e) => {
                              setIsDualCitizen(e.target.checked);
                            }}
                          >
                            {getLocalString(translations, 'Apply.DualCitizen')}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>

                    <div className={isDualCitizen ? '' : styles.hide}>
                      <Row style={{ width: '100%' }} gutter={isMobile ? 0 : [24, 24]}>
                        <Col span={isMobile ? 24 : 12}>
                          <Form.Item
                            name='second_passport_no'
                            rules={
                              isDualCitizen
                                ? [
                                    {
                                      required: true,
                                      message: getLocalString(
                                        translations,
                                        'Apply.Message.SecondPassport'
                                      ),
                                    },
                                    {
                                      message: getLocalString(
                                        translations,
                                        'Apply.Message.Passport'
                                      ),
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (
                                          value &&
                                          (value.startsWith(' ') || value.endsWith(' '))
                                        ) {
                                          return Promise.reject(
                                            new Error(
                                              getLocalString(
                                                translations,
                                                'Apply.Error.SecondPassport'
                                              )
                                            )
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]
                                : []
                            }
                          >
                            <Row style={{ width: '100%', gap: '5px' }}>
                              <Row
                                className={`${globalStyles.label_r_large} ${styles.label_color}`}
                              >
                                {getLocalString(translations, 'Apply.SecondPassport')}
                              </Row>
                              <Input
                                maxLength={35}
                                onChange={(e) => {
                                  form.setFieldsValue({
                                    second_passport_no: e.currentTarget.value.trim(),
                                  });
                                }}
                                placeholder={getLocalString(
                                  translations,
                                  'Apply.Placeholder.SecondPassport'
                                )}
                                size='large'
                              />
                            </Row>
                          </Form.Item>
                        </Col>
                        <Col span={isMobile ? 24 : 12}>
                          <Form.Item
                            name='second_nation_id'
                            rules={
                              isDualCitizen
                                ? [
                                    {
                                      required: true,
                                      message: getLocalString(
                                        translations,
                                        'Apply.Message.SecondCountry'
                                      ),
                                    },
                                  ]
                                : []
                            }
                          >
                            <Row style={{ width: '100%', gap: '5px' }}>
                              <Row
                                className={`${globalStyles.label_r_large} ${styles.label_color}`}
                              >
                                {getLocalString(translations, 'Apply.SecondCountry')}
                              </Row>
                              <Select
                                showSearch
                                filterOption={filterOption}
                                onChange={(e) => form.setFieldsValue({ second_nation_id: e })}
                                style={{ width: '100%' }}
                                placeholder={getLocalString(
                                  translations,
                                  'Apply.Placeholder.SecondPassport'
                                )}
                                size='large'
                                options={countryList.map((el) => ({
                                  value: el.id,
                                  label: el.name,
                                }))}
                              ></Select>
                            </Row>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Row>

                  <Row className={isMobile ? '' : current === 2 ? '' : styles.hide}>
                    <Row
                      style={{
                        width: '100%',
                        marginTop: '24px',
                      }}
                      gutter={isMobile ? 0 : [24, 24]}
                    >
                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          validateStatus={studentPicture.length === 0 ? 'error' : ''}
                          help={studentPicture.length === 0 ? 'Please upload student picture!' : ''}
                          name='studentPicture'
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row> {getLocalString(translations, 'Apply.StudentPicture')}</Row>
                            <Upload
                              accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                              style={{ width: '100%' }}
                              beforeUpload={(file: UploadFile) =>
                                beforeUpload([file], 'studentPicture', setStudentPicture)
                              }
                              fileList={studentPicture}
                              maxCount={0}
                              onPreview={handlePreview}
                              listType='picture-card'
                              onRemove={() => {
                                setStudentPicture([]);
                                setPreviewImage(undefined);
                                setPreviewPdf(undefined);
                                setPreviewOpen(false);
                                form.setFieldValue('studentPicture', undefined);
                              }}
                            >
                              {studentPicture?.length > 0 ? null : (
                                <div>
                                  <PlusOutlined />
                                  <div style={{ marginTop: 8 }}>
                                    {' '}
                                    {getLocalString(translations, 'Apply.Upload')}
                                  </div>
                                </div>
                              )}
                            </Upload>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          validateStatus={passportPicture.length === 0 ? 'error' : ''}
                          help={passportPicture.length === 0 ? 'Please upload passport!' : ''}
                          name='passport'
                        >
                          <Row style={{ width: '100%', gap: '5px' }} className='upload-container'>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.Passport')}
                            </Row>
                            <Upload
                              accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                              style={{ width: '100%' }}
                              beforeUpload={(file: UploadFile) =>
                                beforeUpload([file], 'passport', setPassportPicture)
                              }
                              fileList={passportPicture}
                              onPreview={handlePreview}
                              listType='picture-card'
                              onRemove={() => {
                                setPassportPicture([]);
                                setPreviewImage(undefined);
                                setPreviewPdf(undefined);
                                setPreviewOpen(false);
                                form.setFieldValue('passport', undefined);
                              }}
                            >
                              {passportPicture?.length > 0 ? null : (
                                <div>
                                  <PlusOutlined />
                                  <div style={{ marginTop: 8 }}>
                                    {getLocalString(translations, 'Apply.Upload')}
                                  </div>
                                </div>
                              )}
                            </Upload>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          validateStatus={transcriptPicture.length === 0 ? 'error' : ''}
                          help={transcriptPicture.length === 0 ? 'Please upload transcript!' : ''}
                          name='transcript'
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.Transcript')}
                            </Row>
                            <Upload
                              accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                              style={{ width: '100%' }}
                              beforeUpload={(file: UploadFile) =>
                                beforeUpload([file], 'transcript', setTranscriptPicture)
                              }
                              fileList={transcriptPicture}
                              maxCount={0}
                              onPreview={handlePreview}
                              listType='picture-card'
                              onRemove={() => {
                                setTranscriptPicture([]);
                                setPreviewImage(undefined);
                                setPreviewPdf(undefined);
                                setPreviewOpen(false);
                                form.setFieldValue('transcript', undefined);
                              }}
                            >
                              {transcriptPicture?.length > 0 ? null : (
                                <div>
                                  <PlusOutlined />
                                  <div style={{ marginTop: 8 }}>
                                    {' '}
                                    {getLocalString(translations, 'Apply.Upload')}
                                  </div>
                                </div>
                              )}
                            </Upload>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={isMobile ? 24 : 6}>
                        <Form.Item
                          validateStatus={certificatePicture.length === 0 ? 'error' : ''}
                          help={certificatePicture.length === 0 ? 'Please upload certificate!' : ''}
                          name='certificate'
                        >
                          <Row style={{ width: '100%', gap: '5px' }}>
                            <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                              {getLocalString(translations, 'Apply.Certificate')}
                            </Row>
                            <Upload
                              accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                              style={{ width: '100%' }}
                              beforeUpload={(file: UploadFile) =>
                                beforeUpload([file], 'certificate', setCertificatePicture)
                              }
                              fileList={certificatePicture}
                              maxCount={0}
                              onPreview={handlePreview}
                              listType='picture-card'
                              onRemove={() => {
                                setCertificatePicture([]);
                                setPreviewImage(undefined);
                                setPreviewPdf(undefined);
                                setPreviewOpen(false);
                                form.setFieldValue('certificate', undefined);
                              }}
                            >
                              {certificatePicture?.length > 0 ? null : (
                                <div>
                                  <PlusOutlined />
                                  <div style={{ marginTop: 8 }}>
                                    {' '}
                                    {getLocalString(translations, 'Apply.Upload')}
                                  </div>
                                </div>
                              )}
                            </Upload>
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      style={{ width: '100%', marginTop: '12px' }}
                      gutter={isMobile ? 0 : [24, 24]}
                    >
                      <Col span={24}>
                        <Form.Item
                          name='is_contract'
                          valuePropName='checked'
                          rules={[
                            {
                              validator: (_, value) =>
                                value
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(
                                        getLocalString(translations, 'Apply.Error.Agreement')
                                      )
                                    ),
                            },
                          ]}
                        >
                          <Checkbox>
                            {getLocalString(translations, 'Apply.agreement1')}

                            <a target='_blank' href='https://www.uniteamedu.com/'>
                              {getLocalString(translations, 'Apply.agreement2')}
                            </a>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Row>
                </>
                {isMobile && (
                  <Row
                    style={{ marginBottom: '50px', marginTop: '50px', width: '100%' }}
                    justify={'center'}
                  >
                    <Button
                      loading={isOn}
                      size='large'
                      style={{ width: '50%' }}
                      type='primary'
                      onClick={() => form.submit()}
                    >
                      {getLocalString(translations, 'Apply.Done')}
                    </Button>
                  </Row>
                )}
              </Form>
            </Col>
          </Row>

          {!isMobile && (
            <>
              <Divider></Divider>
              <Row style={{ marginTop: '24px', width: '100%' }} justify={'space-between'}>
                {current > 0 && (
                  <Button
                    size='large'
                    onClick={() => {
                      setCurrent(current - 1);
                    }}
                  >
                    {getLocalString(translations, 'Apply.Previous')}
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button size='large' type='primary' onClick={() => setCurrent(current + 1)}>
                    {getLocalString(translations, 'Apply.Next')}
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button loading={isOn} size='large' type='primary' onClick={() => form.submit()}>
                    {getLocalString(translations, 'Apply.Done')}
                  </Button>
                )}
              </Row>
            </>
          )}
          <Modal
            open={previewOpen}
            style={{ width: '120%', padding: '24px 36px' }}
            footer={null}
            onCancel={() => setPreviewOpen(false)}
          >
            {!!previewImage ? (
              <img alt='example' style={{ width: '93%' }} src={previewImage} />
            ) : (
              <iframe
                src={previewPdf}
                width='100%'
                height='100%'
                style={{ border: 'none' }}
              ></iframe>
            )}
          </Modal>
        </>
      )}
    </PageContainer>
  );
};

export default ApplyNow;
