import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Card, Input, DatePicker } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import globalStyles from '../../../styles/global.module.scss';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import { constantsSelector } from '../../../store/constants';
import { apiURL } from '../../../service';
import { createDepartment, getDepartmentDetail, updateDepartment } from '../../../store/university';
import { useNavigate, useParams } from 'react-router-dom';
import isNumeric from '../../../helpers/isNumeric';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { notifyError, notifySuccess } from '../../../helpers/notify';
import { StyleInfo } from 'antd/es/theme/util/genComponentStyleHook';
import moment from 'moment';
import {
  adminCreate,
  agencyCreate,
  getAdmin,
  getAgency,
  getWorkerByAdmin,
  workerCreateByAdmin,
  workerUpdateByAdmin,
} from '../../../store/admin';
import { IWorkerInfo } from '../../../types/worker';

const AddWorker: React.FC = () => {
  const { agencyId, workerId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');

  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [status, setStatus] = useState<string>('');

  const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(true);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true);
  const [isPasswordsValid, setIsPasswordsValid] = useState<boolean>(false);

  const [workerInfo, setWorkerInfo] = useState<IWorkerInfo>();

  useEffect(() => {
    agencyId &&
      workerId &&
      dispatch(
        getWorkerByAdmin({
          agencyId: agencyId,
          workerId: workerId,
          onSuccess: (data: any) => {
            setName(data.data.name);
            setSurname(data.data.surname);
            setEmail(data.data.email);
            setPhone(data.data.phone);
            setWorkerInfo(data.data);
          },
        })
      );
  }, [agencyId, workerId]);
  useEffect(() => {
    if (
      name !== '' &&
      surname !== '' &&
      email !== '' &&
      phone !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      isPasswordsValid
    )
      setIsCreateDisabled(false);
    else setIsCreateDisabled(true);

    if (
      agencyId &&
      workerId &&
      name.length &&
      surname.length &&
      email.length &&
      phone.length &&
      (password.length > 0
        ? isPasswordsValid
        : confirmPassword.length > 0
        ? isPasswordsValid
        : true)
    ) {
      if (
        name !== workerInfo?.name ||
        surname !== workerInfo?.surname ||
        email !== workerInfo?.email ||
        phone !== workerInfo?.phone ||
        (password.length > 0
          ? isPasswordsValid
          : confirmPassword.length > 0
          ? isPasswordsValid
          : isPasswordsValid)
      ) {
        setIsEditDisabled(false);
      } else {
        setIsEditDisabled(true);
      }
    } else {
      setIsEditDisabled(true);
    }
  }, [name, surname, phone, email, password, confirmPassword, isPasswordsValid]);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailBlur = () => {
    if (email && !validateEmail(email)) {
      notifyError(getLocalString(translations, 'CreateWorker.NotifyError.Email'));
    }
  };

  const validatePasswords = () => {
    if (password.length < 8 || confirmPassword.length < 8) {
      notifyError(getLocalString(translations, 'CreateWorker.NotifyError.PasswordLong'));
      setIsPasswordsValid(false);
    } else if (password !== confirmPassword) {
      notifyError(getLocalString(translations, 'CreateWorker.NotifyError.PasswordsNotMatch'));
      setIsPasswordsValid(false);
    } else setIsPasswordsValid(true);
  };
  const validatePhoneNumber = () => {
    const regex = /^\+[1-9]\d{1,14}$/;

    if (!regex.test(phone)) {
      notifyError(getLocalString(translations, 'CreateWorker.NotifyError.Phone'));
    }
  };

  return (
    <PageContainer
      pageName={
        workerId
          ? getLocalString(translations, 'CreateWorker.EditWorker')
          : getLocalString(translations, 'CreateWorker.CreateWorker')
      }
      backUrl={'/agency-detail/' + agencyId}
    >
      <Row style={{ width: '100%' }}>
        <Col offset={6} span={12}>
          <Row gutter={[16, 16]}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateWorker.Name')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'CreateWorker.Placeholder.Name')}
                value={name || undefined}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>

            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateWorker.Surname')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'CreateWorker.Placeholder.Surname')}
                value={surname || undefined}
                onChange={(e) => {
                  setSurname(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateWorker.Phone')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'CreateWorker.Placeholder.Phone')}
                value={phone || undefined}
                onChange={(e) => {
                  setPhone(e.currentTarget.value);
                }}
                onKeyDown={(e) => {
                  if (!isNumeric(e.key) && e.key !== 'Backspace' && e.key !== '+')
                    e.preventDefault();
                }}
                onBlur={validatePhoneNumber}
                size='large'
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateWorker.Email')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'CreateWorker.Placeholder.Email')}
                value={email || undefined}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
                onBlur={handleEmailBlur}
                size='large'
              ></Input>
            </Row>

            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateWorker.Password')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'CreateWorker.Placeholder.Password')}
                size='large'
                value={password}
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                  setIsCreateDisabled(true);
                  setConfirmPassword('');
                }}
                //   onBlur={validatePasswords}
              />
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateWorker.ConfirmPassword')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'CreateWorker.Placeholder.Password')}
                size='large'
                value={confirmPassword}
                onChange={(e) => {
                  setIsCreateDisabled(true);
                  setConfirmPassword(e.currentTarget.value);
                  setIsPasswordsValid(false);
                }}
                onBlur={validatePasswords}
              />
            </Row>
          </Row>

          {agencyId && workerId ? (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                onClick={() => {
                  agencyId &&
                    workerId &&
                    dispatch(
                      workerUpdateByAdmin({
                        workerId: workerId,
                        agencyId: agencyId,
                        name: name,
                        surname: surname,
                        phone: phone,
                        email: email,
                        password: password,
                        password_confirmation: confirmPassword,
                        onSuccess: (data: any) => {
                          notifySuccess(
                            getLocalString(translations, 'CreateWorker.NotifySuccess.WorkerUpdated')
                          );
                          navigate('/agency-detail/' + agencyId);
                        },
                      })
                    );
                }}
                size='large'
                type='primary'
                disabled={isEditDisabled}
                style={!isCreate ? { display: 'none' } : {}}
              >
                {getLocalString(translations, 'CreateWorker.EditWorker')}
              </Button>
            </Row>
          ) : (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                onClick={() => {
                  agencyId &&
                    dispatch(
                      workerCreateByAdmin({
                        agencyId: agencyId,
                        name: name,
                        surname: surname,
                        phone: phone,
                        email: email,
                        password: password,
                        password_confirmation: confirmPassword,
                        onSuccess: (data: any) => {
                          notifySuccess(
                            getLocalString(translations, 'CreateWorker.NotifySuccess.WorkerCreated')
                          );
                          navigate('/agency-detail/' + agencyId);
                        },
                      })
                    );
                }}
                size='large'
                type='primary'
                disabled={isCreateDisabled}
                style={!isCreate ? { display: 'none' } : {}}
              >
                {getLocalString(translations, 'CreateWorker.CreateWorker')}
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </PageContainer>
  );
};

export default AddWorker;
