import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Card, Statistic, DatePicker } from 'antd';
import { TeamOutlined, GlobalOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';
import CountUp from 'react-countup';
import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import {
  constantsSelector,
  getAdminAndAdminWorkerUsers,
  getInterviewStatusList,
  getInterviewTypes,
} from '../../../store/constants';
import { apiURL } from '../../../service';
import { universitySelector } from '../../../store/university';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CustomizedTable from '../../../components/CustomizedTable';

import {
  detailInterviewList,
  getInterviewTypeCount,
  getTotalInterviewCount,
  getUserAndDateBasedTable,
} from '../../../store/interview';
import { spinnerSelector } from '../../../store/spinner';

const { Option } = Select;
const { RangePicker } = DatePicker;

const Reports: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { isOn } = useAppSelector(spinnerSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { universityList, departmentList, programList } = useAppSelector(constantsSelector);
  const { userList, userAdminList } = useAppSelector(constantsSelector);

  const [userDetails, setUserDetails] = useState<any>();
  const [userAndDateDetails, setUserAndDateDetails] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>();
  const [yearStart, setYearStart] = useState<number>();
  const [yearEnd, setYearEnd] = useState<number>();
  const [faceToFace, setFaceToFace] = useState<number>();
  const [advertising, setAdvertising] = useState<number>();

  const [languageId, setLanguageId] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);
  const [userId, setUserId] = useState<string>();
  const [filters, setFilters] = useState<object>({});
  const [filters2, setFilters2] = useState<object>({});
  const [reload, setReload] = useState<boolean>(true);

  const renderUserOptions = useCallback(
    () =>
      userAdminList.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name + ' ' + el.surname}
        </Option>
      )),
    [userAdminList]
  );

  const totalColumns = [
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.New'),
      dataIndex: '8', //     "name": "New"
      key: '8',
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.Discussed'),
      dataIndex: '1', // "name": "Discussed"
      key: '1',
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.NotDiscussed'),
      dataIndex: '2',
      key: '2', // "name": "Not Discussed"
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.NotInterested'),
      dataIndex: '3',
      key: '3', //     "name": "Not Interested"
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.Interested'),
      dataIndex: '4',
      key: '4', //     "name": "Interested"
    },
    {
      title: getLocalString(
        translations,
        'PreliminaryInterviewList.TotalColumns.IncorrectInformation'
      ),
      dataIndex: '5', //     "name": "Missing or incorrect contact information"
      key: '5',
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.MeetingLater'),
      dataIndex: '6',
      key: '6', //     "name": "Meeting Later"
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.CustomerDefined'),
      dataIndex: '7', //     "name": "Customer Defined"
      key: '7',
    },

    {
      title: getLocalString(
        translations,
        'PreliminaryInterviewList.TotalColumns.WaitingforRegistration'
      ),
      dataIndex: '9',
      key: '9', //     "name": "Waiting for registration"
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.Created'),
      dataIndex: '10', //     "name": "Created"
      key: '10',
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.Total'),
      dataIndex: 'total',
      key: 'total',
    },
  ];
  const conductedByUsersColumn = [
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.User'),
      dataIndex: 'name',
      key: 'name',
      render: (e: any, record: any) => {
        return record?.name + ' ' + record?.surname;
      },
    },

    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.New'),
      dataIndex: '8',
      key: '8', //     "name": "New"
      render: (e: any, record: any) => {
        const detailCount = record.meet_count.detail.find((detail: any) => detail.status.id === 8);
        return detailCount ? detailCount.count : '-';
      },
    },

    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.Discussed'),
      dataIndex: '1',
      key: '1',
      // "name": "Discussed"
      render: (e: any, record: any) => {
        const detailCount = record.meet_count.detail.find((detail: any) => detail.status.id === 1);
        return detailCount ? detailCount.count : '-';
      },
    },

    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.NotDiscussed'),
      dataIndex: '2',
      key: '2',
      // "name": "Not Discussed"
      render: (e: any, record: any) => {
        const detailCount = record.meet_count.detail.find((detail: any) => detail.status.id === 2);
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.NotInterested'),
      dataIndex: '3',
      key: '3', //     "name": "Not Interested"
      render: (e: any, record: any) => {
        const detailCount = record.meet_count.detail.find((detail: any) => detail.status.id === 3);
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.Interested'),
      dataIndex: '4',
      key: '4', //     "name": "Interested"
      render: (e: any, record: any) => {
        const detailCount = record.meet_count.detail.find((detail: any) => detail.status.id === 4);
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(
        translations,
        'PreliminaryInterviewList.DetailColumns.IncorrectInformation'
      ),
      dataIndex: '5',
      key: '5', //     "name": "Missing or incorrect contact information"
      render: (e: any, record: any) => {
        const detailCount = record.meet_count.detail.find((detail: any) => detail.status.id === 5);
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.MeetingLater'),
      dataIndex: '6',
      key: '6', //     "name": "Meeting Later"
      render: (e: any, record: any) => {
        const detailCount = record.meet_count.detail.find((detail: any) => detail.status.id === 6);
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.CustomerDefined'),
      dataIndex: '7',
      key: '7', //     "name": "Customer Defined"
      render: (e: any, record: any) => {
        const detailCount = record.meet_count.detail.find((detail: any) => detail.status.id === 7);
        return detailCount ? detailCount.count : '-';
      },
    },

    {
      title: getLocalString(
        translations,
        'PreliminaryInterviewList.DetailColumns.WaitingforRegistration'
      ),
      dataIndex: '9',
      key: '9', //     "name": "Waiting for registration"
      render: (e: any, record: any) => {
        const detailCount = record.meet_count.detail.find((detail: any) => detail.status.id === 9);
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.Created'),
      dataIndex: '10',
      key: '10', //     "name": "Created"
      render: (e: any, record: any) => {
        const detailCount = record.meet_count.detail.find((detail: any) => detail.status.id === 10);
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.Total'),
      dataIndex: 'meet_count.total',
      key: 'total', // Total sayımını temsil eden anahtar
      render: (e: any, record: any) => {
        return record.meet_count.total !== undefined ? record.meet_count.total : '-';
      },
    },
  ];
  const userDateDetailColumns = [
    {
      title: getLocalString(
        translations,
        'PreliminaryInterviewList.DetailColumns.CandidateNameSurname'
      ),
      dataIndex: 'name',
      key: 'name',
      render: (e: any, record: any) => {
        return record?.name + ' ' + record?.surname;
      },
    },

    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.New'),
      dataIndex: '8',
      key: '8', //     "name": "New"
      render: (e: any, record: any) => {
        const detailCount = record.details_count.detail.find(
          (detail: any) => detail.status.id === 8
        );
        return detailCount ? detailCount.count : '-';
      },
    },

    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.Discussed'),
      dataIndex: '1',
      key: '1',
      // "name": "Discussed"
      render: (e: any, record: any) => {
        const detailCount = record.details_count.detail.find(
          (detail: any) => detail.status.id === 1
        );
        return detailCount ? detailCount.count : '-';
      },
    },

    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.NotDiscussed'),
      dataIndex: '2',
      key: '2',
      // "name": "Not Discussed"
      render: (e: any, record: any) => {
        const detailCount = record.details_count.detail.find(
          (detail: any) => detail.status.id === 2
        );
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.NotInterested'),
      dataIndex: '3',
      key: '3', //     "name": "Not Interested"
      render: (e: any, record: any) => {
        const detailCount = record.details_count.detail.find(
          (detail: any) => detail.status.id === 3
        );
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.Interested'),
      dataIndex: '4',
      key: '4', //     "name": "Interested"
      render: (e: any, record: any) => {
        const detailCount = record.details_count.detail.find(
          (detail: any) => detail.status.id === 4
        );
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(
        translations,
        'PreliminaryInterviewList.DetailColumns.IncorrectInformation'
      ),
      dataIndex: '5',
      key: '5', //     "name": "Missing or incorrect contact information"
      render: (e: any, record: any) => {
        const detailCount = record.details_count.detail.find(
          (detail: any) => detail.status.id === 5
        );
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.MeetingLater'),
      dataIndex: '6',
      key: '6', //     "name": "Meeting Later"
      render: (e: any, record: any) => {
        const detailCount = record.details_count.detail.find(
          (detail: any) => detail.status.id === 6
        );
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.DetailColumns.CustomerDefined'),
      dataIndex: '7',
      key: '7', //     "name": "Customer Defined"
      render: (e: any, record: any) => {
        const detailCount = record.details_count.detail.find(
          (detail: any) => detail.status.id === 7
        );
        return detailCount ? detailCount.count : '-';
      },
    },

    {
      title: getLocalString(
        translations,
        'PreliminaryInterviewList.DetailColumns.WaitingforRegistration'
      ),
      dataIndex: '9',
      key: '9', //     "name": "Waiting for registration"
      render: (e: any, record: any) => {
        const detailCount = record.details_count.detail.find(
          (detail: any) => detail.status.id === 9
        );
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.Created'),
      dataIndex: '10',
      key: '10', //     "name": "Created"
      render: (e: any, record: any) => {
        const detailCount = record.details_count.detail.find(
          (detail: any) => detail.status.id === 10
        );
        return detailCount ? detailCount.count : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.TotalColumns.Total'),
      dataIndex: 'total',
      key: 'total', // Total sayımını temsil eden anahtar
      render: (e: any, record: any) => {
        // Burada, her kaydın details_count objesi altındaki total değerini kontrol ediyoruz.
        return record.details_count.total !== undefined ? record.details_count.total : '-';
      },
    },
  ];
  useEffect(() => {
    dispatch(getAdminAndAdminWorkerUsers());

    dispatch(
      //Yüzyüze ve Reklamdan gelen data
      getInterviewTypeCount({
        onSuccess: (data: any) => {
          setAdvertising(data.Advert);
          setFaceToFace(data['Face to Face']);
        },
      })
    );

    dispatch(
      //Toplam tablo
      //Total Preliminary Interview Information
      getTotalInterviewCount({
        onSuccess: (data: any) => {
          setTotalCount(processDataForTable(data));
        },
      })
    );

    dispatch(
      //Preliminary Interview Information Conducted by Users

      detailInterviewList({
        onSuccess: (data: any) => {
          setUserDetails(data);
        },
      })
    );

    dispatch(getInterviewTypes());
    dispatch(getInterviewStatusList());
  }, []);

  interface ICount {
    [key: string]: number;
  }

  interface IData {
    count: ICount;
    total: number;
  }

  interface ITableData {
    key: string;
    total: number;
    [key: string]: number | string; // Dinamik anahtarlar için
  }

  const processDataForTable = (data: IData): ITableData[] => {
    const { count, total } = data;

    // Tek bir satır oluştur
    const tableDataRow: ITableData = {
      key: 'uniqueRowKey', // Bu tek satırlı tablo için benzersiz bir anahtar
      total: total,
      ...count, // Spread operatörü ile 'count' objesini doğrudan satıra ekleyin
    };

    return [tableDataRow]; // Dizi olarak döndür
  };

  interface IUserDetails {
    id: number;
    name: string;
    surname: string;
    username: string;
  }

  interface IDetailCount {
    [key: string]: number;
  }

  interface IUserData {
    'detail-count': IDetailCount;
    total: string;
    user: IUserDetails;
  }

  interface ITransformedData {
    key: string;
    username: string;
    surname: string;
    name: string;
    total: string;
    detailCounts: IDetailCount;
  }

  return (
    <PageContainer
      pageName={getLocalString(translations, 'Reports.InterviewReports')}
      // extra={
      //   <Button
      //     size='large'
      //     type='primary'
      //     // disabled={!isCreate}
      //     //isCreate False olursa disable olur
      //     onClick={() => navigate('/create-interview')}
      //   >
      //     {getLocalString(translations, 'PreliminaryInterviewList.CreateInterview')}
      //   </Button>
      // }
    >
      <Row gutter={[24, 24]}>
        <Col flex={5}>
          <Card style={{ background: '#fafafa' }}>
            <Row>
              <Col span={6}>
                <TeamOutlined style={{ fontSize: '63px' }} />
              </Col>
              <Col span={18}>
                <Statistic
                  style={{ textAlign: 'right' }}
                  title={getLocalString(translations, 'PreliminaryInterviewList.FromFaceToFace')}
                  value={faceToFace}
                  precision={2}
                  formatter={(value: any) => <CountUp end={value} separator=',' />}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col flex={5}>
          <Card style={{ background: '#fafafa' }}>
            <Row>
              <Col span={6}>
                <GlobalOutlined style={{ fontSize: '63px' }} />
              </Col>
              <Col span={18}>
                <Statistic
                  style={{ textAlign: 'right' }}
                  title={getLocalString(translations, 'PreliminaryInterviewList.FromAdvertising')}
                  value={advertising}
                  precision={2}
                  formatter={(value: any) => <CountUp end={value} separator=',' />}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <>
        <Row style={{ marginTop: '36px' }}>
          {getLocalString(translations, 'PreliminaryInterviewList.TotalInterviewInformation')}
          <Row style={{ marginTop: '16px' }}>
            <CustomizedTable columns={totalColumns} data={totalCount || []} />
          </Row>
        </Row>
        <Row style={{ marginTop: '36px' }}>
          {getLocalString(translations, 'PreliminaryInterviewList.InterviewInformation')}

          <Row style={{ marginTop: '24px' }}>
            <Row>
              <CustomizedTable columns={conductedByUsersColumn} data={userDetails || []} />
            </Row>
          </Row>
        </Row>
        <Row style={{ marginTop: '36px' }}>
          {getLocalString(translations, 'PreliminaryInterviewList.UserDateInformation')}

          <Row style={{ width: '100%', marginTop: '16px' }} gutter={16}>
            <Col span={5}>
              <Select
                showSearch
                filterOption={filterOption}
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'PreliminaryInterviewList.Placeholder.SelectUser'
                )}
                value={userId || undefined}
                onChange={(e) => setUserId(e)}
              >
                {renderUserOptions()}
              </Select>
            </Col>
            <Col span={5}>
              <RangePicker
                style={{ width: '100%' }}
                // disabledDate={(current: any) => {
                //   return current && current < dayjs().startOf('year');
                // }}
                allowClear
                value={yearStart && yearEnd ? [dayjs(yearStart), dayjs(yearEnd)] : undefined}
                onChange={(dates: any) => {
                  setYearStart(dates ? dates[0].format('YYYY-MM-DD') : null);
                  setYearEnd(dates ? dates[1].format('YYYY-MM-DD') : null);
                }}
              />
            </Col>
            <Col span={5}>
              <Button
                disabled={!userId || !yearStart || !yearEnd}
                loading={isOn}
                type='primary'
                onClick={() => {
                  let filter: any = {};
                  let filterCount = 0;

                  if (yearEnd && yearStart) {
                    filter.yearEnd = yearEnd;
                    filter.yearStart = yearStart;
                    filterCount++;
                  }

                  setFilterCount(filterCount);
                  setFilters(filter);

                  setReload(true);
                }}
              >
                {getLocalString(translations, 'PreliminaryInterviewList.Search')}
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: '24px' }}>
            {userId && yearStart && yearEnd && filterCount > 0 && (
              <CustomizedTable
                columns={userDateDetailColumns}
                data={[]}
                url={apiURL.getUserAndDateBasedTable.replace(':user_id', userId)}
                filter={filters}
                reload={reload}
                setReload={setReload}
              />
            )}
          </Row>
        </Row>
      </>
    </PageContainer>
  );
};

export default Reports;
