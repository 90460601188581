import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { userReducer } from './user';
import { accountReducer } from './account';

import { menuReducer } from './menu';

import { languageReducer } from './language';
import { constantsReducer } from './constants';
import { spinnerReducer } from './spinner';
import { applicationReducer } from './application/reducer';
import { studentReducer } from './student';
import { universityReducer } from './university';
import { adminReducer } from './admin';
import { dashboardReducer } from './dashboard';
import { notificationReducer } from './notification';
import { exceededReducer } from './deadlines';
import { interviewReducer } from './interview';

export const store = configureStore({
  reducer: {
    user: userReducer,
    account: accountReducer,
    menu: menuReducer,
    language: languageReducer,
    constants: constantsReducer,
    spinner: spinnerReducer,
    application: applicationReducer,
    student: studentReducer,
    university: universityReducer,
    admin: adminReducer,
    dashboard: dashboardReducer,
    notification: notificationReducer,
    exceeded: exceededReducer,
    interview: interviewReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
