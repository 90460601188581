import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Card, Input, DatePicker } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import globalStyles from '../../../styles/global.module.scss';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';
import CustomizedTable from '../../../components/CustomizedTable';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import { constantsSelector } from '../../../store/constants';
import { apiURL } from '../../../service';
import { createDepartment, getDepartmentDetail, updateDepartment } from '../../../store/university';
import { useNavigate, useParams } from 'react-router-dom';
import isNumeric from '../../../helpers/isNumeric';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { notifyError, notifySuccess } from '../../../helpers/notify';
import { StyleInfo } from 'antd/es/theme/util/genComponentStyleHook';
import moment from 'moment';
import {
  adminCreate,
  agencyCreate,
  getAdmin,
  getAgency,
  getWorkerByAdmin,
  getWorkerByAgencyUser,
  workerCreateByAdmin,
  workerCreateByAgency,
  workerUpdateByAdmin,
  workerUpdateByAgency,
} from '../../../store/admin';
import { IWorkerInfo } from '../../../types/worker';

const UserAddUpdateWorker: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');

  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [status, setStatus] = useState<string>('');

  const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(true);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true);
  const [isPasswordsValid, setIsPasswordsValid] = useState<boolean>(false);

  const [workerInfo, setWorkerInfo] = useState<IWorkerInfo>();

  useEffect(() => {
    id &&
      dispatch(
        getWorkerByAgencyUser({
          id: id,
          onSuccess: (data: any) => {
            setName(data.data.name);
            setSurname(data.data.surname);
            setEmail(data.data.email);
            setPhone(data.data.phone);
            setWorkerInfo(data.data);
          },
        })
      );
  }, [id]);
  useEffect(() => {
    if (
      name !== '' &&
      surname !== '' &&
      email !== '' &&
      phone !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      isPasswordsValid
    )
      setIsCreateDisabled(false);
    else setIsCreateDisabled(true);

    if (
      id &&
      name.length &&
      surname.length &&
      email.length &&
      phone.length &&
      (password.length > 0
        ? isPasswordsValid
        : confirmPassword.length > 0
        ? isPasswordsValid
        : true)
    ) {
      if (
        name !== workerInfo?.name ||
        surname !== workerInfo?.surname ||
        email !== workerInfo?.email ||
        phone !== workerInfo?.phone ||
        (password.length > 0
          ? isPasswordsValid
          : confirmPassword.length > 0
          ? isPasswordsValid
          : isPasswordsValid)
      ) {
        setIsEditDisabled(false);
      } else {
        setIsEditDisabled(true);
      }
    } else {
      setIsEditDisabled(true);
    }
  }, [name, surname, phone, email, password, confirmPassword, isPasswordsValid]);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailBlur = () => {
    if (email && !validateEmail(email)) {
      notifyError(getLocalString(translations, 'UserAddUpdateWorker.NotifyError.Email'));
    }
  };

  const validatePasswords = () => {
    if (password.length < 8 || confirmPassword.length < 8) {
      notifyError(getLocalString(translations, 'UserAddUpdateWorker.NotifyError.PasswordLong'));
      setIsPasswordsValid(false);
    } else if (password !== confirmPassword) {
      notifyError(
        getLocalString(translations, 'UserAddUpdateWorker.NotifyError.PasswordsNotMatch')
      );
      setIsPasswordsValid(false);
    } else setIsPasswordsValid(true);
  };
  const validatePhoneNumber = () => {
    const regex = /^\+[1-9]\d{1,14}$/;

    if (!regex.test(phone)) {
      notifyError(getLocalString(translations, 'UserAddUpdateWorker.NotifyError.Phone'));
    }
  };

  return (
    <PageContainer
      pageName={
        id
          ? getLocalString(translations, 'UserAddUpdateWorker.EditWorker')
          : getLocalString(translations, 'UserAddUpdateWorker.CreateWorker')
      }
      backUrl={'/users'}
    >
      <Row style={{ width: '100%' }}>
        <Col offset={6} span={12}>
          <Row gutter={[16, 16]}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'UserAddUpdateWorker.Name')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'UserAddUpdateWorker.Placeholder.Name')}
                value={name || undefined}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>

            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'UserAddUpdateWorker.Surname')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'UserAddUpdateWorker.Placeholder.Surname'
                )}
                value={surname || undefined}
                onChange={(e) => {
                  setSurname(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'UserAddUpdateWorker.Phone')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'UserAddUpdateWorker.Placeholder.Phone')}
                value={phone || undefined}
                onChange={(e) => {
                  setPhone(e.currentTarget.value);
                }}
                onKeyDown={(e) => {
                  if (!isNumeric(e.key) && e.key !== 'Backspace' && e.key !== '+')
                    e.preventDefault();
                }}
                onBlur={validatePhoneNumber}
                size='large'
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'UserAddUpdateWorker.Email')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'UserAddUpdateWorker.Placeholder.Email')}
                value={email || undefined}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
                onBlur={handleEmailBlur}
                size='large'
              ></Input>
            </Row>

            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'UserAddUpdateWorker.Password')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'UserAddUpdateWorker.Placeholder.Password'
                )}
                size='large'
                value={password}
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                  setIsCreateDisabled(true);
                  setConfirmPassword('');
                }}
                //   onBlur={validatePasswords}
              />
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'UserAddUpdateWorker.ConfirmPassword')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'UserAddUpdateWorker.Placeholder.Password'
                )}
                size='large'
                value={confirmPassword}
                onChange={(e) => {
                  setIsCreateDisabled(true);
                  setConfirmPassword(e.currentTarget.value);
                  setIsPasswordsValid(false);
                }}
                onBlur={validatePasswords}
              />
            </Row>
          </Row>

          {id ? (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                style={!isCreate ? { display: 'none' } : {}}
                onClick={() => {
                  id &&
                    dispatch(
                      workerUpdateByAgency({
                        workerId: id,
                        name: name,
                        surname: surname,
                        phone: phone,
                        email: email,
                        password: password,
                        password_confirmation: confirmPassword,
                        onSuccess: (data: any) => {
                          notifySuccess(
                            getLocalString(
                              translations,
                              'UserAddUpdateWorker.NotifySuccess.WorkerUpdated'
                            )
                          );
                          navigate('/users');
                        },
                      })
                    );
                }}
                size='large'
                type='primary'
                disabled={isEditDisabled}
              >
                {getLocalString(translations, 'UserAddUpdateWorker.EditWorker')}
              </Button>
            </Row>
          ) : (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                style={!isCreate ? { display: 'none' } : {}}
                onClick={() => {
                  dispatch(
                    workerCreateByAgency({
                      name: name,
                      surname: surname,
                      phone: phone,
                      email: email,
                      password: password,
                      password_confirmation: confirmPassword,
                      onSuccess: (data: any) => {
                        notifySuccess(
                          getLocalString(
                            translations,
                            'UserAddUpdateWorker.NotifySuccess.WorkerCreated'
                          )
                        );
                        navigate('/users');
                      },
                    })
                  );
                }}
                size='large'
                type='primary'
                disabled={isCreateDisabled}
              >
                {getLocalString(translations, 'UserAddUpdateWorker.CreateWorker')}
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </PageContainer>
  );
};

export default UserAddUpdateWorker;
