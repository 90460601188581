import React, { useEffect, useState } from 'react';

import { Button, Menu, Popover, Tooltip } from 'antd';

import styles from './uniteamTopBar.module.scss';
import { IProps } from './uniteamTopBar-type';
import { useAppSelector } from '../../store/hooks';
import { menuSelector } from '../../store/menu';

const UniteamTopBar: React.FC<IProps> = ({ menuItems }) => {
  const { id, activeSubMenu } = useAppSelector(menuSelector);

  // const [screenSize, setScreenSize] = useState(getCurrentDimension());

  // function getCurrentDimension() {
  //   return {
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   };
  // }

  // useEffect(() => {
  //   const updateDimension = () => {
  //     setScreenSize(getCurrentDimension());
  //   };
  //   window.addEventListener('resize', updateDimension);

  //   return () => {
  //     window.removeEventListener('resize', updateDimension);
  //   };
  // }, [screenSize]);

  return (
    <Menu
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      mode='horizontal'
      selectedKeys={[id.toString()]}
      items={menuItems}
    />
  );
};

export default UniteamTopBar;
