import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import { notifyError, notifySuccess } from '../../helpers/notify';
import { axiosClient, apiURL } from '../../service';
import { IActionCallback } from '../types';
import qs from 'qs';
import { ActionTypes } from './types';
import { setSpinner } from '../spinner';

// export const setLoading = createAction(ActionTypes.SET_LOADING);

interface ILoginInput extends IActionCallback {
  email: string;
  password: string;
}
export const login = createAsyncThunk(ActionTypes.LOGIN, async (data: ILoginInput) => {
  const { email, password, onSuccess, onError } = data;

  const formData = new FormData();

  formData.append('email', email);
  formData.append('password', password);

  try {
    const response = await axiosClient.post(apiURL.login, formData);

    // API'den gelen yanıtın doğruluğunu kontrol et
    if (response.data && response.data.access_token) {
      localStorage.setItem('access_token', response.data.access_token);

      if (onSuccess) onSuccess(response.data);
      return response.data;
    } else {
      // Beklenmeyen bir yanıt durumu
      return notifyError('Unexpected response format');
    }
  } catch (error) {
    if (onError) onError(error);
    return notifyError(error);
  }
});

interface ILoginForgotPassportInput extends IActionCallback {
  email: string;
}

export const forgotPassword = createAsyncThunk(
  ActionTypes.FORGOT_PASSWORD,
  async (data: ILoginForgotPassportInput, { dispatch }) => {
    const { email, onSuccess } = data;
    try {
      const response = await axiosClient.post(apiURL.forgotPassword + '?email=' + email);

      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IResetPassportInput extends IActionCallback {
  token: string;
  password: string;
  password_confirmation: string;
}

export const resetPassword = createAsyncThunk(
  ActionTypes.RESET_PASSWORD,
  async (data: IResetPassportInput, { dispatch }) => {
    const { token, password, password_confirmation, onSuccess } = data;
    try {
      const response = await axiosClient.post(apiURL.resetPassword + '/' + token, {
        password,
        password_confirmation,
      });

      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface ISetUserLanguage extends IActionCallback {
  lang: string;
}

export const setUserLanguage = createAsyncThunk(
  ActionTypes.SET_USER_LANGUAGE,
  async (data: ISetUserLanguage, { dispatch }) => {
    const { lang, onSuccess } = data;
    try {
      const response = await axiosClient.put(apiURL.setUserLanguage, { locale: lang });

      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const checkUser = createAsyncThunk(
  ActionTypes.CHECK_USER_INFO,
  async (undefined, { dispatch }) => {
    try {
      const response = await axiosClient.get(apiURL.getUserProfile);
      return response.data;
    } catch (error: any) {
      window.location.href = '/';
    }
  }
);

export const getUserProfile = createAsyncThunk(
  ActionTypes.GET_USER_INFO,
  async (undefined, { dispatch }) => {
    try {
      const response = await axiosClient.get(apiURL.getUserProfile);

      return response.data;
    } catch (error: any) {
      notifyError(error.message);
    }
  }
);
interface IChangePasswordInput extends IActionCallback {
  password: string;
  new_password: string;
  new_password_confirmation: string;
}
export const changePassword = createAsyncThunk(
  ActionTypes.LOGIN,
  async (data: IChangePasswordInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { new_password, new_password_confirmation, password, onSuccess, onError } = data;

    const formData = new FormData();

    formData.append('password', password);
    formData.append('new_password', new_password);
    formData.append('new_password_confirmation', new_password_confirmation);

    try {
      const response = await axiosClient.post(apiURL.changePassword, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

export const logout = createAsyncThunk(ActionTypes.LOGOUT, async (undefined, { dispatch }) => {
  try {
    const response = await axiosClient.post(apiURL.logout);
    dispatch(setSpinner(false));
    sessionStorage.removeItem('access_token');
    return response;
  } catch (error: any) {
    dispatch(setSpinner(false));

    if (error && error.errors) {
      const errors: Record<string, string[]> = error.errors;
      Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
        messages.forEach((message: string) => {
          // notifyError(`${field}: ${message}`);
          notifyError(`${message}`);
        });
      });
    } else if (error && error.message) {
      // Genel bir hata mesajı
      notifyError(error.message);
    } else {
      // Genel bir hata mesajı
      notifyError('An error occurred while processing your request.');
    }
  }
});
