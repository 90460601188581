import { createReducer } from '@reduxjs/toolkit';
import { DEFAULT_LANGUAGE, languages } from '../../resources/constants/language';
import { changeAppLanguage, getAppLanguage } from './actions';

interface LanguageState {
  appLanguage: string;
  translations: any;
}

const initialState: LanguageState = {
  appLanguage: DEFAULT_LANGUAGE.code,
  translations: { [DEFAULT_LANGUAGE.code]: {} },
};

export const languageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeAppLanguage.fulfilled, (state, action) => {
      state.appLanguage = action.payload.language;
      state.translations = action.payload.langugeData;
      window.location.reload();
    })
    .addCase(getAppLanguage.fulfilled, (state, action) => {
      state.appLanguage = action.payload.language;
      state.translations = action.payload.langugeData;
    });
});
