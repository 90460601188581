import React, { useEffect, useState } from 'react';
import { LayoutProps } from './mainLayout-types';
import styles from './mainLayout.module.scss';
import Footer from '../../components/uniteamFooter';
import UniteamSideBar from '../../components/uniteamSideBar';

import { useAppDispatch, useAppSelector } from '../../store/hooks';

import Icon, {
  ArrowLeftOutlined,
  DashboardOutlined,
  AccountBookOutlined,
  AppstoreOutlined,
  ContactsOutlined,
  PercentageOutlined,
  UsergroupAddOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  ShareAltOutlined,
  InsertRowAboveOutlined,
  SnippetsOutlined,
  UserOutlined,
  SwapOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { HeadLine } from '../../components/HeadLine';
import UniteamHeader from '../../components/uniteamHeader';
import UniteamHeaderCultureCurrencyDropdown from '../../components/uniteamHeaderCultureCurrencyDropdown';
import UniteamHeaderNotificationDropdown from '../../components/uniteamHeaderNotificationDropdown';
import UniteamDrawer from '../../components/uniteamDrawer';
import { menuSelector, setActiveMenu } from '../../store/menu';
import UniteamHeaderProfileDropdown from '../../components/uniteamHeaderProfileDropdown';
import { accountSelector, checkUser, logout, setUserLanguage } from '../../store/account';

import UniteamHeaderSearch from '../../components/uniteamHeaderSearch';

import UniteamHeaderCultureDropdown from '../../components/uniteamHeaderCultureDropdown';
import { getMenuIcon } from '../../helpers/menu';
import { useNavigate } from 'react-router-dom';
import { changeAppLanguage } from '../../store/language';
import { adminMenuList } from '../../helpers/menuList';
import { getNotifications } from '../../store/constants';
import { languages } from '../../resources/constants/language';
import { setSpinner } from '../../store/spinner';
import { apiURL, axiosClient } from '../../service';
import { notifyError } from '../../helpers/notify';

const MainLayout: React.FC<LayoutProps> = ({
  children,
  style,
  title = '',
  actions = null,
  backBtn = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { menuList, headerMenuList, drawerMenuList } = useAppSelector(menuSelector);

  const { account } = useAppSelector(accountSelector);
  // const { notifications, pending } = useAppSelector(notificationSelector);

  const [collapsed, setCollapsed] = useState(localStorage.getItem('expandMenuStatus'));

  const logoutHandler = async () => {
    try {
      // Perform the logout operation
      const response = await axiosClient.post(apiURL.logout);

      // Check if the response is successful (optional if you rely on Axios' default behavior)
      if (response.status === 200) {
        // Stop the spinner
        dispatch(setSpinner(false));

        // Remove the access token from storage
        localStorage.removeItem('access_token');

        // Redirect to the home page
        window.location.href = '/';
      }
    } catch (error: any) {
      // Stop the spinner in case of an error
      dispatch(setSpinner(false));

      // Handle different error scenarios
      if (error.response && error.response.data.errors) {
        const errors: Record<string, string[]> = error.response.data.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            notifyError(message); // Notify about each error message
          });
        });
      } else if (error.response && error.response.data.message) {
        // Notify about a general error message
        notifyError(error.response.data.message);
      } else {
        // Notify about an unknown error
        notifyError('An error occurred while processing your request.');
      }
    }
  };

  // const changeActiveCompany = (profileId: string) => {
  //   // dispatch(
  //   //   changeCompany({
  //   //     companyId: companies.find((el) => el.profileId === profileId)?.companyId || '',
  //   //     onSuccess: () => {
  //   //       window.location.reload();
  //   //     },
  //   //   })
  //   // );
  // };

  const cultureHandler = (culture: string, target: any) => {
    dispatch(
      setUserLanguage({
        lang: culture,
        onSuccess: () => {
          dispatch(changeAppLanguage(culture));
        },
      })
    );

    // dispatch(checkUser());
  };
  // const cultureCurrencyHandler = (currency: any, culture: any, target: any) => {
  //   profileInfo.cultureId !== culture
  //     ? dispatch(setCultureInfo(culture)).then(
  //         () => profileInfo.currencyId !== currency && dispatch(setCurrencyInfo(currency))
  //       )
  //     : profileInfo.currencyId !== currency && dispatch(setCurrencyInfo(currency));
  // };

  useEffect(() => {
    setCollapsed(localStorage.getItem('expandMenuStatus'));
  }, [localStorage.getItem('expandMenuStatus')]);

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const menuListWithIcon = menuList.map((el) => ({
    ...el,
    iconObject: getMenuIcon(el.icon),
  }));

  const prepareMenulist = (menus: any) => {
    const preparedMenu = menus.map((item: any, i: number) => ({
      key: item.id,
      label: item.name,
      children: item.dropdown
        ? item.menuItems.map((subItem: any, y: number) => ({
            key: subItem.id,
            label: subItem.name,

            onClick: () => {
              console.log('alt menü');
              dispatch(
                setActiveMenu({
                  id: item.id,
                  mainMenu: item.name,
                  subMenu: subItem.name,
                  is_create: subItem.is_create,
                  is_delete: subItem.is_delete,
                  is_update: subItem.is_update,
                  is_view: subItem.is_view,
                })
              );
              navigate(subItem.url);
            },
          }))
        : undefined,
      icon: <Icon component={item.iconObject as any} className={styles.icon} />,
      onClick: () => {
        if (!item.dropdown) {
          console.log('ana menü', item.url);
          console.log('ana menü', item);

          navigate(item.url);
          dispatch(
            setActiveMenu({
              id: item.id,
              mainMenu: item.name,
              subMenu: item.name,
              is_create: item.is_create,
              is_delete: item.is_delete,
              is_update: item.is_update,
              is_view: item.is_view,
            })
          );
        }
      },
    }));

    return preparedMenu;
  };

  useEffect(() => {
    for (const menu of menuList) {
      // console.log(window.location.pathname, menu.url, menu.dropdown);
      if (!menu.dropdown && window.location.pathname === menu.url) {
        dispatch(
          setActiveMenu({
            id: menu.id,
            mainMenu: menu.name,
            subMenu: menu.name,
            is_create: menu.is_create,
            is_delete: menu.is_delete,
            is_update: menu.is_update,
            is_view: menu.is_view,
          })
        );
      } else if (!menu.dropdown) {
        menu.menuGroups?.forEach((el: any) => {
          if (window.location.pathname.search(el.url) > -1) {
            dispatch(
              setActiveMenu({
                id: menu.id,
                mainMenu: menu.name,
                subMenu: menu.name,
                is_create: menu.is_create,
                is_delete: menu.is_delete,
                is_update: menu.is_update,
                is_view: menu.is_view,
              })
            );
          }
        });
      } else {
        for (const subMenu of menu.menuItems) {
          if (window.location.pathname === subMenu.url) {
            dispatch(
              setActiveMenu({
                id: subMenu.id,
                mainMenu: menu.name,
                subMenu: subMenu.name,
                is_create: subMenu.is_create,
                is_delete: subMenu.is_delete,
                is_update: subMenu.is_update,
                is_view: subMenu.is_view,
              })
            );
          } else {
            subMenu.menuGroups?.find((el: any) => {
              console.log(window.location.pathname, el.url);
              if (window.location.pathname.search(el.url) > -1) {
                console.log('subMenu.id,!!!!!!!!!!!', subMenu.id, el.url);
                dispatch(
                  setActiveMenu({
                    id: subMenu.id,
                    mainMenu: menu.name,
                    subMenu: subMenu.name,
                    is_create: subMenu.is_create,
                    is_delete: subMenu.is_delete,
                    is_update: subMenu.is_update,
                    is_view: subMenu.is_view,
                  })
                );
              }
            });
          }
        }
      }
    }
  }, [menuList, headerMenuList, window.location.pathname]);

  return (
    <div className={`${styles.layout} ${styles.layout__has_sidebar}`}>
      <UniteamHeader
        app='business'
        // activeCompany={}
        projectSelector
        activeProject='warehouse'
        // projectItems={[
        //   {
        //     label: 'Inventory',
        //     key: 'inventory',
        //     icon: '/projects-logo/inventory.svg',
        //     url: process.env.REACT_APP_INVENTORY_UI_URL || '#',
        //   },
        //   {
        //     label: 'Order',
        //     key: 'order',
        //     icon: '/projects-logo/order.svg',
        //     url: process.env.REACT_APP_ORDER_UI_URL || '#',
        //   },
        //   {
        //     label: 'Warehouse',
        //     key: 'warehouse',
        //     icon: '/projects-logo/warehouse.svg',
        //     url: '#',
        //   },
        //   {
        //     label: 'Transportation',
        //     key: 'transportation',
        //     icon: '/projects-logo/transportation.svg',
        //     url: process.env.REACT_APP_UI_URL || '#',
        //   },
        //   {
        //     label: 'Finance',
        //     key: 'finance',
        //     icon: '/projects-logo/finance.svg',
        //     url: '#',
        //   },
        // ]}
        rightPanelRender={
          <>
            <UniteamHeaderCultureDropdown
              profileInfo={account}
              cultureList={languages}
              onChange={cultureHandler}
            />
            <UniteamHeaderNotificationDropdown />

            {/* <Drawer menu={drawerMenuList} />
            
            <HeaderProfileDropdown
              profileInfo={profileInfo}
              changeCompanyProfile={changeActiveCompany}
              profiles={companies}
              logoutHandler={logoutHandler}
            /> */}
            <UniteamHeaderProfileDropdown profileInfo={account} logoutHandler={logoutHandler} />
          </>
        }
      />
      <div className={`${styles.layout__body}`}>
        <UniteamSideBar menuItems={prepareMenulist(menuListWithIcon)} />
        <main className={styles.layout__container} style={style}>
          {actions !== null ? (
            <div className={styles.layout__header}>
              <HeadLine level={6}>
                {backBtn && (
                  <ArrowLeftOutlined
                    // onClick={() => router.back()}
                    style={{ marginRight: 16, fontSize: 18 }}
                  />
                )}
                {title}
              </HeadLine>
              <div className={styles.layout__header_actions}>{actions}</div>
            </div>
          ) : (
            title !== '' && (
              <HeadLine level={6} mb={'32px'}>
                {backBtn && (
                  <ArrowLeftOutlined
                    // onClick={() => router.back()}
                    style={{ marginRight: 16, fontSize: 18 }}
                  />
                )}
                {title}
              </HeadLine>
            )
          )}
          {children}
        </main>
      </div>
      <Footer
        menuItems={
          [
            // {
            //   label: 'Privacy',
            //   href: '/privacy',
            // },
            // {
            //   label: 'FAQ',
            //   href: '/faq',
            // },
            // {
            //   label: 'Help',
            //   href: '/help',
            // },
          ]
        }
        copyright
      />
    </div>
  );
};

export default MainLayout;
