import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Input, Upload, Card, Divider, List } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';
import globalStyles from '../../../styles/global.module.scss';

import PageContainer from '../../../components/pageContainer';
import CustomizedTable from '../../../components/CustomizedTable';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import { constantsSelector, getUniversityDepartments } from '../../../store/constants';
import { apiURL } from '../../../service';

import { Modal } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useNavigate, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../helpers/notify';
import { deleteFile, uploadFile } from '../../../store/student';
import {
  createAnnouncement,
  createUniversity,
  getAnnouncements,
  getUniversityDetail,
  updateUniversity,
} from '../../../store/university';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { title } from 'process';
import { IDepartmentTypes } from '../../../types/constants';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Option } = Select;

const EditUniversity: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { turkeyCities } = useAppSelector(constantsSelector);

  const [universityName, setUniversityName] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [cityId, setCityId] = useState<string>('');

  const [logoPicture, setLogoPicture] = useState<any[]>([]);
  const [catalogPicture, setCatalogPicture] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState('');
  const [universityInfo, setUniversityInfo] = useState<any>();
  const [announcements, setAnnouncements] = useState<any>('');
  const [announcementTitle, setAnnouncementTitle] = useState<string>('');
  const [announcementDescription, setAnnouncementDescription] = useState<string>('');
  const [announcementQuillDescription, setAnnouncementQuillDescription] = useState<string>('');

  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true);
  const [departmentList, setDepartmentList] = useState<IDepartmentTypes[]>([]);

  const quillModules = {
    toolbar: [
      [{ font: [] }], // font family
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // header levels
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['link'],
      ['clean'], // remove formatting
    ],
  };
  useEffect(() => {
    if (id) {
      setUniversityDetail(id);
      dispatch(
        getAnnouncements({
          id,
          onSuccess: (data: any) => {
            setAnnouncements(data.data.data);
          },
        })
      );
      // department-search-list  api ile çağrılıyor
      dispatch(
        getUniversityDepartments({
          universityID: id,
          onSuccess: (data: any) => {
            setDepartmentList(data.data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
    }
  }, [id]);

  useEffect(() => {
    if (universityName !== '' && cityId !== '')
      if (
        content !== universityInfo.content ||
        universityName !== universityInfo.name ||
        cityId !== universityInfo?.city?.country_id
      )
        setIsEditDisabled(false);
      else setIsEditDisabled(true);
    else setIsEditDisabled(true);
  }, [universityName, content, cityId]);

  const handlePreview = (file: any) => {
    //Modal içi gösterim için silmeyin
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64(file.originFileObj);
    // }
    // setPreviewImage(file.url || file.preview);
    // setPreviewVisible(true);
    //Modal içi gösterim için silmeyin
    window.open(file.url, '_blank');
  };

  const announcementsList = useCallback(
    () => (
      <List
        style={{ width: '100%' }}
        itemLayout='horizontal'
        grid={{ gutter: 100, column: 1 }}
        dataSource={announcements || []}
        renderItem={(item: any, index) => (
          <List.Item style={{ width: '100%' }}>
            <Card
              bodyStyle={{ width: '80%' }}
              title={`${item.title}  ${getLocalString(
                translations,
                'EditUniversity.CreatedAt'
              )}  ${dayjs(item.created_at).format('YYYY-MM-DD HH:mm')}`}
            >
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </Card>
          </List.Item>
        )}
      />
    ),
    [announcements]
  );

  const fileSave = (file: any, fileName: string) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

    if (!allowedTypes.includes(file.type)) {
      notifyError(getLocalString(translations, 'EditUniversity.NotifyError.FileType'));
      return false;
    }
    if (file.size > 4194304) {
      notifyError(getLocalString(translations, 'EditUniversity.NotifyError.FileSize'));

      return false;
    }
    id &&
      dispatch(
        uploadFile({
          model_id: id,
          model: 'university',
          file_key: fileName,
          file: file,
          onSuccess: (data: any) => {
            let document: any = {
              uid: data.data.id.toString(),
              name: data.data.name,
              status: 'done',
              url: data.data.path,
              size: data.data.size,
            };

            switch (data.data.key) {
              case 'logo':
                setLogoPicture([document]);
                break;
              case 'catalog':
                setCatalogPicture([document]);
                break;

              default:
                break;
            }
            notifySuccess(
              getLocalString(translations, 'EditUniversity.NotifySuccess.FileUploaded')
            );
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  };

  const setUniversityDetail = (id: string) => {
    dispatch(
      getUniversityDetail({
        id: id,
        onSuccess: (data: any) => {
          setUniversityInfo(data.data);
          setUniversityName(data.data.name);
          setContent(data.data.content);
          setCityId(data.data.city.name);
          data.data.files.forEach((document: any) => {
            let picture: UploadFile;
            switch (document.key) {
              case 'logo':
                picture = {
                  uid: document.id.toString(),
                  name: document.name,
                  status: 'done',
                  url: document.path,
                  size: document.size,
                };
                setLogoPicture([picture]);
                break;
              case 'catalog':
                picture = {
                  uid: document.id.toString(),
                  name: document.name,
                  status: 'done',
                  url: document.path,
                  size: document.size,
                };
                setCatalogPicture([picture]);
                break;

              default:
                break;
            }
          });
        },
      })
    );
  };

  const renderTurkeyCitiesOptions = useCallback(
    () =>
      turkeyCities.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name}
        </Option>
      )),
    [turkeyCities]
  );

  const columns = [
    {
      title: getLocalString(translations, 'EditUniversity.Name'),
      key: 'name',
      dataIndex: 'name',
    },

    {
      title: getLocalString(translations, 'EditUniversity.Language'),

      dataIndex: 'language',
      key: 'language',
      render: (e: any, record: any) => {
        return record.language?.name;
      },
      width: 100,
    },
  ];
  return (
    <PageContainer pageName='Edit University' backUrl={'/universities-list'}>
      <Row style={{ width: '100%' }} gutter={[24, 24]}>
        <Col span={12}>
          <Card style={{ height: '600px' }}>
            <Row style={{ width: '100%' }} gutter={[16, 16]}>
              <Row className={`${globalStyles.title_r_medium} `}>
                {getLocalString(translations, 'EditUniversity.UniversityDetail')}
              </Row>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'EditUniversity.UniversityName')}
                </Row>
                <Input
                  maxLength={35}
                  style={{ width: '100%' }}
                  value={universityName || undefined}
                  placeholder={getLocalString(
                    translations,
                    'EditUniversity.Placeholder.TypeUniversityName'
                  )}
                  onChange={(e) => {
                    setUniversityName(e.currentTarget.value);
                  }}
                  size='large'
                ></Input>
              </Row>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'EditUniversity.Content')}
                </Row>
                <TextArea
                  rows={4}
                  maxLength={500}
                  style={{ width: '100%' }}
                  value={content || undefined}
                  placeholder={getLocalString(
                    translations,
                    'EditUniversity.Placeholder.TypeContent'
                  )}
                  onChange={(e) => {
                    setContent(e.currentTarget.value);
                  }}
                  size='large'
                ></TextArea>
              </Row>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'EditUniversity.City')}
                </Row>
                <Select
                  style={{ width: '100%' }}
                  value={cityId || undefined}
                  placeholder={getLocalString(
                    translations,
                    'EditUniversity.Placeholder.SelectCity'
                  )}
                  onChange={(value) => {
                    setCityId(value);
                  }}
                  allowClear
                  showSearch
                  size='large'
                  filterOption={filterOption}
                >
                  {renderTurkeyCitiesOptions()}
                </Select>
              </Row>
              <Row style={{ width: '100%' }} justify={'start'}>
                <Button
                  style={!isCreate ? { display: 'none' } : {}}
                  disabled={isEditDisabled}
                  onClick={() => {
                    id &&
                      dispatch(
                        updateUniversity({
                          id: id,
                          name: universityName,
                          content: content,
                          city_id: cityId,
                          onSuccess: (data: any) => {
                            notifySuccess(
                              getLocalString(
                                translations,
                                'EditUniversity.NotifySuccess.EditSuccess'
                              )
                            );
                            setUniversityDetail(id);
                            setIsEditDisabled(true);
                          },
                        })
                      );
                  }}
                  type='primary'
                  size='large'
                >
                  {getLocalString(translations, 'EditUniversity.Edit')}
                </Button>
              </Row>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Col span={4}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'EditUniversity.Logo')}
                    </Row>
                    <Upload
                      accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                      listType='picture-card'
                      fileList={logoPicture}
                      onPreview={(e) => handlePreview(e)}
                      disabled={!isCreate}
                      beforeUpload={(file: any) => {
                        fileSave(file, 'logo');

                        return false;
                      }}
                      onRemove={() => {
                        dispatch(
                          deleteFile({
                            id: logoPicture[0].uid,
                            onSuccess: (data: any) => {
                              setLogoPicture([]);
                              notifySuccess(
                                getLocalString(
                                  translations,
                                  'EditUniversity.NotifySuccess.FileDeletedSuccessfully'
                                )
                              );
                            },
                            onError: (err) => notifyError(err),
                          })
                        );
                      }}
                    >
                      {logoPicture.length >= 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>
                            {getLocalString(translations, 'EditUniversity.Upload')}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'EditUniversity.Catalog')}
                    </Row>
                    <Upload
                      accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                      listType='picture-card'
                      fileList={catalogPicture}
                      onPreview={(e) => handlePreview(e)}
                      disabled={!isCreate}
                      beforeUpload={(file: any) => {
                        fileSave(file, 'catalog');

                        return false;
                      }}
                      onRemove={() => {
                        dispatch(
                          deleteFile({
                            id: catalogPicture[0].uid,
                            onSuccess: (data: any) => {
                              setCatalogPicture([]);
                              notifySuccess(
                                getLocalString(
                                  translations,
                                  'EditUniversity.NotifySuccess.FileDeletedSuccessfully'
                                )
                              );
                            },
                            onError: (err) => notifyError(err),
                          })
                        );
                      }}
                    >
                      {catalogPicture?.length >= 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>
                            {getLocalString(translations, 'EditUniversity.Upload')}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Row>
                </Col>
              </Row>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card style={{ height: '600px' }}>
            <Row className={`${globalStyles.title_r_medium} `}>
              {getLocalString(translations, 'EditUniversity.Departments')}
            </Row>
            <Divider></Divider>
            <Row style={{ width: '100%', height: '300px', marginTop: '24px' }}>
              {id && (
                <CustomizedTable
                  scrollY={400}
                  data={departmentList || []}
                  columns={columns}
                  disablePagination
                  bordered={false}
                  actions={[
                    {
                      action: (record) => {
                        navigate('/edit-department/' + record.id);
                      },
                      type: 'edit',

                      hidden: (record: any) => !isCreate ?? true,
                    },
                  ]}
                />
              )}
            </Row>
          </Card>
        </Col>
      </Row>
      <Row style={{ width: '100%', marginTop: '24px' }} gutter={[0, 24]}>
        <Card style={{ width: '100%' }}>
          <Row className={`${globalStyles.title_r_medium} `}>
            {getLocalString(translations, 'EditUniversity.Announcements')}
          </Row>
          <Divider></Divider>
          <div style={{ maxHeight: 'calc(460px - 120px)', overflowY: 'auto' }}>
            {announcementsList()}
          </div>
        </Card>

        {isCreate && (
          <Card style={{ width: '100%' }}>
            <Row className={`${globalStyles.title_r_medium} `}>
              {getLocalString(translations, 'EditUniversity.AddAnnouncement')}
            </Row>
            <Row style={{ width: '100%', marginTop: '24px' }}>
              <Row
                className={`${globalStyles.label_r_large} ${styles.label_color}`}
                style={{ marginBottom: '8px' }}
              >
                {getLocalString(translations, 'EditUniversity.Title')}
              </Row>
              <Input
                maxLength={35}
                value={announcementTitle || undefined}
                onChange={(e) => setAnnouncementTitle(e.currentTarget.value)}
                style={{ width: '100%' }}
                size='large'
              ></Input>
            </Row>
            <Row style={{ width: '100%' }}>
              <Row
                className={`${globalStyles.label_r_large} ${styles.label_color}`}
                style={{ marginBottom: '8px', marginTop: '24px' }}
              >
                {getLocalString(translations, 'EditUniversity.Description')}
              </Row>
              <Row style={{ width: '100%' }}>
                <ReactQuill
                  modules={quillModules}
                  style={{ width: '100%', borderRadius: '5px' }}
                  theme='snow'
                  value={announcementDescription}
                  onChange={setAnnouncementDescription}
                />
              </Row>
            </Row>
            <Row style={{ width: '100%', marginTop: '56px' }} justify={'end'}>
              <Button
                style={!isCreate ? { display: 'none' } : {}}
                type='primary'
                disabled={!announcementDescription || !announcementTitle}
                size='large'
                onClick={async () => {
                  if (id) {
                    await dispatch(
                      createAnnouncement({
                        title: announcementTitle,
                        description: announcementDescription,
                        id: id,
                        onSuccess: (data: any) => {
                          setAnnouncementDescription('');
                          setAnnouncementTitle('');
                        },
                      })
                    );
                    dispatch(
                      getAnnouncements({
                        id,
                        onSuccess: (data: any) => {
                          setAnnouncements(data.data.results);
                        },
                      })
                    );
                  }
                }}
              >
                {getLocalString(translations, 'EditUniversity.Add')}
              </Button>
            </Row>
          </Card>
        )}
      </Row>
      <Modal open={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </PageContainer>
  );
};

export default EditUniversity;
