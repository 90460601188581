import React, { useEffect, useState } from 'react';

import styles from './index.module.scss';
import globalStyles from '../../styles/global.module.scss';
import PageContainer from '../../components/pageContainer';
import { Button, Col, Divider, Input, Row, Tag } from 'antd';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import { getTrackingCodeDetail } from '../../store/application';

import { apiURL } from '../../service';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { notifyError } from '../../helpers/notify';
import { getLocalString } from '../../helpers/language';

import { updateNotificationRead } from '../../store/notification';
import { getNotifications } from '../../store/constants';
import { INotificationResults } from '../../types/constants';
import { menuSelector } from '../../store/menu';
import CustomizedTable from '../../components/CustomizedTable';
import { languageSelector } from '../../store/language';

const Notification: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const [trackingCode, setTrackingCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);

  const [filters, setFilters] = useState<object>();
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    let filter: any = {};
    let filterCount = 0;

    if (searchText) {
      filter.searchText = searchText;
    }
    setFilterCount(filterCount);
    setFilters(filter);
  }, [searchText]);

  const tagColors = [
    'geekblue',
    'magenta',
    'red',
    'blue',
    'volcano',
    'orange',
    'green',
    'lime',
    'cyan',
    'purple',
  ];

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: getLocalString(translations, 'Notifications.Title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: getLocalString(translations, 'Notifications.Message'),
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: getLocalString(translations, 'Notifications.Date'),

      dataIndex: 'date',
      key: 'date',
      render: (record: any) => dayjs(record.date).format('YYYY-MM-DD'),
    },
  ];

  return (
    <PageContainer pageName={getLocalString(translations, 'Notifications.Notifications')}>
      <Row gutter={8}>
        <Col span={18} offset={3}>
          <CustomizedTable
            columns={columns}
            data={[]}
            reload={reload}
            setReload={setReload}
            url={apiURL.getNotification}
            filter={filters}
            actions={[
              {
                action: (record) => {
                  dispatch(
                    updateNotificationRead({
                      notifyIds: [record.id],
                      onError: (err) => {
                        notifyError(err);
                      },
                      onSuccess: (data: any) => {
                        getNotifications();
                        setReload(true);
                      },
                    })
                  );
                },
                type: 'read',
                hidden: (record: INotificationResults) => record.is_read === true,
              },
              {
                action: (record: INotificationResults) => {
                  switch (record.extra.model) {
                    case 1:
                      navigate('/applies-detail/' + record.extra.model_id);
                      break;
                    case 2:
                      navigate('/update-student/' + record.extra.model_id);
                      break;
                    case 3:
                      navigate('/update-interview/' + record.extra.model_id);
                      break;
                    default:
                      break;
                  }
                },
                type: 'detail',
              },
            ]}
          />
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Notification;
