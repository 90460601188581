import { useContext, useState } from 'react';
import { useEffect } from 'react';
import styles from './login.module.scss';
import globalStyles from '../../styles/global.module.scss';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { AuthService } from '../../service/AuthServiceV2';
import { Alert, Button, Col, Form, Input, Result, Row, Select, Space, Upload } from 'antd';
import { ILoginInputs } from '../../types/login';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { accountSelector, login } from '../../store/account';
import { notifyError } from '../../helpers/notify';

import { Header } from 'antd/es/layout/layout';
import uniteamLogo from '../../resources/images/uniteamLogo.svg';
import { languageSelector } from '../../store/language';
import { getLocalString } from '../../helpers/language';
import { createInterview, createInterviewWithoutAuth } from '../../store/interview';
import { constantsSelector, getCountryList } from '../../store/constants';
import filterOption from '../../helpers/filterOption';

const CreateADVMeet: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { countryList } = useAppSelector(constantsSelector);
  const [form] = Form.useForm();
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [phonePrefix, setPhonePrefix] = useState<string>('+90');

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  const location = useLocation();

  // URLSearchParams ile sorgu parametrelerini ayrıştır
  const queryParams = new URLSearchParams(location.search);
  const advValue = queryParams.get('adv');
  // 'adv' parametresinin değerini alır

  useEffect(() => {
    dispatch(getCountryList());
    console.log("const advValue = queryParams.get('adv');", advValue);
    const handleResize = () => {
      // User agent ile mobil cihaz kontrolü yapılıyor.
      const isMobileView = isMobileDevice() || window.innerWidth < 768;
      setIsMobile(isMobileView);
    };

    // İlk render'da ve her resize olayında çalışacak
    handleResize();
    window.addEventListener('resize', handleResize);

    // Komponent unmount olduğunda temizlik işlemi yapılıyor.
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onFinish = async (values: any) => {
    console.log("const advValue = queryParams.get('adv');", advValue);
    advValue &&
      dispatch(
        createInterviewWithoutAuth({
          name: values.name,
          surname: values.surname,
          phone: values.phone,
          phone_code: phonePrefix,
          email: values.email,
          type: 2,
          adv: advValue,
          onSuccess: (data: any) => {
            setIsCreated(true);
          },
        })
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    notifyError(getLocalString(translations, 'Apply.NotifyError.PleaseCheck'));
  };

  return (
    <div
      style={{
        background: '#FAF5FF',
        minHeight: '120vh',
      }}
    >
      <Row>
        <img style={{ margin: '55px 78px' }} src={uniteamLogo} alt='' />
      </Row>
      <div
        style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', height: '80vh' }}
      >
        <Row>
          <Col xs={2} sm={4} md={6} lg={6} xl={6}></Col>
          <Col xs={20} sm={16} md={12} lg={12} xl={12}>
            <Row
              style={{
                borderRadius: '24px',
                background: '#FAF5FF',
                width: '100%',
                padding: '48px',
                boxShadow: '0px 16px 72px 0px rgba(6, 28, 61, 0.08)',
                transform: 'translate(0%, -5%)',
              }}
            >
              {!isCreated ? (
                <>
                  {' '}
                  <Row style={{ width: '100%' }}>
                    <Row
                      style={{ color: '#555', width: '100%', marginBottom: '16px' }}
                      justify={'center'}
                      className={`${globalStyles.headline_m_medium} `}
                    >
                      {getLocalString(translations, 'Meet.Title')}
                    </Row>

                    <>
                      <Row justify={'center'} style={{ color: '#555', width: '100%' }}>
                        {getLocalString(translations, 'Meet.Text1')}
                      </Row>
                      <Row
                        justify={'center'}
                        style={{ color: '#555', width: '100%', marginBottom: '16px' }}
                        className={`${globalStyles.body_r_medium} `}
                      >
                        {getLocalString(translations, 'Meet.Text2')}
                      </Row>
                    </>
                  </Row>
                  <Row style={{ width: '100%' }}>
                    {/* <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}> */}
                    <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                      <Row>
                        <Row style={{ width: '100%' }}>
                          <Row style={{ width: '100%' }}>
                            <Form.Item
                              style={{ width: '100%' }}
                              name='name'
                              rules={[
                                {
                                  required: true,
                                  message: getLocalString(translations, 'Apply.Message.Name'),
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                      return Promise.reject(
                                        new Error(getLocalString(translations, 'Apply.Error.Name'))
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Row style={{ width: '100%', gap: '5px' }}>
                                <Row
                                  className={`${globalStyles.label_r_large} ${styles.label_color}`}
                                >
                                  {getLocalString(translations, 'Apply.Name')}
                                </Row>
                                <Input
                                  maxLength={35}
                                  onChange={(e) => {
                                    form.setFieldsValue({ name: e.currentTarget.value.trim() });
                                  }}
                                  placeholder={getLocalString(
                                    translations,
                                    'Apply.Placeholder.Name'
                                  )}
                                  size='large'
                                />
                              </Row>
                            </Form.Item>
                          </Row>
                          <Row style={{ width: '100%' }}>
                            <Form.Item
                              style={{ width: '100%' }}
                              name='surname'
                              rules={[
                                {
                                  required: true,
                                  message: getLocalString(translations, 'Apply.Message.Surname'),
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                      return Promise.reject(
                                        new Error(
                                          getLocalString(translations, 'Apply.Error.Surname')
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Row style={{ width: '100%', gap: '5px' }}>
                                <Row
                                  className={`${globalStyles.label_r_large} ${styles.label_color}`}
                                >
                                  {getLocalString(translations, 'Apply.Surname')}
                                </Row>

                                <Input
                                  maxLength={35}
                                  onChange={(e) => {
                                    form.setFieldsValue({ surname: e.currentTarget.value.trim() });
                                  }}
                                  placeholder={getLocalString(
                                    translations,
                                    'Apply.Placeholder.Surname'
                                  )}
                                  size='large'
                                />
                              </Row>
                            </Form.Item>
                          </Row>{' '}
                          <Row style={{ width: '100%' }}>
                            <Form.Item
                              style={{ width: '100%' }}
                              name='phone'
                              rules={[
                                {
                                  required: true,
                                  message: getLocalString(translations, 'Apply.Message.Phone'),
                                },
                                {
                                  pattern: new RegExp(/^\+[0-9]{10,15}$/),
                                  message: getLocalString(translations, 'Apply.Message.Phone2'),
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                      return Promise.reject(
                                        new Error(getLocalString(translations, 'Apply.Error.Phone'))
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Row style={{ width: '100%', gap: '5px' }}>
                                <Row
                                  className={`${globalStyles.label_r_large} ${styles.label_color}`}
                                >
                                  {getLocalString(translations, 'Apply.PhoneNumber')}
                                </Row>
                                <Space.Compact size='large' style={{ width: '100%' }}>
                                  <Select
                                    style={{ width: '25%' }}
                                    defaultValue={'+90'}
                                    value={phonePrefix || '+90'}
                                    options={countryList.map((el) => ({
                                      value: el.phone_code,
                                      label: `${el.phone_code} ${el.name}`,
                                    }))}
                                    onChange={(e) => {
                                      setPhonePrefix(e);
                                    }}
                                    showSearch
                                    filterOption={filterOption}
                                  />
                                  <Input
                                    maxLength={35}
                                    onChange={(e) => {
                                      form.setFieldsValue({ phone: e.currentTarget.value.trim() });
                                    }}
                                    placeholder={getLocalString(
                                      translations,
                                      'Apply.Placeholder.Phone'
                                    )}
                                    size='large'
                                  />
                                </Space.Compact>
                              </Row>
                            </Form.Item>
                          </Row>
                          <Row style={{ width: '100%' }}>
                            <Form.Item
                              style={{ width: '100%' }}
                              name='email'
                              rules={[
                                {
                                  required: true,
                                  message: getLocalString(translations, 'Apply.Message.Mail'),
                                },
                                {
                                  type: 'email',
                                  message: getLocalString(translations, 'Apply.Message.Mail2'),
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (value && (value.startsWith(' ') || value.endsWith(' '))) {
                                      return Promise.reject(
                                        new Error(getLocalString(translations, 'Apply.Error.Mail'))
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Row style={{ width: '100%', gap: '5px' }}>
                                <Row
                                  className={`${globalStyles.label_r_large} ${styles.label_color}`}
                                >
                                  {getLocalString(translations, 'Apply.EmailAddress')}
                                </Row>
                                <Input
                                  maxLength={35}
                                  onChange={(e) => {
                                    form.setFieldsValue({ email: e.currentTarget.value.trim() });
                                  }}
                                  placeholder={getLocalString(
                                    translations,
                                    'Apply.Placeholder.Email'
                                  )}
                                  size='large'
                                />
                              </Row>
                            </Form.Item>
                          </Row>
                        </Row>
                      </Row>

                      <Row
                        style={{ marginBottom: '50px', marginTop: '50px', width: '100%' }}
                        justify={'center'}
                      >
                        <Button
                          // loading={isOn}
                          size='large'
                          style={{ width: '50%' }}
                          type='primary'
                          onClick={() => form.submit()}
                        >
                          {getLocalString(translations, 'Apply.Done')}
                        </Button>
                      </Row>
                    </Form>
                  </Row>
                </>
              ) : (
                <Result
                  status='success'
                  title={getLocalString(translations, 'Meet.Created')}
                  subTitle={
                    <Row
                      style={{ color: '#555', width: '100%', marginBottom: '16px' }}
                      justify={'center'}
                      className={`${globalStyles.headline_m_medium} `}
                    >
                      {getLocalString(translations, 'Meet.Created.Message')}
                    </Row>
                  }
                />
              )}
            </Row>
          </Col>
          <Col xs={2} sm={4} md={6} lg={6} xl={6}></Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateADVMeet;
