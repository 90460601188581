export enum ActionTypes {
  GET_UNIVERSITIES = 'GET_UNIVERSITIES',
  GET_COUNTRIES = 'GET_COUNTRIES',
  GET_CITIES = 'GET_CITIES',
  GET_TURKEY_CITIES = 'GET_TURKEY_CITIES',
  GET_FACULTIES = 'GET_FACULTIES',
  GET_SEMESTERS = 'GET_SEMESTERS',
  GET_UNIVERSITY_DEPARTMENTS = 'GET_UNIVERSITY_DEPARTMENTS',
  GET_UNIVERSITY_FACULTIES = 'GET_UNIVERSITY_FACULTIES',
  GET_FACULTY_DEPARTMENTS = 'GET_FACULTY_DEPARTMENTS',
  GET_APPLICATION_TYPES = 'GET_APPLICATION_TYPES',
  GET_LANGUAGES = 'GET_LANGUAGES',
  GET_GENDERS = 'GET_GENDERS',
  GET_PROGRAMS = 'GET_PROGRAMS',
  GET_USERS = 'GET_USERS',
  GET_CURRENCY = 'GET_CURRENCY',
  GET_ADMIN_USERS = 'GET_ADMIN_USERS',
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
  CHECK_TRACKING_CODE = 'CHECK_TRACKING_CODE',
  GET_INTERVIEW_TYPES = 'GET_INTERVIEW_TYPES',
  GET_INTERVIEW_STATUS_LIST = 'GET_INTERVIEW_STATUS_LIST',
}
