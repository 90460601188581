import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Card, Input, DatePicker } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import globalStyles from '../../../styles/global.module.scss';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import { constantsSelector } from '../../../store/constants';
import { apiURL } from '../../../service';
import { createDepartment, getDepartmentDetail, updateDepartment } from '../../../store/university';
import { useNavigate, useParams } from 'react-router-dom';
import isNumeric from '../../../helpers/isNumeric';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { notifyError, notifySuccess } from '../../../helpers/notify';
import { StyleInfo } from 'antd/es/theme/util/genComponentStyleHook';
import moment from 'moment';
import { adminCreate, agencyCreate, deleteAgency, getAdmin, getAgency } from '../../../store/admin';
import CustomizedTable from '../../../components/CustomizedTable';
import { accountSelector } from '../../../store/account';

const AgencyDetail: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { account } = useAppSelector(accountSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const [agencyName, setAgencyName] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [agencyCountry, setAgencyCountry] = useState<string>('');

  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [status, setStatus] = useState<string>('');

  const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(true);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true);
  const [isPasswordsValid, setIsPasswordsValid] = useState<boolean>(false);

  const [filters, setFilters] = useState<object>({});
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      dispatch(
        getAgency({
          id: id,
          onSuccess: (data: any) => {
            // console.log(data.data);
            setAgencyName(data.data.agency_name);
            setName(data.data.name);
            setAgencyCountry(data.data.agency_country);
            setSurname(data.data.surname);
            setEmail(data.data.email);
            setStatus(data.data.is_status);
            setPhone(data.data.phone);
          },
        })
      );
    }
  }, [id]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: getLocalString(translations, 'AgencyDetail.Columns.AgencyName'),
      dataIndex: 'agencyName',
      key: 'agencyName',
    },
    {
      title: getLocalString(translations, 'AgencyDetail.Columns.Name'),
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: getLocalString(translations, 'AgencyDetail.Columns.Surname'),
      dataIndex: 'surname',
      key: 'surname',
    },
    {
      title: getLocalString(translations, 'AgencyDetail.Columns.Country'),
      dataIndex: 'agencyCountry',
      key: 'agencyCountry',
    },

    {
      title: getLocalString(translations, 'AgencyDetail.Columns.Phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: getLocalString(translations, 'AgencyDetail.Columns.Email'),
      dataIndex: 'email',
      key: 'email',
    },
  ];
  const workerColumns = [
    {
      title: getLocalString(translations, 'AgencyDetail.WorkersColumns.AgencyId'),
      dataIndex: 'agency_id',
      key: 'agency_id',
    },
    {
      title: getLocalString(translations, 'AgencyDetail.WorkersColumns.Username'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: getLocalString(translations, 'AgencyDetail.WorkersColumns.Name'),
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: getLocalString(translations, 'AgencyDetail.WorkersColumns.Surname'),
      dataIndex: 'surname',
      key: 'surname',
    },

    {
      title: getLocalString(translations, 'AgencyDetail.WorkersColumns.Phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: getLocalString(translations, 'AgencyDetail.WorkersColumns.Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: getLocalString(translations, 'AgencyDetail.WorkersColumns.CreatedBy'),
      dataIndex: 'created_by',
      key: 'created_by',
      render: (record: any) => record.username,
    },
    {
      title: getLocalString(translations, 'AgencyDetail.WorkersColumns.CreatedDate'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (e: any, record: any) => dayjs(record.created_at).format('YYYY-MM-DD'),
    },
  ];

  return (
    <PageContainer
      pageName={id ? agencyName : getLocalString(translations, 'AgencyDetail.Title')}
      backUrl={'/agency-list'}
      extra={
        <Button
          type='primary'
          size='large'
          disabled={!isCreate}
          onClick={() => navigate('/create-worker/' + id)}
        >
          {/* Agency id ile create worker işlemi yapıldığı için create worker 'a agency id param geçiliyor */}
          {getLocalString(translations, 'AgencyDetail.AddWorker')}
        </Button>
      }
    >
      <Row style={{ marginTop: '24px' }} className={globalStyles.label_r_large}>
        {getLocalString(translations, 'AgencyDetail.AgencyInformation')}
      </Row>
      <Row style={{ marginTop: '8px' }}>
        <CustomizedTable
          columns={columns}
          data={[
            {
              id: id,
              agencyName: agencyName,
              name: name,
              surname: surname,
              agencyCountry: agencyCountry,
              phone: phone,
              email: email,
            },
          ]}
        />
      </Row>
      <Row style={{ marginTop: '24px' }} className={globalStyles.label_r_large}>
        {getLocalString(translations, 'AgencyDetail.Workers')}
      </Row>
      <Row style={{ marginTop: '8px' }}>
        {id && (
          <CustomizedTable
            columns={workerColumns}
            data={[]}
            filter={filters}
            url={apiURL.getWorkerList.replace(':agencyId', id)}
            reload={reload}
            setReload={setReload}
            actions={[
              {
                action: (record: any) => {
                  dispatch(
                    deleteAgency({
                      agencyId: record.agency_id,
                      workerId: record.user_id,
                      onSuccess: (data: any) => {
                        notifySuccess(
                          getLocalString(translations, 'AgencyDetail.AgencyWorkerDeleted')
                        );
                        setReload(true);
                      },
                    })
                  );
                },
                type: 'delete',
                hidden: (record: any) => {
                  return !isCreate;
                },
              },
            ]}
          />
        )}
      </Row>
    </PageContainer>
  );
};

export default AgencyDetail;
