import { createReducer } from '@reduxjs/toolkit';
import { setSpinner } from './actions';

type SpinnerState = {
  isOn: boolean;
};

const initialState: SpinnerState = {
  isOn: false,
};

export const spinnerReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSpinner, (state, action) => {
    state.isOn = action.payload;
  });
});
