import { createReducer } from '@reduxjs/toolkit';
import { checkUser, getUserProfile, login } from './actions';
import { IUserState } from '../../types/user';

interface AccountState {
  account: IUserState;
  pending: boolean;
  errorMessage: string;
}

const initialState: AccountState = {
  pending: false,
  errorMessage: '',
  account: {
    id: 0,
    username: '',
    email: '',
    phone: '',
    status: false,
    email_verified_at: true,
    role: { description: '', id: 0, name: '', slug: '' },
    name: '',
    surname: '',
    avatar: '',
    language: 'en',
  },
};

export const accountReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login.pending, (state) => {
      state.pending = true;
    })
    .addCase(login.rejected, (state) => {
      state.pending = false;
    })
    .addCase(login.fulfilled, (state, { payload }) => {
      state.account = payload.user;
    })
    .addCase(checkUser.pending, (state) => {
      state.pending = true;
    })
    .addCase(checkUser.rejected, (state) => {
      state.pending = false;
    })
    .addCase(checkUser.fulfilled, (state, { payload }) => {
      state.account = payload.data;
    });

  // // getProfileInfo
  // .addCase(getUserProfile.fulfilled, (state, { payload }) => {
  //   state.pending = false;
  //   state.userInfo = payload;
  //   state.errorMessage = '';
  // });
});

export default accountReducer;
