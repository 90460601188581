import React, { useEffect, useState } from 'react';
import styles from './applyLayout.module.scss';

const ApplyLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      {/* <Header></Header> */}
      {/* //style={{ padding: '32px 32px 32px 44px' }} */}
      <div>{children}</div>

      {/* <Footer></Footer> */}
    </>
  );
};

export default ApplyLayout;
