import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';
import globalStyles from './../../../styles/global.module.scss';

import PageContainer from '../../../components/pageContainer';
import CustomizedTable from '../../../components/CustomizedTable';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import {
  constantsSelector,
  getFaculties,
  getInterviewTypes,
  getUniversities,
} from '../../../store/constants';
import { apiURL } from '../../../service';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { deleteStudent, getStudentListExcelExport } from '../../../store/student';
import { notifyError, notifySuccess } from '../../../helpers/notify';
import { getAgencyList } from '../../../store/admin';
import { accountSelector } from '../../../store/account';

const { Option } = Select;

const StudentList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { countryList } = useAppSelector(constantsSelector);
  const { account } = useAppSelector(accountSelector);

  const [countryId, setCountryId] = useState<string>('');
  const [nationId, setNationId] = useState<string>('');
  const [schoolId, setSchoolId] = useState<string>('');

  const [agencyId, setAgencyId] = useState<string>('');
  const [agencyList, setAgencyList] = useState<any[]>([]);

  const [languageId, setLanguageId] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);

  const [filters, setFilters] = useState<object>();
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getFaculties());
    dispatch(getUniversities());
    dispatch(getInterviewTypes());
    dispatch(
      getAgencyList({
        onSuccess: (data: any) => {
          console.log(data);
          setAgencyList(data.data);
        },
      })
    );
  }, []);

  useEffect(() => {
    let filter: any = {};
    let filterCount = 0;
    if (agencyId) {
      filter.agency_id = agencyId;
      filterCount++;
    }
    if (nationId) {
      filter.nation_id = nationId;
      filterCount++;
    }

    if (searchText) {
      filter.search_text = searchText;
    }
    setFilterCount(filterCount);
    setFilters(filter);
  }, [searchText, nationId, agencyId]);

  const renderCountryOptions = useCallback(
    () => countryList.map((el, i) => ({ label: el.name, value: el.id })),
    [countryList]
  );

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 50,
    },
    {
      title: getLocalString(translations, 'StudentList.Columns.Name'),
      key: 'name',
      dataIndex: 'name',
      width: 100,
    },
    {
      title: getLocalString(translations, 'StudentList.Columns.Surname'),
      dataIndex: 'surname',
      key: 'surname',
      width: 100,
    },
    {
      title: getLocalString(translations, 'StudentList.Columns.Mail'),
      dataIndex: 'email',
      key: 'email',
      width: 200,
    },

    {
      title: getLocalString(translations, 'StudentList.Columns.Phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: 120,
    },

    {
      title: getLocalString(translations, 'StudentList.Columns.Country'),
      dataIndex: 'country',
      key: 'country',
      render: (e: any, record: any) => record.country.name,
      width: 100,
    },
    {
      title: getLocalString(translations, 'StudentList.Columns.Nation'),
      dataIndex: 'nation',
      key: 'nation',
      render: (e: any, record: any) => record.nation.name,
      width: 100,
    },
    {
      title: getLocalString(translations, 'StudentList.Columns.PassportNo'),
      dataIndex: 'passport_no',
      key: 'passport_no',
      width: 120,
    },
    {
      title: getLocalString(translations, 'StudentList.Columns.School'),
      dataIndex: 'school',
      key: 'school',
      width: 100,
    },
    {
      title: getLocalString(translations, 'StudentList.Columns.Agency'),
      dataIndex: 'agency',
      key: 'agency',
      render: (e: any, record: any) =>
        record.agency.agency_name ? (
          <div className={globalStyles.waiting_label}>
            {record.agency.agency_name.substring(0, 20)}
          </div>
        ) : (
          <div className={globalStyles.preview_label}>{record.agency}</div>
        ),
      width: 120,
    },
    {
      title: getLocalString(translations, 'StudentList.Columns.CreatedDate'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (e: any, record: any) => dayjs(record.created_at).format('YYYY-MM-DD'),
      width: 100,
    },
  ];

  return (
    <PageContainer
      pageName={getLocalString(translations, 'StudentList.Title')}
      extra={
        <Row style={{ gap: '16px' }}>
          <Button
            onClick={() => {
              dispatch(getStudentListExcelExport());
            }}
            type='primary'
            size='large'
          >
            {getLocalString(translations, 'StudentList.ExportExcel')}
          </Button>
          <Button type='primary' onClick={() => navigate('/create-student')} size='large'>
            {getLocalString(translations, 'StudentList.CreateStudent')}
          </Button>
        </Row>
      }
    >
      <PageFilter
        count={filterCount}
        clear={() => {
          setCountryId('');
          setNationId('');
          setSchoolId('');
          setLanguageId('');
        }}
      >
        <Row gutter={[8, 8]}>
          {account.role.id === 1 ? (
            <>
              <Col span={12}>
                <Select
                  style={{ width: '100%' }}
                  value={agencyId || undefined}
                  placeholder={getLocalString(translations, 'StudentList.Placeholder.Agency')}
                  onChange={(value) => {
                    setAgencyId(value);
                  }}
                  allowClear
                  showSearch
                  options={agencyList.map((el) => ({ value: el.id, label: el.name }))}
                  filterOption={filterOption}
                />
              </Col>
              <Col span={12}>
                <Select
                  style={{ width: '100%' }}
                  placeholder={getLocalString(translations, 'StudentList.Placeholder.Nation')}
                  onChange={setNationId}
                  allowClear
                  showSearch
                  options={renderCountryOptions()}
                  filterOption={filterOption}
                ></Select>
              </Col>
            </>
          ) : (
            <Col span={24}>
              <Select
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'StudentList.Placeholder.Nation')}
                onChange={setNationId}
                allowClear
                showSearch
                options={renderCountryOptions()}
                filterOption={filterOption}
              ></Select>
            </Col>
          )}
        </Row>
      </PageFilter>

      <Row style={{ marginTop: '24px' }}>
        <Row style={{ marginBottom: '24px' }}>
          <Search value={searchText} onChange={(value) => setSearchText(value)} />
        </Row>

        <div style={{ width: '100%' }}>
          <CustomizedTable
            columns={columns}
            data={[]}
            url={apiURL.getStudentList}
            filter={filters}
            reload={reload}
            setReload={setReload}
            actions={[
              {
                action: (record) => {
                  navigate('/update-student/' + record.id);
                },
                type: 'detail',
                // hidden: (record: any) => record.status === true,
              },
              {
                action: (record) => {
                  dispatch(
                    deleteStudent({
                      id: record.id,
                      onSuccess: (data: any) => {
                        notifySuccess(
                          getLocalString(
                            translations,
                            'StudentList.Placeholder.StudentDeletedSuccessfully'
                          )
                        );
                        setReload(true);
                      },
                      onError: (err) => notifyError(err),
                    })
                  );
                },
                type: 'delete',
              },
            ]}
          />
        </div>
      </Row>
    </PageContainer>
  );
};

export default StudentList;
