import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Select,
  Collapse,
  Row,
  Col,
  Tag,
  Card,
  Input,
  DatePicker,
  Divider,
  List,
  Space,
} from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import globalStyles from '../../../styles/global.module.scss';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import {
  constantsSelector,
  getInterviewStatusList,
  getInterviewTypes,
} from '../../../store/constants';
import { apiURL } from '../../../service';
import { createDepartment, getDepartmentDetail, updateDepartment } from '../../../store/university';
import { useNavigate, useParams } from 'react-router-dom';
import isNumeric from '../../../helpers/isNumeric';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { notifyError, notifySuccess } from '../../../helpers/notify';
import { StyleInfo } from 'antd/es/theme/util/genComponentStyleHook';
import moment from 'moment';
import { adminSelector, getAdminWorkerList } from '../../../store/admin';
import { IAgencyInfo } from '../../../types/agency';
import {
  createInterview,
  getInterviewDetail,
  interviewUpdate,
  checkPassportNumber,
  createInterviewMeetingNote,
  appointInterviewToAdminWorker,
} from '../../../store/interview';
import userList from '../../users/user-list';
import { IInterviewInfo } from '../../../types/interview';

const { Option } = Select;

const AddUpdateInterview: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { countryList, interviewTypeList, interviewStatusList } = useAppSelector(constantsSelector);
  const { adminWorkerList } = useAppSelector(adminSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [phonePrefix, setPhonePrefix] = useState<string>('+90');
  const [email, setEmail] = useState<string>('');
  const [passportNo, setPassportNo] = useState<string>('');
  const [interviewTypeId, setInterviewTypeId] = useState<number>();
  const [currentStatusId, setCurrentStatusId] = useState<number>();

  const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(true);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true);
  const [statusTagId, setStatusTagId] = useState<string>('');
  const [appointedAdminWorkerId, setAppointedAdminWorkerId] = useState<string>('');
  const [meetingNote, setMeetingNote] = useState<string>('');
  const [meetingNotes, setMeetingNotes] = useState<any[]>();
  const [interviewInfo, setInterviewInfo] = useState<IInterviewInfo>();
  const [checkPassportNo, setCheckPassportNo] = useState<string>('');

  const interviewDetail = (id: string) => {
    dispatch(
      getInterviewDetail({
        id,
        onSuccess: (data: any) => {
          setName(data.data.name);
          setSurname(data.data.surname);
          setEmail(data.data.email);
          setPassportNo(data.data.passport_no ? data.data.passport_no : '');
          setInterviewTypeId(data.data.type.id);
          setCurrentStatusId(data.data.status.id);
          setPhone(data.data.phone);
          setPhonePrefix(data.data.phone_code);
          setInterviewInfo(data.data);
          setMeetingNotes(data.data.meet_details);
          setAppointedAdminWorkerId(
            data.data.appointed_user?.id ? data.data.appointed_user.id : ''
          );
        },
      })
    );
  };

  const tagColors = [
    'purple',
    'geekblue',
    'magenta',
    'red',
    'blue',
    'volcano',
    'orange',
    'green',
    'lime',
    'cyan',
  ];

  useEffect(() => {
    if (!!id) {
      interviewDetail(id);
    }
  }, [id]);

  useEffect(() => {
    dispatch(getInterviewTypes());
    dispatch(getInterviewStatusList());
  }, []);
  useEffect(() => {
    if (!!isUpdate) dispatch(getAdminWorkerList());
  }, [isUpdate]);

  useEffect(() => {
    if (id && name.length > 0 && surname.length > 0 && email.length > 0 && phone.length) {
      // console.log('Initial conditions met:', { id, name, surname, email, phone });
      if (
        name !== interviewInfo?.name ||
        surname !== interviewInfo?.surname ||
        email !== interviewInfo?.email ||
        phone !== interviewInfo?.phone ||
        phonePrefix !== interviewInfo?.phone_code
      ) {
        // console.log('Editing required:', {
        //   nameChanged: name !== interviewInfo?.name,
        //   surnameChanged: surname !== interviewInfo?.surname,
        //   emailChanged: email !== interviewInfo?.email,
        //   phoneChanged: phone !== interviewInfo?.phone,
        // });

        setIsEditDisabled(false);
      } else {
        // console.log('No editing required');
        setIsEditDisabled(true);
      }
    } else {
      // console.log('Initial conditions not met');
      setIsEditDisabled(true);
    }

    // // Assuming this is in a useEffect or similar, depending on your framework
    // console.log('Dependencies for condition check:', {
    //   name,
    //   surname,
    //   phone,
    //   email,
    // });
  }, [name, surname, phone, phonePrefix, email, interviewInfo]);

  useEffect(() => {
    if (name !== '' && surname !== '' && email !== '' && phone !== '') setIsCreateDisabled(false);
    else {
      setIsCreateDisabled(true);
    }
  }, [name, surname, phone, email]);

  const renderInterviewTypes = useCallback(
    () => interviewTypeList.map((el: any) => ({ value: el.id, label: el.name })),
    [interviewTypeList]
  );

  //yeniin İD si listeye alınmıcak

  const renderStatusTypes = useCallback(
    () =>
      interviewStatusList
        .filter((el) => el.id !== 8) // id'si 8 olan öğeyi filtrele
        .map((el: any) => ({ value: el.id, label: el.name })),
    [interviewStatusList]
  );
  const renderStatusProfileTypes = useCallback(
    () => interviewStatusList.map((el: any) => ({ value: el.id, label: el.name })),
    [interviewStatusList]
  );
  const renderAdminWorkerListOption = useCallback(
    () => adminWorkerList.map((el) => ({ value: el.id, label: `${el.name} ${el.surname}` })),
    [adminWorkerList]
  );
  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailBlur = () => {
    if (email && !validateEmail(email)) {
      notifyError(getLocalString(translations, 'AddUpdateInterview.NotifyError.Email'));
    }
  };

  const validatePhoneNumber = (phone: any) => {
    const regex = /^[1-9]\d{1,14}$/;

    if (!regex.test(phone)) {
      notifyError(getLocalString(translations, 'AddUpdateInterview.NotifyError.Phone'));
    }
  };

  return (
    <PageContainer
      pageName={
        id
          ? getLocalString(translations, 'AddUpdateInterview.EditInterview')
          : getLocalString(translations, 'AddUpdateInterview.CreateInterview')
      }
      backUrl={'/interview-list'}
    >
      <Row style={{ width: '100%' }}>
        {!id ? (
          <Col offset={6} span={12}>
            <Row gutter={[16, 16]}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'AddUpdateInterview.Name')}
                </Row>
                <Input
                  maxLength={35}
                  // disabled={!!id}
                  style={{ width: '100%' }}
                  placeholder={getLocalString(translations, 'AddUpdateInterview.Placeholder.Name')}
                  value={name || undefined}
                  onChange={(e) => {
                    setName(e.currentTarget.value);
                  }}
                  size='large'
                ></Input>
              </Row>

              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'AddUpdateInterview.Surname')}
                </Row>
                <Input
                  maxLength={35}
                  // disabled={!!id}
                  style={{ width: '100%' }}
                  placeholder={getLocalString(
                    translations,
                    'AddUpdateInterview.Placeholder.Surname'
                  )}
                  value={surname || undefined}
                  onChange={(e) => {
                    setSurname(e.currentTarget.value);
                  }}
                  size='large'
                ></Input>
              </Row>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'AddUpdateInterview.Phone')}
                </Row>
                <Space.Compact size='large' style={{ width: '100%' }}>
                  <Select
                    style={{ width: '25%' }}
                    defaultValue={'+90'}
                    value={phonePrefix || '+90'}
                    options={countryList.map((el) => ({
                      value: el.phone_code,
                      label: `${el.phone_code} ${el.name}`,
                    }))}
                    onChange={(e) => {
                      setPhonePrefix(e);
                    }}
                    showSearch
                    filterOption={filterOption}
                  />
                  <Input
                    maxLength={35}
                    // disabled={!!id}
                    //style={{ width: '100%' }}
                    placeholder={getLocalString(
                      translations,
                      'AddUpdateInterview.Placeholder.Phone'
                    )}
                    value={phone || undefined}
                    onChange={(e) => {
                      setPhone(e.currentTarget.value);
                    }}
                    onKeyDown={(e) => {
                      if (!isNumeric(e.key) && e.key !== 'Backspace' && e.key !== '+')
                        e.preventDefault();
                    }}
                    onBlur={() => validatePhoneNumber(phone)}
                    size='large'
                  ></Input>
                </Space.Compact>
              </Row>
              {!!id && (
                <Row style={{ width: '100%', gap: '5px' }}>
                  <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                    {getLocalString(translations, 'AddUpdateInterview.PassportNo')}
                  </Row>
                  <Input
                    maxLength={35}
                    placeholder={getLocalString(
                      translations,
                      'AddUpdateInterview.Placeholder.PassportNo'
                    )}
                    value={passportNo || undefined}
                    size='large'
                    onChange={(e) => {
                      setPassportNo(e.currentTarget.value);
                    }}
                    onKeyDown={(e) => {
                      if (!isNumeric(e.key) && e.key !== 'Backspace') e.preventDefault();
                    }}
                  ></Input>
                </Row>
              )}

              <Row style={{ width: '100%' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'AddUpdateInterview.Email')}
                </Row>
                <Input
                  maxLength={35}
                  // disabled={!!id}
                  style={{ width: '100%' }}
                  placeholder={getLocalString(translations, 'AddUpdateInterview.Placeholder.Email')}
                  value={email || undefined}
                  onChange={(e) => {
                    setEmail(e.currentTarget.value);
                  }}
                  onBlur={handleEmailBlur}
                  size='large'
                ></Input>
              </Row>
            </Row>
            {!id ? (
              <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
                <Button
                  onClick={() => {
                    dispatch(
                      createInterview({
                        name: name,
                        surname: surname,
                        phone: phone,
                        phone_code: phonePrefix,
                        email: email,
                        type: 1,
                        onSuccess: (data: any) => {
                          notifySuccess(
                            getLocalString(
                              translations,
                              'AddUpdateInterview.NotifySuccess.InterviewCreated'
                            )
                          );

                          navigate('/update-interview/' + data.data.id);
                        },
                      })
                    );
                  }}
                  size='large'
                  type='primary'
                  disabled={isCreateDisabled}
                >
                  {getLocalString(translations, 'AddUpdateInterview.CreateInterview')}
                </Button>
              </Row>
            ) : (
              <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
                <Button
                  onClick={() => {
                    id &&
                      interviewTypeId &&
                      dispatch(
                        interviewUpdate({
                          id: id,
                          name: name,
                          surname: surname,
                          phone: phone,
                          phone_code: phonePrefix,
                          email: email,
                          passport_no: passportNo,
                          type: interviewTypeId,
                          onSuccess: (response: any) => {
                            if (response.status === 202) {
                              notifySuccess(
                                getLocalString(
                                  translations,
                                  'AddUpdateInterview.NotifySuccess.StudentAlreadySaved'
                                )
                              );

                              navigate('/update-student/' + response.data.data.id);
                            } else if (response.status === 203) {
                              notifySuccess(
                                getLocalString(
                                  translations,
                                  'AddUpdateInterview.NotifySuccess.StudentCreated'
                                )
                              );

                              navigate('/update-student/' + response.data.data.id);
                            } else if (response.status === 200) {
                              notifySuccess(
                                getLocalString(
                                  translations,
                                  'AddUpdateInterview.NotifySuccess.StudentCreated'
                                )
                              );

                              interviewDetail(id);
                              setIsEditDisabled(true);
                            }
                          },
                        })
                      );
                  }}
                  size='large'
                  type='primary'
                  disabled={isEditDisabled}
                  // style={!isCreate ? { display: 'none' } : {}}
                >
                  {getLocalString(translations, 'AddUpdateInterview.EditInterview')}
                </Button>
              </Row>
            )}
          </Col>
        ) : (
          <Row gutter={[24, 24]} style={{ width: '100%' }}>
            <Col span={12}>
              <Card style={{ width: '100%' }}>
                <Row gutter={[16, 16]} style={{ width: '100%' }}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'AddUpdateInterview.Name')}
                    </Row>
                    <Input
                      maxLength={35}
                      // disabled={!!id}
                      style={{ width: '100%' }}
                      placeholder={getLocalString(
                        translations,
                        'AddUpdateInterview.Placeholder.Name'
                      )}
                      value={name || undefined}
                      onChange={(e) => {
                        setName(e.currentTarget.value);
                      }}
                      size='large'
                    ></Input>
                  </Row>

                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'AddUpdateInterview.Surname')}
                    </Row>
                    <Input
                      maxLength={35}
                      // disabled={!!id}
                      style={{ width: '100%' }}
                      placeholder={getLocalString(
                        translations,
                        'AddUpdateInterview.Placeholder.Surname'
                      )}
                      value={surname || undefined}
                      onChange={(e) => {
                        setSurname(e.currentTarget.value);
                      }}
                      size='large'
                    ></Input>
                  </Row>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'AddUpdateInterview.Phone')}
                    </Row>
                    <Input
                      maxLength={35}
                      // disabled={!!id}
                      style={{ width: '100%' }}
                      placeholder={getLocalString(
                        translations,
                        'AddUpdateInterview.Placeholder.Phone'
                      )}
                      value={phone || undefined}
                      onChange={(e) => {
                        setPhone(e.currentTarget.value);
                      }}
                      onKeyDown={(e) => {
                        if (!isNumeric(e.key) && e.key !== 'Backspace' && e.key !== '+')
                          e.preventDefault();
                      }}
                      onBlur={() => validatePhoneNumber(phone)}
                      size='large'
                    ></Input>
                  </Row>
                  {!!id && (
                    <Row style={{ width: '100%', gap: '5px' }}>
                      <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                        {getLocalString(translations, 'AddUpdateInterview.PassportNo')}
                      </Row>
                      <Input
                        maxLength={35}
                        placeholder={getLocalString(
                          translations,
                          'AddUpdateInterview.Placeholder.PassportNo'
                        )}
                        value={passportNo || undefined}
                        size='large'
                        disabled
                      ></Input>
                    </Row>
                  )}

                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'AddUpdateInterview.InterviewType')}
                    </Row>
                    <Select
                      style={{ width: '100%' }}
                      value={interviewTypeId || undefined}
                      onChange={(e) => {
                        setInterviewTypeId(e);
                      }}
                      size='large'
                      showSearch
                      disabled
                      filterOption={filterOption}
                      options={renderInterviewTypes()}
                    ></Select>
                  </Row>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'AddUpdateInterview.Status')}
                    </Row>
                    <Select
                      style={{ width: '100%' }}
                      value={currentStatusId || undefined}
                      size='large'
                      showSearch
                      disabled
                      filterOption={filterOption}
                      options={renderStatusProfileTypes()}
                    ></Select>
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'AddUpdateInterview.Email')}
                    </Row>
                    <Input
                      maxLength={35}
                      // disabled={!!id}
                      style={{ width: '100%' }}
                      placeholder={getLocalString(
                        translations,
                        'AddUpdateInterview.Placeholder.Email'
                      )}
                      value={email || undefined}
                      onChange={(e) => {
                        setEmail(e.currentTarget.value);
                      }}
                      onBlur={handleEmailBlur}
                      size='large'
                    ></Input>
                  </Row>
                </Row>
                {!!id && (
                  <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
                    <Button
                      onClick={() => {
                        id &&
                          interviewTypeId &&
                          dispatch(
                            interviewUpdate({
                              id: id,
                              name: name,
                              surname: surname,
                              phone: phone,
                              phone_code: phonePrefix,
                              email: email,
                              passport_no: passportNo,
                              type: interviewTypeId,
                              onSuccess: (response: any) => {
                                if (response.status === 202) {
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'AddUpdateInterview.NotifySuccess.StudentAlreadySaved'
                                    )
                                  );
                                  navigate('/update-student/' + response.data.data.id);
                                } else if (response.status === 203) {
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'AddUpdateInterview.NotifySuccess.StudentCreated'
                                    )
                                  );
                                  navigate('/update-student/' + response.data.data.id);
                                } else if (response.status === 200) {
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'AddUpdateInterview.NotifySuccess.InterviewUpdated'
                                    )
                                  );

                                  interviewDetail(id);
                                  setIsEditDisabled(true);
                                }
                              },
                            })
                          );
                      }}
                      size='large'
                      type='primary'
                      disabled={isEditDisabled}
                      // style={!isCreate ? { display: 'none' } : {}}
                    >
                      {getLocalString(
                        translations,
                        'AddUpdateInterview.EditCandidateStudentInformation'
                      )}
                    </Button>
                  </Row>
                )}
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ width: '100%' }}>
                <Row gutter={[16, 16]} style={{ width: '100%' }}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'AddUpdateInterview.PassportNoVerification')}
                    </Row>
                    <Input
                      maxLength={35}
                      placeholder={getLocalString(
                        translations,
                        'AddUpdateInterview.Placeholder.PassportNoVerification'
                      )}
                      disabled={currentStatusId == 7 || currentStatusId == 10}
                      value={checkPassportNo || undefined}
                      size='large'
                      onChange={(e) => {
                        setCheckPassportNo(e.currentTarget.value);
                      }}
                      onKeyDown={(e) => {
                        if (!isNumeric(e.key) && e.key !== 'Backspace') e.preventDefault();
                      }}
                    ></Input>
                  </Row>
                  <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
                    <Button
                      onClick={() => {
                        checkPassportNo &&
                          dispatch(
                            checkPassportNumber({
                              id: id,
                              passport_no: checkPassportNo,
                              onSuccess: (response: any) => {
                                if (response.status === 202) {
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'AddUpdateInterview.NotifySuccess.PassportAlready'
                                    )
                                  );

                                  navigate('/update-student/' + response.data.data.id);
                                } else if (response.status === 203) {
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'AddUpdateInterview.NotifySuccess.WaitingforRegistration'
                                    )
                                  );

                                  const candidateName = encodeURIComponent(name);
                                  const candidateSurname = encodeURIComponent(surname);
                                  const candidatePhone = encodeURIComponent(phone);
                                  const candidateEmail = encodeURIComponent(email);
                                  let path = `/create-student/${candidateName}/${candidateSurname}/${candidatePhone}/${candidateEmail}`;

                                  if (passportNo) {
                                    const candidatePassportNo = encodeURIComponent(passportNo);
                                    path += `/${candidatePassportNo}`;
                                  }

                                  navigate(path);
                                } else if (response.status === 200) {
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'AddUpdateInterview.NotifySuccess.PassportNoUpdated'
                                    )
                                  );

                                  interviewDetail(response.data.data.id);

                                  setIsEditDisabled(true);
                                }
                                notifySuccess(
                                  getLocalString(
                                    translations,
                                    'AddUpdateInterview.NotifySuccess.InterviewUpdated'
                                  )
                                );
                              },
                              onError: (err: any) => {
                                setCheckPassportNo('');
                              },
                            })
                          );

                        //burada her iki türlü de 200 dönmesi lazım
                      }}
                      size='large'
                      type='primary'
                      disabled={!checkPassportNo || currentStatusId == 7 || currentStatusId == 10}
                      // style={!isCreate ? { display: 'none' } : {}}
                    >
                      {getLocalString(translations, 'AddUpdateInterview.CheckPassportNo')}
                    </Button>
                  </Row>
                </Row>
              </Card>
              {/* sadece admin görecek o yüzden isupğdate diğerleri için false */}
              {isUpdate && (
                <Card style={{ width: '100%', marginTop: '24px' }}>
                  <Row gutter={[16, 16]} style={{ width: '100%' }}>
                    <Row style={{ width: '100%', gap: '5px' }}>
                      <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                        {getLocalString(translations, 'AddUpdateInterview.AppointToAdminWorker')}
                      </Row>
                      <Select
                        showSearch
                        disabled={currentStatusId == 7 || currentStatusId == 10}
                        filterOption={filterOption}
                        style={{ width: '100%' }}
                        value={appointedAdminWorkerId || undefined}
                        onChange={(e) => setAppointedAdminWorkerId(e)}
                        size='large'
                        options={renderAdminWorkerListOption()}
                      ></Select>
                    </Row>
                    <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
                      <Button
                        onClick={() => {
                          id &&
                            dispatch(
                              appointInterviewToAdminWorker({
                                id: id,
                                user_id: appointedAdminWorkerId,
                                onSuccess: (data: any) => {
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'AddUpdateInterview.NotifySuccess.UserAppointedSuccessfully'
                                    )
                                  );

                                  interviewDetail(id);
                                  setStatusTagId('');
                                  setMeetingNote('');
                                },
                              })
                            );
                        }}
                        size='large'
                        type='primary'
                        disabled={!appointedAdminWorkerId}
                        // style={!isCreate ? { display: 'none' } : {}}
                      >
                        {getLocalString(translations, 'AddUpdateInterview.AppointUser')}
                      </Button>
                    </Row>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
        )}

        {!!id && (
          <Row style={{ width: '100%', marginTop: '24px' }} gutter={[0, 24]}>
            <Col span={24}>
              <Card style={{ height: '460px' }}>
                <Row className={`${globalStyles.title_r_medium} `}>
                  {getLocalString(translations, 'AddUpdateInterview.InterviewNotes')}
                </Row>
                <Divider></Divider>
                <div style={{ maxHeight: 'calc(460px - 120px)', overflowY: 'auto' }}>
                  <List
                    itemLayout='horizontal'
                    dataSource={meetingNotes}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <p>
                              <Tag style={{ width: '20%' }} color={tagColors[item.status.id]}>
                                {item.status.name}
                              </Tag>
                              &nbsp;&nbsp;
                              {getLocalString(translations, 'AddUpdateInterview.Createdby')}: &nbsp;
                              {item.created_by?.username}
                              &nbsp; &nbsp;
                              {getLocalString(translations, 'AddUpdateInterview.Createddate')}
                              :&nbsp;
                              {dayjs(item.created_at).format('YYYY-MM-DD HH:MM')} &nbsp;
                            </p>
                          }
                          description={<div style={{ marginLeft: '15px' }}> {item.content}</div>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Card>
            </Col>
            <Col span={24}>
              <Card style={{ height: '460px' }}>
                <Row className={`${globalStyles.title_r_medium} `}>
                  {getLocalString(translations, 'AddUpdateInterview.AddInterviewNote')}
                </Row>
                <Row style={{ width: '100%', marginTop: '24px' }}>
                  <Row
                    className={`${globalStyles.label_r_large} ${styles.label_color}`}
                    style={{ marginBottom: '8px' }}
                  >
                    {getLocalString(translations, 'AddUpdateInterview.SelectInterviewStatus')}
                  </Row>
                  <Select
                    showSearch
                    filterOption={filterOption}
                    style={{ width: '100%' }}
                    value={statusTagId || undefined}
                    onChange={(e) => setStatusTagId(e)}
                    size='large'
                    disabled={currentStatusId == 7 || currentStatusId == 10}
                    options={renderStatusTypes()}
                  ></Select>
                </Row>
                <Row style={{ width: '100%' }}>
                  <Row
                    className={`${globalStyles.label_r_large} ${styles.label_color}`}
                    style={{ marginBottom: '8px', marginTop: '24px' }}
                  >
                    {getLocalString(translations, 'AddUpdateInterview.InterviewNote')}
                  </Row>
                  <TextArea
                    value={meetingNote || undefined}
                    disabled={currentStatusId == 7 || currentStatusId == 10}
                    onChange={(e) => setMeetingNote(e.currentTarget.value)}
                    rows={6}
                    maxLength={500}
                    size='large'
                  ></TextArea>
                </Row>
                <Row justify={'end'} style={{ width: '100%', marginTop: '24px' }}>
                  <Button
                    type='primary'
                    size='large'
                    disabled={!statusTagId || currentStatusId == 7 || currentStatusId == 10}
                    onClick={() => {
                      id &&
                        dispatch(
                          createInterviewMeetingNote({
                            id: id,
                            content: meetingNote,
                            status: statusTagId,

                            onSuccess: (response: any) => {
                              if (response.status === 202) {
                                notifySuccess(
                                  getLocalString(
                                    translations,
                                    'AddUpdateInterview.NotifySuccess.StudentAlreadySaved'
                                  )
                                );

                                navigate('/update-student/' + response.data.data.id);
                              } else if (response.status === 203) {
                                notifySuccess(
                                  getLocalString(
                                    translations,
                                    'AddUpdateInterview.NotifySuccess.NavigateStudent'
                                  )
                                );

                                const candidateName = encodeURIComponent(name);
                                const candidateSurname = encodeURIComponent(surname);
                                const candidatePhone = encodeURIComponent(phone);
                                const candidateEmail = encodeURIComponent(email);
                                let path = `/create-student/${candidateName}/${candidateSurname}/${candidatePhone}/${candidateEmail}`;

                                if (passportNo) {
                                  const candidatePassportNo = encodeURIComponent(passportNo);
                                  path += `/${candidatePassportNo}`;
                                }

                                navigate(path);
                              } else if (response.status === 200) {
                                notifySuccess(
                                  getLocalString(
                                    translations,
                                    'AddUpdateInterview.NotifySuccess.MeetingNoteAddedSuccessfully'
                                  )
                                );

                                setStatusTagId('');
                                setMeetingNote('');

                                interviewDetail(id);
                              }
                            },
                          })
                        );
                    }}
                  >
                    {getLocalString(translations, 'AddUpdateInterview.InterviewNote')}
                  </Button>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </Row>
    </PageContainer>
  );
};

export default AddUpdateInterview;
