import { notification } from 'antd';

export const notifyError = (error: any | string) => {
  // debugger;
  if (typeof error === 'string') {
    fireNotification(error);
    return;
  }

  let detail: any;
  // let title: string = error.response.data.title;

  try {
    detail = JSON.parse(error.detail);
  } catch (err) {
    detail = error.detail;
  }

  if (typeof detail === 'object') {
    Object.keys(detail).forEach((el) => {
      fireNotification(detail[el].join(' '));
    });
  } else if (typeof detail === 'string') {
    fireNotification(detail.toString());
  }
};

const fireNotification = (msg: string, title?: string) => {
  const lang = JSON.parse(
    localStorage.getItem('appLanguage') ||
      '{ "code": "en-US", "name": "EN", "longName": "English" }'
  );
  if (lang.code === 'en-US') {
    notification['error']({ message: title || 'Error', description: msg });
  } else if (lang.code === 'tr-TR')
    notification['error']({ message: title || 'Hata', description: msg });
};

export const notifySuccess = (msg: string, title?: string) => {
  const lang = JSON.parse(
    localStorage.getItem('appLanguage') ||
      '{ "code": "en-US", "name": "EN", "longName": "English" }'
  );
  if (lang.code === 'en-US') {
    notification['success']({ message: title || 'Success', description: msg });
  } else if (lang.code === 'tr-TR')
    notification['success']({ message: title || 'Başarılı', description: msg });
};

export const notifyWarning = (msg: string, title?: string) => {
  const lang = JSON.parse(
    localStorage.getItem('appLanguage') ||
      '{ "code": "en-US", "name": "EN", "longName": "English" }'
  );
  if (lang.code === 'en-US') {
    notification['warning']({ message: title || 'Warning', description: msg });
  } else if (lang.code === 'tr-TR')
    notification['warning']({ message: title || 'Uyarı', description: msg });
};
