import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from './actions-types';
import { IActionCallback } from '../types';
import { apiURL, axiosClient } from '../../service';
import { notifyError } from '../../helpers/notify';
import { setSpinner } from '../spinner';

interface ICreateFacultyInput extends IActionCallback {
  name: string;
  university_id: any;
}
export const createFaculty = createAsyncThunk(
  ActionTypes.CREATE_FACULTY,
  async (data: ICreateFacultyInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { name, university_id, onSuccess, onError } = data;

    try {
      const response = await axiosClient.post(apiURL.createFaculty, { name, university_id });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IGetFacultyDetail extends IActionCallback {
  id: string;
}
export const getFacultyDetail = createAsyncThunk(
  ActionTypes.GET_FACULTY_DETAIL,
  async (data: IGetFacultyDetail, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getFacultyDetail.replace(':id', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IUpdateFacultyInput extends IActionCallback {
  id: string;
  university_id: string;
  name: string;
}
export const updateFaculty = createAsyncThunk(
  ActionTypes.UPDATE_FACULTY,
  async (data: IUpdateFacultyInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, university_id, name, onSuccess } = data;

    try {
      const response = await axiosClient.put(apiURL.updateFaculty.replace(':id', id), {
        name,
        university_id,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IDeleteFacultyInput extends IActionCallback {
  id: string;
}
export const deleteFaculty = createAsyncThunk(
  ActionTypes.DELETE_FACULTY,
  async (data: IDeleteFacultyInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.delete(apiURL.deleteFaculty.replace(':id', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
