import {
  UserAddOutlined,
  SettingOutlined,
  FolderAddOutlined,
  HomeOutlined,
  BankOutlined,
  RobotOutlined,
  FileTextFilled,
  ExclamationCircleOutlined,
  StopOutlined,
  DashboardOutlined,
  UserOutlined,
  InsertRowAboveOutlined,
  SolutionOutlined,
} from '@ant-design/icons';

export const getMenuIcon = (iconName: string) => {
  if (iconName === 'UserAddOutlined') return UserAddOutlined;
  else if (iconName === 'SettingOutlined') return SettingOutlined;
  else if (iconName === 'SolutionOutlined') return SolutionOutlined;
  else if (iconName === 'UserOutlined') return UserOutlined;
  else if (iconName === 'FolderAddOutlined') return FolderAddOutlined;
  else if (iconName === 'HomeOutlined') return HomeOutlined;
  else if (iconName === 'BankOutlined') return BankOutlined;
  else if (iconName === 'RobotOutlined') return RobotOutlined;
  else if (iconName === 'FileTextFilled') return FileTextFilled;
  else if (iconName === 'ExclamationCircleOutlined') return ExclamationCircleOutlined;
  else if (iconName === 'StopOutlined') return StopOutlined;
  else if (iconName === 'DashboardOutlined') return DashboardOutlined;
  else if (iconName === 'InsertRowAboveOutlined') return InsertRowAboveOutlined;
};
