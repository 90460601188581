import React from 'react';
import { Route, Outlet } from 'react-router-dom';
// import Sidebar from '../../components/SideBarN';
import DetailLayout from '../../layouts/FormLayout';

import styles from './index.module.scss';

const MainPages: React.FC = () => {
  return (
    // <div className={styles.main_content_container}>
    //   <Sidebar sidebarCollapsed={false} setSidebarCollapsed={{}}/>
    //   <Outlet />
    // </div>
    <DetailLayout>
      <Outlet />
    </DetailLayout>
  );
};

export default MainPages;
