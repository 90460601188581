import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from './actions-types';
import { IActionCallback } from '../types';
import { apiURL, axiosClient } from '../../service';
import { notifyError } from '../../helpers/notify';
import { setSpinner } from '../spinner';

interface IApplicationListInput extends IActionCallback {}
export const getApplicationList = createAsyncThunk(
  ActionTypes.GET_APPLICATION_LIST,
  async (data: IApplicationListInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { onSuccess } = data;

    try {
      const response = await axiosClient.get(apiURL.getApplications);
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface ICreateApplicationInput extends IActionCallback {
  university_id: number;
  department_id: number;
  nation_id: number;
  second_nation_id?: number;
  country_id: number;
  name: string;
  surname: string;
  father_name: string;
  mother_name: string;
  passport_no: number;
  second_passport_no?: number;
  email: string;
  phone: string;
  gender: string;
  application_type: string;
  semester: string;
  school: string;
  is_dual_citizenship: boolean;
  is_contract: boolean;
  files: {
    picture: any; // file olacak
    transcript: any;
    passport: any;
    certificate: any;
    second_passport?: any;
  };
}
export const createApplication = createAsyncThunk(
  ActionTypes.CREATE_APPLICATION,
  async (data: ICreateApplicationInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      university_id,
      department_id,
      nation_id,
      second_nation_id,
      country_id,
      name,
      surname,
      father_name,
      mother_name,
      passport_no,
      second_passport_no,
      email,
      phone,
      gender,
      application_type,
      semester,
      school,
      is_dual_citizenship,
      is_contract,
      files,
      onSuccess,
    } = data;

    // devamı yazılacak
    let formData = new FormData();

    formData.append('university_id', university_id.toString());
    formData.append('department_id', department_id.toString());
    formData.append('nation_id', nation_id.toString());
    if (second_nation_id) formData.append('second_nation_id', second_nation_id.toString());
    formData.append('country_id', country_id.toString());
    formData.append('name', name);
    formData.append('surname', surname);
    formData.append('father_name', father_name);
    formData.append('mother_name', mother_name);
    formData.append('passport_no', passport_no.toString());
    if (second_passport_no) formData.append('second_passport_no', second_passport_no.toString());
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('gender', gender);
    formData.append('application_type', application_type);
    formData.append('semester', semester);
    formData.append('school', school);
    formData.append('is_dual_citizenship', Number(is_dual_citizenship).toString());
    formData.append('is_contract', Number(is_contract).toString());

    formData.append('files[picture]', files.picture);
    formData.append('files[passport]', files.passport);
    formData.append('files[transcript]', files.transcript);
    formData.append('files[certificate]', files.certificate);
    if (files.second_passport) formData.append('files[second_passport]', files.second_passport);

    try {
      const response = await axiosClient.post(apiURL.createApplication, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IGetApplicationInput extends IActionCallback {
  id: string;
}
export const getApplication = createAsyncThunk(
  ActionTypes.GET_APPLICATION,
  async (data: IGetApplicationInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getApplication.replace(':id', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IGetApplicationUsers extends IActionCallback {
  id: string;
}
export const getApplicationUsers = createAsyncThunk(
  ActionTypes.GET_APPLICATION,
  async (data: IGetApplicationUsers, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getApplicationUsers.replace(':id', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IUploadFileApplicationInput extends IActionCallback {
  id: string;
  files: {
    receipt: any; // file olacak
    identity_card: any;
    acceptance_letter: any;
    final_acceptance_letter?: any;
  };
}
export const uploadApplicationFiles = createAsyncThunk(
  ActionTypes.CREATE_APPLICATION,
  async (data: IUploadFileApplicationInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, files, onSuccess } = data;

    let formData = new FormData();

    formData.append('files[receipt]', files.receipt);
    formData.append('files[identity_card]', files.identity_card);
    formData.append('files[acceptance_letter]', files.acceptance_letter);
    if (files.final_acceptance_letter)
      formData.append('files[final_acceptance_letter]', files.final_acceptance_letter);

    try {
      const response = await axiosClient.post(
        apiURL.uploadApplicationFiles.replace(':id', id),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IEditApplication extends IActionCallback {
  id: string;
  application_type_id: string;
  semester_id: string;
  university_id: string;
  department_id: string;
  faculty_id: string;
}
export const editApplication = createAsyncThunk(
  ActionTypes.EDIT_APPLICATION,
  async (data: IEditApplication, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      id,
      application_type_id,
      faculty_id,
      semester_id,
      department_id,
      university_id,
      onSuccess,
    } = data;

    try {
      const response = await axiosClient.put(apiURL.editApplication.replace(':id', id), {
        application_type_id,
        semester_id,
        faculty_id,
        department_id,
        university_id,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IAddNote extends IActionCallback {
  id: string;
  user_ids: string[];
  note: string;
}
export const addNote = createAsyncThunk(
  ActionTypes.ADD_NOTE,
  async (data: IAddNote, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, user_ids, note, onSuccess } = data;
    let formData = new FormData();

    formData.append('note', note);

    try {
      const response = await axiosClient.post(apiURL.addNote.replace(':id', id), {
        user_ids: user_ids,
        note: note,
      });

      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IEditDeadlineAndPinInputs extends IActionCallback {
  applicationId: string;
  deadline_date?: string;
  pin?: string;
}
export const editDeadlineAndPin = createAsyncThunk(
  ActionTypes.EDIT_DEADLINE_AND_PIN,
  async (data: IEditDeadlineAndPinInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const { applicationId, deadline_date, pin, onSuccess } = data;

    try {
      const response = await axiosClient.put(
        apiURL.editDeadlineAndPin.replace(':applicationId', applicationId),
        {
          deadline_date,
          pin,
        }
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IGetTrackingCodeDetail extends IActionCallback {
  id: string;
}
export const getTrackingCodeDetail = createAsyncThunk(
  ActionTypes.GET_TRACKING_CODE_DETAIL,
  async (data: IGetTrackingCodeDetail, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getTrackingCodeDetail.replace(':id', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IGetApplicationZipInputs extends IActionCallback {
  applicationId: string;
}

export const getApplicationZip = createAsyncThunk(
  ActionTypes.GET_APPLICATION_ZIP,
  async (data: IGetApplicationZipInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const { applicationId } = data;
    try {
      const response = await axiosClient.get(
        apiURL.getApplicationZip.replace(':applicationId', applicationId),
        {
          responseType: 'blob', // Excel dosyasını bir blob olarak almak için responseType'u ayarlayın
        }
      );

      // Blob'u bir URL'ye dönüştürme ve kullanıcıya indirme bağlantısı sunma
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // İçerik-dağıtımı başlığını ayrıştırma ve dosya adını alma
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'application id::applicationId.zip'.replace(':applicationId', applicationId); // Varsayılan dosya adı
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (fileNameMatch && fileNameMatch[1]) {
          // Eşleşme bulunursa ve grup 1 (dosya adı) mevcutsa
          filename = fileNameMatch[1].replace(/['"]/g, ''); // Alıntı işaretlerini kaldır
        }
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Bağlantıyı ve URL'yi temizleme
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch(setSpinner(false));
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

export const getApplicationListExport = createAsyncThunk(
  ActionTypes.GET_APPLICATION_LIST_EXPORT,
  async (_, { dispatch }) => {
    dispatch(setSpinner(true));

    try {
      const response = await axiosClient.get(apiURL.getApplicationListExport, {
        responseType: 'blob', // Excel dosyasını bir blob olarak almak için responseType'u ayarlayın
      });

      // Blob'u bir URL'ye dönüştürme ve kullanıcıya indirme bağlantısı sunma
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // İçerik-dağıtımı başlığını ayrıştırma ve dosya adını alma
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'applications_list.xlsx'; // Varsayılan dosya adı
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (fileNameMatch && fileNameMatch[1]) {
          // Eşleşme bulunursa ve grup 1 (dosya adı) mevcutsa
          filename = fileNameMatch[1].replace(/['"]/g, ''); // Alıntı işaretlerini kaldır
        }
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Bağlantıyı ve URL'yi temizleme
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch(setSpinner(false));
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

export const getApplicationStatus = createAsyncThunk(
  ActionTypes.GET_APPLICATION_STATUS,
  async () => {
    const response = await axiosClient.get(apiURL.getApplicationStatusList);
    return response.data;
  }
);

interface IUpdateApplicationStatus extends IActionCallback {
  id: string;
  statusId: number;
}

export const updateApplicationStatus = createAsyncThunk(
  ActionTypes.UPDATE_APPLICATION_STATUS,
  async (data: IUpdateApplicationStatus) => {
    const { id, statusId, onSuccess } = data;
    try {
      const response = await axiosClient.put(
        apiURL.updateApplicationStatus.replace(':application_id', id),
        {
          application_status_id: statusId,
        }
      );
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      notifyError(error);
    }
  }
);

interface IDeleteApplication extends IActionCallback {
  id: string;
}

export const deleteApplication = createAsyncThunk(
  ActionTypes.DELETE_APPLICATION,
  async (data: IDeleteApplication) => {
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.delete(apiURL.deleteApplication.replace(':id', id));
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      notifyError(error);
    }
  }
);
