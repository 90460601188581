import React from 'react';

import { useAppDispatch } from '../../store/hooks';
import styles from './not-found.module.scss';

const NotFound: React.FC = () => {
  return <div>Page Not Found</div>;
};

export default NotFound;
