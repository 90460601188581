import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { IProps } from './uniteamHeaderCultureDropdown-type';

import styles from './uniteamHeaderCultureDropdown.module.scss';
import { languages } from '../../resources/constants/language';

const UniteamHeaderCultureDropdown: React.FC<IProps> = ({ profileInfo, cultureList, onChange }) => {
  const [cultureSelection, setCultureSelection] = useState<string>('en');
  const [cultureName, setCultureName] = useState<string>('English');

  useEffect(() => {
    console.log('profileInfo.language', profileInfo.language);
    setCultureSelection(profileInfo?.language || 'en');
    setCultureName(languages.find((el) => el.name === profileInfo.language)?.longName || 'English');
  }, [profileInfo.language]);

  return (
    <Tooltip title='Language' showArrow={false} placement='bottom'>
      <Dropdown
        trigger={['click']}
        placement='bottomRight'
        arrow
        dropdownRender={() => (
          <div className={styles.menuDropdown}>
            <div className={styles.menuDropdownSteps}>
              <div
                className={`${styles.menuDropdownStepSelections} ${styles.menuDropdownStepActive}`}
              >
                <div className={styles.menuDropdownMenu}>
                  <ul>
                    {cultureList?.map((c, i) => (
                      <li key={c.id}>
                        <button
                          onClick={() => setCultureSelection(c.name)}
                          className={`${
                            cultureSelection === c.name && styles.menuDropdownMenuChecked
                          }`}
                        >
                          <CheckOutlined className={styles.menuDropdownMenuIcon} />
                          <span className={styles.menuDropdownMenuText}>{c.longName}</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={styles.menuDropdownFooter}>
                  <Button
                    block
                    type='primary'
                    size='middle'
                    onClick={(e) => {
                      onChange(cultureSelection, e);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      >
        <Button size='middle' style={{ paddingLeft: 6, paddingRight: 6 }} type='text'>
          {cultureName}
          <DownOutlined />
        </Button>
      </Dropdown>
    </Tooltip>
  );
};

export default UniteamHeaderCultureDropdown;
