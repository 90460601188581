import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from './actions-types';
import { IActionCallback } from '../types';
import { apiURL, axiosClient } from '../../service';
import { notifyError } from '../../helpers/notify';
import { setSpinner } from '../spinner';

interface IGetStudentInput extends IActionCallback {
  id: string;
}
export const getStudent = createAsyncThunk(
  ActionTypes.GET_STUDENT,
  async (data: IGetStudentInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getStudent.replace(':id', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface ICreateApplicationInput extends IActionCallback {
  university_id: number;
  department_id: number;
  nation_id: number;
  second_nation_id?: number;
  country_id: number;
  name: string;
  surname: string;
  father_name: string;
  mother_name: string;
  passport_no: string;
  second_passport_no?: string;
  email: string;
  phone: string;
  gender: string;
  application_type: string;
  semester: string;
  school: string;
  second_phone: string;
  is_dual_citizenship: boolean;
  is_contract: boolean;
  files: {
    picture: any; // file olacak
    transcript: any;
    passport: any;
    certificate: any;
    second_passport?: any;
  };
}
export const createApplication = createAsyncThunk(
  ActionTypes.CREATE_STUDENT,
  async (data: ICreateApplicationInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      university_id,
      department_id,
      nation_id,
      second_nation_id,
      country_id,
      name,
      surname,
      father_name,
      mother_name,
      passport_no,
      second_passport_no,
      email,
      phone,
      gender,
      application_type,
      semester,
      school,
      is_dual_citizenship,
      is_contract,
      files,
      onSuccess,
    } = data;

    let formData = new FormData();

    formData.append('university_id', university_id.toString());
    formData.append('department_id', department_id.toString());
    formData.append('nation_id', nation_id.toString());
    if (second_nation_id) formData.append('second_nation_id', second_nation_id.toString());
    formData.append('country_id', country_id.toString());
    formData.append('name', name);
    formData.append('surname', surname);
    formData.append('father_name', father_name);
    formData.append('mother_name', mother_name);
    formData.append('passport_no', passport_no.toString());
    if (second_passport_no) formData.append('second_passport_no', second_passport_no.toString());
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('gender', gender);
    formData.append('application_type', application_type);
    formData.append('semester', semester);
    formData.append('school', school);
    formData.append('is_dual_citizenship', Number(is_dual_citizenship).toString());
    formData.append('is_contract', Number(is_contract).toString());

    // formData.append('files[picture]', files.picture);
    // formData.append('files[passport]', files.passport);
    // formData.append('files[transcript]', files.transcript);
    // formData.append('files[certificate]', files.certificate);
    // if (files.second_passport) formData.append('files[second_passport]', files.second_passport);

    try {
      const response = await axiosClient.post(apiURL.createApplication, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface ICreateStudentInput extends IActionCallback {
  nation_id: number;
  second_nation_id?: number | null;
  country_id: number;
  name: string;
  surname: string;
  father_name: string;
  mother_name: string;
  passport_no: string;
  second_passport_no?: string | null;
  email: string;
  phone: string;
  phone_code: string;
  second_phone?: string | null;
  phone_code_2?: string | null;
  gender: string;
  school: string;
}
export const createStudent = createAsyncThunk(
  ActionTypes.CREATE_STUDENT,
  async (data: ICreateStudentInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      name,
      surname,
      father_name,
      mother_name,
      gender,
      nation_id,
      country_id,
      passport_no,
      second_nation_id,
      second_passport_no,
      phone,
      phone_code,
      second_phone,
      phone_code_2,
      email,
      school,
      onSuccess,
    } = data;

    let formData = new FormData();

    formData.append('name', name);
    formData.append('surname', surname);
    formData.append('father_name', father_name);
    formData.append('mother_name', mother_name);
    formData.append('gender_id', gender);

    formData.append('nation_id', nation_id.toString());
    formData.append('country_id', country_id.toString());
    if (second_nation_id) formData.append('second_nation_id', second_nation_id.toString());
    formData.append('passport_no', passport_no.toString());
    if (second_passport_no) formData.append('second_passport_no', second_passport_no.toString());
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('phone_code', phone_code);
    if (second_phone) {
      formData.append('second_phone', second_phone.toString());
      formData.append('phone_code_2', phone_code_2 || '');
    }
    formData.append('school', school);

    try {
      const response = await axiosClient.post(apiURL.createStudent, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IUpdateStudentInput extends IActionCallback {
  id: string;
  nation_id: number;
  second_nation_id?: number | null;
  country_id: number;
  name: string;
  surname: string;
  father_name: string;
  mother_name: string;
  passport_no: string;
  second_passport_no?: string | null;
  email: string;
  phone: string;
  phone_code: string;
  second_phone?: string | null;
  phone_code_2?: string | null;
  gender_id: string;
  school: string;
}

export const updateStudent = createAsyncThunk(
  ActionTypes.UPDATE_STUDENT,
  async (data: IUpdateStudentInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      id,
      name,
      surname,
      father_name,
      mother_name,
      gender_id,
      nation_id,
      country_id,
      passport_no,
      second_nation_id,
      second_passport_no,
      phone,
      phone_code,
      second_phone,
      phone_code_2,
      email,
      school,
      onSuccess,
    } = data;

    try {
      const response = await axiosClient.put(apiURL.updateStudent.replace(':id', id), {
        nation_id: nation_id,
        second_nation_id: second_nation_id || '',
        country_id: country_id,
        name: name,
        surname: surname,
        email: email,
        father_name: father_name,
        mother_name: mother_name,
        gender_id: gender_id,
        passport_no: passport_no,
        second_passport_no: second_passport_no || '',
        phone: phone,
        phone_code: phone_code,
        second_phone: second_phone || '',
        phone_code_2: phone_code_2,
        school: school,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IImportDataInput extends IActionCallback {
  model_id: string;
  model: 'student' | 'application' | 'university';
  file_key: string;
  file: any;
}

export const uploadFile = createAsyncThunk(
  ActionTypes.UPLOAD_STUDENT_FIlE,
  async (data: IImportDataInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { model_id, model, file_key, file, onSuccess, onError } = data;

    const formData = new FormData();
    formData.append('model_id', model_id);
    formData.append('model', model);
    formData.append('file_key', file_key);
    formData.append('file', file);
    try {
      const response = await axiosClient.post(apiURL.uploadFile, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IDeleteFile extends IActionCallback {
  id: string;
}
export const deleteFile = createAsyncThunk(
  ActionTypes.DELETE_FILE,
  async (data: IDeleteFile, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess, onError } = data;
    try {
      const response = await axiosClient.delete(apiURL.deleteFile.replace(':id', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IStudentApply extends IActionCallback {
  student_id: string;
  university_id: string;
  department_id: string;
  faculty_id: string;
  application_type: string;
  semester_id: string;
}

export const applyForThisStudent = createAsyncThunk(
  ActionTypes.APPLY_FOR_THIS_STUDENT,
  async (data: IStudentApply, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      student_id,
      faculty_id,
      university_id,
      department_id,
      application_type,
      semester_id,
      onSuccess,
    } = data;

    let formData = new FormData();

    formData.append('student_id', student_id);
    formData.append('university_id', university_id);
    formData.append('faculty_id', faculty_id);
    formData.append('department_id', department_id);
    formData.append('semester_id', semester_id);
    formData.append('application_type_id', application_type);

    try {
      const response = await axiosClient.post(apiURL.applyForThisStudent, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IDeleteStudent extends IActionCallback {
  id: string;
}
export const deleteStudent = createAsyncThunk(
  ActionTypes.DELETE_STUDENT,
  async (data: IDeleteStudent, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess, onError } = data;
    try {
      const response = await axiosClient.delete(apiURL.deleteThisStudent.replace(':studentId', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

export const getStudentListExcelExport = createAsyncThunk(
  ActionTypes.GET_STUDENT_LIST_EXCEL_EXPORT,
  async (_, { dispatch }) => {
    dispatch(setSpinner(true));

    try {
      const response = await axiosClient.get(apiURL.getStudentListExcelExport, {
        responseType: 'blob', // Excel dosyasını bir blob olarak almak için responseType'u ayarlayın
      });

      // Blob'u bir URL'ye dönüştürme ve kullanıcıya indirme bağlantısı sunma
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // İçerik-dağıtımı başlığını ayrıştırma ve dosya adını alma
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'student-list-export.xlsx'; // Varsayılan dosya adı
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (fileNameMatch && fileNameMatch[1]) {
          // Eşleşme bulunursa ve grup 1 (dosya adı) mevcutsa
          filename = fileNameMatch[1].replace(/['"]/g, ''); // Alıntı işaretlerini kaldır
        }
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Bağlantıyı ve URL'yi temizleme
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch(setSpinner(false));
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
