import { createReducer } from '@reduxjs/toolkit';
import { IAdminWorker } from '../../types/admin';
import { getAdminWorkerList } from './actions';

interface AdminState {
  adminWorkerList: IAdminWorker[];
  pending: boolean;
  error: boolean;
}

const initialState: AdminState = {
  pending: false,
  error: false,
  adminWorkerList: [],
};

export const adminReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAdminWorkerList.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getAdminWorkerList.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getAdminWorkerList.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;
      state.adminWorkerList = payload.payload.data;
    });
});
