export enum ActionTypes {
  GET_APPLICATION_LIST = 'GET_APPLICATION_LIST',
  GET_APPLICATION = 'GET_APPLICATION',
  DELETE_APPLICATION = 'DELETE_APPLICATION',
  GET_APPLICATION_USERS = 'GET_APPLICATION_USERS',
  EDIT_APPLICATION = 'EDIT_APPLICATION',
  EDIT_DEADLINE_AND_PIN = 'EDIT_DEADLINE_AND_PIN',
  CREATE_APPLICATION = 'CREATE_APPLICATION',
  ADD_NOTE = 'ADD_NOTE',
  GET_TRACKING_CODE_DETAIL = 'GET_TRACKING_CODE_DETAIL',
  GET_APPLICATION_ZIP = 'GET_APPLICATION_ZIP',
  GET_APPLICATION_LIST_EXPORT = 'GET_APPLICATION_LIST_EXPORT',
  GET_APPLICATION_STATUS = 'GET_APPLICATION_STATUS',
  UPDATE_APPLICATION_STATUS = 'UPDATE_APPLICATION_STATUS',
}
