//import env from 'react-dotenv';

export const uniteamApiBase =
  process.env.REACT_APP_LOCAL === 'LOCAL'
    ? process.env.REACT_APP_LOCAL_URL
    : process.env.REACT_APP_URL;
const apiUrl = {
  login: uniteamApiBase + 'login',
  logout: uniteamApiBase + 'logout',
  forgotPassword: uniteamApiBase + 'reset-password',
  resetPassword: uniteamApiBase + 'reset-password',
  getUserProfile: uniteamApiBase + 'profile',
  setUserLanguage: uniteamApiBase + 'language',

  changePassword: uniteamApiBase + 'password-update',

  createStudent: uniteamApiBase + 'student',
  updateStudent: uniteamApiBase + 'student/:id',
  uploadFile: uniteamApiBase + 'file-upload',
  deleteFile: uniteamApiBase + 'file-remove/:id',

  getApplications: uniteamApiBase + 'applications',
  getApplication: uniteamApiBase + 'application/:id',
  deleteApplication: uniteamApiBase + 'application/:id',
  getApplicationUsers: uniteamApiBase + 'applications-tags-user-list/:id',
  editApplication: uniteamApiBase + 'application/:id',
  editDeadlineAndPin: uniteamApiBase + 'application/:applicationId/edit',
  createApplication: uniteamApiBase + 'application',
  uploadApplicationFiles: uniteamApiBase + 'application/:id/options-process',
  getApplicationZip: uniteamApiBase + 'application/:applicationId/zip-download',
  getApplicationListExport: uniteamApiBase + 'applications-export',
  getApplicationStatusList: uniteamApiBase + 'application-statuses',
  updateApplicationStatus: uniteamApiBase + 'application/status/:application_id',

  addNote: uniteamApiBase + 'application/:id/note',
  getMenuList: uniteamApiBase + 'dashboard/menu',

  createUniversity: uniteamApiBase + 'university',
  getUniversityDetail: uniteamApiBase + 'university/:id',
  updateUniversity: uniteamApiBase + 'university/:id',
  getAnnouncements: uniteamApiBase + 'university/:id/announcements',
  createAnnouncements: uniteamApiBase + 'university/:id/announcement',

  createDepartment: uniteamApiBase + 'university/department',
  updateDepartment: uniteamApiBase + 'university/department/:id',
  getDepartmentDetail: uniteamApiBase + 'university/department/:id',
  getDepartmentsExportWithFilter: uniteamApiBase + 'departments-export',

  getUniversities: uniteamApiBase + 'lookup-universities',
  getUniversityList: uniteamApiBase + 'universities',
  getCountries: uniteamApiBase + 'countries',
  getTurkeyCities: uniteamApiBase + 'cities/218',
  getFaculties: uniteamApiBase + 'faculties',
  getSemesters: uniteamApiBase + 'semesters',
  getApplicationTypes: uniteamApiBase + 'application-types',
  getLanguages: uniteamApiBase + 'languages',
  getGenders: uniteamApiBase + 'genders',
  getPrograms: uniteamApiBase + 'programs',
  getInterviewTypes: uniteamApiBase + 'meets/types',
  getInterviewStatusList: uniteamApiBase + 'meets/details/status',

  getDepartments: uniteamApiBase + 'departments',
  getUniversityDepartments: uniteamApiBase + 'departments?per_page=1000&page=1&university_id=:id',
  getFacultyDepartments: uniteamApiBase + 'university-departments?',
  getUniversityFaculties: uniteamApiBase + 'lookup-faculties?university_id=:id',
  getUniversitiesExportWithFilter: uniteamApiBase + 'universities-export',

  getUsers: uniteamApiBase + 'lookup-users',
  getAdminUsers: uniteamApiBase + 'lookup-users/admin-admin-workers',

  getNotifications: uniteamApiBase + 'notifications?per_page=5&page=1',

  getStudentList: uniteamApiBase + 'students',
  getStudent: uniteamApiBase + 'student/:id',
  applyForThisStudent: uniteamApiBase + 'student/apply',
  deleteThisStudent: uniteamApiBase + 'student/:studentId',
  getStudentListExcelExport: uniteamApiBase + 'students-export',

  getUpcomingDeadlines: uniteamApiBase + 'upcoming-deadlines',
  getUpcomingDeadlinesExcel: uniteamApiBase + 'upcoming-deadlines-export',
  getExceededDeadlines: uniteamApiBase + 'exceeded-deadlines',
  getExceededDeadlinesExcel: uniteamApiBase + 'exceeded-deadlines-export',

  applyWithoutSession: uniteamApiBase + 'application',
  getTrackingCodeDetail: uniteamApiBase + 'apply/:id',

  getAdminList: uniteamApiBase + 'admins',
  getAdminWorkerList: uniteamApiBase + 'admin/worker-list',
  getAdmin: uniteamApiBase + 'admin/:adminId',
  createAdmin: uniteamApiBase + 'admin/create',
  updateAdmin: uniteamApiBase + 'admin/:adminId',
  deleteAdmin: uniteamApiBase + 'admin/:adminId',

  agencyList: uniteamApiBase + 'agency-list ',

  getAgencyList: uniteamApiBase + 'agencies',
  getAgency: uniteamApiBase + 'agency/:agencyId',
  createAgency: uniteamApiBase + 'agency',
  agencyUpdate: uniteamApiBase + 'agency/:agencyId',
  deleteAgency: uniteamApiBase + 'agency/:agencyId/worker/:workerId',

  getWorkerList: uniteamApiBase + 'agency-worker-list/:agencyId',
  getWorkerByAdmin: uniteamApiBase + 'agency/:agencyId/worker/:workerId',

  workerCreateByAdmin: uniteamApiBase + 'agency/:agencyId/worker',
  workerUpdateByAdmin: uniteamApiBase + 'agency/:agencyId/worker/:workerId',

  getDashboardStatics: uniteamApiBase + 'dashboard',
  getDashboardApplicationList: uniteamApiBase + 'dashboard/applications',
  getDashboardAnnouncementList: uniteamApiBase + 'dashboard/announcements',

  getNotification: uniteamApiBase + 'notifications',
  updateNotificationRead: uniteamApiBase + 'notification/reads',

  getWorkersByAgency: uniteamApiBase + 'agency-worker-list',
  workerCreateByAgency: uniteamApiBase + 'agency/worker',
  workerUpdateByAgency: uniteamApiBase + 'agency/worker/:workerId',
  getWorkerByAgencyUser: uniteamApiBase + 'agency/worker/:workerId',
  deleteWorkerByAgency: uniteamApiBase + 'agency/worker/:workerId',

  createFaculty: uniteamApiBase + 'faculty',
  getFacultyDetail: uniteamApiBase + 'faculty/:id',
  updateFaculty: uniteamApiBase + 'faculty/:id',
  deleteFaculty: uniteamApiBase + 'faculty/:id',
  getFacultyList: uniteamApiBase + 'faculties',
  getFacultiesByUniversity: uniteamApiBase + 'lookup-faculties?university_id=:university_id',

  preliminaryInterviewList: uniteamApiBase + 'meets',
  detailInterviewList: uniteamApiBase + 'user-meet-count-list',
  getInterviewTypeCount: uniteamApiBase + 'meet-type-count',
  createInterview: uniteamApiBase + 'meets',
  createInterviewWithoutAuth: uniteamApiBase + 'meet-apply',
  getInterviewDetail: uniteamApiBase + 'meets/:id',
  getTotalInterviewCount: uniteamApiBase + 'meet-status-count',
  getUserAndDateBasedTable: uniteamApiBase + 'meet-detail-status-count-list/:user_id',

  interviewUpdate: uniteamApiBase + 'meets/:id',
  checkPassportNumber: uniteamApiBase + 'meet-passport-check/:id',
  createInterviewMeetingNote: uniteamApiBase + 'meets/details/:id',
  appointInterviewToAdminWorker: uniteamApiBase + 'meets-appointed/:id',
  getCurrency: uniteamApiBase + 'currencies',
};

export default apiUrl;
