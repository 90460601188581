import { createReducer } from '@reduxjs/toolkit';
import {
  IConstantFullNodesWareHouses,
  IConstantTypes,
  ICountry,
  INotificationResponseData,
  INotificationResults,
} from '../../types/constants';
import {
  getCountryList,
  getSemesterList,
  getPrograms,
  getUniversities,
  getUsers,
  getGenders,
  getLanguages,
  getApplicationTypes,
  getNotifications,
  getTurkeyCities,
  getFaculties,
  getInterviewTypes,
  getInterviewStatusList,
  getAdminAndAdminWorkerUsers,
  getCurrency,
} from './actions';
import { getApplicationList } from '../application';

export type ConstantState = {
  countryList: ICountry[];
  applicationTypeList: IConstantTypes[];
  genderList: IConstantTypes[];
  languageList: IConstantTypes[];
  programList: IConstantTypes[];
  semesterList: IConstantTypes[];
  departmentList: IConstantTypes[];
  universityList: IConstantTypes[];
  turkeyCities: IConstantTypes[];
  facultyList: IConstantTypes[];
  interviewTypeList: IConstantTypes[];
  interviewStatusList: IConstantTypes[];
  positions: string[];
  userList: any[];
  userAdminList: any[];
  notifications: INotificationResponseData;
  currency: IConstantTypes[];
  pending: boolean;
  error: boolean;
};

const initialState: ConstantState = {
  applicationTypeList: [],
  languageList: [],
  genderList: [],
  countryList: [],
  turkeyCities: [],
  facultyList: [],
  semesterList: [],
  programList: [],
  departmentList: [],
  universityList: [],
  interviewTypeList: [],
  interviewStatusList: [],
  positions: [],
  userList: [],
  userAdminList: [],
  currency: [],
  notifications: {
    data: [], // INotificationResults tipinde boş bir dizi
    meta: {
      total: 0,
      count: 0,
      per_page: 0,
      current_page: 0,
      total_pages: 0,
      prev_page_url: null,
      next_page_url: null,
      path: null,
    },
  },
  pending: false,
  error: false,
};

export const constantsReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(getCountryList.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getCountryList.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getCountryList.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      // const responce = payload.payload as unknown as ConstantResponse;
      // if (responce.status === 200) state.cityList = responce;

      state.countryList = payload.payload.data;
    })
    .addCase(getSemesterList.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getSemesterList.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getSemesterList.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      // const responce = payload.payload as unknown as ConstantResponse;
      // if (responce.status === 200) state.cityList = responce;
      state.semesterList = payload.payload.data;
    })
    .addCase(getPrograms.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getPrograms.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getPrograms.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;

      state.programList = payload.payload.data;
    })
    .addCase(getUniversities.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getUniversities.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getUniversities.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;

      state.universityList = payload.payload.data;
    })
    .addCase(getGenders.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getGenders.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getGenders.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;

      state.genderList = payload.payload.data;
    })
    .addCase(getLanguages.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getLanguages.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getLanguages.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;

      state.languageList = payload.payload.data;
    })
    .addCase(getApplicationTypes.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getApplicationTypes.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getApplicationTypes.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;

      state.applicationTypeList = payload.payload.data;
    })

    .addCase(getUsers.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getUsers.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getUsers.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;

      state.userList = payload.payload.data;
    })
    .addCase(getNotifications.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getNotifications.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getNotifications.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;

      state.notifications = payload.payload.data;
    })
    .addCase(getTurkeyCities.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getTurkeyCities.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getTurkeyCities.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;

      state.turkeyCities = payload.payload.data;
    })
    .addCase(getFaculties.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getFaculties.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getFaculties.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;
      state.facultyList = payload.payload.data.data;
    })
    .addCase(getInterviewTypes.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getInterviewTypes.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getInterviewTypes.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;
      state.interviewTypeList = payload.payload.data;
    })
    .addCase(getInterviewStatusList.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getInterviewStatusList.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getInterviewStatusList.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;
      state.interviewStatusList = payload.payload.data;
    })
    .addCase(getAdminAndAdminWorkerUsers.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getAdminAndAdminWorkerUsers.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getAdminAndAdminWorkerUsers.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;
      state.userAdminList = payload.payload.data;
    })
    .addCase(getCurrency.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getCurrency.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getCurrency.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      //   const response = payload.payload as unknown as ConstantResponse;
      //   if (response.status === 200) state.advertisementList = payload;
      state.currency = payload.payload.data;
    });
});
