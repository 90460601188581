import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from './actions-types';
import { IActionCallback } from '../types';
import { apiURL, axiosClient } from '../../service';
import { notifyError } from '../../helpers/notify';
import { setSpinner } from '../spinner';

interface INotificationReadUpdate extends IActionCallback {
  notifyIds: string[];
}

export const updateNotificationRead = createAsyncThunk(
  ActionTypes.UPDATE_NOTIFICATION_READ,
  async (data: INotificationReadUpdate, { dispatch }) => {
    dispatch(setSpinner(true));
    const { notifyIds, onSuccess } = data;

    try {
      const response = await axiosClient.put(apiURL.updateNotificationRead, {
        notifyIds: notifyIds,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
