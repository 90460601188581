import React, { useEffect, useState } from 'react';
import { Avatar, Button, Dropdown, Menu, Tooltip } from 'antd';
import { UserOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import styles from './uniteamHeaderProfileDropdown.module.scss';
import { useNavigate } from 'react-router-dom';
import { languageSelector } from '../../store/language';
import { useAppSelector } from '../../store/hooks';
import { getLocalString } from '../../helpers/language';

const add3Dot = (input: string, maxLen: number = 16) => {
  if (!input) return '';

  if (input.length <= maxLen) return input;

  return input.slice(0, maxLen) + '...';
};

interface IProps {
  profileInfo?: {
    id: number;

    username: string;
    email: string;
    phone: string | null;
    status: boolean;
    email_verified_at: boolean | null;

    name: string;
    surname: string;
    avatar: string;

    role: {
      description: string;
      id: number;
      name: string;
      slug: string;
    };
  };
  logoutHandler: () => void;
  // changeCompanyProfile: (profileId: string) => void;
}

const UniteamHeaderProfileDropdown: React.FC<IProps> = ({
  profileInfo,
  logoutHandler,
  // changeCompanyProfile,
}) => {
  const name = `${profileInfo?.name} ${profileInfo?.surname}`;
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const navigate = useNavigate();

  return (
    <>
      <Tooltip
        title={getLocalString(translations, 'Profile.MyAccount')}
        showArrow={false}
        placement='bottom'
      >
        <Dropdown
          trigger={['click']}
          dropdownRender={() => (
            <div className={styles.login}>
              <div className={styles.login__first}>
                {name ? (
                  <>
                    <div>
                      {/* <Avatar
                        icon={!profileInfo?.avatar && <UserOutlined />}
                        shape='square'
                        size={64}
                        src={profileInfo?.avatar}
                      /> */}
                    </div>
                    <div className={styles.login__first_info}>
                      <div className={styles.login__first_info_name}>
                        {profileInfo?.name} {profileInfo?.surname}
                      </div>
                      <div
                        style={{ marginBottom: '5px' }}
                        className={styles.login__first_info_company}
                      >
                        {profileInfo?.role ? profileInfo?.role.name : ''}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      href='/login'
                      type='primary'
                      size='large'
                      block
                      ghost={false}
                      danger={false}
                    >
                      Sign In / Register
                    </Button>
                  </>
                )}
              </div>
              {/* {login ? (
                profiles?.length > 1 && (
                  <div className={styles.login__second}>
                    <div className={styles.login__second_text}>Profiles</div>
                    {profiles
                      //.filter((el) => !el.isCurrent)
                      .map((el, i) => {
                        return (
                          <div
                            key={`${i}-${el.companyName}`}
                            className={styles.login__second_up}
                            onClick={() => changeCompanyProfile(el.profileId)}
                          >
                            <div className={styles.login__second_up_avatar}>
                              {el.companyName?.[0] || ''}
                            </div>
                            <div className={styles.login__second_up_info}>
                              <div className={styles.login__second_up_info_name}>
                                {el.companyName}
                              </div>
                              <div className={styles.login__second_up_info_company}>
                                {el.roleName}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )
              ) : (
                <div className={styles.login__third}>
                  <div className={styles.login__third_menu}>
                    <div className={styles.login__third_menu_item}> Products</div>
                    <div className={styles.login__third_menu_item}>User Plans</div>
                  </div>
                </div>
              )} */}
              {/* <div className={styles.login__third}>
                <div className={styles.login__third_menu}>
                  <div className={styles.login__third_menu_item}>My Orders</div>
                  <div className={styles.login__third_menu_item}>Favorites & Collections</div>
                  <div className={styles.login__third_menu_item}>Questions & Comments</div>
                  <div className={styles.login__third_menu_item}> Pay</div>
                  <div
                    className={styles.login__third_menu_item}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_IDENTITY_UI_URL}account-profile`,
                        '_self'
                      )
                    }
                  >
                    My Account
                  </div>
                </div>
              </div> */}
              <div onClick={() => navigate('/my-profile')} className={styles.login__fourth}>
                {getLocalString(translations, 'Profile.MyProfile')}
              </div>
              <div onClick={() => logoutHandler()} className={styles.login__fourth}>
                {getLocalString(translations, 'Profile.LogOut')}
              </div>
            </div>
          )}
          placement='bottomRight'
          arrow
        >
          <Button type='text' icon={<UserOutlined />} size='large' />
        </Dropdown>
      </Tooltip>
    </>
  );
};

export default UniteamHeaderProfileDropdown;
