import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Input } from 'antd';
import { getLocalString } from '../../helpers/language';
import { useAppSelector } from '../../store/hooks';
import { languageSelector } from '../../store/language';

import { SearchOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

interface ISearchProps {
  value: string;
  onChange: (value: string) => void;
}

const Search: React.FC<ISearchProps> = ({ value, onChange }) => {
  const [searchText, setSearchText] = useState(value);

  const { appLanguage, translations } = useAppSelector(languageSelector);
  const timeoutId = useRef<any>();
  const handleChange = useCallback((value: string) => {
    setSearchText(value);
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => onChange(value), 500);
  }, []);

  useEffect(() => setSearchText(value), [value]);
  return (
    <div className={styles.custom_search_div}>
      <Input
        // size='large'
        prefix={<SearchOutlined style={{ color: '#B3B9C1' }} />}
        placeholder={'Search'}
        value={searchText}
        onChange={(e) => handleChange(e.target.value)}
        // bordered={false}
        className={styles.custom_search}
      />
    </div>
  );
};

export default Search;
