import { createReducer } from '@reduxjs/toolkit';
import { IApplicationList } from '../../types/application';
import { getApplicationStatus } from './actions';
import { IConstantTypes } from '../../types/constants';

interface ApplicationState {
  applicationStatus: IConstantTypes[];
  pending: boolean;
  error: boolean;
}

const initialState: ApplicationState = {
  applicationStatus: [],
  pending: false,
  error: false,
};

export const applicationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getApplicationStatus.pending, (state) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getApplicationStatus.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(getApplicationStatus.fulfilled, (state, payload) => {
      state.pending = false;
      state.error = false;
      state.applicationStatus = payload.payload.data;
    });
});
