import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from './actions-types';
import { IActionCallback } from '../types';
import { apiURL, axiosClient } from '../../service';
import { notifyError } from '../../helpers/notify';
import { setSpinner } from '../spinner';

interface ICreateUniversityInput extends IActionCallback {
  name: string;
  logo?: any;
  city_id: string;
  catalog?: any;
  content?: string;
}
export const createUniversity = createAsyncThunk(
  ActionTypes.CREATE_UNIVERSITY,
  async (data: ICreateUniversityInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { name, city_id, logo, catalog, content, onSuccess } = data;

    let formData = new FormData();
    formData.append('name', name);

    if (logo) formData.append('logo', logo);
    if (catalog) formData.append('catalog', catalog);
    if (content) formData.append('content', content);
    if (city_id) formData.append('city_id', city_id);

    try {
      const response = await axiosClient.post(apiURL.createUniversity, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IGetUniversityDetail extends IActionCallback {
  id: string;
}
export const getUniversityDetail = createAsyncThunk(
  ActionTypes.GET_UNIVERSITY_DETAIL,
  async (data: IGetUniversityDetail, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getUniversityDetail.replace(':id', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IGetAnnouncements extends IActionCallback {
  id: string;
  filter?: { page?: number; per_page?: number };
}

export const getAnnouncements = createAsyncThunk(
  ActionTypes.GET_ANNOUNCEMENTS,
  async (
    { filter = { page: 1, per_page: 1000 }, id, onSuccess }: IGetAnnouncements,
    { dispatch }
  ) => {
    try {
      dispatch(setSpinner(true));
      const response = await axiosClient.get(apiURL.getAnnouncements.replace(':id', id), {
        params: { ...filter },
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface ICreateAnnouncement extends IActionCallback {
  id: string;
  title: string;
  description: string;
}
export const createAnnouncement = createAsyncThunk(
  ActionTypes.CREATE_ANNOUNCEMENT,
  async (data: ICreateAnnouncement, { dispatch }) => {
    dispatch(setSpinner(true));
    const { title, description, id, onSuccess } = data;

    let formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);

    try {
      const response = await axiosClient.post(
        apiURL.createAnnouncements.replace(':id', id),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IUpdateUniversityInput extends IActionCallback {
  id: string;
  name: string;
  content?: string;
  city_id: string;
}
export const updateUniversity = createAsyncThunk(
  ActionTypes.UPDATE_UNIVERSITY,
  async (data: IUpdateUniversityInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, name, city_id, content, onSuccess } = data;

    try {
      const response = await axiosClient.put(apiURL.updateUniversity.replace(':id', id), {
        name,
        content,
        city_id,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface ICreateDepartmentInput extends IActionCallback {
  university_id: string;

  faculty_id: string;
  name: string;
  language_id: string;
  fee: string;
  discount: string;
  cash_discount: string;
  program_id: string;
  currency: number;
  price_year_start?: any;
  price_year_end?: any;
  note?: string;
}
export const createDepartment = createAsyncThunk(
  ActionTypes.CREATE_DEPARTMENT,
  async (data: ICreateDepartmentInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      university_id,
      name,
      language_id,
      fee,
      faculty_id,
      discount,
      cash_discount,
      program_id,
      price_year_start,
      price_year_end,
      note,
      currency,
      onSuccess,
    } = data;

    try {
      const response = await axiosClient.post(apiURL.createDepartment, {
        university_id,
        name,
        language_id,
        fee,
        faculty_id,
        discount,
        cash_discount,
        program_id,
        price_year_start,
        price_year_end,
        note,
        currency,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IGetDepartmentDetail extends IActionCallback {
  id: string;
}
export const getDepartmentDetail = createAsyncThunk(
  ActionTypes.GET_DEPARTMENT_DETAIL,
  async (data: IGetDepartmentDetail, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getDepartmentDetail.replace(':id', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IUpdateDepartmentInput extends IActionCallback {
  university_id: string;
  department_id: string;
  name: string;
  language_id: string;
  fee: string;
  discount: string;
  cash_discount: string;
  program_id: string;
  faculty_id: string;
  note: string;
  currency: number;
  price_year_start: number;
  price_year_end: number;
  is_status: number;
}
export const updateDepartment = createAsyncThunk(
  ActionTypes.UPDATE_DEPARTMENT,
  async (data: IUpdateDepartmentInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const {
      university_id,
      department_id,
      name,
      language_id,
      fee,
      discount,
      cash_discount,
      program_id,
      note,
      price_year_start,
      price_year_end,
      is_status,
      faculty_id,
      currency,
      onSuccess,
    } = data;

    try {
      const response = await axiosClient.put(
        apiURL.updateDepartment.replace(':id', department_id),
        {
          name,
          language_id,
          fee,
          faculty_id,
          discount,
          cash_discount,
          program_id,
          note,
          price_year_start,
          price_year_end,
          is_status,
          university_id,
          currency,
        }
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

export const getDepartmentsExportWithFilter = createAsyncThunk(
  ActionTypes.DEPARTMENT_EXPORT_EXCEL_WITH_FILTER,
  async (filters: any, { dispatch }) => {
    dispatch(setSpinner(true));

    try {
      // API isteği için filtreleri query parametrelerine dönüştürme
      const queryParams = new URLSearchParams(filters).toString();

      const response = await axiosClient.get(
        `${apiURL.getDepartmentsExportWithFilter}?${queryParams}`,
        {
          responseType: 'blob',
        }
      );

      // Blob'u bir URL'ye dönüştürme ve kullanıcıya indirme bağlantısı sunma
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // İçerik-dağıtımı başlığını ayrıştırma ve dosya adını alma
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'departments_list.xlsx'; // Varsayılan dosya adı
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (fileNameMatch && fileNameMatch[1]) {
          filename = fileNameMatch[1].replace(/['"]/g, ''); // Alıntı işaretlerini kaldır
        }
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Bağlantıyı ve URL'yi temizleme
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch(setSpinner(false));
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

export const getUniversitiesExportWithFilter = createAsyncThunk(
  ActionTypes.UNIVERSITY_EXPORT_EXCEL_WITH_FILTER,
  async (filters: any, { dispatch }) => {
    dispatch(setSpinner(true));

    try {
      // API isteği için filtreleri query parametrelerine dönüştürme
      const queryParams = new URLSearchParams(filters).toString();

      const response = await axiosClient.get(
        `${apiURL.getUniversitiesExportWithFilter}?${queryParams}`,
        {
          responseType: 'blob',
        }
      );

      // Blob'u bir URL'ye dönüştürme ve kullanıcıya indirme bağlantısı sunma
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // İçerik-dağıtımı başlığını ayrıştırma ve dosya adını alma
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'universities_list.xlsx'; // Varsayılan dosya adı
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (fileNameMatch && fileNameMatch[1]) {
          filename = fileNameMatch[1].replace(/['"]/g, ''); // Alıntı işaretlerini kaldır
        }
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Bağlantıyı ve URL'yi temizleme
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch(setSpinner(false));
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
