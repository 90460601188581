import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, DatePicker } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import styles from './index.module.scss';

import PageContainer from '../../components/pageContainer';
import CustomizedTable from '../../components/CustomizedTable';
// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../components/Search';
import { menuSelector } from '../../store/menu';

import PageFilter from '../../components/pageFilter';
import {
  constantsSelector,
  getFacultyDepartments,
  getUniversityDepartments,
  getUniversityFaculties,
} from '../../store/constants';
import { apiURL } from '../../service';
import dayjs from 'dayjs';
import { notifyError } from '../../helpers/notify';
import { getLocalString } from '../../helpers/language';

import { getUpcomingDeadlines } from '../../store/deadlines';
import { useNavigate } from 'react-router-dom';
import { IDepartmentTypes } from '../../types/constants';
import { languageSelector } from '../../store/language';

const { Option } = Select;
const { RangePicker } = DatePicker;

const UpcomingDeadlinesList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { universityList, programList } = useAppSelector(constantsSelector);
  const [nodeId, setNodeId] = useState<string>('');

  const [roleId, setRoleId] = useState<string>('');
  const [departmentId, setDepartmentId] = useState<string>('');
  const [universityId, setUniversityId] = useState<string>('');
  const [deadlineStart, setDeadlineStart] = useState<number>();
  const [deadlineEnd, setDeadlineEnd] = useState<number>();
  const [status, setStatus] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);
  const [departmentList, setDepartmentList] = useState<any>([]);

  const [filters, setFilters] = useState<object>();
  const [facultyId, setFacultyId] = useState<string>('');
  const [facultyList, setFacultyList] = useState<IDepartmentTypes[]>([]);

  useEffect(() => {
    if (universityId)
      dispatch(
        getUniversityFaculties({
          universityID: universityId,
          onSuccess: (data: any) => {
            setFacultyList(data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  }, [universityId]);
  useEffect(() => {
    if (universityId)
      dispatch(
        getFacultyDepartments({
          faculty_id: facultyId,
          onSuccess: (data: any) => {
            setDepartmentList(data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  }, [facultyId]);

  const renderUniversityOptions = useCallback(
    () => universityList.map((el: any) => ({ value: el.id, label: el.name })),
    [universityList]
  );

  const renderDepartmentOptions = useCallback(
    () => departmentList.map((el: any) => ({ value: el.id, label: el.name })),
    [departmentList]
  );
  const renderFacultyOptions = useCallback(
    () => facultyList.map((el: any) => ({ value: el.id, label: el.name })),
    [facultyList]
  );
  useEffect(() => {
    let filter: any = {};
    let filterCount = 0;
    if (universityId) {
      filter.university_id = universityId;
      filterCount++;
    }
    if (facultyId) {
      filter.faculty_id = facultyId;
      filterCount++;
    }
    if (departmentId) {
      filter.department_id = departmentId;
      filterCount++;
    }
    if (deadlineStart) {
      filter.deadline_to = deadlineStart;
      filterCount++;
    }
    if (deadlineEnd) {
      filter.deadline_from = deadlineEnd;
      filterCount++;
    }

    if (searchText) {
      filter.search_text = searchText;
    }
    setFilterCount(filterCount);
    setFilters(filter);
  }, [searchText, departmentId, facultyId, universityId, deadlineEnd, deadlineStart]);

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: getLocalString(translations, 'UpcomingDeadlines.Name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: getLocalString(translations, 'UpcomingDeadlines.Phone'),

      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: getLocalString(translations, 'UpcomingDeadlines.University'),

      dataIndex: 'university',
      key: 'university',
    },
    {
      title: getLocalString(translations, 'UpcomingDeadlines.Department'),

      dataIndex: 'department',
      key: 'department',
    },
    // {
    //   title: 'By',
    //   dataIndex: 'responsible',
    //   key: 'responsible',
    // },

    // {
    //   title: 'Pin Code',
    //   dataIndex: 'pin',
    //   key: 'pin',
    // },
    {
      title: getLocalString(translations, 'UpcomingDeadlines.Deadline'),

      dataIndex: 'deadline',
      key: 'deadline',
      render: (record: any) => {
        return dayjs(record).format('YYYY-MM-DD');
      },
    },
  ];

  return (
    <PageContainer
      pageName={getLocalString(translations, 'UpcomingDeadlines.UpcomingDeadlines')}
      extra={
        <Button type='primary' onClick={() => dispatch(getUpcomingDeadlines())} size='large'>
          {getLocalString(translations, 'UpcomingDeadlines.ExportExcel')}
        </Button>
      }
    >
      <PageFilter
        count={filterCount}
        clear={() => {
          setUniversityId('');
          setFacultyId('');
          setDepartmentId('');
          setDeadlineEnd(undefined);
          setDeadlineStart(undefined);
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={6}>
            <Select
              style={{ width: '100%' }}
              value={universityId || undefined}
              placeholder={getLocalString(translations, 'UpcomingDeadlines.SelectUniversity')}
              onChange={(value) => {
                setUniversityId(value);
                setFacultyId('');
                setDepartmentId('');
              }}
              allowClear
              showSearch
              filterOption={filterOption}
              options={renderUniversityOptions()}
            ></Select>
          </Col>
          <Col span={6}>
            <Select
              disabled={!universityId}
              style={{ width: '100%' }}
              value={facultyId || undefined}
              placeholder={getLocalString(translations, 'UpcomingDeadlines.SelectFaculty')}
              onChange={(value) => {
                setFacultyId(value);
                setDepartmentId('');
              }}
              showSearch
              filterOption={filterOption}
              options={renderFacultyOptions()}
            ></Select>
          </Col>
          <Col span={6}>
            <Select
              style={{ width: '100%' }}
              value={departmentId || undefined}
              disabled={!facultyId}
              placeholder={getLocalString(translations, 'UpcomingDeadlines.SelectDepartment')}
              onChange={setDepartmentId}
              allowClear
              showSearch
              filterOption={filterOption}
              options={renderDepartmentOptions()}
            ></Select>
          </Col>

          <Col span={6}>
            <RangePicker
              style={{ width: '100%' }}
              format={'YYYY-MM-DD'}
              value={
                deadlineStart && deadlineEnd
                  ? [dayjs(deadlineStart), dayjs(deadlineEnd)]
                  : undefined
              }
              onChange={(dates: any) => {
                setDeadlineStart(dates ? dates[0].format('YYYY-MM-DD') : null);
                setDeadlineEnd(dates ? dates[1].format('YYYY-MM-DD') : null);
              }}
            />
          </Col>
        </Row>
      </PageFilter>
      <Row style={{ marginTop: '24px' }}>
        <Row style={{ marginBottom: '24px' }}>
          <Search value={searchText} onChange={(value) => setSearchText(value)} />
        </Row>

        <Row>
          <CustomizedTable
            columns={columns}
            data={[]}
            url={apiURL.getUpcomingDeadlines}
            filter={filters}
            actions={[
              {
                action: (record) => {
                  navigate('/applies-detail/' + record.id);
                },
                type: 'detail',
                // hidden: (record: any) => record.status === true,
              },
            ]}
          />
        </Row>
      </Row>
    </PageContainer>
  );
};

export default UpcomingDeadlinesList;
