import React, { useEffect, useState } from 'react';

import { Button, Menu, Popover, Tooltip } from 'antd';

import styles from './uniteamSideBar.module.scss';
import { IProps } from './uniteamSideBar-type';
import { useAppSelector } from '../../store/hooks';
import { menuSelector } from '../../store/menu';

const UniteamSideBar: React.FC<IProps> = ({ menuItems }) => {
  const { id, activeSubMenu } = useAppSelector(menuSelector);

  const [expandMenuStatus, setExpandMenuStatus] = useState<string>(
    localStorage.getItem('expandMenuStatus') || 'false'
  );

  useEffect(() => {
    localStorage.setItem('expandMenuStatus', expandMenuStatus);
  }, [expandMenuStatus, localStorage.getItem('expandMenuStatus')]);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    // console.log('getCurrentDimension()', getCurrentDimension());
    if (screenSize.width < 1200) {
      setExpandMenuStatus('true');
    } else {
      setExpandMenuStatus('false');
    }

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return (
    <aside
      className={`${styles.sidebar} ${expandMenuStatus === 'true' && styles.sidebarCollapsedWidth}`}
    >
      {expandMenuStatus === 'false' && (
        <Popover content='Narrow Menu' overlayClassName='custom_popover-dark' placement='bottom'>
          <Button
            shape='circle'
            className={styles.sidebarMenuBtn}
            size='small'
            icon={
              <svg
                style={{ fontSize: 0, lineHeight: 1 }}
                width='7'
                height='12'
                viewBox='0 0 7 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6.20858 1.41058V0.202767C6.20858 0.0980799 6.1223 0.0402674 6.06404 0.10433L1.01307 5.60589C0.97016 5.65243 0.935435 5.71203 0.911548 5.78013C0.887661 5.84824 0.875244 5.92305 0.875244 5.99886C0.875244 6.07467 0.887661 6.14949 0.911548 6.21759C0.935435 6.28569 0.97016 6.34529 1.01307 6.39183L6.06404 11.8934C6.12342 11.9575 6.20858 11.8996 6.20858 11.795V10.5871C6.20858 10.5106 6.18281 10.4371 6.14023 10.3903L2.10663 5.99964L6.14023 1.60745C6.18281 1.56058 6.20858 1.48714 6.20858 1.41058Z'
                  fill='currentColor'
                />
              </svg>
            }
            onClick={() => setExpandMenuStatus('true')}
          />
        </Popover>
      )}
      <div className={styles.sidebarInner}>
        {expandMenuStatus === 'true' && (
          <Tooltip title='Expand Menu' placement='right'>
            <Button
              className={styles.sidebarMenuBtn2}
              size='large'
              type='text'
              icon={
                <svg
                  width='7'
                  height='12'
                  viewBox='0 0 7 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M5.91424 5.60625L0.863288 0.104687C0.85009 0.090197 0.834228 0.0811919 0.817525 0.0787061C0.800822 0.0762204 0.783955 0.0803547 0.768861 0.0906345C0.753768 0.100914 0.741059 0.116922 0.732195 0.136821C0.723331 0.156719 0.718671 0.1797 0.718751 0.203125V1.41094C0.718751 1.4875 0.744521 1.56094 0.787098 1.60781L4.82069 6L0.787098 10.3922C0.743401 10.4391 0.718751 10.5125 0.718751 10.5891V11.7969C0.718751 11.9016 0.805025 11.9594 0.863288 11.8953L5.91424 6.39375C5.95717 6.34705 5.99189 6.28731 6.01578 6.21908C6.03967 6.15085 6.05208 6.07593 6.05208 6C6.05208 5.92407 6.03967 5.84915 6.01578 5.78092C5.99189 5.71269 5.95717 5.65295 5.91424 5.60625Z'
                    fill='#010E1C'
                  />
                </svg>
              }
              onClick={() => setExpandMenuStatus('false')}
            />
          </Tooltip>
        )}
        <Menu
          mode='inline'
          selectedKeys={[id.toString()]}
          inlineCollapsed={expandMenuStatus === 'true'}
          items={menuItems}
          className={styles.sidebarComp}
          // style={{ backgroundColor: '#f3426d' }}
        />
      </div>
    </aside>
  );
};

export default UniteamSideBar;
