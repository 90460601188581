import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiURL, axiosClient } from '../../service';
import { IMenuAuth } from '../../types/menu';
import { ActionTypes } from './action-types';
import QueryString from 'qs';
import { notifyError } from '../../helpers/notify';

interface ISetMenu extends IMenuAuth {
  mainMenu: string;
  subMenu: string;
}

export const setActiveMenu = createAction(
  ActionTypes.SET_ACTIVE_PAGE,
  function prepare(menu: ISetMenu) {
    return {
      payload: {
        menu,
      },
    };
  }
);

export const getMenuList = createAsyncThunk(ActionTypes.GET_MENU_LIST, async () => {
  try {
    const response = await axiosClient.get(apiURL.getMenuList);
    return response.data;
  } catch (error) {
    console.log('error', error);
  }
});
