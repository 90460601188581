import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from './actions-types';
import { IActionCallback } from '../types';
import { apiURL, axiosClient } from '../../service';
import { notifyError } from '../../helpers/notify';
import { setSpinner } from '../spinner';
import qs from 'qs';
interface IInterviewCreateInputs extends IActionCallback {
  name: string;
  surname: string;
  phone: string;
  phone_code: string;
  email: string;
  type: number;
  adv?: string;
}
export const createInterview = createAsyncThunk(
  ActionTypes.CREATE_INTERVIEW,
  async (data: IInterviewCreateInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const { name, surname, phone, phone_code, email, type, adv, onSuccess } = data;

    try {
      const response = await axiosClient.post(apiURL.createInterview, {
        name,
        surname,
        phone,
        phone_code,
        email,
        type,
        adv,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
export const createInterviewWithoutAuth = createAsyncThunk(
  ActionTypes.CREATE_INTERVIEW_WITH_ADV,
  async (data: IInterviewCreateInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const { name, surname, phone, email, type, adv, onSuccess } = data;

    try {
      const response = await axiosClient.post(apiURL.createInterviewWithoutAuth, {
        name,
        surname,
        phone,
        email,
        type,
        adv,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IGetInterviewInput extends IActionCallback {
  id: string;
}

export const getInterviewDetail = createAsyncThunk(
  ActionTypes.GET_INTERVIEW_DETAIL,
  async (data: IGetInterviewInput, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, onSuccess } = data;
    try {
      const response = await axiosClient.get(apiURL.getInterviewDetail.replace(':id', id));
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data);
    } catch (error: any) {
      dispatch(setSpinner(false));
      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IInterviewUpdateInputs extends IActionCallback {
  id: string;
  name: string;
  surname: string;
  phone: string;
  phone_code: string;
  email: string;
  passport_no: string;
  type: number;
}
export const interviewUpdate = createAsyncThunk(
  ActionTypes.UPDATE_INTERVIEW,
  async (data: IInterviewUpdateInputs, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, name, surname, phone, phone_code, passport_no, email, type, onSuccess } = data;

    try {
      const response = await axiosClient.put(apiURL.interviewUpdate.replace(':id', id), {
        name,
        surname,
        phone,
        phone_code,
        email,
        passport_no,
        type,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface ICheckPassportNumber extends IActionCallback {
  id: string;
  passport_no: string;
}
export const checkPassportNumber = createAsyncThunk(
  ActionTypes.CHECK_PASSPORT_NO,
  async (data: ICheckPassportNumber, { dispatch }) => {
    dispatch(setSpinner(true));
    const { passport_no, id, onSuccess, onError } = data;
    try {
      const response = await axiosClient.put(apiURL.checkPassportNumber.replace(':id', id), {
        passport_no,
      });
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response);
    } catch (error: any) {
      dispatch(setSpinner(false));
      if (onError) onError(error);
      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı

        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface IAppointedAdminWorker extends IActionCallback {
  id: string;
  user_id: string;
}
export const appointInterviewToAdminWorker = createAsyncThunk(
  ActionTypes.APPOINTED_ADMIN_WORKER,
  async (data: IAppointedAdminWorker, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, user_id, onSuccess } = data;
    try {
      const response = await axiosClient.put(
        apiURL.appointInterviewToAdminWorker.replace(':id', id),

        { user_id }
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
interface ICreateMeetingNote extends IActionCallback {
  id: string;
  content?: string;
  status: string;
}
export const createInterviewMeetingNote = createAsyncThunk(
  ActionTypes.CREATE_MEETING_NOTE,
  async (data: ICreateMeetingNote, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, content, status, onSuccess } = data;
    try {
      const requestBody: { status: string; content?: string } = { status };
      if (content) {
        requestBody.content = content;
      }
      const response = await axiosClient.post(
        apiURL.createInterviewMeetingNote.replace(':id', id),

        requestBody
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response);
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
export const detailInterviewList = createAsyncThunk(
  ActionTypes.GET_USER_INTERVIEW_DETAIL,
  async (data: IActionCallback, { dispatch }) => {
    dispatch(setSpinner(true));
    const { onSuccess, onError } = data;
    try {
      const response = await axiosClient.get(apiURL.detailInterviewList);
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data.data.data);
      return response.data.data.data;
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
export const getInterviewTypeCount = createAsyncThunk(
  ActionTypes.GET_INTERVIEW_TYPE_COUNT,
  async (data: IActionCallback, { dispatch }) => {
    dispatch(setSpinner(true));
    const { onSuccess, onError } = data;
    try {
      const response = await axiosClient.get(apiURL.getInterviewTypeCount);
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data.data);
      return response.data.data.data;
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
export const getTotalInterviewCount = createAsyncThunk(
  ActionTypes.GET_TOTAL_INTERVIEW_COUNT,
  async (data: IActionCallback, { dispatch }) => {
    dispatch(setSpinner(true));
    const { onSuccess, onError } = data;
    try {
      const response = await axiosClient.get(apiURL.getTotalInterviewCount);
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data.data);
      return response.data.data;
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);

interface IGetUserAndDateBasedTable extends IActionCallback {
  id: string;
  start_date: number;
  end_date: number;
}
export const getUserAndDateBasedTable = createAsyncThunk(
  ActionTypes.GET_TOTAL_INTERVIEW_COUNT,
  async (data: IGetUserAndDateBasedTable, { dispatch }) => {
    dispatch(setSpinner(true));
    const { id, end_date, start_date, onSuccess, onError } = data;
    try {
      const response = await axiosClient.get(
        apiURL.getUserAndDateBasedTable.replace(':user_id', id) +
          '?' +
          qs.stringify({ start_date, end_date })
      );
      dispatch(setSpinner(false));
      if (onSuccess) onSuccess(response.data.data);
      return response.data.data;
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
