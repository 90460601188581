import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Input, Card, Form } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import styles from './index.module.scss';
import globalStyles from '../../styles/global.module.scss';

import PageContainer from '../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../components/Search';
import { menuSelector } from '../../store/menu';
import { getLocalString } from '../../helpers/language';
import { languageSelector } from '../../store/language';
import PageFilter from '../../components/pageFilter';
import { constantsSelector } from '../../store/constants';
import { apiURL } from '../../service';
import { accountSelector, changePassword } from '../../store/account';
import { notifySuccess } from '../../helpers/notify';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const MyProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { account } = useAppSelector(accountSelector);

  const [nodeId, setNodeId] = useState<string>('');
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [roleId, setRoleId] = useState<string>('');

  const [status, setStatus] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);

  const [filters, setFilters] = useState<object>();
  const passwordRules = [
    {
      required: true,
      message: getLocalString(translations, 'Login.NewPasswordModal.Message.PleaseEnterPassword'),
    },
    {
      pattern: new RegExp(
        '^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=[\\]{};:"\\\'`~,<>.\\/?]).{8,}$'
      ),
      message: getLocalString(translations, 'Login.NewPasswordModal.Message.RequireChar'),
    },
  ];

  useEffect(() => {
    // console.log('account', account);
  }, [account]);

  const onFinish = (values: any) => {
    dispatch(
      changePassword({
        password: values.oldPassword,
        new_password: values.newPassword,
        new_password_confirmation: values.confirmNewPassword,
        onSuccess: (data: any) => {
          notifySuccess(getLocalString(translations, 'Login.ResetPasswordModal.Message.Updated'));
        },
      })
    );
    // Burada şifre değiştirme işlemini gerçekleştirin.
  };

  return (
    <PageContainer pageName={getLocalString(translations, 'MyProfile.Title')}>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Card style={{ height: '100%' }}>
            <Row style={{ width: '100%' }} align={'middle'} justify={'space-between'}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'MyProfile.Name')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{account?.name}</Row>
            </Row>
            <Row style={{ width: '100%' }} align={'middle'} justify={'space-between'}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'MyProfile.Surname')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{account?.surname}</Row>
            </Row>
            <Row style={{ width: '100%' }} align={'middle'} justify={'space-between'}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {' '}
                {getLocalString(translations, 'MyProfile.Username')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{account?.username}</Row>
            </Row>
            <Row style={{ width: '100%' }} align={'middle'} justify={'space-between'}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'MyProfile.Email')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{account?.email}</Row>
            </Row>

            <Row style={{ width: '100%' }} align={'middle'} justify={'space-between'}>
              <Row className={`${globalStyles.body_r_large} ${styles.label_color}`}>
                {' '}
                {getLocalString(translations, 'MyProfile.Role')}
              </Row>
              <Row className={`${globalStyles.body_r_large} `}>{account?.role?.name}</Row>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card style={{ height: '100%' }}>
            <Form form={form} name='passwordChange' onFinish={onFinish} scrollToFirstError>
              <Form.Item
                name='oldPassword'
                label={getLocalString(translations, 'MyProfile.OldPassword')}
                rules={[
                  {
                    required: true,
                    message: getLocalString(translations, 'MyProfile.Required.OldPassword'),
                  },
                ]}
              >
                <Input.Password maxLength={35} />
              </Form.Item>

              <Form.Item
                name='newPassword'
                label={getLocalString(translations, 'MyProfile.NewPassword')}
                rules={passwordRules}
                hasFeedback
              >
                <Input.Password maxLength={35} />
              </Form.Item>

              <Form.Item
                name='confirmNewPassword'
                label={getLocalString(translations, 'MyProfile.ConfirmNewPassword')}
                dependencies={['newPassword']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: getLocalString(
                      translations,
                      'MyProfile.Required.PleaseConfirmNewPassword'
                    ),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          getLocalString(translations, 'MyProfile.NotifyError.PasswordsNotMatch')
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  {getLocalString(translations, 'MyProfile.ChangePassword')}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default MyProfile;
