let keys = {};
export const getLocalString = (source: any, key: string) => {
  // let existingKey = Object.keys(keys).find((el) => el === key);
  // if (!existingKey) {
  //   let returnObject = Object.assign(keys, { [key]: '' });
  //   console.log(returnObject);
  // }

  return source[key] || key;
};
