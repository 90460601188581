import { createReducer } from '@reduxjs/toolkit';
import { getMenuIcon } from '../../helpers/menu';
import { IDrawer, IMenu, IMenuAuth } from '../../types/menu';
import { getMenuList, setActiveMenu } from './actions';

interface IMenuListResponse {
  data: { leftMenu: IMenu[]; headerMenu: IMenu[] };
  status: number;
}
interface IDrawerListResponse {
  data: [IDrawer];
  status: number;
}

interface IMenuState extends IMenuAuth {
  id: string;
  activeMainMenu: string;
  activeSubMenu: string;
  menuList: IMenu[];
  drawerMenuList: IDrawer[];
  headerMenuList: IMenu[];
  pending: boolean;
  error: boolean;
}

const initialState: IMenuState = {
  id: '',
  activeMainMenu: '',
  activeSubMenu: '',
  menuList: [],
  headerMenuList: [],
  drawerMenuList: [],
  pending: false,
  error: false,
};

export const menuReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setActiveMenu, (state, action) => {
      state.id = action.payload.menu.id;
      state.activeMainMenu = action.payload.menu.mainMenu;
      state.activeSubMenu = action.payload.menu.subMenu;
      state.is_create = action.payload.menu.is_create;
      state.is_update = action.payload.menu.is_update;
      state.is_view = action.payload.menu.is_view;
      state.is_delete = action.payload.menu.is_delete;
    })
    .addCase(getMenuList.pending, (state) => {
      state.pending = true;
    })
    .addCase(getMenuList.fulfilled, (state, { payload }) => {
      state.pending = false;
      const response = payload;
      state.menuList = response.data;
    })
    .addCase(getMenuList.rejected, (state) => {
      state.pending = false;
      state.error = true;
    });
});
