import React, { useEffect, useState } from 'react';

import { Collapse, Button } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { languageSelector } from '../../store/language';
import { useAppSelector } from '../../store/hooks';
import { getLocalString } from '../../helpers/language';

const { Panel } = Collapse;

const PageFilter: React.FC<{
  children: React.ReactNode;
  count: number;
  clear: () => void;
}> = ({ count, children, clear }) => {
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const [filterCount, setFilterCount] = useState<number>(0);
  useEffect(() => {
    setFilterCount(count);
  }, [count]);

  return (
    <div>
      <Collapse
        // style={{ border: '1px solid #FFE5E5', borderRadius: '5px', fontSize: '14px' }}
        style={{ border: '1px solid #EEEEEE', borderRadius: ' 8px', fontSize: '14px' }}
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className='site-collapse-custom-collapse'
      >
        <Panel
          header={'Filter' + (filterCount ? '(' + filterCount + ')' : '')}
          key='1'
          className='site-collapse-custom-panel'
          extra={
            <Button
              style={{ color: 'rgba(0, 0, 0, 0.88)' }}
              type='link'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                clear();
              }}
            >
              {'Clear'}
            </Button>
          }
        >
          {children}
        </Panel>
      </Collapse>
    </div>
  );
};

export default PageFilter;
