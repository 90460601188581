import { createReducer } from '@reduxjs/toolkit';

interface AdminState {
  pending: boolean;
  error: boolean;
}

const initialState: AdminState = {
  pending: false,
  error: false,
};

export const interviewReducer = createReducer(initialState, (builder) => {});
