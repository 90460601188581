import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Card, Statistic, Divider, List } from 'antd';
import {
  TeamOutlined,
  SolutionOutlined,
  IdcardOutlined,
  PartitionOutlined,
  FileProtectOutlined,
  DollarOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import CountUp from 'react-countup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import styles from './index.module.scss';
import globalStyles from '../../styles/global.module.scss';
import PageContainer from '../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../components/Search';
import { menuSelector } from '../../store/menu';
import { getLocalString } from '../../helpers/language';
import { languageSelector } from '../../store/language';
import PageFilter from '../../components/pageFilter';
import { constantsSelector } from '../../store/constants';
import { apiURL } from '../../service';
import { getDashboardStatics } from '../../store/dashboard';
import { notifySuccess } from '../../helpers/notify';
import { Navigate, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CustomizedTable from '../../components/CustomizedTable';

const { Option } = Select;

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);

  const [totalStudents, setTotalStudents] = useState<string>('');
  const [totalApplies, setTotalApplies] = useState<string>('');
  const [totalRegistered, setTotalRegistered] = useState<string>('');

  const [totalAgency, setTotalAgency] = useState<string>('');
  const [totalWorkers, setTotalWorkers] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);

  const [searchText, setSearchText] = useState<string>('');

  const [filters, setFilters] = useState<object>();

  useEffect(() => {
    dispatch(
      getDashboardStatics({
        onSuccess: (data: any) => {
          setTotalStudents(data.data.total_students);
          setTotalAgency(data.data.total_agency);
          setTotalApplies(data.data.total_applies);
          setTotalWorkers(data.data.total_workers);
          setTotalRegistered(data.data.total_registered);
        },
      })
    );
  }, []);
  const applicationColumns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: getLocalString(translations, 'Dashboard.Columns.Name'),
      // title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },

    {
      title: getLocalString(translations, 'Dashboard.Columns.University'),
      dataIndex: 'university',
      key: 'university',
      render: (e: any, record: any) => {
        return record.university.name;
      },
    },

    {
      title: getLocalString(translations, 'Dashboard.Columns.CreatedDate'),
      dataIndex: 'created_at',
      key: 'created_at',

      render: (e: any, record: any) => dayjs(record.created_at).format('YYYY-MM-DD'),
    },
  ];
  const announcementColumns = [
    {
      title: getLocalString(translations, 'Dashboard.announcementColumns.University'),
      dataIndex: 'university',
      key: 'university',
      render: (e: any, record: any) => {
        return record.university.name;
      },
    },

    {
      title: getLocalString(translations, 'Dashboard.announcementColumns.Title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: getLocalString(translations, 'Dashboard.announcementColumns.CreatedDate'),
      dataIndex: 'created_at',
      key: 'created_at',

      render: (e: any, record: any) => dayjs(record.created_at).format('YYYY-MM-DD'),
    },
  ];
  return (
    <PageContainer pageName={getLocalString(translations, 'Dashboard.Title')}>
      <Row gutter={[24, 24]}>
        <Col flex={5}>
          <Card style={{ background: '#fafafa' }}>
            <Row>
              <Col span={6}>
                <TeamOutlined style={{ fontSize: '63px' }} />
              </Col>
              <Col span={18}>
                <Statistic
                  style={{ textAlign: 'right' }}
                  title={getLocalString(translations, 'Dashboard.Statistic.TotalStudent')}
                  value={totalStudents}
                  precision={2}
                  formatter={(value: any) => <CountUp end={value} separator=',' />}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col flex={5}>
          <Card style={{ background: '#fafafa' }}>
            <Row>
              <Col span={6}>
                <SolutionOutlined style={{ fontSize: '63px' }} />
              </Col>
              <Col span={18}>
                <Statistic
                  style={{ textAlign: 'right' }}
                  title={getLocalString(translations, 'Dashboard.Statistic.TotalApplies')}
                  value={totalApplies}
                  precision={2}
                  formatter={(value: any) => <CountUp end={value} separator=',' />}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col flex={5}>
          <Card style={{ background: '#fafafa' }}>
            <Row>
              <Col span={6}>
                {/* <IdcardOutlined style={{ fontSize: '63px' }} /> */}
                <FileProtectOutlined style={{ fontSize: '63px' }} />
              </Col>
              <Col span={18}>
                <Statistic
                  style={{ textAlign: 'right' }}
                  title={getLocalString(translations, 'Dashboard.Statistic.TotalRegistered')}
                  value={totalRegistered}
                  precision={2}
                  formatter={(value: any) => <CountUp end={value} separator=',' />}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col flex={5}>
          <Card style={{ background: '#fafafa' }}>
            <Row>
              <Col span={6}>
                {/* <IdcardOutlined style={{ fontSize: '63px' }} /> */}
                <PartitionOutlined style={{ fontSize: '63px' }} />
              </Col>
              <Col span={18}>
                <Statistic
                  style={{ textAlign: 'right' }}
                  title={getLocalString(translations, 'Dashboard.Statistic.TotalAgency')}
                  value={totalAgency}
                  precision={2}
                  formatter={(value: any) => <CountUp end={value} separator=',' />}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col flex={5}>
          <Card style={{ background: '#fafafa' }}>
            <Row>
              <Col span={6}>
                {/* <IdcardOutlined style={{ fontSize: '63px' }} /> */}
                <SmileOutlined style={{ fontSize: '63px' }} />
              </Col>
              <Col span={18}>
                <Statistic
                  style={{ textAlign: 'right' }}
                  title={getLocalString(translations, 'Dashboard.Statistic.TotalWorkers')}
                  value={totalRegistered}
                  precision={2}
                  formatter={(value: any) => <CountUp end={value} separator=',' />}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row style={{ width: '100%', marginTop: '24px' }} gutter={[24, 24]}>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Card style={{ height: '460px' }}>
            <Row className={`${globalStyles.title_r_medium} `}>
              {getLocalString(translations, 'Dashboard.Announcements')}
            </Row>
            <div style={{ maxHeight: 'calc(460px - 120px)', marginTop: '24px', overflowY: 'auto' }}>
              <CustomizedTable
                columns={announcementColumns}
                data={[]}
                bordered={false}
                disablePagination={false}
                url={apiURL.getDashboardAnnouncementList}
                useFilter={false}
                scrollY={230}
                actions={[
                  {
                    action: (record) => {
                      navigate('/edit-university/' + record.university.id);
                    },
                    type: 'detail',
                  },
                ]}
              />
            </div>
          </Card>
        </Col>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Card style={{ height: '460px' }}>
            <Row className={`${globalStyles.title_r_medium} `}>
              {getLocalString(translations, 'Dashboard.AppliesOpen')}
            </Row>

            <div style={{ maxHeight: 'calc(460px - 120px)', overflowY: 'auto', marginTop: '16px' }}>
              <CustomizedTable
                columns={applicationColumns}
                data={[]}
                bordered={false}
                scrollY={230}
                disablePagination={false}
                url={apiURL.getDashboardApplicationList}
                useFilter={false}
                actions={[
                  {
                    action: (record) => {
                      navigate('/applies-detail/' + record.id);
                    },
                    type: 'detail',
                    // hidden: (record: any) => record.status === true,
                  },
                ]}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Dashboard;
