import { createReducer } from '@reduxjs/toolkit';

interface NotificationState {
  pending: boolean;
  error: boolean;
}

const initialState: NotificationState = {
  pending: false,
  error: false,
};

export const notificationReducer = createReducer(initialState, (builder) => {});
