import React, { useEffect, useState } from 'react';
import { Router, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import NotFound from './pages/not-found';
import Home from './pages/home';

import AdminPages from './pages/main-pages';
import DetailPages from './pages/detail-pages';
import PublicPages from './pages/public-pages';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { ConfigProvider, Spin } from 'antd';
import 'dayjs/locale/tr';
import 'dayjs/locale/en';
import LocalFormat from 'dayjs/plugin/localizedFormat';

import tr_TR from 'antd/locale/tr_TR';
import en_US from 'antd/locale/en_US';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { getAppLanguage, languageSelector } from './store/language';

import { userSelector } from './store/user';
import dayjs from 'dayjs';

import { accountSelector, checkUser, login } from './store/account';
import Login from './pages/login';
import {
  getApplicationTypes,
  getCountryList,
  getGenders,
  getInterviewStatusList,
  getInterviewTypes,
  getLanguages,
  getNotifications,
  getPrograms,
  getSemesterList,
  getTurkeyCities,
  getUniversities,
  getUsers,
} from './store/constants';
import UniversitiesApp from './pages/universities/university-list';
import SearchDepartment from './pages/department/search-department';
import StudentList from './pages/student-app/student-list';
import CreateStudent from './pages/student-app/create-student';

import UpcomingDeadlines from './pages/upcoming-deadlines';
import ExceedDeadlines from './pages/exceed-deadlines';
import ApplyNow from './pages/apply-now';
import ApplyDetail from './pages/applications/apply-detail';
import ApplicationsList from './pages/applications/apply-list/applications-list';

import AddUniversity from './pages/universities/add-university';
import EditUniversity from './pages/universities/edit-university';
import AddUpdateDepartment from './pages/department/add-update-department';
import MyProfile from './pages/my-profile';
import Dashboard from './pages/dashboard';
import TrackingCode from './pages/tracking-code';
import ApplyPages from './pages/apply-pages';
import AdminList from './pages/admin/admin-list/admin-list';

import AddUpdateAdmin from './pages/admin/add-update-admin';
import AgencyList from './pages/agency/agency-list';
import AddUpdateAgency from './pages/agency/add-update-agency';
import AgencyDetail from './pages/agency/agency-detail';
import AddWorker from './pages/agency/create-worker';
import { getMenuList } from './store/menu';
import Notification from './pages/notification';
import UserList from './pages/users/user-list';
import UserAddUpdateWorker from './pages/users/user-add-update-worker';
import AddUpdateFaculty from './pages/faculty/add-update-faculty';
import FacultyList from './pages/faculty/faculty-list';
import PreliminaryInterview from './pages/preliminary-interview/preliminary-interview';
import AddUpdateInterview from './pages/preliminary-interview/add-update-interview';
import Reports from './pages/reports/reports';
import CreateADVMeet from './pages/adv-interview/adv-interview';
import NewPassword from './pages/new-password';
import SearchDepartmentPublic from './pages/search-department-public';

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [En, SetEn] = useState<any>(undefined);

  // const { appLanguage, translations } = useAppSelector(languageSelector);
  // const { userInfo } = useAppSelector(accountSelector);

  dayjs.extend(LocalFormat);
  const checkAccessAndRedirect = () => {
    const token = localStorage.getItem('access_token');
    const pathname = window.location.pathname;
    const pathWithoutToken = [
      '/',
      '',
      '/apply',
      '/tracking-code',
      '/study-search',
      '/forgot-passport/',
    ];
    const isForgetPasswordRoute = pathname.startsWith('/forget-password/');
    const isNewMeetingRoute = pathname.startsWith('/newmeeting'); // Yazım hatası düzeltildi

    if (pathWithoutToken.includes(pathname) || isForgetPasswordRoute || isNewMeetingRoute) {
      // dış sayfalar
      localStorage.removeItem('access_token');
      console.log('remove item');
    } else {
      if (token && token.length > 0) {
        // devam
      } else {
        // navigate('/');
        window.location.href = '/';
      }
    }

    // if (token && token.length > 0) {
    //   if (!pathWithoutToken.includes(pathname) && !isForgetPasswordRoute && !isNewMeetingRoute) {
    //     console.log('Token var, tokenLı sayfalardan birinde, sayfada kal.');
    //     appStart();
    //   } else {
    //     console.log(
    //       'Token var, izin verilen tokensız sayfalardan birinde değil, dashboarda yönlendiriliyor.'
    //     );
    //     window.location.href = '/dashboard';
    //   }
    // } else {
    //   if (!pathWithoutToken.includes(pathname) && !isForgetPasswordRoute && !isNewMeetingRoute) {
    //     console.log(
    //       'Token yok, izin verilen sayfalardan birinde değil, login sayfasına yönlendiriliyor.'
    //     );
    //     window.location.href = '/';
    //   } else {
    //     console.log('Token yok, izin verilen tokensız sayfalardan birinde, sayfada kal.');
    //   }
    // }
  };

  checkAccessAndRedirect();

  async function appStart() {
    dispatch(getNotifications());
    dispatch(getUsers());
  }

  useEffect(() => {
    const currentLang = JSON.parse(
      localStorage.getItem('appLanguage') ||
        '{"code":"en-US","name":"en","longName":"English","id":1}'
    );

    if (currentLang) {
      if (currentLang.name === 'tr') {
        SetEn(tr_TR);
      } else {
        SetEn(en_US);
      }
    }

    dispatch(getAppLanguage());

    dispatch(getPrograms());
    dispatch(getCountryList());
    dispatch(getUniversities());
    dispatch(getSemesterList());
    dispatch(getGenders());
    dispatch(getApplicationTypes());
    dispatch(getLanguages());
    dispatch(getTurkeyCities());
  }, []);

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      dispatch(checkUser());
      dispatch(getMenuList());
    }
  }, [localStorage.getItem('access_token')]);

  return (
    <>
      <ConfigProvider
        locale={En}
        theme={{
          token: {
            colorPrimary: '#840DDF',
            //  colorPrimary: '#7C16C5',
            // colorPrimary: '#4E009B',
            // fontFamily: 'Roboto',
          },
          components: {
            Statistic: {
              fontSize: 16,
            },
            Divider: { marginLG: 16 },
            Modal: {
              marginXS: 16,
            },
            Form: {
              colorText: 'rgba(0, 0, 0, 0.88)',
              colorTextHeading: 'rgba(0, 0, 0, 0.45)',
            },
            Rate: {
              yellow6: '#ed722e',
            },
            Upload: {
              colorPrimary: 'rgba(0, 0, 0, 0.88)',
            },
            Table: {
              fontSize: 12,
              //alt taraf sonradan eklendi
              borderRadius: 5,
              borderRadiusLG: 5,

              colorBorderSecondary: '#F7EFE5',
            }, //üsttarf sonradan eklendi

            Button: {
              //alt taraf sonradan eklendi
              borderRadius: 2,
              borderRadiusLG: 5,
              //üsttarf sonradan eklendi
              colorLinkHover: '#ed722e',
              colorLinkActive: '#eea22a',
            },
            //alt taraf sonradan eklendi
            Input: {
              borderRadius: 5,
              borderRadiusLG: 5,
            }, //üsttarf sonradan eklendi
            //alt taraf sonradan eklendi
            Select: {
              borderRadius: 5,
              borderRadiusLG: 5,
            }, //üsttarf sonradan eklendi
            Card: {
              borderRadius: 5,
              borderRadiusLG: 5,

              // colorBorderSecondary: '#FFE5E5',
            }, //üsttarf sonradan eklendi
          },
        }}
      >
        <ToastContainer />
        {/* <div>Logo</div> */}
        <Routes>
          <Route index element={<Login />} />
          <Route path='newmeeting' element={<CreateADVMeet />} />
          <Route path='forget-password/:token' element={<NewPassword />} />

          <Route element={<ApplyPages />}>
            {/* <Route path='apply-now' element={<ApplyNow />} /> */}
          </Route>

          <Route element={<PublicPages />}>
            {/* <Route index element={<Home />} /> */}
            <Route path='apply' element={<ApplyNow />} />
            <Route path='tracking-code' element={<TrackingCode />} />
            <Route path='study-search' element={<SearchDepartmentPublic />} />
          </Route>

          <Route element={<AdminPages />}>
            {/* <Route path='dashboard' element={<Home />} /> */}
            <Route path='dashboard' element={<Dashboard />} />
            {/* 
              <Route path='userRoles' element={<UserRoleList />} />
              <Route path='users' element={<User />} /> */}
            <Route path='universities-list' element={<UniversitiesApp />} />
            <Route path='add-university' element={<AddUniversity />} />
            <Route path='edit-university/:id' element={<EditUniversity />} />
            <Route path='search-department' element={<SearchDepartment />} />
            <Route path='create-department' element={<AddUpdateDepartment />} />
            <Route path='edit-department/:id' element={<AddUpdateDepartment />} />
            <Route path='student-list' element={<StudentList />} />
            <Route path='create-student' element={<CreateStudent />} />
            <Route
              path='create-student/:candidateName/:candidateSurname/:candidatePhone/:candidateEmail/:candidatePassportNo'
              element={<CreateStudent />}
            />

            <Route path='update-student/:id' element={<CreateStudent />} />
            <Route path='applications-list' element={<ApplicationsList />} />
            <Route path='applies-detail/:id' element={<ApplyDetail />} />
            <Route path='upcoming-deadlines-list' element={<UpcomingDeadlines />} />
            <Route path='exceed-deadlines-list' element={<ExceedDeadlines />} />
            <Route path='admin-list' element={<AdminList />} />
            <Route path='update-admin/:id' element={<AddUpdateAdmin />} />
            <Route path='create-admin' element={<AddUpdateAdmin />} />
            <Route path='create-agency' element={<AddUpdateAgency />} />
            <Route path='update-agency/:id' element={<AddUpdateAgency />} />
            <Route path='agency-detail/:id' element={<AgencyDetail />} />
            <Route path='agency-list' element={<AgencyList />} />
            <Route path='create-worker/:agencyId' element={<AddWorker />} />
            <Route path='update-worker/:agencyId/:workerId' element={<AddWorker />} />
            <Route path='notifications' element={<Notification />} />
            <Route path='users' element={<UserList />} />
            <Route path='user-add-update-worker/:id' element={<UserAddUpdateWorker />} />
            <Route path='user-add-update-worker' element={<UserAddUpdateWorker />} />
            <Route path='create-faculty' element={<AddUpdateFaculty />} />
            <Route path='edit-faculty/:id' element={<AddUpdateFaculty />} />
            <Route path='faculty-list' element={<FacultyList />} />
            <Route path='interview-list' element={<PreliminaryInterview />} />
            <Route path='create-interview' element={<AddUpdateInterview />} />
            <Route path='update-interview/:id' element={<AddUpdateInterview />} />
            <Route path='reports' element={<Reports />} />

            <Route path='my-profile' element={<MyProfile />} />
          </Route>
          <Route element={<DetailPages />}></Route>

          <Route path='*' element={<NotFound />} />
        </Routes>
      </ConfigProvider>
    </>
  );
}

export default App;
