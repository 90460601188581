import React from 'react';

import { LayoutProps } from './formLayout-types';

import styles from './formLayout.module.scss';
import { HeadLine } from '../../components/HeadLine';
import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Spin } from 'antd';

import UniteamHeader from '../../components/uniteamHeader';
import UniteamFooter from '../../components/uniteamFooter';
import { useAppSelector } from '../../store/hooks';
import { accountSelector } from '../../store/account';

const { confirm } = Modal;
const FormLayout: React.FC<LayoutProps> = ({
  steps = null,
  children,
  title = '',
  loading = false,
  result = false,
  centeredContent = false,
  fullScreen = true,
  // backBtnAction = Router.back,
  formStatus = null,
  modalTitle = 'Quit form?',
  modalContent = 'Are you sure to quit form?',
}) => {
  // const { companies } = useAppSelector(accountSelector);
  const showConfirm = () => {
    confirm({
      title: modalTitle,
      icon: <ExclamationCircleOutlined />,
      content: modalContent,
      cancelText: 'Cancel',
      okText: 'Quit',
      onOk() {
        // backBtnAction();
      },
    });
  };

  return (
    <div className={styles.layout}>
      <UniteamHeader
        app='warehouse'
        activeProject='warehouse'
        // activeCompany={companies.find((el) => el.isCurrent)?.companyName}
      />
      <main className={styles.page}>
        {title && (
          <div className={styles.pageHeader}>
            <Button
              icon={<ArrowLeftOutlined />}
              size='small'
              // onClick={() => (!formStatus ? backBtnAction() : showConfirm())}
              type='text'
            />
            <HeadLine level={6} className={styles.pageHeaderTitle} mb={0}>
              {title}
            </HeadLine>
          </div>
        )}
        {loading ? (
          <div className={`${styles.pageBody} ${styles.pageLoading}`}>
            <Spin size='large' />
          </div>
        ) : (
          <>
            {steps && <div className={styles.pageSteps}>{steps}</div>}
            <div
              className={`${fullScreen ? styles.pageBodyFull : styles.pageBody} ${
                result && styles.pageCentered
              } ${centeredContent && styles.pageCentered} `}
            >
              {children}
            </div>
          </>
        )}
      </main>
      <UniteamFooter
        menuItems={[
          {
            href: '/',
            label: 'Apps',
          },
        ]}
      />
    </div>
  );
};

export default FormLayout;
