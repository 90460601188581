import React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';

import styles from './index.module.scss';
import { Layout, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const PageHeader: React.FC<{
  title: string;
  onBack?: (() => void) | undefined;
}> = ({ title, onBack }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.page_container}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '88px',
        }}
      >
        {typeof onBack === 'function' ? (
          <ArrowLeftOutlined
            style={{ marginRight: '16px' }}
            onClick={() => {
              onBack?.();
            }}
            size={500}
          />
        ) : undefined}

        {/* <div className={styles.title}>{title}</div> */}
        <Title style={{ marginTop: '0.5em' }} level={3}>
          {title}
        </Title>
      </div>
    </div>
  );
};

export default PageHeader;
