import { createReducer } from '@reduxjs/toolkit';
// import { IUser, IUserDetail, IUserRole } from '../../types/user';
// import { basicUser, getMyUser, getUser, getUserRoles, getUsers } from './actions';

// interface IUsersRolesResponse {
//   data: [IUserRole];
//   status: number;
// }

// interface IUsersResponse {
//   data: {
//     pageNumber: number;
//     pageSize: number;
//     results: IUser[];
//     totalNumberOfPages: number;
//     totalNumberOfRecords: number;
//   };
//   status: number;
// }

// interface IUserResponse {
//   data: IUserDetail;
//   status: number;
// }

interface IUserKYCResponce {
  data: { isSystemUser: boolean; businessTypes: [IUserKYCBusinessTypes] };
  status: number;
}

interface IUserKYCBusinessTypes {
  id: number;
  link: string;
  isCompany: boolean;
}

export type UserState = {
  // userRoles: IUserRole[];
  // users: IUser[];
  // user?: IUserDetail;
  // myUser?: IUserDetail;
  permissions?: { isSystemUser: boolean; businessTypes: [IUserKYCBusinessTypes] };
  pending: boolean;
  error: boolean;
};

const initialState: UserState = {
  // userRoles: [],
  // users: [],
  pending: false,
  error: false,
};

export const userReducer = createReducer(initialState, (builder) => {
  // builder
  //   .addCase(getUserRoles.pending, (state) => {
  //     state.pending = true;
  //   })
  //   .addCase(getUserRoles.fulfilled, (state, { payload }) => {
  //     state.pending = false;
  //     const response = payload as unknown as IUsersRolesResponse;
  //     if (response.status === 200) state.userRoles = response.data;
  //   })
  //   .addCase(getUserRoles.rejected, (state) => {
  //     state.pending = false;
  //     state.error = true;
  //   })
  //   .addCase(getUsers.pending, (state) => {
  //     state.pending = true;
  //   })
  //   .addCase(getUsers.fulfilled, (state, { payload }) => {
  //     state.pending = false;
  //     const response = payload as unknown as IUsersResponse;
  //     if (response.status === 200) state.users = response.data.results;
  //   })
  //   .addCase(getUsers.rejected, (state) => {
  //     state.pending = false;
  //     state.error = true;
  //   })
  //   .addCase(getUser.pending, (state) => {
  //     state.pending = true;
  //   })
  //   .addCase(getUser.fulfilled, (state, { payload }) => {
  //     state.pending = false;
  //     const response = payload as unknown as IUserResponse;
  //     if (response.status === 200) state.user = response.data;
  //   })
  //   .addCase(getUser.rejected, (state) => {
  //     state.pending = false;
  //     state.error = true;
  //   })
  //   .addCase(getMyUser.pending, (state) => {
  //     state.pending = true;
  //   })
  //   .addCase(getMyUser.fulfilled, (state, { payload }) => {
  //     state.pending = false;
  //     const response = payload as unknown as IUserResponse;
  //     if (response.status === 200) state.myUser = response.data;
  //   })
  //   .addCase(getMyUser.rejected, (state) => {
  //     state.pending = false;
  //     state.error = true;
  //   })
  //   .addCase(basicUser.pending, (state) => {
  //     state.pending = true;
  //   })
  //   .addCase(basicUser.fulfilled, (state, { payload }) => {
  //     state.pending = false;
  //     const response = payload as unknown as IUserKYCResponce;
  //     if (response.status === 200) state.permissions = response.data;
  //   })
  //   .addCase(basicUser.rejected, (state) => {
  //     state.pending = false;
  //     state.error = true;
  //   });
});

export default userReducer;
