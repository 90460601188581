import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Select,
  Collapse,
  Row,
  Col,
  Tag,
  Input,
  Upload,
  Form,
  Divider,
  Checkbox,
  Space,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';
import globalStyles from '../../../styles/global.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import {
  constantsSelector,
  getFacultyDepartments,
  getUniversityFaculties,
} from '../../../store/constants';
import { apiURL } from '../../../service';

import { Modal } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { IStudent } from '../../../types/student';
import { useNavigate, useParams } from 'react-router-dom';
import {
  applyForThisStudent,
  createStudent,
  deleteFile,
  getStudent,
  updateStudent,
  uploadFile,
} from '../../../store/student';
import dayjs from 'dayjs';
import isNumeric from '../../../helpers/isNumeric';
import NumericInput from '../../../components/numericInput';
import { truncate } from 'lodash';
import { notifyError, notifySuccess } from '../../../helpers/notify';
import { uploadApplicationFiles } from '../../../store/application';
import { IConstantTypes, IDepartmentTypes } from '../../../types/constants';
import CustomizedTable from '../../../components/CustomizedTable';

const { Option } = Select;

const CreateStudent: React.FC = () => {
  const {
    id,
    candidateName,
    candidateSurname,
    candidatePhone,
    candidateEmail,
    candidatePassportNo,
  } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const {
    countryList,
    programList,
    universityList,
    semesterList,
    genderList,
    applicationTypeList,
    languageList,
  } = useAppSelector(constantsSelector);

  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [fatherName, setFatherName] = useState<string>('');
  const [motherName, setMotherName] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [nation, setNation] = useState<number | null>();
  const [country, setCountry] = useState<number | null>();
  const [passportNo, setPassportNo] = useState<string | null>('');
  const [secondNation, setSecondNation] = useState<number | null>();
  const [secondPassportNo, setSecondPassportNo] = useState<string | null>('');
  const [phone, setPhone] = useState<string>('');
  const [phonePrefix, setPhonePrefix] = useState<string>('+90');
  const [secondPhone, setSecondPhone] = useState<string | null>('');
  const [secondPhonePrefix, setSecondPhonePrefix] = useState<string | null>('+90');
  const [mail, setMail] = useState<string>('');
  const [school, setSchool] = useState<string>('');

  const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(true);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState<boolean>(true);

  const [studentPicture, setStudentPicture] = useState<UploadFile[]>([]);
  const [passportPicture, setPassportPicture] = useState<UploadFile[]>([]);
  const [transcriptPicture, setTranscriptPicture] = useState<UploadFile[]>([]);
  const [certificatePicture, setCertificatePicture] = useState<UploadFile[]>([]);
  const [secondPassportPicture, setSecondPassportPicture] = useState<any[]>([]);
  const [extraPicture, setExtraPicture] = useState<any[]>([]);

  const [studentInfo, setStudentInfo] = useState<IStudent>();

  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState('');

  const [isApplyModalOpen, setIsApplyModalOpen] = useState<boolean>(false);

  const [universityId, setUniversityId] = useState<string>('');
  const [facultyId, setFacultyId] = useState<string>('');
  const [languageId, setLanguageId] = useState<string>('');
  const [programId, setProgramId] = useState<string>('');
  const [applicationType, setApplicationType] = useState<string>('');
  const [semester, setSemester] = useState<string>('');
  const [departmentId, setDepartmentId] = useState<string>('');
  const [departmentList, setDepartmentList] = useState<IDepartmentTypes[]>([]);
  const [facultyList, setFacultyList] = useState<IDepartmentTypes[]>([]);

  useEffect(() => {
    getStudentInfo();
  }, [id]);

  useEffect(() => {
    if (universityId)
      dispatch(
        getUniversityFaculties({
          universityID: universityId,
          onSuccess: (data: any) => {
            setFacultyList(data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  }, [universityId]);
  useEffect(() => {
    if (universityId)
      dispatch(
        getFacultyDepartments({
          faculty_id: facultyId,
          program_id: programId,
          language_id: languageId,
          onSuccess: (data: any) => {
            setDepartmentList(data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  }, [universityId, facultyId, programId, languageId]);

  useEffect(() => {
    if (
      ((secondPassportNo !== '' && secondPassportNo !== null) ||
      (secondNation !== undefined && secondNation !== null)
        ? secondPassportNo !== '' &&
          secondPassportNo !== null &&
          secondNation !== undefined &&
          secondNation !== null
        : true) &&
      (secondPhone !== '' && secondPhone !== null
        ? secondPhone !== '' && secondPhone !== null && phone !== ''
        : true) &&
      name !== '' &&
      surname !== '' &&
      fatherName !== '' &&
      motherName !== '' &&
      gender !== '' &&
      nation !== undefined &&
      phone !== '' &&
      country !== undefined &&
      passportNo !== '' &&
      school !== '' &&
      mail !== ''
    ) {
      setIsCreateDisabled(false);
      if (
        secondNation != studentInfo?.second_nation?.id ||
        secondPassportNo != studentInfo?.second_passport_no ||
        passportNo != studentInfo?.passport_no ||
        secondPhone != studentInfo?.second_phone ||
        name != studentInfo?.name ||
        surname != studentInfo?.surname ||
        fatherName != studentInfo?.father_name ||
        motherName != studentInfo?.mother_name ||
        gender != studentInfo?.gender.id ||
        nation != studentInfo?.nation.id ||
        phone != studentInfo?.phone ||
        country != studentInfo?.country.id ||
        school != studentInfo?.school ||
        mail != studentInfo?.email
      ) {
        setIsUpdateDisabled(false);
      } else setIsUpdateDisabled(true);
    } else {
      setIsCreateDisabled(true);
      setIsUpdateDisabled(true);
    }
  }, [
    name,
    surname,
    fatherName,
    motherName,
    gender,
    nation,
    country,
    passportNo,
    secondNation,
    secondPassportNo,
    phone,
    secondPhone,
    school,
    mail,
  ]);
  useEffect(() => {
    setName(studentInfo?.name || '');
    setSurname(studentInfo?.surname || '');
    setFatherName(studentInfo?.father_name || '');
    setMotherName(studentInfo?.mother_name || '');
    setGender(studentInfo?.gender.id.toString() || '');
    setNation(studentInfo?.nation.id || null);
    setCountry(studentInfo?.country.id || null);
    setPassportNo(studentInfo?.passport_no || '');
    setSecondPassportNo(studentInfo?.second_passport_no || null);
    setSecondNation(studentInfo?.second_nation?.id || undefined);
    setPhone(studentInfo?.phone || '');
    setSecondPhone(studentInfo?.second_phone || null);
    setMail(studentInfo?.email || '');
    setSchool(studentInfo?.school || '');

    studentInfo?.documents.forEach((document: any) => {
      let picture: UploadFile;
      switch (document.key) {
        case 'picture':
          picture = {
            uid: document.id.toString(),
            name: document.name,
            status: 'done',
            url: document.path,
            size: document.size,
          };
          setStudentPicture([picture]);
          break;
        case 'passport':
          picture = {
            uid: document.id.toString(),
            name: document.name,
            status: 'done',
            url: document.path,
            size: document.size,
          };
          setPassportPicture([picture]);
          break;
        case 'transcript':
          picture = {
            uid: document.id.toString(),
            name: document.name,
            status: 'done',
            url: document.path,
            size: document.size,
          };
          setTranscriptPicture([picture]);
          break;
        case 'certificate':
          picture = {
            uid: document.id.toString(),
            name: document.name,
            status: 'done',
            url: document.path,
            size: document.size,
          };
          setCertificatePicture([picture]);
          break;
        case 'second-passport':
          picture = {
            uid: document.id.toString(),
            name: document.name,
            status: 'done',
            url: document.path,
            size: document.size,
          };
          setSecondPassportPicture([picture]);
          break;
        case 'other':
          picture = {
            uid: document.id.toString(),
            name: document.name,
            status: 'done',
            url: document.path,
            size: document.size,
          };
          setExtraPicture([picture]);
          break;

        default:
          break;
      }
    });
  }, [studentInfo]);
  useEffect(() => {
    if (candidateName) setName(decodeURIComponent(candidateName));
    if (candidateSurname) setSurname(decodeURIComponent(candidateSurname));
    if (candidatePhone) setPhone(decodeURIComponent(candidatePhone));
    if (candidateEmail) setMail(decodeURIComponent(candidateEmail));
    if (candidatePassportNo) setPassportNo(decodeURIComponent(candidatePassportNo));
  }, [candidateName, candidateSurname, candidatePhone, candidateEmail, candidatePassportNo]);
  const handlePreview = (file: any) => {
    //Modal içi gösterim için silmeyin
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64(file.originFileObj);
    // }
    // setPreviewImage(file.url || file.preview);
    // setPreviewVisible(true);
    //Modal içi gösterim için silmeyin
    window.open(file.url, '_blank');
  };

  // Dosyayı Base64 formatına çevirmek için yardımcı fonksiyon
  //Modal içi gösterim için silmeyin
  // const getBase64 = (file: any) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };
  //Modal içi gösterim için silmeyin
  const fileSave = (file: any, fileName: string) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

    if (!allowedTypes.includes(file.type)) {
      notifyError(getLocalString(translations, 'CreateStudent.NotifyError.FileTypeNotAllowed'));
      return false;
    }
    if (file.size > 4194304) {
      notifyError(getLocalString(translations, 'CreateStudent.NotifyError.FileSize'));
      return false;
    }
    id &&
      dispatch(
        uploadFile({
          model_id: id,
          model: 'student',
          file_key: fileName,
          file: file,
          onSuccess: (data: any) => {
            let document: any = {
              uid: data.data.id.toString(),
              name: data.data.name,
              status: 'done',
              url: data.data.path,
              size: data.data.size,
            };

            switch (data.data.key) {
              case 'picture':
                setStudentPicture([document]);
                break;
              case 'passport':
                setPassportPicture([document]);
                break;
              case 'transcript':
                setTranscriptPicture([document]);
                break;
              case 'certificate':
                setCertificatePicture([document]);
                break;
              case 'second-passport':
                setSecondPassportPicture([document]);
                break;
              case 'other':
                setExtraPicture([document]);
                break;

              default:
                break;
            }

            notifySuccess(getLocalString(translations, 'CreateStudent.NotifySuccess.FileUploaded'));
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  };
  const getStudentInfo = () => {
    if (id) {
      dispatch(
        getStudent({
          id,
          onSuccess: (data: any) => {
            setStudentInfo(data.data);
          },
        })
      );
    }
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailBlur = () => {
    if (mail && !validateEmail(mail)) {
      notifyError(getLocalString(translations, 'CreateStudent.NotifyError.EmailInvalid'));
    }
  };

  const renderCountryOptions = useCallback(
    () =>
      countryList.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name}
        </Option>
      )),
    [countryList]
  );
  const renderSemesterOptions = useCallback(
    () =>
      semesterList.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name}
        </Option>
      )),
    [semesterList]
  );
  const renderUniversityOptions = useCallback(
    () => universityList.map((el: any) => ({ value: el.id, label: el.name })),
    [universityList]
  );
  const renderDepartmentOptions = useCallback(
    () =>
      departmentList.map((el: IDepartmentTypes) => ({
        value: el.id,
        label: el?.name,
      })),
    [departmentList]
  );
  const renderFacultyOptions = useCallback(
    () => facultyList.map((el: any) => ({ value: el.id, label: el.name })),
    [facultyList]
  );
  const renderGenderOptions = useCallback(
    () =>
      genderList.map((el, i) => (
        <Option key={i} value={`${el.id}`}>
          {el.name}
        </Option>
      )),
    [genderList]
  );
  const renderApplicationTypeOptions = useCallback(
    () =>
      applicationTypeList.map((el, i) => (
        <Option key={i} value={`${el.id}`}>
          {el.name}
        </Option>
      )),
    [applicationTypeList]
  );

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: getLocalString(translations, 'CreateStudent.Columns.University'),
      key: 'university',
      dataIndex: 'university',
      render: (e: any, record: any) => record?.university?.name,
    },
    {
      title: getLocalString(translations, 'CreateStudent.Columns.Department'),
      dataIndex: 'Department',
      key: 'Department',
      render: (e: any, record: any) => record?.department?.name,
    },

    {
      title: getLocalString(translations, 'CreateStudent.Columns.Apply'),
      dataIndex: 'application_status',
      key: 'application_status',
      render: (e: any, record: any) => e?.name,
    },

    // {
    //   title: getLocalString(translations, 'CreateStudent.Columns.UniApply'),
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: (e: any, record: any) => <Tag>{record.status}</Tag>,
    // },
    {
      title: getLocalString(translations, 'CreateStudent.Columns.Deadline'),
      dataIndex: 'deadline',
      key: 'deadline',
      render: (e: any, record: any) =>
        record.deadline ? dayjs(record.deadline).format('YYYY-MM-DD') : '-',
    },
  ];

  return (
    <PageContainer
      pageName={
        !id
          ? getLocalString(translations, 'CreateStudent.Title.CreateStudent')
          : getLocalString(translations, 'CreateStudent.Title.UpdateStudent')
      }
      backUrl={'/student-list'}
      extra={
        id && (
          <Button type='primary' size='large' onClick={() => setIsApplyModalOpen(true)}>
            {getLocalString(translations, 'CreateStudent.Button.ApplyForThisStudent')}
          </Button>
        )
      }
    >
      {(candidateName ||
        candidateSurname ||
        candidatePhone ||
        candidateEmail ||
        candidatePassportNo) && (
        <Row style={{ width: '100%', marginBottom: '16px', color: 'red' }}>
          This Student Information Comes From Preliminary Interview. Dont Close This page until you
          complete other information and create.
        </Row>
      )}

      <Form layout='vertical'>
        <Row style={{ width: '100%' }} gutter={[16, 16]}>
          <Row style={{ width: '100%' }} gutter={[24, 24]}>
            <Col span={6}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.Name')}
                </Row>
                <Input
                  maxLength={35}
                  placeholder={getLocalString(translations, 'CreateStudent.Placeholder.Name')}
                  value={name || undefined}
                  onChange={(e) => {
                    setName(e.currentTarget.value);
                  }}
                ></Input>
              </Row>
            </Col>
            <Col span={6}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.Surname')}
                </Row>
                <Input
                  maxLength={35}
                  placeholder={getLocalString(translations, 'CreateStudent.Placeholder.Surname')}
                  value={surname || undefined}
                  onChange={(e) => {
                    setSurname(e.currentTarget.value);
                  }}
                ></Input>
              </Row>
            </Col>
            <Col span={6}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.FathersName')}
                </Row>
                <Input
                  maxLength={35}
                  placeholder={getLocalString(
                    translations,
                    'CreateStudent.Placeholder.FathersName'
                  )}
                  value={fatherName || undefined}
                  onChange={(e) => {
                    setFatherName(e.currentTarget.value);
                  }}
                ></Input>
              </Row>
            </Col>
            <Col span={6}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.MothersName')}
                </Row>
                <Input
                  maxLength={35}
                  placeholder={getLocalString(
                    translations,
                    'CreateStudent.Placeholder.MothersName'
                  )}
                  value={motherName || undefined}
                  onChange={(e) => {
                    setMotherName(e.currentTarget.value);
                  }}
                ></Input>
              </Row>
            </Col>
          </Row>
          <Row />
          {/*               GENDER NATION COUNRTY                   */}
          <Row style={{ width: '100%' }} gutter={[24, 24]}>
            <Col span={8}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.Gender')}
                </Row>
                <Select
                  style={{ width: '100%' }}
                  value={gender || undefined}
                  showSearch
                  filterOption={filterOption}
                  placeholder={getLocalString(translations, 'CreateStudent.Placeholder.Gender')}
                  onChange={(e) => {
                    console.log('eee', e);
                    setGender(e);
                  }}
                >
                  {renderGenderOptions()}
                </Select>
              </Row>
            </Col>
            <Col span={8}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.Nation')}
                </Row>
                <Select
                  style={{ width: '100%' }}
                  value={nation || undefined}
                  placeholder={getLocalString(translations, 'CreateStudent.Placeholder.Nation')}
                  showSearch
                  filterOption={filterOption}
                  onChange={(e) => {
                    setNation(e);
                  }}
                >
                  {renderCountryOptions()}
                </Select>
              </Row>
            </Col>
            <Col span={8}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.Country')}
                </Row>
                <Select
                  style={{ width: '100%' }}
                  value={country || undefined}
                  showSearch
                  filterOption={filterOption}
                  placeholder={getLocalString(translations, 'CreateStudent.Placeholder.Country')}
                  onChange={(e) => {
                    setCountry(e);
                  }}
                >
                  {renderCountryOptions()}
                </Select>
              </Row>
            </Col>
          </Row>
          <Row style={{ width: '100%' }} gutter={[24, 24]}>
            {/*                  Passport seckond NATION                    */}
            <Col span={8}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.PassportNo')}
                </Row>
                <Input
                  maxLength={35}
                  placeholder={getLocalString(translations, 'CreateStudent.Placeholder.Passport')}
                  value={passportNo || undefined}
                  onChange={(e) => {
                    setPassportNo(e.currentTarget.value);
                  }}
                ></Input>
              </Row>
            </Col>
            <Col span={8}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.Phone')}
                </Row>

                <Space.Compact style={{ width: '100%' }}>
                  <Select
                    style={{ width: '25%' }}
                    defaultValue={'+90'}
                    value={phonePrefix || '+90'}
                    options={countryList.map((el) => ({
                      value: el.phone_code,
                      label: `${el.phone_code} ${el.name}`,
                    }))}
                    onChange={(e) => {
                      setPhonePrefix(e);
                    }}
                    showSearch
                    filterOption={filterOption}
                  />
                  <Input
                    maxLength={14}
                    value={phone || undefined}
                    placeholder={getLocalString(translations, 'CreateStudent.Placeholder.Phone')}
                    onChange={(e) => {
                      setPhone(e.currentTarget.value);
                    }}
                    onKeyDown={(e) => {
                      if (!isNumeric(e.key) && e.key !== 'Backspace') e.preventDefault();
                    }}
                  />
                </Space.Compact>
              </Row>
            </Col>
            <Col span={8}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.Mail')}
                </Row>
                <Input
                  maxLength={35}
                  placeholder={getLocalString(translations, 'CreateStudent.Placeholder.Mail')}
                  value={mail || undefined}
                  onChange={(e) => {
                    setMail(e.target.value);
                  }}
                  onBlur={handleEmailBlur}
                ></Input>
              </Row>
            </Col>
          </Row>

          <Row style={{ width: '100%' }} gutter={[24, 24]}>
            {isView ? (
              <>
                <Col span={16}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'CreateStudent.Label.School')}
                    </Row>
                    <Input
                      maxLength={35}
                      placeholder={getLocalString(translations, 'CreateStudent.Placeholder.School')}
                      value={school || undefined}
                      onChange={(e) => {
                        setSchool(e.currentTarget.value);
                      }}
                    ></Input>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'CreateStudent.Label.IdentityNo')}
                    </Row>
                    <Input
                      maxLength={35}
                      disabled
                      value={studentInfo?.identity_no || undefined}
                    ></Input>
                  </Row>
                </Col>
              </>
            ) : (
              <Col span={24}>
                <Row style={{ width: '100%', gap: '5px' }}>
                  <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                    {getLocalString(translations, 'CreateStudent.Label.School')}
                  </Row>
                  <Input
                    maxLength={35}
                    placeholder={getLocalString(translations, 'CreateStudent.Placeholder.School')}
                    value={school || undefined}
                    onChange={(e) => {
                      setSchool(e.currentTarget.value);
                    }}
                  ></Input>
                </Row>
              </Col>
            )}
          </Row>
          <Row style={{ width: '100%' }} gutter={[24, 24]}>
            <Col span={8}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.SecondNation')}
                </Row>
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  filterOption={filterOption}
                  disabled={!nation}
                  value={secondNation || undefined}
                  placeholder={getLocalString(
                    translations,
                    'CreateStudent.Placeholder.SecondNation'
                  )}
                  allowClear
                  onChange={(e) => {
                    setSecondNation(e);
                    setSecondPassportNo(null);
                  }}
                >
                  {renderCountryOptions()}
                </Select>
              </Row>
            </Col>
            <Col span={8}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                  {getLocalString(translations, 'CreateStudent.Label.SecondPassportNo')}
                </Row>

                <Input
                  maxLength={35}
                  placeholder={getLocalString(
                    translations,
                    'CreateStudent.Placeholder.SecondPassportNo'
                  )}
                  value={secondPassportNo || undefined}
                  disabled={secondNation ? !secondNation : true}
                  onChange={(e) => {
                    setSecondPassportNo(e.currentTarget.value);
                  }}
                ></Input>
              </Row>
            </Col>
            <Col span={8}>
              <Row style={{ width: '100%', gap: '5px' }}>
                <Row
                  className={`${globalStyles.label_r_large} ${styles.label_color}`}
                  style={{ width: '100%' }}
                >
                  {getLocalString(translations, 'CreateStudent.Label.SecondPhone')}
                </Row>
                <Space.Compact style={{ width: '100%' }}>
                  <Select
                    style={{ width: '25%' }}
                    defaultValue={'+90'}
                    value={secondPhonePrefix || '+90'}
                    options={countryList.map((el) => ({
                      value: el.phone_code,
                      label: `${el.phone_code} ${el.name}`,
                    }))}
                    onChange={(e) => {
                      setSecondPhonePrefix(e);
                    }}
                    showSearch
                    filterOption={filterOption}
                  />
                  <Input
                    maxLength={14}
                    placeholder={getLocalString(
                      translations,
                      'CreateStudent.Placeholder.SecondPhone'
                    )}
                    disabled={phone.length === 0}
                    value={secondPhone || undefined}
                    onChange={(e) => {
                      setSecondPhone(e.currentTarget.value);
                    }}
                    onKeyDown={(e) => {
                      if (!isNumeric(e.key) && e.key !== 'Backspace') e.preventDefault();
                    }}
                  />
                </Space.Compact>
              </Row>
            </Col>
          </Row>
          {!id ? (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                type='primary'
                disabled={isCreateDisabled}
                onClick={() => {
                  nation &&
                    passportNo &&
                    dispatch(
                      createStudent({
                        name: name,
                        surname: surname,
                        father_name: fatherName,
                        mother_name: motherName,
                        gender: gender,
                        nation_id: nation,
                        country_id: nation,
                        passport_no: passportNo,
                        second_nation_id: secondNation,
                        second_passport_no: secondPassportNo,
                        phone: phone,
                        phone_code: phonePrefix,
                        second_phone: secondPhone,
                        phone_code_2: secondPhonePrefix,
                        email: mail,
                        school: school,
                        onSuccess: (data: any) => {
                          notifySuccess(
                            getLocalString(
                              translations,
                              'CreateStudent.NotifySuccess.StudentCreatedSuccessfully'
                            )
                          );
                          navigate('/update-student/' + data.data.id);
                        },
                      })
                    );
                }}
              >
                {getLocalString(translations, 'CreateStudent.Button.Create')}
              </Button>
            </Row>
          ) : (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                type='primary'
                disabled={isUpdateDisabled}
                onClick={() => {
                  id &&
                    nation &&
                    passportNo &&
                    dispatch(
                      updateStudent({
                        id: id,
                        name: name,
                        surname: surname,
                        father_name: fatherName,
                        mother_name: motherName,
                        gender_id: gender,
                        nation_id: nation,
                        country_id: nation,
                        passport_no: passportNo,
                        second_nation_id: secondNation,
                        second_passport_no: secondPassportNo,
                        phone: phone,
                        phone_code: phonePrefix,
                        second_phone: secondPhone,
                        phone_code_2: secondPhonePrefix,
                        email: mail,
                        school: school,
                        onSuccess: (data: any) => {
                          notifySuccess(
                            getLocalString(
                              translations,
                              'CreateStudent.NotifySuccess.StudentUpdatedSuccessfully'
                            )
                          );

                          getStudentInfo();
                          setIsUpdateDisabled(true);
                        },
                      })
                    );
                }}
              >
                {getLocalString(translations, 'CreateStudent.Button.Update')}
              </Button>
              <Divider></Divider>
            </Row>
          )}
          {id && (
            <>
              {/*                     Files                  */}
              <Row style={{ width: '100%' }} gutter={[24, 24]}>
                <Col span={4}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'CreateStudent.Label.StudentPicture')}
                    </Row>
                    <Upload
                      accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                      listType='picture-card'
                      fileList={studentPicture}
                      onPreview={(e) => handlePreview(e)}
                      beforeUpload={(file: any) => {
                        fileSave(file, 'picture');

                        return false;
                      }}
                      onRemove={() => {
                        Modal.confirm({
                          title: getLocalString(
                            translations,
                            'CreateStudent.Delete.StudentPicture'
                          ),
                          onOk: () => {
                            dispatch(
                              deleteFile({
                                id: studentPicture[0].uid,
                                onSuccess: (data: any) => {
                                  setStudentPicture([]);
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'CreateStudent.NotifySuccess.FileDeletedSuccessfully'
                                    )
                                  );
                                },
                                onError: (err) => notifyError(err),
                              })
                            );
                          },
                        });
                      }}
                    >
                      {studentPicture.length >= 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>
                            {getLocalString(translations, 'CreateStudent.Upload')}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'CreateStudent.Label.Passport')}
                    </Row>
                    <Upload
                      accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                      listType='picture-card'
                      fileList={passportPicture}
                      onPreview={(e) => handlePreview(e)}
                      beforeUpload={(file: any) => {
                        fileSave(file, 'passport');
                        return false;
                      }}
                      onRemove={() => {
                        Modal.confirm({
                          title: getLocalString(
                            translations,
                            'CreateStudent.Delete.StudentPassport'
                          ),
                          onOk: () => {
                            dispatch(
                              deleteFile({
                                id: passportPicture[0].uid,
                                onSuccess: (data: any) => {
                                  setPassportPicture([]);
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'CreateStudent.NotifySuccess.FileDeletedSuccessfully'
                                    )
                                  );
                                },
                                onError: (err) => notifyError(err),
                              })
                            );
                          },
                        });
                      }}
                    >
                      {passportPicture.length >= 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>
                            {getLocalString(translations, 'CreateStudent.Upload')}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'CreateStudent.Label.Transcript')}
                    </Row>
                    <Upload
                      accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                      listType='picture-card'
                      fileList={transcriptPicture}
                      onPreview={(e) => handlePreview(e)}
                      beforeUpload={(file: any) => {
                        fileSave(file, 'transcript');
                        return false;
                      }}
                      onRemove={() => {
                        Modal.confirm({
                          title: getLocalString(
                            translations,
                            'CreateStudent.Delete.StudentTranscript'
                          ),
                          onOk: () => {
                            dispatch(
                              deleteFile({
                                id: transcriptPicture[0].uid,
                                onSuccess: (data: any) => {
                                  setTranscriptPicture([]);
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'CreateStudent.NotifySuccess.FileDeletedSuccessfully'
                                    )
                                  );
                                },
                                onError: (err) => notifyError(err),
                              })
                            );
                          },
                        });
                      }}
                    >
                      {transcriptPicture.length >= 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>
                            {getLocalString(translations, 'CreateStudent.Upload')}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'CreateStudent.Label.Certificate')}
                    </Row>
                    <Upload
                      accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                      listType='picture-card'
                      fileList={certificatePicture}
                      onPreview={(e) => handlePreview(e)}
                      beforeUpload={(file: any) => {
                        fileSave(file, 'certificate');
                        return false;
                      }}
                      onRemove={() => {
                        Modal.confirm({
                          title: getLocalString(
                            translations,
                            'CreateStudent.Delete.StudentCertificate'
                          ),
                          onOk: () => {
                            dispatch(
                              deleteFile({
                                id: certificatePicture[0].uid,
                                onSuccess: (data: any) => {
                                  setCertificatePicture([]);
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'CreateStudent.NotifySuccess.FileDeletedSuccessfully'
                                    )
                                  );
                                },
                                onError: (err) => notifyError(err),
                              })
                            );
                          },
                        });
                      }}
                    >
                      {certificatePicture.length >= 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>
                            {getLocalString(translations, 'CreateStudent.Upload')}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'CreateStudent.Label.SecondPassport')}
                    </Row>
                    <Upload
                      accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                      listType='picture-card'
                      fileList={secondPassportPicture}
                      onPreview={(e) => handlePreview(e)}
                      beforeUpload={(file: any) => {
                        fileSave(file, 'second-passport');
                        return false;
                      }}
                      onRemove={() => {
                        Modal.confirm({
                          title: getLocalString(
                            translations,
                            'CreateStudent.Delete.StudentSecondPassport'
                          ),
                          onOk: () => {
                            dispatch(
                              deleteFile({
                                id: secondPassportPicture[0].uid,
                                onSuccess: (data: any) => {
                                  setSecondPassportPicture([]);
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'CreateStudent.NotifySuccess.FileDeletedSuccessfully'
                                    )
                                  );
                                },
                                onError: (err) => notifyError(err),
                              })
                            );
                          },
                        });
                      }}
                    >
                      {secondPassportPicture.length >= 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>
                            {getLocalString(translations, 'CreateStudent.Upload')}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row style={{ width: '100%', gap: '5px' }}>
                    <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                      {getLocalString(translations, 'CreateStudent.Label.Other')}
                    </Row>
                    <Upload
                      accept='.jpeg,.jpg,.png,.pdf,application/pdf,image/jpeg,image/png'
                      listType='picture-card'
                      fileList={extraPicture}
                      onPreview={(e) => handlePreview(e)}
                      beforeUpload={(file) => {
                        fileSave(file, 'other');
                        return false;
                      }}
                      onRemove={() => {
                        Modal.confirm({
                          title: getLocalString(
                            translations,
                            'CreateStudent.Delete.StudentOtherFile'
                          ),
                          onOk: () => {
                            dispatch(
                              deleteFile({
                                id: extraPicture[0].uid,
                                onSuccess: (data: any) => {
                                  setExtraPicture([]);
                                  notifySuccess(
                                    getLocalString(
                                      translations,
                                      'CreateStudent.NotifySuccess.FileDeletedSuccessfully'
                                    )
                                  );
                                },
                                onError: (err) => notifyError(err),
                              })
                            );
                          },
                        });
                      }}
                    >
                      {extraPicture?.length >= 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>
                            {getLocalString(translations, 'CreateStudent.Upload')}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Row>
                </Col>
              </Row>
              {/*                     Table                  */}
              <Row style={{ width: '100%', marginTop: '24px' }} gutter={[24, 24]}>
                <CustomizedTable
                  columns={columns}
                  data={studentInfo?.applies.data || []}
                  url={''}
                  actions={[
                    {
                      action: (record) => {
                        navigate('/applies-detail/' + record.id);
                      },
                      type: 'detail',
                      // hidden: (record: any) => record.status === true,
                    },
                  ]}
                />
              </Row>
            </>
          )}
        </Row>
      </Form>

      <Modal open={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <Modal
        width={1000}
        open={isApplyModalOpen}
        title={getLocalString(translations, 'CreateStudent.ApplyForThisStudent')}
        footer={null}
        onCancel={() => {
          setIsApplyModalOpen(false);
          setApplicationType('');
          setSemester('');
          setUniversityId('');
          setDepartmentId('');
        }}
      >
        <Row style={{ width: '100%', marginTop: '24px' }} gutter={[24, 24]}>
          <Col span={12}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateStudent.Label.ApplicationType')}
              </Row>
              <Select
                style={{ width: '100%' }}
                showSearch
                filterOption={filterOption}
                value={applicationType || undefined}
                placeholder={getLocalString(translations, 'CreateStudent.SelectApplicationType')}
                onChange={(e) => {
                  setApplicationType(e);
                }}
                allowClear
              >
                {renderApplicationTypeOptions()}
              </Select>
            </Row>
          </Col>
          <Col span={12}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateStudent.Label.Semester')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={semester || undefined}
                showSearch
                filterOption={filterOption}
                placeholder={getLocalString(translations, 'CreateStudent.SelectSemester')}
                onChange={(e) => {
                  setSemester(e);
                }}
                allowClear
              >
                {renderSemesterOptions()}
              </Select>
            </Row>
          </Col>
        </Row>
        <Row style={{ width: '100%', marginTop: '24px' }} gutter={[24, 24]}>
          <Col span={12}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateStudent.Label.University')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={universityId || undefined}
                showSearch
                filterOption={filterOption}
                placeholder={getLocalString(translations, 'CreateStudent.SelectUniversity')}
                onChange={(value) => {
                  setUniversityId(value);
                  setFacultyId('');
                  setDepartmentId('');
                  setDepartmentList([]);
                  setFacultyList([]);
                }}
                onClear={() => {
                  setDepartmentId('');
                  setFacultyId('');
                  setFacultyList([]);
                  setDepartmentList([]);
                }}
                allowClear
                options={renderUniversityOptions()}
              ></Select>
            </Row>
          </Col>
          <Col span={12}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateStudent.Label.Faculty')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={facultyId || undefined}
                showSearch
                disabled={!universityId}
                filterOption={filterOption}
                placeholder={getLocalString(translations, 'CreateStudent.SelectFaculty')}
                onChange={(value) => {
                  setFacultyId(value);
                  setDepartmentId('');
                  setDepartmentList([]);
                }}
                onClear={() => {
                  setFacultyId('');
                  setDepartmentId('');
                  setDepartmentList([]);
                }}
                allowClear
                options={renderFacultyOptions()}
              ></Select>
            </Row>
          </Col>
        </Row>
        <Row style={{ width: '100%', marginTop: '24px' }} gutter={[24, 24]}>
          <Col span={8}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateStudent.Label.Degree')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={programId || undefined}
                placeholder={getLocalString(
                  translations,
                  'SearchDepartment.Placeholder.SelectProgram'
                )}
                onChange={setProgramId}
                allowClear
                showSearch
                filterOption={filterOption}
                options={programList.map((el) => ({ value: el.id, label: el.name }))}
              ></Select>
            </Row>
          </Col>
          <Col span={8}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateStudent.Label.Language')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={languageId || undefined}
                placeholder={getLocalString(translations, 'CreateStudent.SelectLanguage')}
                onChange={(e) => {
                  setLanguageId(e);
                }}
                showSearch
                filterOption={filterOption}
                allowClear
                options={languageList.map((el) => ({ value: el.id, label: el.name }))}
              ></Select>
            </Row>
          </Col>
          <Col span={8}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'CreateStudent.Label.Department')}
              </Row>
              <Select
                style={{ width: '100%' }}
                value={departmentId || undefined}
                placeholder={getLocalString(translations, 'CreateStudent.SelectDepartment')}
                onChange={(e) => {
                  setDepartmentId(e);
                }}
                showSearch
                filterOption={filterOption}
                allowClear
                options={renderDepartmentOptions()}
              ></Select>
            </Row>
          </Col>
        </Row>
        <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
          <Button
            disabled={!universityId || !semester || !departmentId || !applicationType}
            type='primary'
            onClick={() => {
              id &&
                dispatch(
                  applyForThisStudent({
                    student_id: id,
                    application_type: applicationType,
                    university_id: universityId,
                    faculty_id: facultyId,
                    semester_id: semester,
                    department_id: departmentId,
                    onSuccess: (data: any) => {
                      notifySuccess(
                        getLocalString(
                          translations,
                          'CreateStudent.NotifySuccess.StudentApplyCompleted'
                        )
                      );
                      getStudentInfo();
                      setIsApplyModalOpen(false);
                    },
                  })
                );
            }}
          >
            {getLocalString(translations, 'CreateStudent.Button.Save')}
          </Button>
        </Row>
      </Modal>
    </PageContainer>
  );
};

export default CreateStudent;
