import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Card, Input, DatePicker, Space } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import globalStyles from '../../../styles/global.module.scss';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import { constantsSelector, getCountryList } from '../../../store/constants';
import { apiURL } from '../../../service';
import { createDepartment, getDepartmentDetail, updateDepartment } from '../../../store/university';
import { useNavigate, useParams } from 'react-router-dom';
import isNumeric from '../../../helpers/isNumeric';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { notifyError, notifySuccess } from '../../../helpers/notify';
import { StyleInfo } from 'antd/es/theme/util/genComponentStyleHook';
import moment from 'moment';
import { adminCreate, adminUpdate, getAdmin } from '../../../store/admin';
import { IAdminInfo } from '../../../types/admin';
import { accountSelector } from '../../../store/account';

const { Option } = Select;

const AddUpdateAdmin: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { account } = useAppSelector(accountSelector);
  const { userList, countryList } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);

  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');

  const [phone, setPhone] = useState<string>('');
  const [phonePrefix, setPhonePrefix] = useState<string>('+90');
  const [email, setEmail] = useState<string>('');
  const [userTag, setUserTag] = useState<string[]>([]);
  const [userTagCurrent, setUserTagCurrent] = useState<string[]>([]);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(true);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true);
  const [isPasswordsValid, setIsPasswordsValid] = useState<boolean>(false);
  const [adminInfo, setAdminInfo] = useState<IAdminInfo>();
  const [currentUserId, setCurrentUserId] = useState<number>();

  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  useEffect(() => {
    setCurrentUserId(account.id);
    if (id) {
      dispatch(
        getAdmin({
          id: id,
          onSuccess: (data: any) => {
            setName(data.data.name);
            setSurname(data.data.surname);
            setEmail(data.data.email);
            setPhone(data.data.phone);
            setPhonePrefix(data.data.phone_code);
            setAdminInfo(data.data);
            if (data.data.user_created_bys?.length > 0) {
              const usersData = data.data.user_created_bys.map((item: any) => item.created_by.id);
              setUserTag(usersData);
              setUserTagCurrent(usersData);
            }
          },
        })
      );
    }
  }, [id, account]);

  useEffect(() => {
    if (
      name !== '' &&
      surname !== '' &&
      email !== '' &&
      phone !== '' &&
      phonePrefix !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      isPasswordsValid
    ) {
      setIsCreateDisabled(false);
    } else {
      setIsCreateDisabled(true);
    }

    if (
      id &&
      name.length &&
      surname.length &&
      email.length &&
      phone.length &&
      phonePrefix.length &&
      (password.length > 0
        ? isPasswordsValid
        : confirmPassword.length > 0
        ? isPasswordsValid
        : true)
    ) {
      if (
        name !== adminInfo?.name ||
        surname !== adminInfo?.surname ||
        email !== adminInfo?.email ||
        phone !== adminInfo?.phone ||
        phonePrefix !== adminInfo?.phone_code ||
        userTag !== userTagCurrent ||
        (password.length > 0
          ? isPasswordsValid
          : confirmPassword.length > 0
          ? isPasswordsValid
          : isPasswordsValid)
      ) {
        setIsEditDisabled(false);
      } else {
        setIsEditDisabled(true);
      }
    } else {
      setIsEditDisabled(true);
    }
  }, [
    name,
    surname,
    phone,
    phonePrefix,
    userTag,
    email,
    password,
    confirmPassword,
    isPasswordsValid,
  ]);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailBlur = () => {
    if (email && !validateEmail(email)) {
      notifyError(getLocalString(translations, 'AddUpdateAdmin.NotifyError.Email'));
    }
  };

  const validatePasswords = () => {
    if (password.length < 8 || confirmPassword.length < 8) {
      notifyError(getLocalString(translations, 'AddUpdateAdmin.NotifyError.PasswordLong'));
      setIsPasswordsValid(false);
    } else if (password !== confirmPassword) {
      notifyError(getLocalString(translations, 'AddUpdateAdmin.NotifyError.PasswordsNotMatch'));
      setIsPasswordsValid(false);
    } else setIsPasswordsValid(true);
  };
  const validatePhoneNumber = () => {
    const regex = /^[1-9]\d{1,14}$/;

    if (!regex.test(phone)) {
      notifyError(getLocalString(translations, 'AddUpdateAdmin.NotifyError.Phone'));
    }
  };

  const renderUserOptions = useCallback(
    () =>
      currentUserId &&
      userList
        .filter((el) => el.id != currentUserId)
        .map((el, i) => (
          <Option key={i} value={el.id}>
            {el.name}
          </Option>
        )),
    [userList, currentUserId]
  );

  return (
    <PageContainer
      pageName={
        id
          ? getLocalString(translations, 'AddUpdateAdmin.EditAdmin')
          : getLocalString(translations, 'AddUpdateAdmin.CreateAdmin')
      }
      backUrl={'/admin-list'}
    >
      <Row style={{ width: '100%' }}>
        <Col offset={6} span={12}>
          <Row gutter={[16, 16]}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAdmin.Name')}
              </Row>
              <Input
                maxLength={35}
                // disabled={!!id}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'AddUpdateAdmin.Placeholder.Name')}
                value={name || undefined}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>

            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAdmin.Surname')}
              </Row>
              <Input
                maxLength={35}
                // disabled={!!id}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'AddUpdateAdmin.Placeholder.Surname')}
                value={surname || undefined}
                onChange={(e) => {
                  setSurname(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAdmin.Phone')}
              </Row>
              <Space.Compact size='large' style={{ width: '100%' }}>
                <Select
                  style={{ width: '25%' }}
                  defaultValue={'+90'}
                  value={phonePrefix || '+90'}
                  options={countryList.map((el) => ({
                    value: el.phone_code,
                    label: `${el.phone_code} ${el.name}`,
                  }))}
                  onChange={(e) => {
                    setPhonePrefix(e);
                  }}
                  showSearch
                  filterOption={filterOption}
                />
                <Input
                  maxLength={35}
                  // disabled={!!id}
                  //style={{ width: '100%' }}
                  placeholder={getLocalString(translations, 'AddUpdateAdmin.Placeholder.Phone')}
                  value={phone || undefined}
                  onChange={(e) => {
                    setPhone(e.currentTarget.value);
                  }}
                  onKeyDown={(e) => {
                    if (!isNumeric(e.key) && e.key !== 'Backspace') e.preventDefault();
                  }}
                  onBlur={validatePhoneNumber}
                  size='large'
                ></Input>
              </Space.Compact>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAdmin.Email')}
              </Row>
              <Input
                maxLength={35}
                // disabled={!!id}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'AddUpdateAdmin.Placeholder.Email')}
                value={email || undefined}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
                onBlur={handleEmailBlur}
                size='large'
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAdmin.GroupedUsers')}
              </Row>
              <Select
                showSearch
                filterOption={filterOption}
                style={{ width: '100%' }}
                placeholder={getLocalString(
                  translations,
                  'AddUpdateAdmin.Placeholder.GroupedUsers'
                )}
                value={userTag || undefined}
                onChange={(e) => setUserTag(e)}
                size='large'
                mode='multiple'
              >
                {renderUserOptions()}
              </Select>
            </Row>

            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAdmin.Password')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'AddUpdateAdmin.Placeholder.Password')}
                size='large'
                value={password}
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                  setIsCreateDisabled(true);
                  setConfirmPassword('');
                }}
                // onBlur={validatePasswords}
              />
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAdmin.ConfirmPassword')}
              </Row>
              <Input
                maxLength={35}
                style={{ width: '100%' }}
                size='large'
                placeholder={getLocalString(translations, 'AddUpdateAdmin.Placeholder.Password')}
                value={confirmPassword}
                onChange={(e) => {
                  setIsCreateDisabled(true);
                  setConfirmPassword(e.currentTarget.value);
                  setIsPasswordsValid(false);
                }}
                onBlur={validatePasswords}
              />
            </Row>
          </Row>

          {!id ? (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                onClick={() => {
                  dispatch(
                    adminCreate({
                      name: name,
                      surname: surname,
                      phone: phone,
                      phone_code: phonePrefix,
                      email: email,
                      created_bys: userTag,
                      password: password,
                      password_confirmation: confirmPassword,

                      onSuccess: (data: any) => {
                        notifySuccess(
                          getLocalString(translations, 'AddUpdateAdmin.NotifySuccess.CreateAdmin')
                        );
                        navigate('/admin-list');
                      },
                    })
                  );
                }}
                size='large'
                type='primary'
                disabled={isCreateDisabled}
              >
                {getLocalString(translations, 'AddUpdateAdmin.CreateAdmin')}
              </Button>
            </Row>
          ) : (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                onClick={() => {
                  dispatch(
                    adminUpdate({
                      adminId: id,
                      name: name,
                      surname: surname,
                      phone: phone,
                      phone_code: phonePrefix,
                      email: email,
                      created_bys: userTag,
                      password: password,
                      password_confirmation: confirmPassword,

                      onSuccess: (data: any) => {
                        notifySuccess(
                          getLocalString(translations, 'AddUpdateAdmin.NotifySuccess.EditAdmin')
                        );
                        navigate('/admin-list');
                      },
                    })
                  );
                }}
                size='large'
                type='primary'
                disabled={isEditDisabled}
              >
                {getLocalString(translations, 'AddUpdateAdmin.EditAdmin')}
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </PageContainer>
  );
};

export default AddUpdateAdmin;
