export enum ActionTypes {
  GET_INTERVIEW_DETAIL = 'GET_INTERVIEW_DETAIL',
  CREATE_INTERVIEW = 'CREATE_INTERVIEW',
  CREATE_INTERVIEW_WITH_ADV = 'CREATE_INTERVIEW_WITH_ADV',
  GET_INTERVIEW_STATUS_LIST = 'GET_INTERVIEW_STATUS_LIST',
  UPDATE_INTERVIEW = 'UPDATE_INTERVIEW',
  CHECK_PASSPORT_NO = 'CHECK_PASSPORT_NO',
  CREATE_MEETING_NOTE = 'CREATE_MEETING_NOTE',
  APPOINTED_ADMIN_WORKER = 'APPOINTED_ADMIN_WORKER',
  GET_USER_INTERVIEW_DETAIL = 'GET_USER_INTERVIEW_DETAIL',
  GET_INTERVIEW_TYPE_COUNT = ' GET_INTERVIEW_TYPE_COUNT',
  GET_TOTAL_INTERVIEW_COUNT = 'GET_TOTAL_INTERVIEW_COUNT',
}
