import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { DEFAULT_LANGUAGE } from '../resources/constants/language';
import { setSpinner } from '../store/spinner';
import apiUrl from './apiURL';

import { store } from '../store/store';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { notifyError } from '../helpers/notify';

interface RequestConfig {
  headers:
    | {
        Authorization: string;
      }
    | any;
}

// if (
//   localStorage.getItem('appLanguage') === 'en-US' ||
//   localStorage.getItem('appLanguage') === 'tr-TR'
// ) {
//   localStorage.setItem('appLanguage', JSON.stringify(DEFAULT_LANGUAGE));
// }

const currentLanguage = JSON.parse(
  localStorage.getItem('appLanguage') || JSON.stringify(DEFAULT_LANGUAGE)
);
//localStorage.getItem('appLanguage') ||

const axios = Axios.create({
  timeout: 200000,
  headers: {
    'Content-type': 'application/json',
    'Accept-Language': currentLanguage.name,
  },
});

let numberOfRequest = 0;

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers) {
    // <- adding a check
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
  }

  return config;
});

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },

  async (error: AxiosError) => {
    if (error.response === undefined) {
      throw { detail: 'CORS ERROR' };
    } else {
      switch (error.response?.status) {
        case 401:
          localStorage.removeItem('access_token');
          // window.location.href = '/';

          // eğer oturum yoksa 401 eğer oturum var ve yetki yoksa 403 hatası dönecek bundan sonra
          notifyError('Please check your email or password. Login failed.');
          break;
        case 403:
          // window.location.href = '/';

          // eğer oturum yoksa 401 eğer oturum var ve yetki yoksa 403 hatası dönecek bundan sonra
          notifyError('You have no permission.');
          break;
        case 429:
          localStorage.removeItem('access_token');
          notifyError('Too many request');
          break;

        default:
          throw error.response.data;
      }
    }
  }
);

export default axios;
