import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Card, Statistic } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';
import CountUp from 'react-countup';
import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import {
  constantsSelector,
  getInterviewStatusList,
  getInterviewTypes,
} from '../../../store/constants';
import { apiURL } from '../../../service';
import { universitySelector } from '../../../store/university';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CustomizedTable from '../../../components/CustomizedTable';

import { detailInterviewList, getTotalInterviewCount } from '../../../store/interview';

const { Option } = Select;

const PreliminaryInterviewList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const { universityList, departmentList, programList, interviewStatusList } =
    useAppSelector(constantsSelector);

  const [interviewId, setInterviewId] = useState<string>('');
  const [DepartmentNameId, setWarehouseId] = useState<string>('');
  const [userDetails, setUserDetails] = useState<any>();

  const [languageId, setLanguageId] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);

  const [filters, setFilters] = useState<object>({});
  const [reload, setReload] = useState<boolean>(true);

  const tagColors = [
    'purple',
    'geekblue',
    'magenta',
    'red',
    'blue',
    'volcano',
    'orange',
    'green',
    'lime',
    'cyan',
  ];
  const columns = [
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.Columns.Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.Columns.Surname'),

      dataIndex: 'surname',
      key: 'surname',
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.Columns.Email'),
      dataIndex: 'email',
      key: 'Email',
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.Columns.Phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.Columns.AppointedPerson'),
      dataIndex: 'appointed_user',
      key: 'appointed_user',
      render: (e: any, record: any) => {
        return record.appointed_user?.name
          ? record.appointed_user?.name + ' ' + record.appointed_user?.surname
          : '-';
      },
    },
    {
      title: getLocalString(translations, 'PreliminaryInterviewList.Columns.Status'),
      dataIndex: 'status',
      key: 'status',
      render: (e: any, record: any) => {
        // Eğer status.name 26 karakterden uzunsa, kısalt
        const truncatedStatusName =
          record.status?.name.length > 26
            ? `${record.status?.name.substring(0, 26)}...`
            : record.status?.name;

        return <Tag color={tagColors[record.status?.id]}>{truncatedStatusName}</Tag>;
      },
    },
  ];

  //   {
  //     "id": 1,
  //     "name": "Discussed"
  // },
  // {
  //     "id": 2,
  //     "name": "Not Discussed"
  // },
  // {
  //     "id": 3,
  //     "name": "Not Interested"
  // },
  // {
  //     "id": 4,
  //     "name": "Interested"
  // },
  // {
  //     "id": 5,
  //     "name": "Missing or incorrect contact information"
  // },
  // {
  //     "id": 6,
  //     "name": "Meeting Later"
  // },
  // {
  //     "id": 7,
  //     "name": "Customer Defined"
  // },
  // {
  //     "id": 8,
  //     "name": "New"
  // },
  // {
  //     "id": 9,
  //     "name": "Waiting for registration"
  // },
  //  {
  //  "id": 10,
  //    "name": "Created"
  //}

  useEffect(() => {
    dispatch(getInterviewTypes());
    dispatch(getInterviewStatusList());
  }, []);
  const renderInterviewStatusOptions = useCallback(
    () => interviewStatusList.map((el: any) => ({ value: el.id, label: el.name })),
    [interviewStatusList]
  );

  useEffect(() => {
    let filter: any = {};
    let filterCount = 0;

    if (searchText) {
      filter.search_text = searchText;
    }
    if (interviewId) {
      filter.status = interviewId;
    }
    setFilterCount(filterCount);
    setFilters(filter);
  }, [searchText, interviewId]);

  interface IUserDetails {
    id: number;
    name: string;
    surname: string;
    username: string;
  }

  interface IDetailCount {
    [key: string]: number;
  }

  interface IUserData {
    'detail-count': IDetailCount;
    user: IUserDetails;
  }

  interface ITransformedData {
    key: string;
    username: string;
    surname: string;
    name: string;
    detailCounts: IDetailCount;
  }

  const transformDataForTable = (data: { [key: string]: IUserData }): ITransformedData[] => {
    const transformedData: ITransformedData[] = [];

    Object.entries(data).forEach(([key, value]) => {
      const userDetails = value.user;
      const detailCounts = value['detail-count'];

      transformedData.push({
        key: key,
        username: userDetails.username ? userDetails.username : '-',
        name: userDetails.name ? userDetails.name : '-',
        surname: userDetails.surname ? userDetails.surname : '-',
        detailCounts: detailCounts || [],
      });
    });

    return transformedData;
  };

  // Örnek kullanım

  return (
    <PageContainer
      pageName={getLocalString(translations, 'PreliminaryInterviewList.Title')}
      extra={
        <Button
          size='large'
          type='primary'
          // disabled={!isCreate}
          //isCreate False olursa disable olur
          onClick={() => navigate('/create-interview')}
        >
          {getLocalString(translations, 'PreliminaryInterviewList.CreateInterview')}
        </Button>
      }
    >
      <PageFilter
        count={filterCount}
        clear={() => {
          setInterviewId('');
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Select
              style={{ width: '100%' }}
              placeholder={getLocalString(translations, 'PreliminaryInterviewList.Status')}
              onChange={setInterviewId}
              allowClear
              showSearch
              value={interviewId || undefined}
              options={renderInterviewStatusOptions()}
              filterOption={filterOption}
            ></Select>
          </Col>
        </Row>
      </PageFilter>
      <Row style={{ marginBottom: '24px', marginTop: '24px' }}>
        <Search value={searchText} onChange={(value) => setSearchText(value)} />
      </Row>
      <Row style={{ marginTop: '24px' }}>
        <Row>
          <CustomizedTable
            columns={columns}
            data={[]}
            url={apiURL.preliminaryInterviewList}
            filter={filters}
            reload={reload}
            setReload={setReload}
            actions={[
              {
                action: (record) => {
                  navigate('/update-interview/' + record.id);
                },
                type: 'edit',
              },
              // {
              //   action: (record) => {
              //     navigate('/delete-interview/' + record.id);
              //   },
              //   type: 'delete',
              //   // hidden: (record: any) => !isUpdate ?? true,
              //   //isUpdate False olursa Saklanır
              // },
            ]}
          />
        </Row>
      </Row>
    </PageContainer>
  );
};

export default PreliminaryInterviewList;
