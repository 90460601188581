import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, Card, Input, DatePicker, Space } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import globalStyles from '../../../styles/global.module.scss';
import styles from './index.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import { constantsSelector } from '../../../store/constants';
import { apiURL } from '../../../service';
import { createDepartment, getDepartmentDetail, updateDepartment } from '../../../store/university';
import { useNavigate, useParams } from 'react-router-dom';
import isNumeric from '../../../helpers/isNumeric';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { notifyError, notifySuccess } from '../../../helpers/notify';
import { StyleInfo } from 'antd/es/theme/util/genComponentStyleHook';
import moment from 'moment';
import { adminCreate, agencyCreate, agencyUpdate, getAdmin, getAgency } from '../../../store/admin';
import { IAgencyInfo } from '../../../types/agency';

const { Option } = Select;

const AddUpdateAgency: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const {
    countryList,
    programList,
    applicationTypeList,
    genderList,
    universityList,
    semesterList,
  } = useAppSelector(constantsSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const [agencyName, setAgencyName] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [countryId, setCountryId] = useState<number>();

  const [address, setAddress] = useState<string>('');
  const [agencyPhone, setAgencyPhone] = useState<string>('');
  const [agencyPhonePrefix, setAgencyPhonePrefix] = useState<string>('+90');
  const [phone, setPhone] = useState<string>('');
  const [phonePrefix, setPhonePrefix] = useState<string>('+90');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [status, setStatus] = useState<string>('');

  const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(true);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true);
  const [isPasswordsValid, setIsPasswordsValid] = useState<boolean>(false);
  const [agencyInfo, setAgencyInfo] = useState<IAgencyInfo>();

  useEffect(() => {
    if (id) {
      dispatch(
        getAgency({
          id: id,
          onSuccess: (data: any) => {
            setAgencyName(data.data.agency_name);
            setName(data.data.name);
            setSurname(data.data.surname);
            setCountryId(Number(data.data.country_id));
            setEmail(data.data.email);
            setStatus(data.data.is_status);
            setAddress(data.data.agency_address);
            setAgencyPhone(data.data.agency_phone);
            setAgencyPhonePrefix(data.data.agency_phone_code);
            setPhone(data.data.phone);
            setPhonePrefix(data.data.phone_code);
            setAgencyInfo(data.data);
          },
        })
      );
    }
  }, [id]);

  useEffect(() => {
    if (
      agencyName !== '' &&
      name !== '' &&
      surname !== '' &&
      countryId !== undefined &&
      email !== '' &&
      address !== '' &&
      phone !== '' &&
      phonePrefix !== '' &&
      agencyPhone !== '' &&
      agencyPhonePrefix !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      isPasswordsValid
    )
      setIsCreateDisabled(false);
    else setIsCreateDisabled(true);

    if (
      id &&
      agencyName.length > 0 &&
      name.length > 0 &&
      surname.length > 0 &&
      countryId &&
      email.length > 0 &&
      phone.length &&
      phonePrefix.length &&
      address.length &&
      agencyPhone.length > 0 &&
      agencyPhonePrefix.length > 0 &&
      (password.length > 0
        ? isPasswordsValid
        : confirmPassword.length > 0
        ? isPasswordsValid
        : true)
    ) {
      if (
        agencyName !== agencyInfo?.agency_name ||
        name !== agencyInfo?.name ||
        surname !== agencyInfo?.surname ||
        countryId != agencyInfo.country_id ||
        email !== agencyInfo?.email ||
        address !== agencyInfo.agency_address ||
        phone !== agencyInfo?.phone ||
        phonePrefix !== agencyInfo?.phone_code ||
        agencyPhone !== agencyInfo?.agency_phone ||
        agencyPhonePrefix !== agencyInfo?.agency_phone_code ||
        (password.length > 0
          ? isPasswordsValid
          : confirmPassword.length > 0
          ? isPasswordsValid
          : isPasswordsValid)
      ) {
        setIsEditDisabled(false);
      } else {
        setIsEditDisabled(true);
      }
    } else {
      setIsEditDisabled(true);
    }
  }, [
    name,
    surname,
    countryId,
    address,
    phone,
    phonePrefix,
    agencyPhone,
    agencyPhonePrefix,
    email,
    password,
    agencyName,
    confirmPassword,
    password,
    isPasswordsValid,
  ]);
  const renderCountryOptions = useCallback(
    () =>
      countryList.map((el, i) => (
        <Option key={i} value={el.id}>
          {el.name}
        </Option>
      )),
    [countryList]
  );
  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailBlur = () => {
    if (email && !validateEmail(email)) {
      notifyError('Email is invalid');
    }
  };

  const validatePasswords = () => {
    if (password.length < 8 || confirmPassword.length < 8) {
      notifyError('Password must be at least 8 characters long.');
      setIsPasswordsValid(false);
    } else if (password !== confirmPassword) {
      notifyError('Passwords do not match.');
      setIsPasswordsValid(false);
    } else setIsPasswordsValid(true);
  };
  const validatePhoneNumber = (phone: any) => {
    const regex = /^[1-9]\d{1,14}$/;

    if (!regex.test(phone)) {
      notifyError('Please enter a valid international phone number.');
    }
  };

  return (
    <PageContainer
      pageName={
        id
          ? getLocalString(translations, 'AddUpdateAgency.UpdateAgency')
          : getLocalString(translations, 'AddUpdateAgency.CreateAgency')
      }
      backUrl={'/agency-list'}
    >
      <Row style={{ width: '100%' }}>
        <Col offset={6} span={12}>
          <Row gutter={[16, 16]}>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAgency.AgencyName')}
              </Row>
              <Input
                maxLength={35}
                // disabled={!!id}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'AddUpdateAgency.PlaceHolder.Name')}
                value={agencyName || undefined}
                onChange={(e) => {
                  setAgencyName(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAgency.Name')}
              </Row>
              <Input
                maxLength={35}
                // disabled={!!id}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'AddUpdateAgency.PlaceHolder.Name')}
                value={name || undefined}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>

            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAgency.Surname')}
              </Row>
              <Input
                maxLength={35}
                // disabled={!!id}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'AddUpdateAgency.PlaceHolder.Surname')}
                value={surname || undefined}
                onChange={(e) => {
                  setSurname(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAgency.Country')}
              </Row>
              <Select
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'AddUpdateAgency.PlaceHolder.Country')}
                value={countryId || undefined}
                onChange={(e) => {
                  setCountryId(e);
                }}
                size='large'
                showSearch
                filterOption={filterOption}
              >
                {renderCountryOptions()}
              </Select>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAgency.Phone')}
              </Row>
              <Space.Compact size='large' style={{ width: '100%' }}>
                <Select
                  style={{ width: '25%' }}
                  defaultValue={'+90'}
                  value={phonePrefix || '+90'}
                  options={countryList.map((el) => ({
                    value: el.phone_code,
                    label: `${el.phone_code} ${el.name}`,
                  }))}
                  onChange={(e) => {
                    setPhonePrefix(e);
                  }}
                  showSearch
                  filterOption={filterOption}
                />
                <Input
                  maxLength={35}
                  // disabled={!!id}
                  //style={{ width: '100%' }}
                  placeholder={getLocalString(translations, 'AddUpdateAgency.PlaceHolder.Phone')}
                  value={phone || undefined}
                  onChange={(e) => {
                    setPhone(e.currentTarget.value);
                  }}
                  onKeyDown={(e) => {
                    if (!isNumeric(e.key) && e.key !== 'Backspace') e.preventDefault();
                  }}
                  onBlur={() => validatePhoneNumber(phone)}
                  size='large'
                ></Input>
              </Space.Compact>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAgency.AgencyPhone')}
              </Row>
              <Space.Compact size='large' style={{ width: '100%' }}>
                <Select
                  style={{ width: '25%' }}
                  defaultValue={'+90'}
                  value={agencyPhonePrefix || '+90'}
                  options={countryList.map((el) => ({
                    value: el.phone_code,
                    label: `${el.phone_code} ${el.name}`,
                  }))}
                  onChange={(e) => {
                    setAgencyPhonePrefix(e);
                  }}
                  showSearch
                  filterOption={filterOption}
                />
                <Input
                  maxLength={35}
                  // disabled={!!id}
                  //style={{ width: '100%' }}
                  placeholder={getLocalString(
                    translations,
                    'AddUpdateAgency.PlaceHolder.AgencyPhone'
                  )}
                  value={agencyPhone || undefined}
                  onChange={(e) => {
                    setAgencyPhone(e.currentTarget.value);
                  }}
                  onKeyDown={(e) => {
                    if (!isNumeric(e.key) && e.key !== 'Backspace' && e.key !== '+')
                      e.preventDefault();
                  }}
                  onBlur={() => validatePhoneNumber(agencyPhone)}
                  size='large'
                ></Input>
              </Space.Compact>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAgency.Address')}
              </Row>
              <Input
                maxLength={200}
                // disabled={!!id}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'AddUpdateAgency.PlaceHolder.Address')}
                value={address || undefined}
                onChange={(e) => {
                  setAddress(e.currentTarget.value);
                }}
                size='large'
              ></Input>
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAgency.Email')}
              </Row>
              <Input
                maxLength={35}
                // disabled={!!id}
                style={{ width: '100%' }}
                placeholder={getLocalString(translations, 'AddUpdateAgency.PlaceHolder.Email')}
                value={email || undefined}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
                onBlur={handleEmailBlur}
                size='large'
              ></Input>
            </Row>

            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAgency.Password')}
              </Row>
              <Input
                maxLength={35}
                // disabled={!!id}
                style={{ width: '100%' }}
                placeholder={
                  !!id ? '' : getLocalString(translations, 'AddUpdateAgency.PlaceHolder.Password')
                }
                size='large'
                value={password}
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                  setIsCreateDisabled(true);
                  setConfirmPassword('');
                }}
                // onBlur={validatePasswords}
              />
            </Row>
            <Row style={{ width: '100%', gap: '5px' }}>
              <Row className={`${globalStyles.label_r_large} ${styles.label_color}`}>
                {getLocalString(translations, 'AddUpdateAgency.Password')}
              </Row>
              <Input
                maxLength={35}
                // disabled={!!id}
                style={{ width: '100%' }}
                placeholder={
                  !!id ? '' : getLocalString(translations, 'AddUpdateAgency.PlaceHolder.Password')
                }
                size='large'
                value={confirmPassword}
                onChange={(e) => {
                  setIsCreateDisabled(true);
                  setConfirmPassword(e.currentTarget.value);
                  setIsPasswordsValid(false);
                }}
                onBlur={validatePasswords}
              />
            </Row>
          </Row>

          {/* {id && (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button onClick={() => {}} size='large' type='primary' disabled={isEditDisabled}>
                Edit Admin
              </Button>
            </Row>
          )} */}

          {!id ? (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                onClick={() => {
                  countryId &&
                    dispatch(
                      agencyCreate({
                        agency_name: agencyName,
                        name: name,
                        surname: surname,
                        agency_address: address,
                        phone: phone,
                        phone_code: phonePrefix,
                        country_id: countryId.toString(),
                        agency_phone: agencyPhone,
                        agency_phone_code: agencyPhonePrefix,
                        email: email,
                        password: password,
                        password_confirmation: confirmPassword,
                        onSuccess: (data: any) => {
                          notifySuccess(
                            getLocalString(
                              translations,
                              'AddUpdateAgency.NotifySuccess.CreateAgency'
                            )
                          );
                          navigate('/agency-list');
                        },
                      })
                    );
                }}
                size='large'
                type='primary'
                disabled={isCreateDisabled}
              >
                {getLocalString(translations, 'AddUpdateAgency.CreateAgency')}
              </Button>
            </Row>
          ) : (
            <Row style={{ width: '100%', marginTop: '24px' }} justify={'end'}>
              <Button
                onClick={() => {
                  id &&
                    countryId &&
                    dispatch(
                      agencyUpdate({
                        agency_id: id,
                        agency_name: agencyName,
                        name: name,
                        surname: surname,
                        country_id: countryId.toString(),
                        agency_address: address,
                        phone: phone,
                        phone_code: phonePrefix,
                        agency_phone: agencyPhone,
                        agency_phone_code: agencyPhonePrefix,
                        email: email,
                        password: password,
                        password_confirmation: confirmPassword,
                        onSuccess: (data: any) => {
                          notifySuccess(
                            getLocalString(
                              translations,
                              'AddUpdateAgency.NotifySuccess.UpdateAgency'
                            )
                          );
                          navigate('/agency-list');
                        },
                      })
                    );
                }}
                size='large'
                type='primary'
                disabled={isEditDisabled}
                style={!isCreate ? { display: 'none' } : {}}
              >
                {getLocalString(translations, 'AddUpdateAgency.UpdateAgency')}
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </PageContainer>
  );
};

export default AddUpdateAgency;
