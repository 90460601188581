import React from 'react';
import { Row, Col, Space } from 'antd';
import styles from './uniteamFooter.module.scss';
import { FooterProps } from './uniteamFooter-types';
import UniteamLinkButton from '../uniteamLinkButton';

const UniteamFooter: React.FC<FooterProps> = ({ menuItems, copyright = false }) => {
  return (
    <footer className={`${styles.footer} `}>
      <Row align='middle'>
        <Col span={16}>
          <Space size={'large'} className={styles.footer__menu}>
            {menuItems.map((menu, m) => (
              <UniteamLinkButton href={menu.href} key={m}>
                {menu.label}
              </UniteamLinkButton>
            ))}
          </Space>
        </Col>
        {copyright && (
          <Col span={8}>
            <div className={styles.footer__copyright}>
              <span>© 2023 uniteam.co</span>
              {/* <svg
                width='270'
                height='54'
                viewBox='0 0 270 54'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M11.6016 22.7419C11.6016 25.6994 13.9979 28.0957 16.9554 28.0957H25.8145H32.4952C32.9837 25.6102 32.9408 22.9399 32.076 20.1541C30.1088 13.8332 24.5041 9.17257 17.9093 8.58504C7.52185 7.65753 -1.08647 16.4243 0.111697 26.8612C0.969889 34.3539 7.02344 40.3843 14.5194 41.2194C21.8107 42.0314 28.2571 38.0507 31.1617 32.0401H23.3291C21.2463 34.0667 18.2789 35.1923 15.0607 34.7665C10.6014 34.1789 7.03334 30.5316 6.53493 26.0624C5.86488 20.0617 10.542 14.9819 16.4074 14.9819C21.144 14.9819 25.1016 18.2991 26.0951 22.7353L11.6016 22.7419Z'
                  fill='#ED6994'
                />
                <path
                  d='M68.4883 22.7419C68.4883 25.6994 70.8846 28.0957 73.8421 28.0957H82.7012H89.3819C89.8704 25.6102 89.8275 22.9399 88.9627 20.1541C86.9955 13.8332 81.3909 9.17257 74.796 8.58504C64.4086 7.65753 55.8002 16.4243 56.9984 26.8612C57.8566 34.3539 63.9102 40.3843 71.4061 41.2194C78.6975 42.0314 85.1438 38.0507 88.0484 32.0401H80.2125C78.1297 34.0667 75.1624 35.1923 71.9441 34.7665C67.4849 34.1789 63.9168 30.5316 63.4183 26.0624C62.7483 20.0617 67.4254 14.9819 73.2908 14.9819C78.0274 14.9819 81.985 18.2991 82.9785 22.7353L68.4883 22.7419Z'
                  fill='#ED6994'
                />
                <path
                  d='M61.4137 0.732422H57.6476C55.1918 0.732422 52.9539 2.13854 51.8878 4.35003L44.504 19.6489L37.1731 4.45565C36.0739 2.18145 33.77 0.732422 31.245 0.732422H27.5977L43.3455 31.0563C43.56 31.4722 43.9891 31.7296 44.4545 31.7296H44.6129C45.042 31.7296 45.4348 31.492 45.6329 31.1091L61.4137 0.732422Z'
                  fill='#ED6994'
                />
                <path
                  d='M117.841 8.60172L104.978 21.4647L122.126 38.5856H116.059L101.958 24.4849L98.5881 27.8549V38.5856H94.3005V26.2904H94.0859L94.3005 26.0758V0H98.5881V21.8146L111.775 8.60172H117.841Z'
                  fill='#EDA0A8'
                />
                <path
                  d='M147.267 8.62891H151.555V38.5864H147.267V34.0578C144.33 37.0779 140.768 38.5864 136.563 38.5864C132.437 38.5864 128.932 37.1308 125.994 34.1898C123.057 31.2521 121.598 27.7468 121.598 23.6208C121.598 19.4949 123.053 15.9631 125.994 13.0255C128.932 10.0878 132.437 8.62891 136.563 8.62891C140.768 8.62891 144.33 10.1373 147.267 13.1575V8.62891ZM144.138 31.1993C146.215 29.0968 147.267 26.5618 147.267 23.6241C147.267 20.6568 146.215 18.1251 144.138 16.049C142.062 13.9728 139.527 12.9199 136.563 12.9199C133.626 12.9199 131.091 13.9728 128.988 16.049C126.912 18.1251 125.859 20.6601 125.859 23.6241C125.859 26.5618 126.912 29.0968 128.988 31.1993C131.091 33.2755 133.626 34.3284 136.563 34.3284C139.531 34.3251 142.062 33.2755 144.138 31.1993Z'
                  fill='#EDA0A8'
                />
                <path
                  d='M160.679 13.1608C163.617 10.1406 167.205 8.63221 171.384 8.63221V12.9199C168.446 12.9199 165.911 13.9728 163.808 16.0754C161.732 18.1515 160.679 20.6865 160.679 23.6241V38.6161H156.418V8.62891H160.679V13.1608Z'
                  fill='#EDA0A8'
                />
                <path
                  d='M176.246 4.28765V0H180.534V4.28765H176.246ZM176.246 38.5856V8.62812H180.534V38.5856H176.246Z'
                  fill='#EDA0A8'
                />
                <path
                  d='M207.803 46.4322C209.988 44.2735 211.064 41.6594 211.064 38.5864V34.0578C208.127 37.0779 204.565 38.5864 200.36 38.5864C196.234 38.5864 192.729 37.1308 189.791 34.1898C186.853 31.2521 185.395 27.7468 185.395 23.6208V8.62891H189.682V23.6208C189.682 26.5585 190.735 29.0935 192.811 31.196C194.887 33.2722 197.422 34.3251 200.36 34.3251C203.327 34.3251 205.859 33.2722 207.935 31.196C210.011 29.0935 211.064 26.5585 211.064 23.6208V8.62891H215.352V38.5864C215.352 42.8212 213.844 46.4586 210.85 49.4524C207.856 52.4726 204.215 53.981 199.984 53.981C195.749 53.981 192.112 52.4726 189.091 49.4788L192.112 46.4322C194.297 48.6173 196.911 49.6933 199.984 49.6933C203.03 49.6966 205.645 48.6173 207.803 46.4322Z'
                  fill='#EDA0A8'
                />
                <path
                  d='M245.776 13.0255C248.713 15.9631 250.172 19.4982 250.172 23.6208C250.172 24.2678 250.12 24.9147 249.984 25.5353C249.984 25.6178 249.984 25.6706 249.958 25.7498H224.72C225.258 28.2584 226.499 30.3081 228.414 31.9255C230.355 33.5165 232.619 34.3251 235.18 34.3251C238.659 34.3251 241.518 32.8959 243.7 30.0375H248.661C245.64 35.7279 241.138 38.5864 235.18 38.5864C231.054 38.5864 227.549 37.1308 224.611 34.1898C221.674 31.2521 220.215 27.7468 220.215 23.6208C220.215 19.4949 221.67 15.9631 224.611 13.0255C227.549 10.0878 231.054 8.62891 235.18 8.62891C239.306 8.62891 242.838 10.0845 245.776 13.0255ZM228.411 15.3426C226.496 16.9336 225.255 18.9833 224.717 21.4622H245.667C245.129 18.98 243.888 16.9336 241.947 15.3426C240.032 13.7252 237.768 12.9166 235.18 12.9166C232.619 12.9166 230.355 13.7252 228.411 15.3426Z'
                  fill='#EDA0A8'
                />
                <path
                  d='M259.293 13.1608C262.23 10.1406 265.818 8.63221 269.997 8.63221V12.9199C267.059 12.9199 264.524 13.9728 262.422 16.0754C260.345 18.1515 259.293 20.6865 259.293 23.6241V38.6161H255.031V8.62891H259.293V13.1608Z'
                  fill='#EDA0A8'
                />
              </svg> */}
            </div>
          </Col>
        )}
      </Row>
    </footer>
  );
};

export default UniteamFooter;
