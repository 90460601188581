import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from './actions-types';
import { IActionCallback } from '../types';
import { apiURL, axiosClient } from '../../service';
import { notifyError } from '../../helpers/notify';
import { setSpinner } from '../spinner';
export const getUpcomingDeadlines = createAsyncThunk(
  ActionTypes.GET_UPCOMING_DEADLINES_EXPORT,
  async (_, { dispatch }) => {
    dispatch(setSpinner(true));

    try {
      const response = await axiosClient.get(apiURL.getUpcomingDeadlinesExcel, {
        responseType: 'blob', // Excel dosyasını bir blob olarak almak için responseType'u ayarlayın
      });

      // Blob'u bir URL'ye dönüştürme ve kullanıcıya indirme bağlantısı sunma
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // İçerik-dağıtımı başlığını ayrıştırma ve dosya adını alma
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'upcoming-deadlines.xlsx'; // Varsayılan dosya adı
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (fileNameMatch && fileNameMatch[1]) {
          // Eşleşme bulunursa ve grup 1 (dosya adı) mevcutsa
          filename = fileNameMatch[1].replace(/['"]/g, ''); // Alıntı işaretlerini kaldır
        }
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Bağlantıyı ve URL'yi temizleme
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch(setSpinner(false));
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
export const getExceedDeadlines = createAsyncThunk(
  ActionTypes.GET_EXCEED_DEADLINES_EXPORT,
  async (_, { dispatch }) => {
    dispatch(setSpinner(true));

    try {
      const response = await axiosClient.get(apiURL.getExceededDeadlinesExcel, {
        responseType: 'blob', // Excel dosyasını bir blob olarak almak için responseType'u ayarlayın
      });

      // Blob'u bir URL'ye dönüştürme ve kullanıcıya indirme bağlantısı sunma
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // İçerik-dağıtımı başlığını ayrıştırma ve dosya adını alma
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'exceed-deadlines.xlsx'; // Varsayılan dosya adı
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (fileNameMatch && fileNameMatch[1]) {
          // Eşleşme bulunursa ve grup 1 (dosya adı) mevcutsa
          filename = fileNameMatch[1].replace(/['"]/g, ''); // Alıntı işaretlerini kaldır
        }
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Bağlantıyı ve URL'yi temizleme
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch(setSpinner(false));
    } catch (error: any) {
      dispatch(setSpinner(false));

      if (error && error.errors) {
        const errors: Record<string, string[]> = error.errors;
        Object.entries(errors).forEach(([field, messages]: [string, string[]]) => {
          messages.forEach((message: string) => {
            // notifyError(`${field}: ${message}`);
            notifyError(`${message}`);
          });
        });
      } else if (error && error.message) {
        // Genel bir hata mesajı
        notifyError(error.message);
      } else {
        // Genel bir hata mesajı
        notifyError('An error occurred while processing your request.');
      }
    }
  }
);
