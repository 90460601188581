import React, { useEffect, useState } from 'react';

import styles from './index.module.scss';
import globalStyles from '../../styles/global.module.scss';
import PageContainer from '../../components/pageContainer';
import { Button, Col, Divider, Input, Row, Tag } from 'antd';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getTrackingCodeDetail } from '../../store/application';
import { languageSelector } from '../../store/language';
import { getLocalString } from '../../helpers/language';

const TrackingCode: React.FC = () => {
  const dispatch = useAppDispatch();

  const { appLanguage, translations } = useAppSelector(languageSelector);
  const [identity_no, setIdentity_no] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  useEffect(() => {
    const handleResize = () => {
      // User agent ile mobil cihaz kontrolü yapılıyor.
      const isMobileView = isMobileDevice() || window.innerWidth < 768;
      setIsMobile(isMobileView);
    };

    // İlk render'da ve her resize olayında çalışacak
    handleResize();
    window.addEventListener('resize', handleResize);

    // Komponent unmount olduğunda temizlik işlemi yapılıyor.
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <PageContainer pageName={getLocalString(translations, 'Tracking.Title')}>
      <Row gutter={isMobile ? 0 : 8}>
        <Col span={isMobile ? 24 : 4}>
          <Input
            maxLength={35}
            placeholder={getLocalString(translations, 'Tracking.Placeholder.No')}
            type='text'
            size='large'
            value={identity_no || undefined}
            onChange={(e) => {
              setIdentity_no(e.target.value.trim());
            }}
          ></Input>
        </Col>
        <Col span={isMobile ? 24 : 4} style={isMobile ? { marginTop: '24px' } : {}}>
          <Button
            disabled={!identity_no}
            type='primary'
            size='large'
            onClick={() => {
              dispatch(
                getTrackingCodeDetail({
                  id: identity_no,
                  onSuccess: (data: any) => {
                    setName(data.data.name);
                    setSurname(data.data.surname);
                    setStatus(data.data.status);
                  },
                })
              );
            }}
          >
            {getLocalString(translations, 'Tracking.Search')}
          </Button>
        </Col>
      </Row>

      {name && (
        <>
          <Row style={{ width: '100%' }} gutter={[16, 16]}>
            <Divider></Divider>
            <Row style={{ width: '100%' }}>
              <span className={styles.label_color}>
                {getLocalString(translations, 'Tracking.Name')}:
              </span>
              &nbsp; {name} &nbsp; &nbsp;
              <span className={styles.label_color}>
                {getLocalString(translations, 'Tracking.Surname')}:
              </span>
              &nbsp; {surname}
            </Row>
            <Divider></Divider>
            <Row style={{ width: '100%' }} align={'middle'}>
              <span className={styles.label_color}>
                {getLocalString(translations, 'Tracking.ApplicationStatus')}
              </span>
              &nbsp;
              <Tag>{status}</Tag>
            </Row>
          </Row>
        </>
      )}
    </PageContainer>
  );
};

export default TrackingCode;
