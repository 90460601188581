import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Collapse, Row, Col, Tag, DatePicker } from 'antd';
import { EditOutlined, MailOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './index.module.scss';
import globalStyles from './../../../styles/global.module.scss';

import PageContainer from '../../../components/pageContainer';

// import { getUserRoles, userSelector } from '../../../store/user';
import filterOption from '../../../helpers/filterOption';
// import { UserStatus } from '../../../types/user';
import Search from '../../../components/Search';
import { menuSelector } from '../../../store/menu';
import { getLocalString } from '../../../helpers/language';
import { languageSelector } from '../../../store/language';
import PageFilter from '../../../components/pageFilter';
import {
  constantsSelector,
  getFaculties,
  getFacultyDepartments,
  getInterviewTypes,
  getUniversities,
  getUniversityFaculties,
} from '../../../store/constants';
import { apiURL } from '../../../service';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { IDepartmentTypes } from '../../../types/constants';
import { notifyError } from '../../../helpers/notify';
import { getExceedDeadlines } from '../../../store/deadlines';
import { deleteApplication, getApplicationListExport } from '../../../store/application';
import CustomizedTable from '../../../components/CustomizedTable';
import { accountSelector } from '../../../store/account';

const ApplicationsList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const { userRoles } = useAppSelector(userSelector);
  // const { AccountProfileStatus } = useAppSelector(constantsSelector);
  const { account } = useAppSelector(accountSelector);
  const {
    is_view: isView,
    is_create: isCreate,
    is_update: isUpdate,
    is_delete: isDelete,
  } = useAppSelector(menuSelector);
  const { appLanguage, translations } = useAppSelector(languageSelector);
  const {
    countryList,
    programList,
    applicationTypeList,
    genderList,
    universityList,
    semesterList,
  } = useAppSelector(constantsSelector);
  const [countryId, setCountryId] = useState<string>('');
  const [nationId, setNationId] = useState<string>('');
  const [universityId, setUniversityId] = useState<string>('');
  const [departmentId, setDepartmentId] = useState<string>('');

  const [semesterId, setSemesterId] = useState<string>();
  const [facultyId, setFacultyId] = useState<string>('');
  const [facultyList, setFacultyList] = useState<IDepartmentTypes[]>([]);

  const [departmentList, setDepartmentList] = useState<IDepartmentTypes[]>([]);

  const [reload, setReload] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [filterCount, setFilterCount] = useState<number>(0);

  const [filters, setFilters] = useState<object>();
  const tagColors = [
    'geekblue',
    'green',

    'cyan',
    'lime',
    'magenta',
    'red',
    'blue',
    'volcano',
    'orange',

    'purple',
  ];
  useEffect(() => {
    if (universityId)
      dispatch(
        getUniversityFaculties({
          universityID: universityId,
          onSuccess: (data: any) => {
            setFacultyList(data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  }, [universityId]);
  useEffect(() => {
    if (universityId)
      dispatch(
        getFacultyDepartments({
          faculty_id: facultyId,
          onSuccess: (data: any) => {
            setDepartmentList(data.data);
          },
          onError: (err) => {
            notifyError(err);
          },
        })
      );
  }, [facultyId]);

  const renderUniversityOptions = useCallback(
    () => universityList.map((el: any) => ({ value: el.id, label: el.name })),
    [universityList]
  );

  const renderDepartmentOptions = useCallback(
    () => departmentList.map((el: any) => ({ value: el.id, label: el.name })),
    [departmentList]
  );
  const renderFacultyOptions = useCallback(
    () => facultyList.map((el: any) => ({ value: el.id, label: el.name })),
    [facultyList]
  );
  const renderSemesterOptions = useCallback(
    () => semesterList.map((el: any) => ({ value: el.id, label: el.name })),
    [semesterList]
  );

  useEffect(() => {
    dispatch(getFaculties());
    dispatch(getUniversities());
    dispatch(getInterviewTypes());
  }, []);
  useEffect(() => {
    let filter: any = {};
    let filterCount = 0;
    if (countryId) {
      filter.country_id = countryId;
      filterCount++;
    }
    if (nationId) {
      filter.nation_id = nationId;
      filterCount++;
    }
    if (universityId) {
      filter.university_id = universityId;
      filterCount++;
    }
    if (universityId) {
      filter.faculty_id = facultyId;
      filterCount++;
    }
    if (departmentId) {
      filter.department_id = departmentId;
      filterCount++;
    }
    if (semesterId) {
      filter.semester_id = semesterId;
      filterCount++;
    }

    if (searchText) {
      filter.search_text = searchText;
    }
    setFilterCount(filterCount);
    setFilters(filter);
  }, [searchText, universityId, departmentId, nationId, countryId, semesterId, facultyId]);

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Name'),
      key: 'name',
      dataIndex: 'name',
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Agency'),
      key: 'agency',
      dataIndex: 'agency',
      render: (e: any, record: any) =>
        record.agency.agency_name ? (
          <div className={globalStyles.waiting_label}>
            {record.agency.agency_name.substring(0, 20)}
          </div>
        ) : (
          <div className={globalStyles.preview_label}>{record.agency}</div>
        ),
      width: 120,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Surname'),
      key: 'surname',
      dataIndex: 'surname',
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Nation'),
      dataIndex: 'nation',
      key: 'nation',
      render: (e: any, record: any) => {
        return record.nation?.name;
      },
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.University'),
      dataIndex: 'university',
      key: 'university',
      render: (e: any, record: any) => {
        return record.university?.name;
      },
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Faculty'),
      dataIndex: 'faculty',
      key: 'faculty',
      render: (e: any, record: any) => {
        return record.faculty?.name;
      },
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Department'),
      dataIndex: 'department',
      key: 'department',
      render: (e: any, record: any) => {
        return record.department?.name;
      },
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Semester'),
      dataIndex: 'department',
      key: 'department',
      render: (e: any, record: any) => {
        return record.semester?.name;
      },
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Status'),
      dataIndex: 'application_status',
      key: 'application_status',
      render: (e: any, record: any) => {
        return <Tag style={{ whiteSpace: 'normal' }}>{record?.application_status?.name}</Tag>;
      },
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Country'),
      dataIndex: 'country',
      key: 'country',
      render: (e: any, record: any) => {
        return record.country?.name;
      },
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.School'),
      dataIndex: 'school',
      key: 'school',
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.CreatedDate'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (e: any, record: any) => dayjs(record.created_at).format('YYYY-MM-DD'),
      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.DeadlineDate'),
      dataIndex: 'deadline_date',
      key: 'deadline_date',
      render: (e: any, record: any) =>
        record.deadline_date ? dayjs(record.deadline_date).format('YYYY-MM-DD') : '-',
      width: 100,
    },

    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: 100,
    },

    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Type'),
      dataIndex: 'application_type',
      key: 'application_type',
      render: (e: any, record: any) => {
        return (
          <Tag color={tagColors[record.application_type?.id]}>{record.application_type?.name}</Tag>
        );
      },

      width: 100,
    },
    {
      title: getLocalString(translations, 'ApplicationsList.Columns.Password'),
      dataIndex: 'pin',
      key: 'pin',
      width: 100,
    },
  ];

  return (
    <PageContainer
      pageName={getLocalString(translations, 'ApplicationsList.Title')}
      extra={
        <Button
          onClick={() => {
            dispatch(getApplicationListExport());
          }}
          type='primary'
          size='large'
        >
          {getLocalString(translations, 'ApplicationsList.ExportExcel')}
        </Button>
      }
    >
      <PageFilter
        count={filterCount}
        clear={() => {
          setCountryId('');
          setNationId('');
          setUniversityId('');
          setFacultyId('');
          setDepartmentId('');
          setSemesterId(undefined);
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={getLocalString(
                translations,
                'ApplicationsList.Placeholder.SelectUniversity'
              )}
              value={universityId || undefined}
              onChange={(e) => {
                setUniversityId(e);
                setFacultyId('');
                setDepartmentId('');
              }}
              showSearch
              filterOption={filterOption}
              options={renderUniversityOptions()}
            ></Select>
          </Col>
          <Col span={8}>
            <Select
              disabled={!universityId}
              style={{ width: '100%' }}
              value={facultyId || undefined}
              placeholder={getLocalString(
                translations,
                'ApplicationsList.Placeholder.SelectFaculty'
              )}
              onChange={(value) => {
                setFacultyId(value);
                setDepartmentId('');
              }}
              showSearch
              filterOption={filterOption}
              options={renderFacultyOptions()}
            ></Select>
          </Col>
          <Col span={8}>
            <Select
              allowClear
              showSearch
              filterOption={filterOption}
              value={departmentId || undefined}
              disabled={!facultyId}
              onChange={(e) => {
                setDepartmentId(e);
              }}
              style={{ width: '100%' }}
              placeholder={getLocalString(
                translations,
                'ApplicationsList.Placeholder.SelectDepartment'
              )}
              options={renderDepartmentOptions()}
            ></Select>
          </Col>
        </Row>

        <Row gutter={[8, 8]} style={{ marginTop: '8px' }}>
          <Col span={8}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={getLocalString(
                translations,
                'ApplicationsList.Placeholder.SelectCountry'
              )}
              value={countryId || undefined}
              onChange={(e) => {
                setCountryId(e);
              }}
              showSearch
              filterOption={filterOption}
              options={countryList.map((el) => ({
                value: el.id,
                label: el.name,
              }))}
            ></Select>
          </Col>
          <Col span={8}>
            <Select
              style={{ width: '100%' }}
              value={nationId || undefined}
              placeholder={getLocalString(
                translations,
                'ApplicationsList.Placeholder.SelectNation'
              )}
              onChange={setNationId}
              allowClear
              showSearch
              filterOption={filterOption}
              options={countryList.map((el) => ({
                value: el.id,
                label: el.name,
              }))}
            ></Select>
          </Col>
          <Col span={8}>
            <Select
              showSearch
              filterOption={filterOption}
              style={{ width: '100%' }}
              value={semesterId || undefined}
              placeholder={getLocalString(
                translations,
                'ApplicationsList.Placeholder.SelectSemester'
              )}
              onChange={(e) => {
                setSemesterId(e);
              }}
              options={renderSemesterOptions()}
              allowClear
            ></Select>
          </Col>
        </Row>
      </PageFilter>

      <Row style={{ marginTop: '24px' }}>
        <Row style={{ marginBottom: '24px' }}>
          <Search value={searchText} onChange={(value) => setSearchText(value)} />
        </Row>

        <div style={{ width: '100%' }}>
          <CustomizedTable
            columns={columns}
            data={[]}
            url={apiURL.getApplications}
            filter={filters}
            scrollX={1000}
            scrollY={500}
            reload={reload}
            setReload={setReload}
            actions={[
              {
                action: (record) => {
                  navigate('/applies-detail/' + record.id);
                },
                type: 'detail',
                // hidden: (record: any) => record.status === true,
              },
              {
                action: (record) => {
                  dispatch(
                    deleteApplication({
                      id: record.id,
                      onSuccess: (data: any) => {
                        setReload(true);
                      },
                    })
                  );
                },
                type: 'delete',
                hidden: (record) => (account.role.id === 1 ? false : true),
              },
            ]}
          />
        </div>
      </Row>
    </PageContainer>
  );
};

export default ApplicationsList;
